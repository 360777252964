/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CarrierChequeInformation } from './CarrierChequeInformation';
import {
    CarrierChequeInformationFromJSON,
    CarrierChequeInformationFromJSONTyped,
    CarrierChequeInformationToJSON,
} from './CarrierChequeInformation';
import type { CarrierPaymentStrategy } from './CarrierPaymentStrategy';
import {
    CarrierPaymentStrategyFromJSON,
    CarrierPaymentStrategyFromJSONTyped,
    CarrierPaymentStrategyToJSON,
} from './CarrierPaymentStrategy';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { MissingInvoiceStrategy } from './MissingInvoiceStrategy';
import {
    MissingInvoiceStrategyFromJSON,
    MissingInvoiceStrategyFromJSONTyped,
    MissingInvoiceStrategyToJSON,
} from './MissingInvoiceStrategy';

/**
 * 
 * @export
 * @interface CarrierPaymentProperties
 */
export interface CarrierPaymentProperties {
    /**
     * 
     * @type {string}
     * @memberof CarrierPaymentProperties
     */
    paymentProcessingInstructions: string;
    /**
     * 
     * @type {CarrierPaymentStrategy}
     * @memberof CarrierPaymentProperties
     */
    invoicePaymentStrategy: CarrierPaymentStrategy;
    /**
     * 
     * @type {MissingInvoiceStrategy}
     * @memberof CarrierPaymentProperties
     */
    missingInvoiceStrategy: MissingInvoiceStrategy;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierPaymentProperties
     */
    shouldCreatePaymentInvoiceTasks: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarrierPaymentProperties
     */
    daysToWaitSincePickupBeforePayingInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof CarrierPaymentProperties
     */
    daysToWaitSinceDeliveryBeforePayingInvoices: number;
    /**
     * Some carriers treat us as an end user, some carriers treat us as an interline - A carrier that treats us as an end user will apply GST/HST/QST as applicable to domestic Canadian shipments. - A carrier that treat us a another carrier they are interlining with will not charge taxes at all. Doesn't seem to be rhyme or reason to it Taxes only apply on domestic Canadian shipments anyway NOTE: This can be overriden at the service level Also - sometimes a carrier will provide a quote with tax but then an invoice with no tax, or vice versa
     * @type {boolean}
     * @memberof CarrierPaymentProperties
     */
    chargesTaxesOnInvoices: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierPaymentProperties
     */
    chargesTaxesOnQuotes: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarrierPaymentProperties
     */
    xeroContactId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarrierPaymentProperties
     */
    sendRemittanceAdvice: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarrierPaymentProperties
     */
    remitttanceAdviceAdditionalText?: string;
    /**
     * 
     * @type {CarrierChequeInformation}
     * @memberof CarrierPaymentProperties
     */
    chequeInformationCad?: CarrierChequeInformation;
    /**
     * 
     * @type {CarrierChequeInformation}
     * @memberof CarrierPaymentProperties
     */
    chequeInformationUsd?: CarrierChequeInformation;
    /**
     * 
     * @type {Array<CurrencyCode>}
     * @memberof CarrierPaymentProperties
     */
    allowedCurrencyCodes: Array<CurrencyCode>;
    /**
     * 
     * @type {string}
     * @memberof CarrierPaymentProperties
     */
    billToAddress: string;
}

/**
 * Check if a given object implements the CarrierPaymentProperties interface.
 */
export function instanceOfCarrierPaymentProperties(value: object): boolean {
    if (!('paymentProcessingInstructions' in value)) return false;
    if (!('invoicePaymentStrategy' in value)) return false;
    if (!('missingInvoiceStrategy' in value)) return false;
    if (!('shouldCreatePaymentInvoiceTasks' in value)) return false;
    if (!('daysToWaitSincePickupBeforePayingInvoices' in value)) return false;
    if (!('daysToWaitSinceDeliveryBeforePayingInvoices' in value)) return false;
    if (!('chargesTaxesOnInvoices' in value)) return false;
    if (!('chargesTaxesOnQuotes' in value)) return false;
    if (!('xeroContactId' in value)) return false;
    if (!('sendRemittanceAdvice' in value)) return false;
    if (!('allowedCurrencyCodes' in value)) return false;
    if (!('billToAddress' in value)) return false;
    return true;
}

export function CarrierPaymentPropertiesFromJSON(json: any): CarrierPaymentProperties {
    return CarrierPaymentPropertiesFromJSONTyped(json, false);
}

export function CarrierPaymentPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierPaymentProperties {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentProcessingInstructions': json['paymentProcessingInstructions'],
        'invoicePaymentStrategy': CarrierPaymentStrategyFromJSON(json['invoicePaymentStrategy']),
        'missingInvoiceStrategy': MissingInvoiceStrategyFromJSON(json['missingInvoiceStrategy']),
        'shouldCreatePaymentInvoiceTasks': json['shouldCreatePaymentInvoiceTasks'],
        'daysToWaitSincePickupBeforePayingInvoices': json['daysToWaitSincePickupBeforePayingInvoices'],
        'daysToWaitSinceDeliveryBeforePayingInvoices': json['daysToWaitSinceDeliveryBeforePayingInvoices'],
        'chargesTaxesOnInvoices': json['chargesTaxesOnInvoices'],
        'chargesTaxesOnQuotes': json['chargesTaxesOnQuotes'],
        'xeroContactId': json['xeroContactId'],
        'sendRemittanceAdvice': json['sendRemittanceAdvice'],
        'remitttanceAdviceAdditionalText': json['remitttanceAdviceAdditionalText'] == null ? undefined : json['remitttanceAdviceAdditionalText'],
        'chequeInformationCad': json['chequeInformationCad'] == null ? undefined : CarrierChequeInformationFromJSON(json['chequeInformationCad']),
        'chequeInformationUsd': json['chequeInformationUsd'] == null ? undefined : CarrierChequeInformationFromJSON(json['chequeInformationUsd']),
        'allowedCurrencyCodes': ((json['allowedCurrencyCodes'] as Array<any>).map(CurrencyCodeFromJSON)),
        'billToAddress': json['billToAddress'],
    };
}

export function CarrierPaymentPropertiesToJSON(value?: CarrierPaymentProperties | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentProcessingInstructions': value['paymentProcessingInstructions'],
        'invoicePaymentStrategy': CarrierPaymentStrategyToJSON(value['invoicePaymentStrategy']),
        'missingInvoiceStrategy': MissingInvoiceStrategyToJSON(value['missingInvoiceStrategy']),
        'shouldCreatePaymentInvoiceTasks': value['shouldCreatePaymentInvoiceTasks'],
        'daysToWaitSincePickupBeforePayingInvoices': value['daysToWaitSincePickupBeforePayingInvoices'],
        'daysToWaitSinceDeliveryBeforePayingInvoices': value['daysToWaitSinceDeliveryBeforePayingInvoices'],
        'chargesTaxesOnInvoices': value['chargesTaxesOnInvoices'],
        'chargesTaxesOnQuotes': value['chargesTaxesOnQuotes'],
        'xeroContactId': value['xeroContactId'],
        'sendRemittanceAdvice': value['sendRemittanceAdvice'],
        'remitttanceAdviceAdditionalText': value['remitttanceAdviceAdditionalText'],
        'chequeInformationCad': CarrierChequeInformationToJSON(value['chequeInformationCad']),
        'chequeInformationUsd': CarrierChequeInformationToJSON(value['chequeInformationUsd']),
        'allowedCurrencyCodes': ((value['allowedCurrencyCodes'] as Array<any>).map(CurrencyCodeToJSON)),
        'billToAddress': value['billToAddress'],
    };
}


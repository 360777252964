import { isEmpty } from "./isEmpty";
import { isBlank as isBlankString } from "./isNotBlank";

export enum MutationType {
  Deleted = "deleted",
  Added = "added",
  Changed = "changed",
}

export interface ObjectDifference {
  mutationType: MutationType;
  key: string;
  description?: string;
  left: any;
  right: any;
  path?: string;
}

export interface ObjectDifferencesConfig<L, R> {
  path: string[];
  describeKey: (key: string) => string;
  ignoreKeys: (keyof (L & R))[];
  focusKeys?: (keyof (L & R))[];
  onLeftMissing: MutationType;
  onRightMissing: MutationType;
}

export const defaultObjectDifferencesConfig = <L, R>(): ObjectDifferencesConfig<
  L,
  R
> => ({
  path: [],
  describeKey: (key: string) => key,
  ignoreKeys: [] as unknown as (keyof (L & R))[],
  onLeftMissing: MutationType.Added,
  onRightMissing: MutationType.Deleted,
});

export function objectDifferences<L extends object, R extends object>(
  left: L,
  right: R,
  config?: Partial<ObjectDifferencesConfig<L, R>>
): ObjectDifference[] {
  return _objectDifferences(
    left,
    right,
    config ?? { ...defaultObjectDifferencesConfig<L, R>() }
  );
}

export function _objectDifferences<L, R>(
  left: L,
  right: R,
  _config: Partial<ObjectDifferencesConfig<L, R>>
): ObjectDifference[] {
  if (!left || !right) {
    return [];
  }

  const config: ObjectDifferencesConfig<L, R> = {
    path: _config.path ?? [],
    describeKey: _config.describeKey ?? ((key: string) => key),
    ignoreKeys: _config.ignoreKeys ?? ([] as unknown as (keyof (L & R))[]),
    focusKeys: _config.focusKeys,
    onLeftMissing: _config.onLeftMissing ?? MutationType.Added,
    onRightMissing: _config.onLeftMissing ?? MutationType.Deleted,
  };

  const differences: ObjectDifference[] = [];

  const leftKeys = left ? Object.keys(left) : [];
  const rightKeys = right ? Object.keys(right) : [];

  const keysOnlyInLeft = leftKeys.filter((lk) => !rightKeys.includes(lk));
  const keysOnlyInRight = rightKeys.filter((rk) => !leftKeys.includes(rk));
  const keysInBoth = leftKeys.filter((lk) => rightKeys.includes(lk));

  const path = config.path.join(".");

  /**
   * Set the right value to null
   */
  keysOnlyInLeft.forEach((key) => {
    const value = left[key as keyof L];

    if (isEmptyArray(value)) {
      return;
    }

    if (isObject(value)) {
      const c: Partial<ObjectDifferencesConfig<L, R>> = {
        path: [...config.path, key],
        describeKey: config.describeKey,
        ignoreKeys: [],
        focusKeys: config.focusKeys,
        onLeftMissing: config.onLeftMissing,
        onRightMissing: config.onRightMissing,
      };
      differences.push(..._objectDifferences(value as any, null as any, c));
      return;
    }

    if (shouldIgnoreDifference(value, key, config)) {
      return;
    }

    differences.push({
      mutationType: config.onRightMissing,
      key: key,
      description: config.describeKey(key),
      left: value,
      right: null,
      path: path,
    });
  });

  /**
   * Set the left value to null
   */
  keysOnlyInRight.forEach((key) => {
    const value = right[key as keyof R];

    if (isEmptyArray(value)) {
      return;
    }

    if (isObject(value)) {
      const c: Partial<ObjectDifferencesConfig<L, R>> = {
        path: [...config.path, key],
        describeKey: config.describeKey,
        ignoreKeys: [],
        focusKeys: config.focusKeys,
        onLeftMissing: config.onLeftMissing,
        onRightMissing: config.onRightMissing,
      };
      differences.push(..._objectDifferences(null as any, value as any, c));
      return;
    }

    if (shouldIgnoreDifference(value, key, config)) {
      return;
    }

    differences.push({
      mutationType: config.onLeftMissing,
      key: key,
      description: config.describeKey(key),
      left: null,
      right: value,
      path: path,
    });
  });

  /**
   * Check differences in both objects
   */
  keysInBoth.forEach((key) => {
    const leftValue = left[key as keyof L] as any;
    const rightValue = right[key as keyof R] as any;

    if (Array.isArray(leftValue) || Array.isArray(rightValue)) {
      const arrayDiffs = _objectDifferences(leftValue, rightValue, {
        path: [...config.path, key],
      });

      if (arrayDiffs.length === 0) {
        return;
      }

      if (shouldIgnoreDifference(leftValue, key, config)) {
        return;
      }

      differences.push({
        mutationType: MutationType.Changed,
        key: key,
        description: config.describeKey(key),
        left: leftValue,
        right: rightValue,
        path: path,
      });
      return;
    }

    if (isObject(leftValue) && isObject(rightValue)) {
      const c: Partial<ObjectDifferencesConfig<L, R>> = {
        path: [...config.path, key],
        describeKey: config.describeKey,
        ignoreKeys: [],
        focusKeys: config.focusKeys,
        onLeftMissing: config.onLeftMissing,
        onRightMissing: config.onRightMissing,
      };
      differences.push(..._objectDifferences(leftValue, rightValue, c));
      return;
    }

    if (isBlank(leftValue) && isBlank(rightValue)) {
      return;
    }

    if (leftValue !== rightValue) {
      if (config.ignoreKeys.includes(key as keyof L & R)) {
        return;
      }

      if (config.focusKeys && !config.focusKeys.includes(key as keyof L & R)) {
        return;
      }

      differences.push({
        mutationType: MutationType.Changed,
        key: key,
        description: config.describeKey(key),
        left: leftValue,
        right: rightValue,
        path: path,
      });
    }
  });

  return differences;
}

function isObject(value: any): boolean {
  return typeof value === "object" && !Array.isArray(value);
}

function isEmptyArray(value: any): boolean {
  return Array.isArray(value) && isEmpty(value);
}

function shouldIgnoreDifference<L, R>(
  value: any,
  key: string,
  config: ObjectDifferencesConfig<L, R>
): boolean {
  if (isBlank(value)) {
    return true;
  }

  if (config.ignoreKeys.includes(key as keyof L & R)) {
    return true;
  }

  if (config.focusKeys && !config.focusKeys.includes(key as keyof L & R)) {
    return true;
  }
  return false;
}

function isBlank(value: any) {
  try {
    return isBlankString(value);
  } catch (e) {
    return false;
  }
}

import {
  Form,
  InputNumber,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Switch,
  Typography,
} from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { CurrencyInput } from "../../Components/CurrencyInput";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { WarningComponent } from "../../Components/WarningComponent";
import { CompanyData } from "../../generated-openapi-client";
import Spacer from "../../Spacer";

const { Text } = Typography;

interface SetPaymentTermsForCompanyMenuItemProps {
  data: CompanyData;
  onRefresh: () => Promise<void>;
}

export function SetPaymentTermsForCompanyMenuItem(
  props: SetPaymentTermsForCompanyMenuItemProps & MenuItemProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentTermsDays, setPaymentTermsDays] = useState(
    props.data.company.paymentTermsDays || 0
  );
  const createCompaniesApi = useCompaniesApi();
  const companyId = props.data.company.companyId!!;
  const [loading, setLoading] = useState(false);
  const [creditLimitAmount, setCreditLimitAmount] = useState(
    props.data.company.creditLimit || 0
  );
  const [doNotAutochargeOverdueInvoices, setDoNotAutochargeOverdueInvoices] =
    useState(props.data.company.doNotAutochargeOverdueInvoices!!);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (paymentTermsDays < 0) {
      return message.error("Days is negative");
    }

    if (paymentTermsDays > 90) {
      return message.error("Days is too long");
    }

    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.setCreditTermsForCompany({
        companyId,
        paymentTermsDays,
        creditLimitAmount,
        doNotAutochargeOverdueInvoices,
      });
      await props.onRefresh();
      message.success(`Set Payment Terms Days to ${paymentTermsDays}`);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setLoading(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Set Payment Terms Days"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
        confirmLoading={loading}
        width={900}
      >
        <Form
          style={{ width: "1000px" }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left" style={{ width: "600px" }}>
            <Text style={{ marginLeft: "24px" }}>Notes</Text>
            <ul>
              <li>
                If we put a company on credit, they are given a certain number
                of days to pay an invoice. Typically 7, 14, 30 days.
              </li>
              <li>
                If you enter 0 days means they must pay each invoice
                immediately. This is basically takes them off credit.
              </li>
              <li>
                Their account will be temporarily suspended if
                <ul>
                  <li>There are any overdue unpaid invoices</li>
                  <li>The company is set 'needs verification'</li>
                  <li>
                    They have unpaid invoices totalling more than the credit
                    limit
                  </li>
                </ul>
              </li>
              <li>
                They will see a warning in their dashboard if
                <ul>
                  <li>An invoice is close to overdue</li>
                  <li>They are near their credit limit</li>
                </ul>
              </li>
              <li>
                <WarningComponent /> If you put a company on credit AND they
                intend to pay by a non-credit card method (eg. EFT, Cheque etc)
                then make sure 'do not auto-charge' is set. This is very
                important - otherwise it causes a billing mess
              </li>
            </ul>
          </Stack>
          <Spacer height={32} />
          <Form.Item label="Payment Terms Days">
            <InputNumber
              value={paymentTermsDays}
              onChange={setPaymentTermsDays}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item label="Credit Limit Amount">
            <HorizontalStack>
              <CurrencyInput
                amount={creditLimitAmount}
                setAmount={setCreditLimitAmount}
                currency={props.data.company.currency!!}
              />
            </HorizontalStack>
          </Form.Item>
          <Form.Item label="Do not auto-charge overdue invoices?">
            <Switch
              checked={doNotAutochargeOverdueInvoices}
              onChange={setDoNotAutochargeOverdueInvoices}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Set Credit Terms
      </Menu.Item>
    </>
  );
}

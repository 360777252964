import { Button, Form, message, Modal, Select, Table, Typography } from "antd";
import Input from "antd/lib/input/Input";
import moment from "moment";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useCustomerInvoiceApi, useShipmentsApi } from "../../Apis/Apis";
import { BooleanCell } from "../../Components/BooleanCell";
import { ButtonRow } from "../../Components/ButtonRow";
import CreditCardLogo from "../../Components/CardLogo";
import { CustomerInvoiceStateTag } from "../../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { ViewMarkdownButton } from "../../Components/ViewMarkdownButton";
import { ViewRawButton } from "../../Components/ViewRawButton";
import {
  AdditionalCharge,
  AdditionalChargeState,
  AdditionalChargeWaivedCause,
  PaymentMethodItem,
} from "../../generated-openapi-client";
import { Coupon } from "../../generated-openapi-client/models/Coupon";
import { CustomerInvoice } from "../../generated-openapi-client/models/CustomerInvoice";
import { PaymentMethodInformation } from "../../generated-openapi-client/models/PaymentMethodInformation";
import { assertNever } from "../../Helpers/assertNever";
import { formatAsCurrency } from "../../Helpers/numberFormatting";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import { AddAdditionalChargeButton } from "./AddAdditionalChargeButton";
import { AdditionalChargeSupportEmailButton } from "./AdditionalChargeSupportEmailButton";
import { ApproveAdditionalChargeButton } from "./ApproveAdditionalChargeButton";
import { CalculateAdditionalChargeButton } from "./CalculateAdditionalChargeButton";
import { DeleteAdditionalChargeButton } from "./DeleteAdditionalChargeButton";
import { TabProps } from "./TabProps";
import { ViewCustomerInvoiceButton } from "./ViewCustomerInvoiceButton";
import { WaivedAdditionaChargeSupportEmailButton } from "./WaivedAdditionalChargeSupportEmailButton";

const { Title, Text } = Typography;
const { Option } = Select;

function describeAdditionalChargeWaivedCause(
  additionalChargeWaivedCause: AdditionalChargeWaivedCause
) {
  switch (additionalChargeWaivedCause) {
    case AdditionalChargeWaivedCause.Bug:
      return "Bug";
    case AdditionalChargeWaivedCause.ChallengedWithCarrier:
      return "Challenged with carrier";
    case AdditionalChargeWaivedCause.Grace:
      return "Grace";
    default:
      assertNever(additionalChargeWaivedCause);
  }
}

interface WaiveAdditionalChargeButtonProps {
  additionalCharge: AdditionalCharge;
  onRefresh: () => Promise<void>;
}

function WaiveAdditionalChargeButton(props: WaiveAdditionalChargeButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  const additionalChargeId = props.additionalCharge.additionalChargeId!!;
  const [reason, setReason] = useState<string>(props.additionalCharge.reason!!);
  const [waivedReason, setWaivedReason] = useState<string>("");
  const [waivedCause, setWaivedCause] = useState<AdditionalChargeWaivedCause>(
    AdditionalChargeWaivedCause.Grace
  );

  const [saving, setSaving] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (reason === "") {
      return;
    }

    if (waivedReason === "") {
      return;
    }

    try {
      setSaving(true);
      const customerInvoiceApi = await createCustomerInvoiceApi();

      await customerInvoiceApi.waiveAdditionalCharge({
        additionalChargeId,
        reason,
        waivedReason,
        waivedCause,
      });
      await props.onRefresh();
      message.success("Waived");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (
    props.additionalCharge.state !== AdditionalChargeState.Proposed &&
    props.additionalCharge.state !== AdditionalChargeState.Approved
  ) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="👋 Waive Additional Charge"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        confirmLoading={saving}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            This additional charge was proposed due to an invoice which was
            higher than we expected. If we are not going to charge the customer
            for it, then we must waive it. Please enter the reason why
          </Text>
          <Spacer height={32} />
          <Form
            style={{ width: "800px" }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Additional Charge Reason">
              <Input
                value={reason}
                onChange={function (e) {
                  setReason(e.target.value);
                }}
                width={400}
              />
            </Form.Item>
            <Form.Item label="Waived Reason">
              <Input
                value={waivedReason}
                onChange={function (e) {
                  setWaivedReason(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="Waived Cause">
              <Select
                value={waivedCause}
                style={{ width: 266 }}
                onChange={setWaivedCause}
              >
                {Object.entries(AdditionalChargeWaivedCause).map(function (
                  value
                ) {
                  const v = value[1];
                  const description = describeAdditionalChargeWaivedCause(v);
                  return <Option value={v}>{description}</Option>;
                })}
              </Select>
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
      <Button onClick={showModal}>👋 Waive</Button>
    </>
  );
}

function PaymentMethodsTable(props: TabProps) {
  const createShipmentApi = useShipmentsApi();
  const [selecting, setSelecting] = useState(false);
  const [paymentMethodInformation, setPaymentMethodInformation] = useState<
    PaymentMethodInformation | undefined
  >(undefined);
  const shipmentId = props.shipmentData.shipmentId;

  async function onSelectForShipment(stripePaymentMethodId: string) {
    const shipmentApi = await createShipmentApi();
    setSelecting(true);
    try {
      await shipmentApi.changePaymentMethodForShipment({
        shipmentId,
        stripePaymentMethodId,
      });
      await props.onRefresh();
      await loadData();
      message.success("Payment method switched");
    } catch (e: any) {
      message.error(`Ooops. Something went wrong ${e}`);
    }

    setSelecting(false);
  }

  async function loadData() {
    const shipmentApi = await createShipmentApi();

    const response = await shipmentApi.getShipmentPaymentMethodInfoById({
      shipmentId,
    });
    setPaymentMethodInformation(response);
  }

  useEffect(
    function () {
      loadData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipmentId]
  );

  const columns: DataTableColumn<PaymentMethodItem>[] = [
    {
      title: "Brand",
      render: (o) => (
        <HorizontalStack verticalAlign="middle">
          <CreditCardLogo brand={o.brand} size="small" />
          <Spacer width={4} />
          <div>{o.brand}</div>
        </HorizontalStack>
      ),
    },
    {
      title: "Last 4 Digits",
      render: (o) => <div>...{o.lastFourDigits}</div>,
    },
    {
      title: "Cardholder Name",
      render: (o) => <div>{o.cardholderName}</div>,
    },
    {
      title: "Stripe ID",
      render: (o) => <div>{o.stripePaymentMethodId}</div>,
    },
    {
      title: "Is company's default?",
      render: (o) => <BooleanCell>{o.companyDefault}</BooleanCell>,
    },
    {
      title: "Currently selected for this shipment?",
      render: (o) => (
        <BooleanCell>{o.currentlySelectedForShipment}</BooleanCell>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <Button
          onClick={function () {
            onSelectForShipment(o.stripePaymentMethodId);
          }}
          loading={selecting}
        >
          Select for this shipment
        </Button>
      ),
    },
  ];

  if (paymentMethodInformation === undefined) {
    return <div>Loading</div>;
  }

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={paymentMethodInformation.paymentMethods}
    />
  );
}

function CouponsTable(props: TabProps) {
  const columns: DataTableColumn<Coupon>[] = [
    {
      title: "State",
      render: (o) => <div>{o.couponState}</div>,
    },
    {
      title: "Created by",
      render: (o) => <div>{o.createdBy}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <div>
          {formatAsCurrency(
            o.amount,
            props.shipmentData.shipment.company.currency
          )}
        </div>
      ),
    },
  ];
  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.shipmentData.billingTab.couponsUsed}
    />
  );
}

function CustomerInvoicesTable(props: TabProps) {
  return (
    <Table
      pagination={false}
      columns={safeColumns([
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (createdAt) => (
            <div>
              {moment(createdAt)
                .tz("America/Vancouver")
                .format("ddd, MMM Do YYYY, h:mma")}
            </div>
          ),
        },
        {
          title: "Invoice Type",
          dataIndex: "invoiceType",
          key: "invoiceType",
          render: (invoiceType) => <div>{invoiceType}</div>,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (amount, invoice: CustomerInvoice) => (
            <div>{formatAsCurrency(amount, invoice.currency)}</div>
          ),
        },
        {
          title: "Customer Invoice State",
          dataIndex: "customerInvoiceState",
          key: "customerInvoiceState",
          render: function (_, customerInvoice) {
            return (
              <CustomerInvoiceStateTag customerInvoice={customerInvoice} />
            );
          },
        },
        {
          title: "Due Date",
          dataIndex: "dueDate",
          key: "dueDate",
          render: (dueDate) => (
            <div>{moment(dueDate).format("ddd, MMM Do YYYY")}</div>
          ),
        },
        {
          title: "Payment Method",
          dataIndex: "paymentMethod",
          key: "paymentMethod",
          render: (paymentMethod) => <div>{paymentMethod}</div>,
        },
        {
          title: "Description",
          dataIndex: "paymentMethod",
          key: "description",
          render: (paymentMethod, o: CustomerInvoice) => (
            <div>{o.description}</div>
          ),
        },
        {
          title: "Last failed attempt",
          dataIndex: "lastPaymentFailureCode",
          key: "lastPaymentFailureCode",
          render: function (lastPaymentFailureCode) {
            return lastPaymentFailureCode !== undefined ? (
              <div>❌ {lastPaymentFailureCode}</div>
            ) : (
              <div></div>
            );
          },
        },
        {
          title: "Actions",
          dataIndex: "customerInvoiceId",
          key: "customerInvoiceId",
          render: function (
            customerInvoiceId: string,
            customerInvoice: CustomerInvoice
          ) {
            return (
              <HorizontalStack>
                <ViewCustomerInvoiceButton
                  customerInvoice={customerInvoice}
                  data={props.shipmentData}
                  onRefresh={props.onRefresh}
                  onPatchShipmentData={props.onPatchShipmentData}
                />
              </HorizontalStack>
            );
          },
        },
      ])}
      dataSource={props.shipmentData.billingTab.customerInvoices}
    />
  );
}

function AdditionalChargesTable(props: TabProps) {
  const additionalCharges = props.shipmentData.billingTab.additionalCharges!!;

  const columns: DataTableColumn<AdditionalCharge>[] = [
    {
      title: "State",
      render: (o) => <div>{o.state}</div>,
    },
    {
      title: "Reason",
      render: (o) => <div>{o.reason}</div>,
    },
    {
      title: "Waived Reason",
      render: (o) => <div>{o.waivedReason}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <NumericFormat
          value={o.amount}
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          prefix={o.currency + "$"}
        />
      ),
    },
    {
      title: "Created by",
      render: (o) => <div>{o.createdBy}</div>,
    },

    {
      title: "Actions",
      render: function (o) {
        return (
          <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
            <ApproveAdditionalChargeButton additionalCharge={o} {...props} />
            <WaiveAdditionalChargeButton
              additionalCharge={o}
              onRefresh={props.onRefresh}
            />
            <DeleteAdditionalChargeButton
              additionalCharge={o}
              onRefresh={props.onRefresh}
            />
            <AdditionalChargeSupportEmailButton
              additionalCharge={o}
              data={props.shipmentData}
            />
            <WaivedAdditionaChargeSupportEmailButton
              additionalCharge={o}
              data={props.shipmentData}
            />
            {o.calculationExplanation !== undefined && (
              <ViewMarkdownButton
                title="Explain"
                data={o.calculationExplanation}
              />
            )}
            <ViewRawButton data={o} />
          </HorizontalStack>
        );
      },
    },
  ];

  if (additionalCharges.length === 0) {
    return <></>;
  }

  return (
    <>
      <Title level={4}>Additional Charges</Title>
      <DataTable
        pagination={false}
        columns={columns}
        data={additionalCharges}
      />
    </>
  );
}

export function CustomerInvoicesTab(props: TabProps) {
  function describeCreditTerms() {
    const c = props.shipmentData.shipment.company;
    if (c.paymentTermsDays === 0) {
      return "Immediate Payment (No Credit)";
    }

    return `Credit: ${c.paymentTermsDays} days, $${c.creditLimit} ${props.shipmentData.shipment.company.currency}`;
  }

  function describeDiscountsAndSurcharges() {
    const c = props.shipmentData.shipment.company;
    const fixedMarkup = c.fixedMarkup!!;
    const discountPercentage = c.discountPercentage!!;

    function discountDescription(): string {
      if (discountPercentage > 0) {
        return `⬇️ ${discountPercentage}% discount`;
      }

      if (discountPercentage < 0) {
        return `⬆️ ${Math.abs(
          discountPercentage
        )}% surcharge/premium support plan`;
      }

      return `Company has no discount`;
    }

    function fixedDescription(): string {
      if (fixedMarkup > 0) {
        return `, Fixed Markup on every shipment ${fixedMarkup} ${c.currency}`;
      }

      return "";
    }

    return `${discountDescription()}${fixedDescription()}`;
  }

  const profitLossStartDate = moment()
    .subtract(12, "months")
    .format("YYYY-MM-DD");
  const profitLossUrl = `/profit-loss?startDate=${profitLossStartDate}&companyId=${props
    .shipmentData.shipment.company.companyId!!}`;

  return (
    <>
      <Spacer height={32} />
      <Title level={4}>Actions</Title>
      <ButtonRow>
        <AddAdditionalChargeButton {...props} />
        <CalculateAdditionalChargeButton {...props} />
        <Button href={profitLossUrl} target="_new">
          View Company Profit/Loss
        </Button>
      </ButtonRow>
      <Spacer height={32} />
      <AdditionalChargesTable {...props} />
      <Spacer height={32} />
      {props.shipmentData.billingTab.couponsUsed.length > 0 && (
        <>
          <Title level={4}>Coupons</Title>
          <CouponsTable {...props} />
          <Spacer height={32} />
        </>
      )}
      <Title level={4}>
        Customer Invoices ({describeCreditTerms()} -{" "}
        {describeDiscountsAndSurcharges()})
      </Title>
      <CustomerInvoicesTable {...props} />
      <Spacer height={32} />
      <Title level={4}>Payment Methods</Title>
      <PaymentMethodsTable {...props} />
    </>
  );
}

import { PlusCircleTwoTone } from "@ant-design/icons";
import { Alert, DatePicker, Form, Menu, Radio, Row, Tag, Tooltip } from "antd";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import { useProfitLossApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CampaignSelect } from "../Components/CampaignSelect";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import { CompaniesDropdown } from "../Components/CompaniesDropdown";
import {
  DataTable,
  DataTableColumn,
  DataTableCsvColumn,
} from "../Components/DataTable";
import { Dollar } from "../Components/Dollar";
import { GeoFilterDropdown } from "../Components/GeoFilterDropdown";
import HorizontalStack from "../Components/HorizontalStack";
import { LeadSourceSelect } from "../Components/LeadSourceSelect";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import { ServiceTypeFilter } from "../Components/ServiceTypeFilter";
import { SettledStatusSelect } from "../Components/SettledStatusSelect";
import Stack from "../Components/Stack";
import { ViewShipmentMenuItem } from "../Components/ViewShipmentButton";
import { Weight } from "../Components/Weight";
import {
  CurrencyCode,
  GenerateProfitLossReportFilterOptions,
  GenerateProfitLossReportFilterOptionsSettledStatus,
  GeoFilter,
  ProfitLossReport,
  ShipmentReport,
  ShipmentReportSettlementStatus,
} from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import { emojiForCountry } from "../Helpers/emojiForCountry";
import { groupBy } from "../Helpers/groupBy";
import { countIf } from "../Helpers/sum";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { ProfitLossGroupByTable } from "./ProfitLossScreenComponents/ProfitLossGroupByTable";
import { ProfitLossGroupType } from "./ProfitLossScreenComponents/ProfitLossGroupType";
import { ProfitLossViewCalendarButton } from "./ProfitLossScreenComponents/ProfitLossViewCalendarButton";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

const { RangePicker } = DatePicker;

interface ProfitLossReportProps {
  report: ProfitLossReport;
  filterOptions: GenerateProfitLossReportFilterOptions;
  groupBy: ProfitLossGroupType | undefined;
}

interface ProfitLossTableProps {
  data: ShipmentReport[];
}

export function describeSettlementStatus(
  status: ShipmentReportSettlementStatus
): ReactNode {
  switch (status) {
    case ShipmentReportSettlementStatus.Approved:
      return <Tag color="blue">Approved</Tag>;
    case ShipmentReportSettlementStatus.Open:
      return <Tag color="orange">Open</Tag>;
    case ShipmentReportSettlementStatus.Projected:
      return <Tag color="red">Projected</Tag>;
    case ShipmentReportSettlementStatus.Settled:
      return <Tag color="green">Settled</Tag>;
    default:
      assertNever(status);
  }
}

function ProfitLossTable(props: ProfitLossTableProps) {
  const columns: DataTableColumn<ShipmentReport>[] = [
    {
      title: "Company",
      render: (o) => <div>{o.companyName}</div>,
    },
    {
      title: "Details",
      render: (o) => (
        <>
          <Stack align="left">
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(o.pickupCountry!!)}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{o.pickupCityState}</div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                {emojiForCountry(o.deliveryCountry!!)}
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{o.deliveryCityState}</div>
              </Stack>
            </HorizontalStack>
            <HorizontalStack verticalAlign="top">
              <div
                style={{
                  fontSize: "10px",
                  color: Colors.LightText,
                  width: "16px",
                  textAlign: "right",
                  paddingTop: "4px",
                }}
              >
                📦
              </div>
              <Spacer width={8} />
              <Stack align="left">
                <div>{o.lineItemDescription}</div>
                <div
                  style={{
                    marginTop: "-4px",
                    fontSize: "12px",
                    color: Colors.LightText,
                  }}
                >
                  {o.totalHandlingUnits} skids,{" "}
                  <Weight>{o.totalWeight!!}</Weight>
                </div>
              </Stack>
            </HorizontalStack>
          </Stack>
        </>
      ),
    },
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Booking Date",
      render: (o) => <div>{moment(o.bookedAt).format("YYYY-MM-DD")}</div>,
      sorter: function (a, b) {
        return moment(a.bookedAt).valueOf() - moment(b.bookedAt).valueOf();
      },
    },
    {
      title: "Money In",
      render: (o) => (
        <HorizontalStack>
          <Dollar>{o.moneyInCad!!}</Dollar>
          {o.numberCustomerInvoices!! > 1 && (
            <Tooltip
              overlay={
                <div>
                  Additional charges: Customer paid this over{" "}
                  {o.numberCustomerInvoices} invoices
                </div>
              }
            >
              <PlusCircleTwoTone style={{ marginLeft: "4px" }} />
            </Tooltip>
          )}
        </HorizontalStack>
      ),
    },
    {
      title: "Money Out",
      render: (o) => <Dollar>{o.moneyOutCad!!}</Dollar>,
    },
    {
      title: "Margin",
      subtitle: "With Insurance",
      render: (o) => (
        <>{o.margin && <Percentage colored>{o.margin}</Percentage>}</>
      ),
    },
    {
      title: "Margin",
      subtitle: "Insurance Free",
      show: false,
      render: (o) => (
        <>
          {o.marginInsuranceFree && (
            <Percentage colored>{o.marginInsuranceFree}</Percentage>
          )}
        </>
      ),
    },
    {
      title: "Profit",
      render: (o) => <Dollar colored>{o.profit!!}</Dollar>,
    },
    {
      title: "Original Quote",
      render: (o) => <Dollar>{o.originalQuote!!}</Dollar>,
    },
    {
      title: "Settlement",
      render: (o) => describeSettlementStatus(o.settlementStatus!!),
      sorter: function (a, b) {
        return a.settlementStatus!!.localeCompare(b.settlementStatus!!);
      },
    },
    {
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow
            extrasMenu={
              <Menu>
                <ViewShipmentMenuItem shipmentId={o.shipmentId!!} />
              </Menu>
            }
          >
            <></>
          </ButtonRow>
        );
      },
    },
  ];

  const csvColumns: DataTableCsvColumn<ShipmentReport>[] = [
    {
      title: "Company Name",
      render: (o) => o.companyName,
    },
    {
      title: "Carrier",
      render: (o) => o.carrierIdentifier,
    },
    {
      title: "Booking Date",
      render: (o) => o.bookedAt,
    },
    {
      title: "Money In",
      render: (o) => o.moneyInCad,
    },
    {
      title: "Money Out",
      render: (o) => o.moneyOutCad,
    },
    {
      title: "Profit",
      render: (o) => o.profit,
    },
    {
      title: "Original Quote",
      render: (o) => o.originalQuote,
    },
    {
      title: "Settlement",
      render: (o) => o.settlementStatus,
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.data}
      csvColumns={csvColumns}
    />
  );
}

interface FinancialStatisticsProps {
  report: ProfitLossReport;
}

const StatKey = styled.div`
  width: 200px;
  color: ${Colors.LightText};
`;
const StatValue = styled.div`
  width: 200px;
  text-align: right;
  font-weight: 500;
`;

function ShipmentStatistics(props: FinancialStatisticsProps) {
  const { report } = props;

  const numberCompanies = groupBy(
    report.shipmentLines,
    (o) => o.companyName
  ).length;
  const numberCarriers = groupBy(
    report.shipmentLines,
    (o) => o.carrierIdentifier
  ).length;

  return (
    <Stack align="left" style={{ width: "400px" }}>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Total Shipments</StatKey>
        <StatValue>{report.numberShipments}</StatValue>
      </HorizontalStack>
      <Spacer height={8} />
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Companies</StatKey>
        <StatValue>{numberCompanies}</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Carriers</StatKey>
        <StatValue>{numberCarriers}</StatValue>
      </HorizontalStack>
      <Spacer height={8} />
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Additional Charges</StatKey>
        <StatValue>
          {report.numberShipmentsWithAdditionalCustomerCharges}
        </StatValue>
      </HorizontalStack>
    </Stack>
  );
}

function OtherStatistics(props: FinancialStatisticsProps) {
  const { report } = props;

  const residential = countIf(
    report.shipmentLines,
    (s) => s.needsResidentialPickup!! || s.needsResidentialDelivery!!
  );

  const liftgate = countIf(
    report.shipmentLines,
    (s) => s.needsLiftgatePickup!! || s.needsLiftgateDelivery!!
  );

  const inside = countIf(
    report.shipmentLines,
    (s) => s.needsInsidePickup!! || s.needsInsideDelivery!!
  );

  const appointment = countIf(
    report.shipmentLines,
    (s) => s.needsDeliveryAppointment!!
  );

  return (
    <Stack align="left" style={{ width: "400px" }}>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Average Weight</StatKey>
        <StatValue>{report.averageShipmentWeightLb.toFixed(0)}lb</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Average Volume</StatKey>
        <StatValue>{report.averageShipmentVolumeCuft.toFixed(0)}cuft</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Standard Pallet Equivalent</StatKey>
        {/* 48x40x48 */}
        <StatValue>
          {(report.averageShipmentVolumeCuft / 53.3333).toFixed(1)} pallets
        </StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Average Density</StatKey>
        <StatValue>{report.averageShipmentDensityPcf.toFixed(1)}pcf</StatValue>
      </HorizontalStack>
      <Spacer height={8} />
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Residential</StatKey>
        <StatValue>{residential}</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Liftgate</StatKey>
        <StatValue>{liftgate}</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Inside</StatKey>
        <StatValue>{inside}</StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Delivery Appointment</StatKey>
        <StatValue>{appointment}</StatValue>
      </HorizontalStack>
    </Stack>
  );
}

function FinancialStatistics(props: FinancialStatisticsProps) {
  const { report } = props;

  const incoming = report.totalIncomingDollars;
  const outgoing = report.totalOutgoingDollars;
  const profit = report.totalIncomingDollars - report.totalOutgoingDollars;
  const averageProfit = report.averageProfit;
  const margin = report.averageMarginPercentage;
  const insuranceFreeMargin = report.averageInsuranceFreeMarginPercentage;

  return (
    <Stack align="left" style={{ width: "400px" }}>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Incoming</StatKey>
        <StatValue>
          <Dollar currency={CurrencyCode.Cad}>{incoming}</Dollar>
        </StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Outgoing</StatKey>
        <Dollar currency={CurrencyCode.Cad}>{outgoing}</Dollar>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Profit</StatKey>
        <StatValue>
          <Dollar
            style={{ borderTop: "1px solid #bbb" }}
            currency={CurrencyCode.Cad}
          >
            {profit}
          </Dollar>
        </StatValue>
      </HorizontalStack>
      <Spacer height={8} />
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Average Profit</StatKey>
        <StatValue>
          <Dollar currency={CurrencyCode.Cad}>{averageProfit}</Dollar>
        </StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Margin</StatKey>
        <StatValue>
          <Percentage colored>{margin}</Percentage>
        </StatValue>
      </HorizontalStack>
      <HorizontalStack spacing={16} align="spread" width="100%">
        <StatKey>Margin (Insurance-Free)</StatKey>
        <StatValue>
          <Percentage colored>{insuranceFreeMargin}</Percentage>
        </StatValue>
      </HorizontalStack>
    </Stack>
  );
}

function ProfitLossReportComponent(props: ProfitLossReportProps) {
  const { report } = props;

  const filteredShipmentLines = report.shipmentLines;

  return (
    <div style={{ width: "100%" }}>
      <HorizontalStack width="100%" align="left" verticalAlign="top">
        <Stack align="left">
          <ShipmentStatistics report={report} />
        </Stack>
        <Spacer width={32} />
        <Stack align="left">
          <FinancialStatistics report={report} />
        </Stack>
        <Spacer width={32} />
        <Stack align="left">
          <OtherStatistics report={report} />
        </Stack>
      </HorizontalStack>
      <Spacer height={32} />
      <Alert
        type="info"
        description="NOTE: This will not precisely match Xero for money out. Our conversion from USD to CAD is naive."
      />
      <Spacer height={16} />
      <HorizontalStack align="right">
        <ProfitLossViewCalendarButton
          filterOptions={props.filterOptions}
          report={report}
          groupBy={props.groupBy}
        />
      </HorizontalStack>
      <Spacer height={16} />

      {props.groupBy === undefined && (
        <ProfitLossTable data={filteredShipmentLines} />
      )}
      {props.groupBy !== undefined && (
        <ProfitLossGroupByTable
          data={filteredShipmentLines}
          groupBy={props.groupBy}
        />
      )}
    </div>
  );
}

interface ProfitLossControlBarProps {
  filterOptions: GenerateProfitLossReportFilterOptions;
  setFilterOptions: (_: GenerateProfitLossReportFilterOptions) => void;
  loading: boolean;
  groupBy: ProfitLossGroupType | undefined;
  setGroupBy: (_: ProfitLossGroupType | undefined) => void;
}

function ProfitLossControlBar(props: ProfitLossControlBarProps) {
  const {
    onlyIncludeLosses,
    leadSource,
    startDate,
    endDate,
    carrierIdentifier,
    serviceIdentifier,
    companyId,
    campaign,
  } = props.filterOptions;
  const { filterOptions, setFilterOptions, loading } = props;

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Date Range">
          <RangePicker
            disabled={loading}
            value={[moment(startDate), moment(endDate)]}
            picker="month"
            allowClear={false}
            onChange={function (e) {
              if (e && e[0] && e[1]) {
                setFilterOptions({
                  ...filterOptions,
                  startDate: e[0].startOf("month").format("YYYY-MM-DD"),
                  endDate: e[1].endOf("month").format("YYYY-MM-DD"),
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item label="Only Include Settled?">
          <SettledStatusSelect
            settledStatus={filterOptions.settledStatus}
            setSettledStatus={function (s) {
              setFilterOptions({ ...filterOptions, settledStatus: s });
            }}
          />
        </Form.Item>
        <Form.Item label="Only Show Losses?">
          <Radio.Group
            disabled={loading}
            value={onlyIncludeLosses}
            onChange={function (e) {
              const newValue = e.target.value;
              setFilterOptions({
                ...filterOptions,
                onlyIncludeLosses: newValue,
              });
            }}
          >
            <Radio.Button value={false}>No</Radio.Button>
            <Radio.Button value={true}>Yes</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Lead Source">
          <LeadSourceSelect
            disabled={loading}
            leadSource={leadSource}
            setLeadSource={function (newValue) {
              setFilterOptions({
                ...filterOptions,
                leadSource: newValue,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Campaign">
          <CampaignSelect
            disabled={loading}
            campaign={campaign}
            setCampaign={function (newValue) {
              setFilterOptions({
                ...filterOptions,
                campaign: newValue,
              });
            }}
          />
        </Form.Item>
      </ButtonRow>
      <ButtonRow>
        <Form.Item label="Carrier">
          <CarrierFilter
            disabled={loading}
            carrierIdentifier={carrierIdentifier}
            setCarrierIdentifier={function (ci) {
              setFilterOptions({ ...filterOptions, carrierIdentifier: ci });
            }}
            allowClear
          />
        </Form.Item>
        {carrierIdentifier !== undefined && (
          <Form.Item label="Service Type">
            <ServiceTypeFilter
              disabled={loading}
              carrierIdentifier={carrierIdentifier}
              serviceIdentifier={serviceIdentifier}
              setServiceIdentifier={function (si) {
                setFilterOptions({ ...filterOptions, serviceIdentifier: si });
              }}
              allowClear
            />
          </Form.Item>
        )}
        <Form.Item label="Company">
          <CompaniesDropdown
            disabled={loading}
            companyId={companyId}
            setCompanyId={function (ci) {
              setFilterOptions({ ...filterOptions, companyId: ci });
            }}
          />
        </Form.Item>
        <Form.Item label="Group">
          <GroupByDropdown
            disabled={loading}
            value={props.groupBy}
            setValue={props.setGroupBy}
          />
        </Form.Item>
        <Form.Item label="Geo">
          <GeoFilterDropdown
            disabled={loading}
            value={props.filterOptions.geoFilter ?? undefined}
            setValue={function (_geoFilter) {
              setFilterOptions({ ...filterOptions, geoFilter: _geoFilter });
            }}
          />
        </Form.Item>
      </ButtonRow>
    </Stack>
  );
}

const GroupByDropdown = OptionalEnumDropdown<
  ProfitLossGroupType,
  typeof ProfitLossGroupType
>(ProfitLossGroupType, "Do not group", function (et) {
  switch (et) {
    case ProfitLossGroupType.Carrier:
      return "Carrier";
    case ProfitLossGroupType.Company:
      return "Company";
    case ProfitLossGroupType.LeadSource:
      return "Lead Source";
    case ProfitLossGroupType.Week:
      return "Week";
    case ProfitLossGroupType.Year:
      return "Year";
    case ProfitLossGroupType.Quarter:
      return "Quarter";
    case ProfitLossGroupType.Month:
      return "Month";
    case ProfitLossGroupType.DayOfWeek:
      return "Day of Week";
    default:
      assertNever(et);
  }
});

export function ProfitLossReportScreen() {
  const navigate = useNavigate();
  const query = useQuery();
  const queryStartDate = query.startDate as string;
  const queryEndDate = query.endDate as string;
  const [loading, setLoading] = useState(false);

  function defaultGroupBy(): undefined | ProfitLossGroupType {
    if (!query.groupBy) {
      return undefined;
    }

    return query.groupBy as ProfitLossGroupType;
  }

  const [groupBy, setGroupBy] = useState<undefined | ProfitLossGroupType>(
    defaultGroupBy()
  );

  function defaultStartDate() {
    if (queryStartDate) {
      return queryStartDate;
    }
    return moment().startOf("month").format("YYYY-MM-DD");
  }

  function defaultEndDate() {
    if (queryEndDate) {
      return queryEndDate;
    }
    return moment().endOf("month").format("YYYY-MM-DD");
  }

  function defaultFilterOptions(): GenerateProfitLossReportFilterOptions {
    return {
      startDate: defaultStartDate(),
      endDate: defaultEndDate(),
      carrierIdentifier: query.carrierIdentifier as string | undefined,
      companyId: query.companyId as string | undefined,
      settledStatus:
        (query.settledStatus as
          | GenerateProfitLossReportFilterOptionsSettledStatus
          | undefined) ??
        GenerateProfitLossReportFilterOptionsSettledStatus.Everything,
      onlyIncludeLosses: query.onlyIncludeLosses === "true",
      leadSource: query.leadSource as string | undefined,
      geoFilter: query.geoFilter as GeoFilter | undefined,
    };
  }

  const [filterOptions, setFilterOptions] =
    useState<GenerateProfitLossReportFilterOptions>(defaultFilterOptions());

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      setFilterOptions(value);
    },
    // delay in ms
    2000
  );

  function buildUrl() {
    let url = `/profit-loss`;
    url += `?startDate=${filterOptions.startDate}`;
    url += `&endDate=${filterOptions.endDate}`;

    if (filterOptions.carrierIdentifier !== undefined) {
      url += `&carrierIdentifier=${filterOptions.carrierIdentifier}`;
    }

    if (filterOptions.companyId !== undefined) {
      url += `&companyId=${filterOptions.companyId}`;
    }

    if (filterOptions.settledStatus) {
      url += `&settledStatus=${filterOptions.settledStatus}`;
    }

    if (filterOptions.onlyIncludeLosses) {
      url += `&onlyIncludeLosses=true`;
    }

    if (filterOptions.leadSource) {
      url += `&leadSource=${filterOptions.leadSource}`;
    }

    if (filterOptions.geoFilter !== undefined) {
      url += `&geoFilter=${filterOptions.geoFilter}`;
    }

    if (groupBy !== undefined) {
      url += `&groupBy=${groupBy}`;
    }

    return url;
  }

  function updateUrl() {
    navigate(buildUrl(), {
      replace: true,
    });
  }

  useEffect(
    function () {
      updateUrl();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filterOptions)]
  );

  const [report, setReport] = useState<ProfitLossReport | undefined>();
  const createProfitLossApi = useProfitLossApi();

  async function refresh() {
    setLoading(true);
    updateUrl();

    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.generateProfitLossReport({
      generateProfitLossReportFilterOptions: filterOptions,
    });

    setReport(response);
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filterOptions)]
  );

  if (report === undefined) {
    return <Loading />;
  } else {
    return (
      <Page
        title={`Profit/Loss`}
        tags={[]}
        extra={[
          <ProfitLossControlBar
            filterOptions={filterOptions}
            setFilterOptions={debounced}
            loading={loading}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
          />,
        ]}
      >
        <PageTitle>Profit/Loss</PageTitle>
        <Row style={{ width: "100%" }}>
          <Stack align="left" style={{ width: "100%" }}>
            <ProfitLossReportComponent
              report={report}
              filterOptions={filterOptions}
              groupBy={groupBy}
            />
          </Stack>
        </Row>
      </Page>
    );
  }
}

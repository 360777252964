/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdditionalCarrierFee } from './AdditionalCarrierFee';
import {
    AdditionalCarrierFeeFromJSON,
    AdditionalCarrierFeeFromJSONTyped,
    AdditionalCarrierFeeToJSON,
} from './AdditionalCarrierFee';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { QuoteGenerationMethod } from './QuoteGenerationMethod';
import {
    QuoteGenerationMethodFromJSON,
    QuoteGenerationMethodFromJSONTyped,
    QuoteGenerationMethodToJSON,
} from './QuoteGenerationMethod';
import type { RunQuoteInputLocation } from './RunQuoteInputLocation';
import {
    RunQuoteInputLocationFromJSON,
    RunQuoteInputLocationFromJSONTyped,
    RunQuoteInputLocationToJSON,
} from './RunQuoteInputLocation';

/**
 * 
 * @export
 * @interface Quote
 */
export interface Quote {
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    shipmentId?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    quotedPriceByCarrierCad?: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    additionalCarrierFeesCad?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    taxLineDescription1?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    taxLineValueCad1?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    taxLineDescription2?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    taxLineValueCad2?: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    priceCad?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    carrierQuoteReference?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    transitBusinessDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    expectedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quotedPickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    latestEstimatedDeliveryDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    latestTransitBusinessDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    pickupTerminalPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    deliveryTerminalPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    quotedPriceByCarrierOriginalCurrency?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Quote
     */
    originalCurrency?: CurrencyCode;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quote
     */
    pickupAccessorials?: Array<string>;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof Quote
     */
    lineItems?: Array<LineItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quote
     */
    deliveryAccessorials?: Array<string>;
    /**
     * 
     * @type {RunQuoteInputLocation}
     * @memberof Quote
     */
    pickupLocation?: RunQuoteInputLocation;
    /**
     * 
     * @type {RunQuoteInputLocation}
     * @memberof Quote
     */
    deliveryLocation?: RunQuoteInputLocation;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    pickupLocationType?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    deliveryLocationType?: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    declaredValue?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Quote
     */
    declaredValueCurrency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quoteGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quoteHash?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Quote
     */
    hiddenFromCustomer?: boolean;
    /**
     * 
     * @type {QuoteGenerationMethod}
     * @memberof Quote
     */
    quoteGenerationMethod?: QuoteGenerationMethod;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    customerVisibleNote?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    internalNote?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    earliestPermittedPickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    latestPermittedPickupDate?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Quote
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {boolean}
     * @memberof Quote
     */
    exclusiveUseNeeded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    insurancePriceCad?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Quote
     */
    addInsuranceToShipment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    insuranceAmount?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Quote
     */
    insuranceCurrency?: CurrencyCode;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    priceUsd?: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    taxLineValueUsd1?: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    taxLineValueUsd2?: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    margin?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    brokeredCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    additionalCarrierFeesNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Quote
     */
    includesTax?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    quotedPriceByCarrierCadWithoutTax?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    customerPriceExplanation?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    carrierPriceExplanationInternal?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    carrierPriceExplanationExternal?: string;
    /**
     * 
     * @type {Array<AdditionalCarrierFee>}
     * @memberof Quote
     */
    additionalCarrierFees?: Array<AdditionalCarrierFee>;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    loadingDiagramSvg?: string;
}

/**
 * Check if a given object implements the Quote interface.
 */
export function instanceOfQuote(value: object): boolean {
    return true;
}

export function QuoteFromJSON(json: any): Quote {
    return QuoteFromJSONTyped(json, false);
}

export function QuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Quote {
    if (json == null) {
        return json;
    }
    return {
        
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'quotedPriceByCarrierCad': json['quotedPriceByCarrierCad'] == null ? undefined : json['quotedPriceByCarrierCad'],
        'additionalCarrierFeesCad': json['additionalCarrierFeesCad'] == null ? undefined : json['additionalCarrierFeesCad'],
        'taxLineDescription1': json['taxLineDescription_1'] == null ? undefined : json['taxLineDescription_1'],
        'taxLineValueCad1': json['taxLineValueCad_1'] == null ? undefined : json['taxLineValueCad_1'],
        'taxLineDescription2': json['taxLineDescription_2'] == null ? undefined : json['taxLineDescription_2'],
        'taxLineValueCad2': json['taxLineValueCad_2'] == null ? undefined : json['taxLineValueCad_2'],
        'priceCad': json['priceCad'] == null ? undefined : json['priceCad'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'carrierQuoteReference': json['carrierQuoteReference'] == null ? undefined : json['carrierQuoteReference'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'transitBusinessDays': json['transitBusinessDays'] == null ? undefined : json['transitBusinessDays'],
        'expectedDeliveryDate': json['expectedDeliveryDate'] == null ? undefined : json['expectedDeliveryDate'],
        'quotedPickupDate': json['quotedPickupDate'] == null ? undefined : json['quotedPickupDate'],
        'latestEstimatedDeliveryDate': json['latestEstimatedDeliveryDate'] == null ? undefined : json['latestEstimatedDeliveryDate'],
        'latestTransitBusinessDays': json['latestTransitBusinessDays'] == null ? undefined : json['latestTransitBusinessDays'],
        'pickupTerminalPhone': json['pickupTerminalPhone'] == null ? undefined : json['pickupTerminalPhone'],
        'deliveryTerminalPhone': json['deliveryTerminalPhone'] == null ? undefined : json['deliveryTerminalPhone'],
        'quotedPriceByCarrierOriginalCurrency': json['quotedPriceByCarrierOriginalCurrency'] == null ? undefined : json['quotedPriceByCarrierOriginalCurrency'],
        'originalCurrency': json['originalCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['originalCurrency']),
        'pickupAccessorials': json['pickupAccessorials'] == null ? undefined : json['pickupAccessorials'],
        'lineItems': json['lineItems'] == null ? undefined : ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'deliveryAccessorials': json['deliveryAccessorials'] == null ? undefined : json['deliveryAccessorials'],
        'pickupLocation': json['pickupLocation'] == null ? undefined : RunQuoteInputLocationFromJSON(json['pickupLocation']),
        'deliveryLocation': json['deliveryLocation'] == null ? undefined : RunQuoteInputLocationFromJSON(json['deliveryLocation']),
        'pickupLocationType': json['pickupLocationType'] == null ? undefined : json['pickupLocationType'],
        'deliveryLocationType': json['deliveryLocationType'] == null ? undefined : json['deliveryLocationType'],
        'declaredValue': json['declaredValue'] == null ? undefined : json['declaredValue'],
        'declaredValueCurrency': json['declaredValueCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['declaredValueCurrency']),
        'quoteGroup': json['quoteGroup'] == null ? undefined : json['quoteGroup'],
        'quoteHash': json['quoteHash'] == null ? undefined : json['quoteHash'],
        'hiddenFromCustomer': json['hiddenFromCustomer'] == null ? undefined : json['hiddenFromCustomer'],
        'quoteGenerationMethod': json['quoteGenerationMethod'] == null ? undefined : QuoteGenerationMethodFromJSON(json['quoteGenerationMethod']),
        'customerVisibleNote': json['customerVisibleNote'] == null ? undefined : json['customerVisibleNote'],
        'internalNote': json['internalNote'] == null ? undefined : json['internalNote'],
        'earliestPermittedPickupDate': json['earliestPermittedPickupDate'] == null ? undefined : json['earliestPermittedPickupDate'],
        'latestPermittedPickupDate': json['latestPermittedPickupDate'] == null ? undefined : json['latestPermittedPickupDate'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'exclusiveUseNeeded': json['exclusiveUseNeeded'] == null ? undefined : json['exclusiveUseNeeded'],
        'insurancePriceCad': json['insurancePriceCad'] == null ? undefined : json['insurancePriceCad'],
        'addInsuranceToShipment': json['addInsuranceToShipment'] == null ? undefined : json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'insuranceCurrency': json['insuranceCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['insuranceCurrency']),
        'priceUsd': json['priceUsd'] == null ? undefined : json['priceUsd'],
        'taxLineValueUsd1': json['taxLineValueUsd_1'] == null ? undefined : json['taxLineValueUsd_1'],
        'taxLineValueUsd2': json['taxLineValueUsd_2'] == null ? undefined : json['taxLineValueUsd_2'],
        'margin': json['margin'] == null ? undefined : json['margin'],
        'brokeredCarrierIdentifier': json['brokeredCarrierIdentifier'] == null ? undefined : json['brokeredCarrierIdentifier'],
        'additionalCarrierFeesNotes': json['additionalCarrierFeesNotes'] == null ? undefined : json['additionalCarrierFeesNotes'],
        'includesTax': json['includesTax'] == null ? undefined : json['includesTax'],
        'quotedPriceByCarrierCadWithoutTax': json['quotedPriceByCarrierCadWithoutTax'] == null ? undefined : json['quotedPriceByCarrierCadWithoutTax'],
        'customerPriceExplanation': json['customerPriceExplanation'] == null ? undefined : json['customerPriceExplanation'],
        'carrierPriceExplanationInternal': json['carrierPriceExplanationInternal'] == null ? undefined : json['carrierPriceExplanationInternal'],
        'carrierPriceExplanationExternal': json['carrierPriceExplanationExternal'] == null ? undefined : json['carrierPriceExplanationExternal'],
        'additionalCarrierFees': json['additionalCarrierFees'] == null ? undefined : ((json['additionalCarrierFees'] as Array<any>).map(AdditionalCarrierFeeFromJSON)),
        'loadingDiagramSvg': json['loadingDiagramSvg'] == null ? undefined : json['loadingDiagramSvg'],
    };
}

export function QuoteToJSON(value?: Quote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quoteId': value['quoteId'],
        'createdAt': value['createdAt'],
        'shipmentId': value['shipmentId'],
        'quotedPriceByCarrierCad': value['quotedPriceByCarrierCad'],
        'additionalCarrierFeesCad': value['additionalCarrierFeesCad'],
        'taxLineDescription_1': value['taxLineDescription1'],
        'taxLineValueCad_1': value['taxLineValueCad1'],
        'taxLineDescription_2': value['taxLineDescription2'],
        'taxLineValueCad_2': value['taxLineValueCad2'],
        'priceCad': value['priceCad'],
        'carrierIdentifier': value['carrierIdentifier'],
        'carrierQuoteReference': value['carrierQuoteReference'],
        'serviceIdentifier': value['serviceIdentifier'],
        'transitBusinessDays': value['transitBusinessDays'],
        'expectedDeliveryDate': value['expectedDeliveryDate'],
        'quotedPickupDate': value['quotedPickupDate'],
        'latestEstimatedDeliveryDate': value['latestEstimatedDeliveryDate'],
        'latestTransitBusinessDays': value['latestTransitBusinessDays'],
        'pickupTerminalPhone': value['pickupTerminalPhone'],
        'deliveryTerminalPhone': value['deliveryTerminalPhone'],
        'quotedPriceByCarrierOriginalCurrency': value['quotedPriceByCarrierOriginalCurrency'],
        'originalCurrency': CurrencyCodeToJSON(value['originalCurrency']),
        'pickupAccessorials': value['pickupAccessorials'],
        'lineItems': value['lineItems'] == null ? undefined : ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'deliveryAccessorials': value['deliveryAccessorials'],
        'pickupLocation': RunQuoteInputLocationToJSON(value['pickupLocation']),
        'deliveryLocation': RunQuoteInputLocationToJSON(value['deliveryLocation']),
        'pickupLocationType': value['pickupLocationType'],
        'deliveryLocationType': value['deliveryLocationType'],
        'declaredValue': value['declaredValue'],
        'declaredValueCurrency': CurrencyCodeToJSON(value['declaredValueCurrency']),
        'quoteGroup': value['quoteGroup'],
        'quoteHash': value['quoteHash'],
        'hiddenFromCustomer': value['hiddenFromCustomer'],
        'quoteGenerationMethod': QuoteGenerationMethodToJSON(value['quoteGenerationMethod']),
        'customerVisibleNote': value['customerVisibleNote'],
        'internalNote': value['internalNote'],
        'earliestPermittedPickupDate': value['earliestPermittedPickupDate'],
        'latestPermittedPickupDate': value['latestPermittedPickupDate'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'exclusiveUseNeeded': value['exclusiveUseNeeded'],
        'insurancePriceCad': value['insurancePriceCad'],
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyCodeToJSON(value['insuranceCurrency']),
        'priceUsd': value['priceUsd'],
        'taxLineValueUsd_1': value['taxLineValueUsd1'],
        'taxLineValueUsd_2': value['taxLineValueUsd2'],
        'margin': value['margin'],
        'brokeredCarrierIdentifier': value['brokeredCarrierIdentifier'],
        'additionalCarrierFeesNotes': value['additionalCarrierFeesNotes'],
        'includesTax': value['includesTax'],
        'quotedPriceByCarrierCadWithoutTax': value['quotedPriceByCarrierCadWithoutTax'],
        'customerPriceExplanation': value['customerPriceExplanation'],
        'carrierPriceExplanationInternal': value['carrierPriceExplanationInternal'],
        'carrierPriceExplanationExternal': value['carrierPriceExplanationExternal'],
        'additionalCarrierFees': value['additionalCarrierFees'] == null ? undefined : ((value['additionalCarrierFees'] as Array<any>).map(AdditionalCarrierFeeToJSON)),
        'loadingDiagramSvg': value['loadingDiagramSvg'],
    };
}


import { useMsal } from "@azure/msal-react";
import { Button, Form, message, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import showdown from "showdown";
import { useCompaniesApi, useTechnicalApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import {
  EmailTemplate,
  EmailTemplateImage,
  SendSalesEmailInput,
} from "../../generated-openapi-client";
import { addEmailSignature } from "../../Helpers/addEmailSignature";
import { unique } from "../../Helpers/unique";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";
import { EditSalesEmailBodyButton } from "./EditSalesEmailBodyButton";
import { EditSalesEmailSubjectButton } from "./EditSalesEmailSubjectButton";
import { ManageRecipientsDropdown } from "./ManageRecipientsDropdown";
import { OtherRecipientsDropdown } from "./OtherRecipientsDropdown";
import { SalesEmailTemplateDropdown } from "./SalesEmailTemplateDropdown";

const { Text } = Typography;

// We need to go into outlook admin and enable 'Send As' under delegation for each users
// And mark Amy as able to send emails as them
// 'Send as' is the first one of the delegation options
// It takes maybe 20 minutes for it to become active - so might fail inititally for a bit
export const emailSenders = [
  {
    email: "natasha@freightsimple.com",
    firstName: "Natasha",
    lastName: "Ross",
    phoneNumber: "(780) 660-4807",
  },
  {
    email: "chris@freightsimple.com",
    firstName: "Chris",
    lastName: "Stott",
    phoneNumber: "(778) 668-5574",
  },
  {
    email: "enma@freightsimple.com",
    firstName: "Enma",
    lastName: "Leon",
    phoneNumber: "(604) 921-3915",
  },
];

export function SalesforceSendEmailButton(props: CompanyTabProps) {
  const [sending, setSending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailIdentifier, setEmailIdentifier] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedSalesContactIds, setSelectedContactIds] = useState<string[]>(
    []
  );
  const [emailBody, setEmailBody] = useState("");
  const converter = new showdown.Converter();
  converter.setFlavor("github");
  const [otherEmails, setOtherEmails] = useState<string[]>([]);

  const contacts = props.data.salesContacts.filter((o) => o.email);
  const companyId = props.data.company.companyId!!;

  const createCompaniesApi = useCompaniesApi();

  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>();
  const [emailTemplateImages, setEmailTemplateImages] =
    useState<EmailTemplateImage[]>();
  const createTechnicalApi = useTechnicalApi();

  async function refresh() {
    try {
      const technicalApi = await createTechnicalApi();
      const response = await technicalApi.getEmailTemplates();
      setEmailTemplates(response.emailTemplates);
      setEmailTemplateImages(response.emailTemplateImages);
    } catch (e) {
      message.error(`Something went wrong : ${e}`);
    }
  }

  useEffect(
    function () {
      if (isModalVisible) {
        refresh();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible]
  );

  function reset() {
    setSubject("");
    setSelectedContactIds([]);
    setEmailBody("");
    setOtherEmails([]);
  }

  const addresses = unique([
    ...selectedSalesContactIds.map(
      (salesContactId) =>
        contacts.find((c) => c.salesContactId === salesContactId)!!.email!!
    ),
  ]);
  const title = `✉️ Send Email`;

  const salesContactId =
    selectedSalesContactIds.length > 0
      ? contacts.find((c) => c.salesContactId === selectedSalesContactIds[0])
          ?.salesContactId
      : undefined;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const currentUserEmail = activeAccount!!.username;

  const sender = emailSenders.find((o) => o.email === currentUserEmail);

  if (sender === undefined) {
    return <div>No email profile created for this apollo user</div>;
  }

  const renderedEmailBody = addEmailSignature(
    converter.makeHtml(emailBody),
    sender.firstName,
    sender.lastName,
    sender.phoneNumber,
    ""
  );

  async function handleOk() {
    if (sender === undefined) {
      message.warn("No email profile created for this apollo user");
      return;
    }

    if (addresses.length === 0) {
      message.warn("There are no recipients");
      return;
    }

    if (salesContactId === undefined) {
      message.warn("Please select a customer");
      return;
    }

    if (subject.trim() === "") {
      message.warn("Subject is empty");
      return;
    }

    if (emailBody.trim() === "") {
      message.warn("Email is empty");
      return;
    }

    setSending(true);
    try {
      const input: SendSalesEmailInput = {
        companyId,
        salesContactId,
        emailAddresses: addresses,
        ccEmailAddresses: otherEmails,
        subject: subject,
        body: renderedEmailBody,
        unprocessedBody: emailBody,
        attachments: [],
        emailIdentifier: emailIdentifier,
      };

      const companiesApi = await createCompaniesApi();
      const response = await companiesApi.sendEmail({
        sendSalesEmailInput: input,
      });
      if (response.success) {
        setIsModalVisible(false);
        await props.onRefresh();
        message.success("Email sent!");
        reset();
      } else {
        message.error(
          `Error sending : ${response.errorMessage}. Check your Sent items to see if this sent`
        );
      }
    } catch (e: any) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSending(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={`Send email to ${props.data.company.companyName}`}
        visible={isModalVisible}
        onOk={handleOk}
        okText="✉️ Send email"
        confirmLoading={sending}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Stack align="left" style={{ minHeight: "700px" }}>
          <HorizontalStack
            verticalAlign="middle"
            spacing={16}
            width="100%"
            style={{
              backgroundColor: Colors.VeryVeryLightBlue,
              padding: "8px",
              position: "relative",
              top: "-16px",
            }}
          >
            <HorizontalStack>
              <div style={{ marginRight: "8px" }}>Template</div>
              {emailTemplates && (
                <SalesEmailTemplateDropdown
                  companyId={companyId}
                  onChangeTemplate={function (
                    _emailIdentifier,
                    _subject,
                    _emailBody
                  ) {
                    setEmailIdentifier(_emailIdentifier);
                    setSubject(_subject);
                    setEmailBody(_emailBody);
                  }}
                  emailTemplates={emailTemplates}
                />
              )}
            </HorizontalStack>
            <Spacer width={8} />
            {/* This is there to trick auto-complete. Somehow safari decides to pick the following dropdowns and offer to autocomplete your own email address. This isn't helpful - but if we put a hidden email box there, I guess it attaches to that instead */}
            <input type="email" style={{ display: "none" }} />
            <HorizontalStack>
              <div style={{ marginRight: "8px" }}>Recipients</div>
              <ManageRecipientsDropdown
                contacts={contacts}
                selectedContactIds={selectedSalesContactIds}
                setSelectedContactIds={setSelectedContactIds}
              />
            </HorizontalStack>
            <HorizontalStack>
              <div style={{ marginRight: "8px" }}>Others</div>
              <OtherRecipientsDropdown
                otherEmails={otherEmails}
                setOtherEmails={setOtherEmails}
              />
            </HorizontalStack>

            {/* 
            <ManageAttachmentsButton
              shipmentData={props.shipmentData}
              attachments={attachments}
              onUpdateAttachments={setAttachments}
            /> */}
          </HorizontalStack>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <>
              <Form.Item label="From" style={{ marginBottom: "0" }}>
                <Text>{sender.email}</Text>
              </Form.Item>
              <Form.Item label="Recipients" style={{ marginBottom: "0" }}>
                <Text>{addresses.join(", ")}</Text>
              </Form.Item>
              {otherEmails.length > 0 && (
                <Form.Item label="CC" style={{ marginBottom: "0" }}>
                  <Text>{otherEmails.join(", ")}</Text>
                </Form.Item>
              )}
              <Form.Item
                label={
                  <Stack align="right">
                    <div>Subject</div>
                    <Spacer width={8} />
                    <EditSalesEmailSubjectButton
                      subject={subject}
                      setSubject={setSubject}
                    />
                  </Stack>
                }
              >
                <HorizontalStack verticalAlign="middle">
                  {subject.trim() === "" && (
                    <Text style={{ color: Colors.LightText }}>
                      (Missing subject)
                    </Text>
                  )}
                  {subject.trim() !== "" && <Text>{subject}</Text>}
                </HorizontalStack>
              </Form.Item>
              <Form.Item
                label={
                  <Stack align="right">
                    <div>Email Body</div>
                    <Spacer height={8} />
                    {emailTemplateImages !== undefined && (
                      <EditSalesEmailBodyButton
                        emailBody={emailBody}
                        setEmailBody={setEmailBody}
                        senderFirstName={sender.firstName}
                        senderLastName={sender.lastName}
                        senderPhoneNumber={sender.phoneNumber}
                        emailTemplateImages={emailTemplateImages}
                      />
                    )}
                  </Stack>
                }
              >
                <Stack align="left">
                  <div
                    style={{
                      border: "1px solid #eee",
                      padding: "16px",
                      width: "1100px",
                    }}
                  >
                    <div
                      title="Email Preview"
                      dangerouslySetInnerHTML={{
                        __html: renderedEmailBody,
                      }}
                    ></div>
                  </div>
                </Stack>
              </Form.Item>
            </>
          </Form>
        </Stack>
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentType,
  ImageResponse,
  PdfResponse,
} from '../models/index';
import {
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    ImageResponseFromJSON,
    ImageResponseToJSON,
    PdfResponseFromJSON,
    PdfResponseToJSON,
} from '../models/index';

export interface CreateFromExistingDocumentRequest {
    shipmentId: string;
    companyId: string;
    documentS3Path: string;
    pages: Array<number>;
    documentType: DocumentType;
}

export interface DownloadDocumentByIdRequest {
    documentId: string;
}

export interface DownloadDocumentByS3PathRequest {
    documentPath: string;
}

export interface DownloadThumbnailByS3PathRequest {
    documentPath: string;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     */
    async createFromExistingDocumentRaw(requestParameters: CreateFromExistingDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling createFromExistingDocument().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling createFromExistingDocument().'
            );
        }

        if (requestParameters['documentS3Path'] == null) {
            throw new runtime.RequiredError(
                'documentS3Path',
                'Required parameter "documentS3Path" was null or undefined when calling createFromExistingDocument().'
            );
        }

        if (requestParameters['pages'] == null) {
            throw new runtime.RequiredError(
                'pages',
                'Required parameter "pages" was null or undefined when calling createFromExistingDocument().'
            );
        }

        if (requestParameters['documentType'] == null) {
            throw new runtime.RequiredError(
                'documentType',
                'Required parameter "documentType" was null or undefined when calling createFromExistingDocument().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['documentS3Path'] != null) {
            queryParameters['documentS3Path'] = requestParameters['documentS3Path'];
        }

        if (requestParameters['pages'] != null) {
            queryParameters['pages'] = requestParameters['pages'];
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['documentType'] = requestParameters['documentType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/documents/create-from-existing-document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createFromExistingDocument(requestParameters: CreateFromExistingDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFromExistingDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async downloadDocumentByIdRaw(requestParameters: DownloadDocumentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling downloadDocumentById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['documentId'] != null) {
            queryParameters['documentId'] = requestParameters['documentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/documents/download-document-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     */
    async downloadDocumentById(requestParameters: DownloadDocumentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.downloadDocumentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downloadDocumentByS3PathRaw(requestParameters: DownloadDocumentByS3PathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['documentPath'] == null) {
            throw new runtime.RequiredError(
                'documentPath',
                'Required parameter "documentPath" was null or undefined when calling downloadDocumentByS3Path().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['documentPath'] != null) {
            queryParameters['documentPath'] = requestParameters['documentPath'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/documents/download-document`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     */
    async downloadDocumentByS3Path(requestParameters: DownloadDocumentByS3PathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.downloadDocumentByS3PathRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async downloadThumbnailByS3PathRaw(requestParameters: DownloadThumbnailByS3PathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImageResponse>> {
        if (requestParameters['documentPath'] == null) {
            throw new runtime.RequiredError(
                'documentPath',
                'Required parameter "documentPath" was null or undefined when calling downloadThumbnailByS3Path().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['documentPath'] != null) {
            queryParameters['documentPath'] = requestParameters['documentPath'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/documents/download-thumbnail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageResponseFromJSON(jsonValue));
    }

    /**
     */
    async downloadThumbnailByS3Path(requestParameters: DownloadThumbnailByS3PathRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageResponse> {
        const response = await this.downloadThumbnailByS3PathRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

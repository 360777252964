import { CopyTwoTone } from "@ant-design/icons";
import { message } from "antd";
import { ReactNode, useEffect } from "react";
import useClipboard from "react-use-clipboard";
import styled from "styled-components";
import Spacer from "../Spacer";
import HorizontalStack from "./HorizontalStack";

const Hover = styled.div`
  &:hover {
    filter: brightness(90%);
  }
`;

interface WithClipboardLinkProps {
  children: ReactNode[] | ReactNode | string[] | string | undefined;
  copyText?: string;
}

function RenderArray(props: WithClipboardLinkProps) {
  if (Array.isArray(props.children)) {
    return (
      <div>
        {props.children.map(function (m, i) {
          return <div key={i}>{m}</div>;
        })}
      </div>
    );
  } else {
    return <div>{props.children}</div>;
  }
}

export function WithClipboardLink(props: WithClipboardLinkProps) {
  function contentToCopy(): string {
    if (props.copyText !== undefined) {
      return props.copyText;
    }

    if (Array.isArray(props.children)) {
      return props.children.join("\n");
    }
    return (props.children as string) || "";
  }
  const content = contentToCopy();
  const [isCopied, setCopied] = useClipboard(content);

  useEffect(
    function () {
      if (isCopied) {
        message.success(`'${content}' copied to clipboard`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCopied]
  );

  if (props.children === undefined) {
    return <></>;
  }

  if (content === undefined || content === "") {
    return <>{props.children}</>;
  }
  return (
    <Hover onClick={setCopied} style={{ cursor: "pointer" }}>
      <HorizontalStack verticalAlign="middle">
        <RenderArray>{props.children}</RenderArray>
        <Spacer width={8} />
        <CopyTwoTone twoToneColor="#ccc" />
      </HorizontalStack>
    </Hover>
  );
}

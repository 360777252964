import { Button, Tooltip } from "antd";
import { useState } from "react";
import {
  AdditionalCharge,
  AdditionalChargeState,
  CarrierInvoiceState,
  InvoiceAuditProblemConfidence,
  InvoiceAuditProblemFault,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { AdditionalChargeEmailComponent } from "./AdditionalChargeEmailComponent";
import { ApproveAdditionalChargeComponent } from "./ApproveAdditionalChargeComponent";
import { TabProps } from "./TabProps";

interface ApproveAdditionalChargeButtonProps extends TabProps {
  additionalCharge: AdditionalCharge;
  shipmentData: ViewShipmentDataForApollo;
}

export function ApproveAdditionalChargeButton(
  props: ApproveAdditionalChargeButtonProps
) {
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [associatedProblemIds, setAssociatedProblemIds] = useState<string[]>(
    []
  );

  function generateDisabledReason(): string | undefined {
    if (
      !props.shipmentData.shipment.shipment.invoiceAuditProblems!!.some(
        (iap) =>
          iap.fault === InvoiceAuditProblemFault.Customer &&
          (iap.confidence === InvoiceAuditProblemConfidence.High ||
            iap.confidence === InvoiceAuditProblemConfidence.Medium)
      )
    ) {
      return "No High/Medium confidence customer problems";
    }

    if (
      props.shipmentData.billingTab.carrierInvoices.some(
        (ci) =>
          !ci.problemsIdentified &&
          ci.invoiceState !== CarrierInvoiceState.Voided
      )
    ) {
      return "Identify all problems in carrier invoices before making additional charge";
    }
  }

  const disabledReason = generateDisabledReason();

  async function showEmailModal(_associatedProblemIds: string[]) {
    setIsEmailModalVisible(true);
    setAssociatedProblemIds(_associatedProblemIds);
  }

  if (props.additionalCharge.state !== AdditionalChargeState.Proposed) {
    return <></>;
  }

  return (
    <>
      <ApproveAdditionalChargeComponent
        {...props}
        isApproveModalVisible={isApproveModalVisible}
        setIsApproveModalVisible={setIsApproveModalVisible}
        showEmailModal={showEmailModal}
      />
      <AdditionalChargeEmailComponent
        {...props}
        isEmailModalVisible={isEmailModalVisible}
        setIsEmailModalVisible={setIsEmailModalVisible}
        associatedProblemIds={associatedProblemIds}
      />
      <Tooltip title={disabledReason}>
        <Button
          disabled={disabledReason !== undefined}
          onClick={function () {
            setIsApproveModalVisible(true);
          }}
        >
          👍 Approve
        </Button>
      </Tooltip>
    </>
  );
}

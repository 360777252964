import { Button, message } from "antd";
import { ReactNode, useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  AdditionalCharge,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { AdditionalChargeState } from "../../generated-openapi-client/models/AdditionalChargeState";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButtonBase } from "./SupportEmailButton";

interface AdditionalChargeSupportEmailBaseProps {
  data: ViewShipmentDataForApollo;
  additionalCharge: AdditionalCharge;
  button: (label: string, onClick: () => void) => ReactNode;
}

export function AdditionalChargeSupportEmailBase(
  props: AdditionalChargeSupportEmailBaseProps
) {
  const shipmentId = props.data.shipmentId;
  const additionalCharge = props.additionalCharge;
  const additionalChargeId = additionalCharge.additionalChargeId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  if (additionalCharge.state !== AdditionalChargeState.Approved) {
    return <></>;
  }

  const hash = JSON.stringify({
    additionalChargeId,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButtonBase
      label="Additional Charge Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
      button={props.button}
    />
  );
}

interface AdditionalChargeSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  additionalCharge: AdditionalCharge;
}

export function AdditionalChargeSupportEmailButton(
  props: AdditionalChargeSupportEmailButtonProps
) {
  return (
    <AdditionalChargeSupportEmailBase
      {...props}
      button={function (label: string, onClick: () => void) {
        return <Button onClick={onClick}>{label}</Button>;
      }}
    />
  );
}

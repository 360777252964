import {
  BankOutlined,
  BoxPlotOutlined,
  CalendarOutlined,
  CarOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Tag, Typography } from "antd";
import HorizontalStack from "../../Components/HorizontalStack";
import {
  ApolloMapCompany,
  ApolloMapQuery,
} from "../../generated-openapi-client";
import { ApolloMapQueryBookingStatus } from "../../generated-openapi-client/models/ApolloMapQueryBookingStatus";
import { QuoteFilterType } from "../../generated-openapi-client/models/QuoteFilterType";
import { describeState } from "../../Helpers/describeState";
import { emojiForShipmentVolume } from "../../Helpers/emojiForShipmentVolume";

const { Text } = Typography;

interface AllShipmentsScreenActiveFiltersProps {
  query: ApolloMapQuery;
  companies: ApolloMapCompany[] | undefined;
}

interface FilterTagProps {
  icon?: React.ReactNode;
  label: string;
  color?: string;
}

function FilterTag({ icon, label, color = "#108ee9" }: FilterTagProps) {
  return (
    <Tag
      color={color}
      style={{ display: "flex", alignItems: "center", gap: "4px" }}
    >
      {icon}
      <span>{label}</span>
    </Tag>
  );
}

function formatWeight(weight: number): string {
  return `${weight.toLocaleString()} lbs`;
}

function formatVolume(volume: number): string {
  return `${volume.toLocaleString()} ft³`;
}

export function AllShipmentsScreenActiveFilters(
  props: AllShipmentsScreenActiveFiltersProps
) {
  const filters: JSX.Element[] = [];
  const { companies, query } = props;

  function lookupCompany() {
    if (companies === undefined) {
      return "Loading";
    }
    const foundCompany = companies.find(function (c) {
      return c.companyId === query.companyId;
    });

    if (foundCompany === undefined) {
      return "Unknown";
    }

    return foundCompany?.companyName;
  }

  // City filters
  if (query.pickupCity || query.deliveryCity) {
    const locationText = [
      query.pickupCity && `From: ${query.pickupCity}`,
      query.deliveryCity && `To: ${query.deliveryCity}`,
    ]
      .filter(Boolean)
      .join(" → ");

    filters.push(
      <FilterTag
        key="location"
        icon={<EnvironmentOutlined />}
        label={locationText}
        color="blue"
      />
    );
  }

  // Location type filters
  if (query.pickupLocationType || query.deliveryLocationType) {
    const locationTypeText = [
      query.pickupLocationType && `Pickup: ${query.pickupLocationType}`,
      query.deliveryLocationType && `Delivery: ${query.deliveryLocationType}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push(
      <FilterTag
        key="locationTypes"
        icon={<DashboardOutlined />}
        label={`Location Types: ${locationTypeText}`}
        color="cyan"
      />
    );
  }

  // Booking status
  if (
    query.bookingStatus &&
    query.bookingStatus !== ApolloMapQueryBookingStatus.Any
  ) {
    filters.push(
      <FilterTag
        key="status"
        icon={<DashboardOutlined />}
        label={
          query.bookingStatus === ApolloMapQueryBookingStatus.Booked
            ? "Booked Only"
            : "Quoted Only"
        }
        color="green"
      />
    );
  }

  // Company filter
  if (query.companyId) {
    const queryCompanyName = query.companyId ? lookupCompany() : undefined;
    filters.push(
      <FilterTag
        key="company"
        icon={<BankOutlined />}
        label={`Company: ${queryCompanyName}`}
        color="purple"
      />
    );
  }

  // Carrier filters
  if (query.bookedCarrierIdentifier) {
    filters.push(
      <FilterTag
        key="carrier"
        icon={<CarOutlined />}
        label={`Booked Carrier: ${query.bookedCarrierIdentifier}`}
        color="orange"
      />
    );
  }

  if (query.inCarrierCoverageAreaCarrierIdentifier) {
    filters.push(
      <FilterTag
        key="coverage"
        icon={<CarOutlined />}
        label={`Carrier Coverage: ${query.inCarrierCoverageAreaCarrierIdentifier}`}
        color="orange"
      />
    );
  }

  if (query.hadQuotesFromCarrierIdentifier) {
    filters.push(
      <FilterTag
        key="coverage"
        icon={<CarOutlined />}
        label={`Has Quotes: ${query.hadQuotesFromCarrierIdentifier}`}
        color="orange"
      />
    );
  }

  if (query.lowestRateCarrierIdentifier) {
    filters.push(
      <FilterTag
        key="coverage"
        icon={<CarOutlined />}
        label={`Lowest Rate: ${query.lowestRateCarrierIdentifier}`}
        color="orange"
      />
    );
  }

  // Date range
  if (query.startDate && query.endDate) {
    const startDate = new Date(query.startDate).toLocaleDateString();
    const endDate = new Date(query.endDate).toLocaleDateString();
    filters.push(
      <FilterTag
        key="dates"
        icon={<CalendarOutlined />}
        label={`${startDate} - ${endDate}`}
        color="cyan"
      />
    );
  }

  // Weight filters
  if (query.minimumShipmentWeight || query.maximumShipmentWeight) {
    const weightText = [
      query.minimumShipmentWeight &&
        `Min: ${formatWeight(query.minimumShipmentWeight)}`,
      query.maximumShipmentWeight &&
        `Max: ${formatWeight(query.maximumShipmentWeight)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push(
      <FilterTag
        key="weight"
        icon={<BoxPlotOutlined />}
        label={`Weight: ${weightText}`}
        color="magenta"
      />
    );
  }

  // Units filters
  if (query.minimumNumberHandlingUnits || query.maximumNumberHandlingUnits) {
    const unitsText = [
      query.minimumNumberHandlingUnits &&
        `Min: ${query.minimumNumberHandlingUnits}`,
      query.maximumNumberHandlingUnits &&
        `Max: ${query.maximumNumberHandlingUnits}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push(
      <FilterTag
        key="units"
        icon={<BoxPlotOutlined />}
        label={`Units: ${unitsText}`}
        color="magenta"
      />
    );
  }

  // Volume filters
  if (query.minimumVolume || query.maximumVolume) {
    const volumeText = [
      query.minimumVolume && `Min: ${formatVolume(query.minimumVolume)}`,
      query.maximumVolume && `Max: ${formatVolume(query.maximumVolume)}`,
    ]
      .filter(Boolean)
      .join(", ");

    filters.push(
      <FilterTag
        key="volume"
        icon={<BoxPlotOutlined />}
        label={`Volume: ${volumeText}`}
        color="magenta"
      />
    );
  }

  // Free text search filter
  if (query.freeTextQuery) {
    filters.push(
      <FilterTag
        key="freeText"
        icon={<SearchOutlined />}
        label={`Search: ${query.freeTextQuery}`}
        color="default"
      />
    );
  }

  // Shipment state filter
  if (query.shipmentState) {
    filters.push(
      <FilterTag
        key="shipmentState"
        icon={<DashboardOutlined />}
        label={`State: ${describeState(query.shipmentState)}`}
        color="blue"
      />
    );
  }

  // Quote filter type
  if (query.quoteFilter) {
    filters.push(
      <FilterTag
        key="quoteFilter"
        icon={<DashboardOutlined />}
        label={`Quote Filter: ${QuoteFilterType[query.quoteFilter]}`}
        color="green"
      />
    );
  }

  // Shipment frequency filter
  if (query.companyShipmentFrequency) {
    filters.push(
      <FilterTag
        key="shipmentFrequency"
        icon={<FieldTimeOutlined />}
        label={`Frequency: ${emojiForShipmentVolume(
          query.companyShipmentFrequency
        )} ${query.companyShipmentFrequency}`}
        color="geekblue"
      />
    );
  }

  // Company first quoted date range
  if (
    query.companyFirstQuotedDateStartDate &&
    query.companyFirstQuotedDateEndDate
  ) {
    const startDate = new Date(
      query.companyFirstQuotedDateStartDate
    ).toLocaleDateString();
    const endDate = new Date(
      query.companyFirstQuotedDateEndDate
    ).toLocaleDateString();
    filters.push(
      <FilterTag
        key="firstQuotedDates"
        icon={<FieldTimeOutlined />}
        label={`First Quoted: ${startDate} - ${endDate}`}
        color="cyan"
      />
    );
  }

  // Accessorial filters
  if (query.pickupLiftGateRequired !== undefined) {
    filters.push(
      <FilterTag
        key="pickupLiftgate"
        icon={<BoxPlotOutlined />}
        label={`Pickup Liftgate: ${
          query.pickupLiftGateRequired ? "Required" : "Not Required"
        }`}
        color={query.pickupLiftGateRequired ? "orange" : "red"}
      />
    );
  }

  if (query.deliveryLiftGateRequired !== undefined) {
    filters.push(
      <FilterTag
        key="deliveryLiftgate"
        icon={<BoxPlotOutlined />}
        label={`Delivery Liftgate: ${
          query.deliveryLiftGateRequired ? "Required" : "Not Required"
        }`}
        color={query.deliveryLiftGateRequired ? "orange" : "red"}
      />
    );
  }

  if (query.pickupInsideRequired !== undefined) {
    filters.push(
      <FilterTag
        key="pickupInside"
        icon={<BoxPlotOutlined />}
        label={`Inside Pickup: ${
          query.pickupInsideRequired ? "Required" : "Not Required"
        }`}
        color={query.pickupInsideRequired ? "orange" : "red"}
      />
    );
  }

  if (query.deliveryInsideRequired !== undefined) {
    filters.push(
      <FilterTag
        key="deliveryInside"
        icon={<BoxPlotOutlined />}
        label={`Inside Delivery: ${
          query.deliveryInsideRequired ? "Required" : "Not Required"
        }`}
        color={query.deliveryInsideRequired ? "orange" : "red"}
      />
    );
  }

  if (query.dangerous !== undefined) {
    filters.push(
      <FilterTag
        key="dangerous"
        icon={<BoxPlotOutlined />}
        label={`Dangerous Goods: ${query.dangerous ? "Yes" : "No"}`}
        color={query.dangerous ? "orange" : "red"}
      />
    );
  }

  if (query.protectFromFreezingRequired !== undefined) {
    filters.push(
      <FilterTag
        key="freezing"
        icon={<BoxPlotOutlined />}
        label={`Protect from Freezing: ${
          query.protectFromFreezingRequired ? "Required" : "Not Required"
        }`}
        color={query.protectFromFreezingRequired ? "orange" : "red"}
      />
    );
  }

  if (query.deliveryAppointmentRequired !== undefined) {
    filters.push(
      <FilterTag
        key="appointment"
        icon={<BoxPlotOutlined />}
        label={`Delivery Appointment: ${
          query.deliveryAppointmentRequired ? "Required" : "Not Required"
        }`}
        color={query.deliveryAppointmentRequired ? "orange" : "red"}
      />
    );
  }

  if (filters.length === 0) {
    return (
      <Text type="secondary" style={{ fontSize: "14px" }}>
        No active filters
      </Text>
    );
  }

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        marginLeft: "8px",
        marginRight: "16px",
        position: "relative",
      }}
    >
      <div style={{ overflowX: "auto", scrollbarWidth: "none" }}>
        <HorizontalStack style={{ marginLeft: "8px" }} spacing={8}>
          {filters}
        </HorizontalStack>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "20px",
          height: "100%",
          background: "linear-gradient(to right, transparent, white)",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "20px",
          height: "100%",
          background: "linear-gradient(to left, transparent, white)",
          pointerEvents: "none",
        }}
      />
    </div>
  );
}

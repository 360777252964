import { CountryCode } from "../generated-openapi-client/models/CountryCode";
import { assertNever } from "../Helpers/assertNever";
import {
  EnumDropdown,
  OptionalEnumDropdown,
} from "../Screens/ViewShipmentScreenComponents/EnumDropdown";

export const CountryCodeDropdown = EnumDropdown<
  CountryCode,
  typeof CountryCode
>(CountryCode, function (et) {
  switch (et) {
    case CountryCode.Ca:
      return "Canada";
    case CountryCode.Us:
      return "USA";
    default:
      assertNever(et);
  }
});

export const OptionalCountryCodeDropdown = OptionalEnumDropdown<
  CountryCode,
  typeof CountryCode
>(CountryCode, "Unknown", function (et) {
  switch (et) {
    case CountryCode.Ca:
      return "Canada";
    case CountryCode.Us:
      return "USA";
    default:
      assertNever(et);
  }
});

import { ShipmentState } from "../generated-openapi-client/models/ShipmentState";
import { assertNever } from "./assertNever";

export function describeState(state: ShipmentState): string {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return "Booking Confirmed";
    case ShipmentState.BookingFailed:
      return "Booking Failed";
    case ShipmentState.BookingRequested:
      return "Booking Requested";
    case ShipmentState.Cancelled:
      return "Cancelled";
    case ShipmentState.Lost:
      return "Lost";
    case ShipmentState.Delivered:
      return "Delivered";
    case ShipmentState.InTransit:
      return "In Transit";
    case ShipmentState.QuoteRequested:
      return "Quote Requested";
    case ShipmentState.Quoted:
      return "Quoted";
    case ShipmentState.OnHold:
      return "On Hold";
    default:
      assertNever(state);
  }
}

import { Button, Form, message, Modal, Typography } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useCarrierInvoiceAuditApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { DisplayMarkdown } from "../../Components/ViewMarkdownButton";
import { InvoiceAuditProblemType } from "../../generated-openapi-client";

const { Title } = Typography;

interface EditInvoiceAuditProblemTypeNotesButtonProps {
  invoiceAuditProblemType: InvoiceAuditProblemType;
  notes: string | undefined;
  onRefreshProblemTypeNotes: () => Promise<void>;
}

export function EditInvoiceAuditProblemTypeNotesButton(
  props: EditInvoiceAuditProblemTypeNotesButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { invoiceAuditProblemType } = props;
  const [notes, setNotes] = useState(props.notes ?? "");

  const [saving, setSaving] = useState(false);
  const createCarrierInvoiceAuditApi = useCarrierInvoiceAuditApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setSaving(true);

    try {
      const carrierInvoiceAuditApi = await createCarrierInvoiceAuditApi();
      await carrierInvoiceAuditApi.setNoteForInvoiceAuditProblemType({
        invoiceAuditProblemType,
        notes,
      });

      await props.onRefreshProblemTypeNotes();
      message.success("Saved!");
    } catch (e) {
      message.error("Eek. Something went wrong");
    }
    setSaving(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={
          <Stack align="left">
            <div>Edit Invoice Audit Problem Type Notes</div>
            <div style={{ color: Colors.LightText, fontSize: "12px" }}>
              Use markdown format to apply formatting like bullet points and
              links to these notes. You can learn about this here:{" "}
              <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                View Markdown Guide
              </a>
            </div>
          </Stack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        okText="Save"
        confirmLoading={saving}
        onCancel={handleCancel}
        width={1400}
      >
        <HorizontalStack verticalAlign="top">
          <Form
            style={{ width: "50%" }}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item label="Notes">
              <TextArea
                style={{ width: "600px", height: "100px" }}
                value={notes}
                onChange={function (e) {
                  setNotes(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
          <div
            style={{ width: "50%", border: "1px solid #ccc", padding: "32px" }}
          >
            <Title level={4}>Preview</Title>
            <div>
              <DisplayMarkdown data={notes} />
            </div>
          </div>
        </HorizontalStack>
      </Modal>
      <Button onClick={showModal} {...props} type="link">
        Edit
      </Button>
    </>
  );
}

import { Button, message, Popconfirm, Tooltip } from "antd";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import {
  CustomerInvoice,
  CustomerInvoiceState,
} from "../../generated-openapi-client";

interface PayCustomerInvoiceButtonProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

export function PayCustomerInvoiceButton(props: PayCustomerInvoiceButtonProps) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();
  const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
  async function confirm() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    try {
      const response = await customerInvoiceApi.payInvoice({
        customerInvoiceId,
      });
      await props.onRefresh();
      if (response.success) {
        message.success("Payment Processed");
      } else {
        message.error(`Failed : ${response.message}`);
      }
    } catch (e: any) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  const enabled =
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued;

  if (!enabled) {
    return <></>;
  }

  return (
    <Popconfirm
      title="Are you sure to pay this invoice? It will attempt to charge the current payment method associated with this shipment"
      onConfirm={confirm}
      okText="Yes, charge the payment method"
      cancelText="Cancel"
      disabled={!enabled}
    >
      <Tooltip
        placement="topLeft"
        title="Use this if you need to charge the customer for a shipment on their attached payment method. This might happen if they are on account, or if a credit card charge failed"
      >
        <Button disabled={!enabled} danger>
          Process Payment
        </Button>
      </Tooltip>
    </Popconfirm>
  );
}

import { Select, Spin } from "antd";
import { useState } from "react";
import { useMapsApi } from "../../Apis/Apis";
import { ApolloMapLocationFilterGroup } from "../../generated-openapi-client";
import { ApolloMapLocationFilterType } from "../../generated-openapi-client/models/ApolloMapLocationFilterType";
import { useOnce } from "../../Hooks/useOnce";

const { Option, OptGroup } = Select;

interface LocationDropdownProps {
  locationFilterType: ApolloMapLocationFilterType | undefined | null;
  setLocationFilterType: (_: ApolloMapLocationFilterType | undefined) => void;
}

export function LocationDropdown(props: LocationDropdownProps) {
  const createMapsApi = useMapsApi();
  const [filterGroups, setFilterGroups] = useState<
    ApolloMapLocationFilterGroup[] | undefined
  >();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getLocationFilterOptions();
    setFilterGroups(response);
  });

  if (filterGroups === undefined) {
    return <Spin size="small" />;
  }

  return (
    <Select
      allowClear={true}
      showSearch
      style={{ width: 220 }}
      value={props.locationFilterType}
      placeholder="Filter Type"
      optionFilterProp="children"
      onSelect={function (_filterGroupType: ApolloMapLocationFilterType) {
        props.setLocationFilterType(_filterGroupType);
      }}
      onClear={function () {
        props.setLocationFilterType(undefined);
      }}
      filterOption={function (input, option) {
        // @ts-ignore
        return option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      filterSort={function (optionA, optionB) {
        return (
          // @ts-ignore
          optionA?.value
            // @ts-ignore
            ?.toLowerCase()
            // @ts-ignore
            ?.localeCompare(optionB?.value.toLowerCase())
        );
      }}
    >
      {filterGroups.map(function (filterGroup) {
        return (
          <OptGroup label={filterGroup.description} key={filterGroup.type}>
            {filterGroup.filters.map(function (filter) {
              return (
                <Option value={filter.type} key={filter.type}>
                  <div>{filter.description}</div>
                </Option>
              );
            })}
          </OptGroup>
        );
      })}
    </Select>
  );
}

import {
  AudioOutlined,
  DownOutlined,
  ExportOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Typography } from "antd";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { useCompaniesApi } from "../../../Apis/Apis";
import Colors from "../../../Components/Colors";
import { CollapsibleNote } from "../../../Components/NotesBase/CollapseNote";
import { ModifiedGenericNote } from "../../../Components/NotesBase/ModifiedGenericNote";
import { NoteDetailsBox } from "../../../Components/NotesBase/NoteDetailsBox";
import { NoteAuthorHeadline } from "../../../Components/NotesBase/NoteHeadline";
import { NoteSubmessage } from "../../../Components/NotesBase/NoteSubmessage";
import { NoteTag } from "../../../Components/NotesBase/NoteTag";
import { useCallContext } from "../../../Contexts/CallContext/CallContext";
import { CompanyData } from "../../../generated-openapi-client";
import { useDisplay } from "../../../Hooks/useDisplay";
import Spacer from "../../../Spacer";
import { CompanyNoteElementProps, SignificantHeadline } from "../CompanyNote";
import { DeleteSalesCallNoteBodyButton } from "../DeleteSalesCallNote";
import { InboundLogSalesCallModal } from "../LogSalesCall/InboundLogSalesCallModal";
import { OutboundLogSalesCallModal } from "../LogSalesCall/OutboundLogSalesCall";

export function CallCompanyNoteElement(props: CompanyNoteElementProps) {
  const { note } = props;

  let allowAddNotes = false;
  let changes: any;
  let dialpadSummary: any;
  let dialpadActions: any | string[];
  let hangupData: any;
  let adminRecordingData: any;
  let datas: any[];
  let recordingDetails: RecordingDetail[] | undefined;

  function findData(id: string): any {
    const filter = datas?.filter((d) => d?.identifier === id);

    if (filter.length > 1) {
      return filter[filter.length - 1]?.data;
    }

    return filter[0]?.data;
  }

  if (note.metadata) {
    const metadata = JSON.parse(note.metadata!);
    const identifier = metadata.identifier;
    const metadataData = metadata.data;

    if (identifier === "log-call-dialpad") {
      datas = metadataData as any[];

      const logData = findData("apollo-log-call");

      const hasNotes =
        datas?.find((d) => d?.identifier === "apollo-log-call") !== undefined;

      if (!hasNotes) {
        allowAddNotes = true;
      }

      changes = logData?.changes;

      dialpadSummary = findData("dialpad-recap_summary")?.recapSummary;
      dialpadActions = findData("dialpad-recap_action_items")?.actions;
      adminRecordingData = findData("dialpad-admin_recording");
      hangupData = findData("dialpad-hangup");

      if (adminRecordingData) {
        recordingDetails =
          adminRecordingData.recordingDetails as RecordingDetail[];
      } else {
        recordingDetails = hangupData?.adminRecordingUrls?.map(
          (url: string) => ({ url })
        );
      }
    } else if (identifier === "log-call") {
      changes = metadataData.changes;
    }
  }

  return (
    <div>
      <CollapsibleNote
        collapseActive={props.collapseActive}
        header={
          <div>
            <NoteAuthorHeadline
              headline={
                <>
                  <SignificantHeadline significant={note.significant}>
                    <CurrentCallTag {...props} />
                    <NoNotesTag {...props} />
                    {note.subject}
                  </SignificantHeadline>
                </>
              }
              author={note.author}
            />
            <div style={{ margin: 0, padding: 0 }}>
              <NoteSubmessage>{note.subMessage}</NoteSubmessage>
            </div>
          </div>
        }
      >
        {allowAddNotes && (
          <div style={{ paddingBlock: "10px" }}>
            <AddCallNotesButton {...props} />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
            minWidth: "800px",
          }}
        >
          {note.body && (
            <NoteDetailsBox>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Title level={3}>Call notes</Typography.Title>

                  {!allowAddNotes && (
                    <DeleteSalesCallNoteBodyButton
                      note={note}
                      onRefresh={props.onRefresh}
                    />
                  )}
                </div>
                {note.body}
              </div>
              {changes && (
                <ModifiedGenericNote
                  boxStyle={{
                    margin: 0,
                    padding: 0,
                    border: 0,
                    marginTop: "12px",
                  }}
                  title={
                    <Typography.Title level={5} style={{ marginTop: "10px" }}>
                      Updates
                    </Typography.Title>
                  }
                  beforeData={changes.before}
                  afterData={changes.after}
                  fields={["shipmentFrequency", "temperature"]}
                />
              )}
            </NoteDetailsBox>
          )}

          {(dialpadSummary || dialpadActions) && (
            <NoteDetailsBox>
              <Typography.Title level={3}>Dialpad</Typography.Title>
              {dialpadSummary && (
                <>
                  <Typography.Title level={5}>Summary</Typography.Title>
                  <p>{dialpadSummary}</p>
                </>
              )}
              {dialpadActions && (
                <>
                  <Typography.Title level={5}>Actions</Typography.Title>
                  {dialpadActions.map((action: string) => (
                    <p style={{ margin: 0, padding: 0 }}>- {action}</p>
                  ))}
                </>
              )}
            </NoteDetailsBox>
          )}
        </div>
      </CollapsibleNote>

      <Spacer height={8} />
      <div style={{ display: "flex", alignItems: "center", columnGap: "20px" }}>
        <DialpadRecordings recordingDetails={recordingDetails} />
        <DialpadLinksDropdown
          links={[
            {
              url: hangupData?.companyCallReviewShareLink,
              render: `Company Review Share Link`,
            },
            {
              url: adminRecordingData?.adminCallRecordingShareLinks,
              render: `Admin Review Share Link`,
            },
            {
              url: hangupData?.publicCallReviewShareLink,
              render: `Public Review Share Link`,
            },
          ]}
        />
        {props.origin === "sales-call-list" && (
          <Link to={`/view-company?companyId=${note.companyId}`}>
            View Company
          </Link>
        )}
      </div>
    </div>
  );
}

interface RecordingDetail {
  url: string;
  duration?: number;
}

function DialpadRecordings({
  recordingDetails,
}: {
  recordingDetails: RecordingDetail[] | undefined;
}) {
  if (!recordingDetails) {
    return <></>;
  }

  if (recordingDetails.length === 1) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={recordingDetails[0].url}
      >
        <AudioOutlined /> Dialpad Recording{" "}
        {recordingDetails[0].duration &&
          `(${recordingDetails[0].duration / 1000}s)`}
      </a>
    );
  }

  function renderLabel(details: RecordingDetail, index: number): ReactNode {
    if (details.duration) {
      return `Recording ${index + 1} (${details.duration / 1000 + "s"})`;
    }
    return `Recording ${index + 1}`;
  }
  return (
    <DialpadLinksDropdown
      links={recordingDetails.map((details, index) => ({
        url: details.url,
        render: renderLabel(details, index),
      }))}
    >
      <AudioOutlined />
      Dialpad Recordings
    </DialpadLinksDropdown>
  );
}

function DialpadLinksDropdown({
  links,
  children,
}: {
  links: { render: ReactNode; url: string }[] | undefined;
  children?: ReactNode;
}) {
  if (!links) {
    return <></>;
  }

  const toRender = links.filter((l) => l?.url);

  if (toRender.length === 0) {
    return <></>;
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {toRender.map((link) => (
            <Menu.Item icon={<ExportOutlined />}>
              <a target="_blank" rel="noopener noreferrer" href={link.url}>
                {link.render}
              </a>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={(e) => e.preventDefault()}>
        {children ? (
          children
        ) : (
          <>
            <LinkOutlined style={{ marginRight: "4px" }} />
            Dialpad Links
          </>
        )}
        <DownOutlined style={{ marginLeft: "4px" }} />
      </a>
    </Dropdown>
  );
}

function AddCallNotesButton(props: CompanyNoteElementProps) {
  const { note } = props;

  const display = useDisplay();
  const { callData } = useCallContext();

  const [data, setData] = useState<CompanyData | undefined>(props.data);
  const [isLoading, setIsLoading] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  async function fetchData() {
    if (!note.companyId) {
      message.error("Something went wrong!");
      return;
    }
    setIsLoading(true);
    try {
      const api = await createCompaniesApi();
      const response = await api.viewCompanyData({ companyId: note.companyId });
      setData(response);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  async function handleClick() {
    if (!data) {
      await fetchData();
    }
    display.show();
  }

  if (callData && callData.companyNoteId === note.companyNoteId) {
    return (
      <>
        <Button type="primary" onClick={handleClick} loading={isLoading}>
          Open Sales Call Notes
        </Button>
        {data && (
          <OutboundLogSalesCallModal
            display={display}
            company={data.company}
            salesContacts={data.salesContacts}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Button type="primary" onClick={handleClick} loading={isLoading}>
        Add Notes
      </Button>
      {data && (
        <InboundLogSalesCallModal
          onRefresh={props.onRefresh}
          company={data.company}
          salesContacts={data.salesContacts}
          display={display}
          companyNoteId={note.companyNoteId}
          salesContactId={note.salesContactId}
        />
      )}
    </>
  );
}

function CurrentCallTag({ note }: CompanyNoteElementProps) {
  const { callData } = useCallContext();

  if (!callData || callData.companyNoteId !== note.companyNoteId) {
    return null;
  }

  return (
    <NoteTag style={{ color: Colors.BlueT[500], background: Colors.BlueT[50] }}>
      Ongoing Call
    </NoteTag>
  );
}

function NoNotesTag({ note }: CompanyNoteElementProps) {
  const { callData } = useCallContext();

  if (note.body !== undefined || note.significant !== undefined) {
    return null;
  }

  if (callData && callData.companyNoteId === note.companyNoteId) {
    return null;
  }

  return (
    <NoteTag style={{ color: Colors.Gray[400], background: Colors.Gray[50] }}>
      No Notes
    </NoteTag>
  );
}

import {
  Button,
  Form,
  Menu,
  message,
  Select,
  Statistic,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import MenuDivider from "antd/lib/menu/MenuDivider";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCarrierInvoiceAuditApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import Colors from "../Components/Colors";
import {
  colorForCustomerInvoiceState,
  CustomerInvoiceStateTag,
  foregroundColorForCustomerInvoiceState,
} from "../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import { ShipmentStatusTag } from "../Components/ShipmentStatusTag";
import Stack from "../Components/Stack";
import {
  ViewShipmentButton,
  ViewShipmentMenuItem,
} from "../Components/ViewShipmentButton";
import { WarningComponent } from "../Components/WarningComponent";
import {
  CarrierInvoice,
  CustomerInvoice,
  CustomerInvoiceState,
  CustomerInvoiceType,
  InvoiceAuditProblem,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemType,
  InvoiceAuditTurn,
  OpenAuditLine,
  ShipmentState,
} from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import { calculateMargin } from "../Helpers/calculateMargin";
import { daysAgo } from "../Helpers/daysAgo";
import { groupBy } from "../Helpers/groupBy";
import { isCustomerInvoiceOverdue } from "../Helpers/isCustomerInvoiceOverdue";
import { sleep } from "../Helpers/sleep";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { AddBillingNoteMenuItem } from "./AddBillingNoteMenuItem";
import { InvoiceAuditAssigneeTag } from "./InvoiceAuditAssigneeTag";
import { SnoozeInvoiceAuditMenuItem } from "./SnoozeInvoiceAuditMenuItem";
import {
  RequiresActionType,
  suggestNextStepForInvoiceAudit,
} from "./suggestNextStepForInvoiceAudit";
import { AdminDropdown } from "./ViewShipmentScreenComponents/AdminDropdown";
import { AssignInvoiceAuditMenuItem } from "./ViewShipmentScreenComponents/AssignInvoiceAuditMenuItem";
import { AuditNotesModal } from "./ViewShipmentScreenComponents/AuditNotesModal";
import { CancelInvoiceAuditSnoozeMenuItem } from "./ViewShipmentScreenComponents/CancelInvoiceAuditSnoozeMenuItem";
import { describeCarrierInvoiceState } from "./ViewShipmentScreenComponents/CarrierInvoicesTab";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";
import {
  colorForInvoiceAuditProblemResolutionState,
  describeInvoiceAuditProblemType,
  emojiForInvoiceAuditProblemFault,
} from "./ViewShipmentScreenComponents/InvoiceAuditProblemTypeDescription";
import { MarkWaitingForCarrierMenuItem } from "./ViewShipmentScreenComponents/MarkWaitingForCarrierMenuItem";
import { SetCarrierAuditFrontConversationIdMenuItem } from "./ViewShipmentScreenComponents/SetCarrierAuditFrontConversationIdMenuItem";
import { SetCarrierAuditTurnMenuItem } from "./ViewShipmentScreenComponents/SetCarrierAuditTurnMenuItem";
import { SetCustomerAuditFrontConversationIdMenuItem } from "./ViewShipmentScreenComponents/SetCustomerAuditFrontConversationIdMenuItem";
import { SetCustomerAuditTurnMenuItem } from "./ViewShipmentScreenComponents/SetCustomerAuditTurnMenuItem";
import { describeCustomerInvoiceType } from "./ViewShipmentScreenComponents/ViewCustomerInvoiceButton";
const { Title } = Typography;

const CellHeader = styled.div`
  font-size: 11px;
  margin-top: 8px;
  color: ${Colors.LightText};
`;
interface CustomerInvoiceComponentsProps {
  openAuditLine: OpenAuditLine;
}

function CustomerInvoiceComponents(props: CustomerInvoiceComponentsProps) {
  if (
    props.openAuditLine.customerInvoices === undefined ||
    props.openAuditLine.customerInvoices.length === 0
  ) {
    return (
      <div>
        <WarningComponent /> No invoices!
      </div>
    );
  } else {
    return (
      <Stack align="left">
        {props.openAuditLine.customerInvoices
          .filter(
            (p) => p.customerInvoiceState !== CustomerInvoiceState.Deleted
          )
          .map((p) => (
            <CustomerInvoiceComponent customerInvoice={p} />
          ))}
      </Stack>
    );
  }
}

interface CarrierInvoiceComponentsProps {
  openAuditLine: OpenAuditLine;
}

function CarrierInvoiceComponents(props: CarrierInvoiceComponentsProps) {
  if (
    props.openAuditLine.carrierInvoices === undefined ||
    props.openAuditLine.carrierInvoices.length === 0
  ) {
    return (
      <div>
        <WarningComponent /> No invoices!
      </div>
    );
  } else {
    return (
      <Stack align="left">
        {props.openAuditLine.carrierInvoices.map((p) => (
          <CarrierInvoiceComponent carrierInvoice={p} />
        ))}
      </Stack>
    );
  }
}

interface InvoiceAuditTurnTagProps {
  turn: InvoiceAuditTurn | undefined;
  turnTime: string | undefined;
  frontConversationId: string | undefined;
  openAuditLine: OpenAuditLine;
  onRefresh: () => Promise<void>;
}

export enum InvoiceAuditContext {
  Customer = "Customer",
  Carrier = "Carrier",
}

function InvoiceAuditCustomerTurnTag(props: InvoiceAuditTurnTagProps) {
  return (
    <AuditNotesModal
      shipmentId={props.openAuditLine.shipmentReport.shipmentId!!}
      onRefresh={props.onRefresh}
      turn={props.turn}
      turnTime={props.turnTime}
      notes={props.openAuditLine.notes}
      turnContext={InvoiceAuditContext.Customer}
      frontConversationId={props.frontConversationId}
    />
  );
}

function InvoiceAuditCarrierTurnTag(props: InvoiceAuditTurnTagProps) {
  return (
    <AuditNotesModal
      shipmentId={props.openAuditLine.shipmentReport.shipmentId!!}
      onRefresh={props.onRefresh}
      turn={props.turn}
      turnTime={props.turnTime}
      notes={props.openAuditLine.notes}
      turnContext={InvoiceAuditContext.Carrier}
      frontConversationId={props.frontConversationId}
    />
  );
}

interface InvoiceAuditProblemComponentProps {
  problem: InvoiceAuditProblem;
}

export function InvoiceAuditProblemComponent(
  props: InvoiceAuditProblemComponentProps
) {
  return (
    <Tooltip
      placement="left"
      overlayStyle={{ minWidth: "432px" }}
      overlay={
        <Stack
          align="left"
          style={{
            backgroundColor: "white",
            color: Colors.NormalText,
            padding: "32px",
            width: "400px",
          }}
        >
          <Title level={4}>Invoice Audit Problem</Title>
          <Title level={5}>Problem Type</Title>
          <div>{props.problem.type}</div>
          {props.problem.type ===
            InvoiceAuditProblemType.PickupLocationTypeChange ||
            (props.problem.type ===
              InvoiceAuditProblemType.DeliveryLocationTypeChange && (
              <>
                <Title level={5}>Corrected Location Type</Title>
                <div>{props.problem.correctedLocationType}</div>
              </>
            ))}
          <Title level={5}>Fault</Title>
          <div>{props.problem.fault}</div>
          <Title level={5}>Confidence</Title>
          <div>{props.problem.confidence}</div>
          <Title level={5}>Estimated Cost Difference</Title>
          {props.problem.estimatedCostDifference !== undefined &&
            props.problem.estimatedCostDifference > 0 && (
              <div>${props.problem.estimatedCostDifference}</div>
            )}
          {props.problem.estimatedCostDifference === undefined ||
            (props.problem.estimatedCostDifference === 0 && <div>-</div>)}

          <Title level={5}>Customer Visible Detail</Title>
          {props.problem.customerVisibleDetail && (
            <div>{props.problem.customerVisibleDetail}</div>
          )}
          {!props.problem.customerVisibleDetail && <div>-</div>}
          <Spacer height={32} />
          <Title level={5}>Internal Visible Detail</Title>
          {props.problem.internalVisibleDetail && (
            <div>{props.problem.internalVisibleDetail}</div>
          )}
          {!props.problem.internalVisibleDetail && <div>-</div>}

          <Title level={5}>Resolution State</Title>
          <div>{props.problem.resolutionState}</div>
          {props.problem.resolutionState !==
            InvoiceAuditProblemResolutionState.Unresolved && (
            <>
              <Title level={5}>Resolution Type</Title>
              <div>{props.problem.resolutionType}</div>
            </>
          )}
        </Stack>
      }
    >
      <Tag
        style={{
          marginBottom: "4px",
          textDecoration:
            props.problem.resolutionState ===
            InvoiceAuditProblemResolutionState.Resolved
              ? "line-through"
              : undefined,
        }}
        color={colorForInvoiceAuditProblemResolutionState(
          props.problem.resolutionState!!
        )}
      >
        {emojiForInvoiceAuditProblemFault(props.problem.fault!!)}{" "}
        {describeInvoiceAuditProblemType(props.problem)}
      </Tag>
    </Tooltip>
  );
}

interface CarrierInvoiceComponentProps {
  carrierInvoice: CarrierInvoice;
}

function CarrierInvoiceComponent(props: CarrierInvoiceComponentProps) {
  const invoiceState = props.carrierInvoice.invoiceState!!;
  const showNew = !props.carrierInvoice.problemsIdentified;

  return (
    <Tooltip
      placement="left"
      overlayStyle={{ minWidth: "432px" }}
      overlay={
        <Stack
          align="left"
          style={{
            backgroundColor: "white",
            color: Colors.NormalText,
            padding: "32px",
            width: "400px",
          }}
        >
          <OverlayHeader>Invoice Identifier</OverlayHeader>
          <div>{props.carrierInvoice.invoiceIdentifier}</div>

          <OverlayHeader>Carrier</OverlayHeader>
          <div>
            <CarrierLogo
              carrierIdentifier={props.carrierInvoice.carrierIdentifier}
              brokeredCarrierIdentifier={undefined}
              width={40}
              height={30}
            />
          </div>

          <OverlayHeader>Amount</OverlayHeader>
          <div>
            {props.carrierInvoice.amount} {props.carrierInvoice.currency}
          </div>

          <OverlayHeader>Problems Identified?</OverlayHeader>
          <div>{props.carrierInvoice.problemsIdentified ? "Yes" : "No"}</div>

          <OverlayHeader>Approval Type</OverlayHeader>
          <div>{props.carrierInvoice.approvalType}</div>

          <OverlayHeader>Approval Reason</OverlayHeader>
          <div>{props.carrierInvoice.approvalReason}</div>

          <OverlayHeader>Approval Method</OverlayHeader>
          <div>{props.carrierInvoice.approvalMethod}</div>
        </Stack>
      }
    >
      {showNew && (
        <Tag
          color={Colors.Blue}
          style={{ color: "white", marginBottom: "4px" }}
        >
          ⭐ New - ${props.carrierInvoice.amount}{" "}
          {props.carrierInvoice.currency}
        </Tag>
      )}
      {!showNew && (
        <Tag style={{ marginBottom: "4px" }}>
          {describeCarrierInvoiceState(invoiceState)} - $
          {props.carrierInvoice.amount} {props.carrierInvoice.currency}
        </Tag>
      )}
    </Tooltip>
  );
}

interface CustomerInvoiceComponentProps {
  customerInvoice: CustomerInvoice;
}

const OverlayHeader = styled.div`
  font-weight: 500;
  margin-top: 16px;
  color: black;
`;

function CustomerInvoiceComponent(props: CustomerInvoiceComponentProps) {
  const isOverdue =
    isCustomerInvoiceOverdue(props.customerInvoice) &&
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued;

  const isDisputed =
    props.customerInvoice.disputed &&
    props.customerInvoice.customerInvoiceState === CustomerInvoiceState.Issued;

  function backgroundColor() {
    if (isDisputed) {
      return Colors.Orange;
    }

    if (isOverdue) {
      return Colors.Red;
    }

    return colorForCustomerInvoiceState(
      props.customerInvoice.customerInvoiceState!!
    );
  }

  function foregroundColor() {
    if (isDisputed) {
      return "black";
    }

    if (isOverdue) {
      return "white";
    }

    return foregroundColorForCustomerInvoiceState(
      props.customerInvoice.customerInvoiceState!!
    );
  }

  function prefix() {
    if (isOverdue) {
      return "Overdue ";
    }

    return "";
  }

  return (
    <Tooltip
      placement="left"
      overlayStyle={{ minWidth: "432px" }}
      overlay={
        <Stack
          align="left"
          style={{
            backgroundColor: "white",
            color: Colors.NormalText,
            padding: "32px",
            width: "400px",
          }}
        >
          <OverlayHeader>Type</OverlayHeader>
          <div>{props.customerInvoice.invoiceType}</div>
          <OverlayHeader>Amount</OverlayHeader>
          <div>
            {props.customerInvoice.amount} {props.customerInvoice.currency}
          </div>

          <OverlayHeader>Due Date</OverlayHeader>
          <div>{props.customerInvoice.dueDate}</div>

          <OverlayHeader>State</OverlayHeader>
          <CustomerInvoiceStateTag customerInvoice={props.customerInvoice} />

          {(props.customerInvoice.invoiceType ===
            CustomerInvoiceType.AdditionalCharge ||
            props.customerInvoice.invoiceType ===
              CustomerInvoiceType.AdditionalChargeRefund) && (
            <>
              <OverlayHeader>Description</OverlayHeader>
              <div>{props.customerInvoice.description}</div>
            </>
          )}
        </Stack>
      }
    >
      <Tag color={backgroundColor()} style={{ marginBottom: "4px" }}>
        <div
          style={{
            color: foregroundColor(),
          }}
        >
          {prefix()}
          {describeCustomerInvoiceType(props.customerInvoice)}
        </div>
      </Tag>
    </Tooltip>
  );
}

export enum OpenInvoiceAuditsGroupType {
  Company = "Company",
  Carrier = "Carrier",
  NextStep = "NextStep",
}

interface OpenInvoiceAuditsTableProps {
  auditLines: OpenAuditLine[];
  groupBy: OpenInvoiceAuditsGroupType | undefined;
  refresh: () => Promise<void>;
}

function isSnoozed(o: OpenAuditLine): boolean {
  return (
    o.csq.shipment.invoiceAuditSnoozedUntilDate !== undefined &&
    daysAgo(o.csq.shipment.invoiceAuditSnoozedUntilDate) < 0
  );
}

export function OpenInvoiceAuditsTable(props: OpenInvoiceAuditsTableProps) {
  const columns: DataTableColumn<OpenAuditLine>[] = [];
  columns.push({
    title: "Status",
    render: (o) => (
      <>
        {isSnoozed(o) && (
          <Tag color="red">
            ⏰ Snoozed until{" "}
            {moment(o.csq.shipment.invoiceAuditSnoozedUntilDate).format(
              "dddd, MMM Do"
            )}
          </Tag>
        )}
        <CellHeader>Bill of Lading Number</CellHeader>
        <div>{o.csq.shipment.billOfLadingNumber}</div>
        <CellHeader>Shipment Status</CellHeader>
        <ShipmentStatusTag
          status={o.csq.shipment.state}
          deliveryDate={o.csq.shipment.actualDeliveryDate}
        />
        <CellHeader>Assigned</CellHeader>
        <InvoiceAuditAssigneeTag
          invoiceAuditAssignee={o.csq.shipment.invoiceAuditAssignee}
          shipmentId={o.csq.shipment.shipmentId!!}
          refresh={props.refresh}
        />

        <CellHeader>Open Since</CellHeader>
        {moment(o.csq.shipment.invoiceAuditFirstOpenedDate).format(
          "ddd, MMM Do YYYY"
        )}

        <CellHeader>Next Step</CellHeader>
        <div>
          {
            suggestNextStepForInvoiceAudit(
              o.csq,
              o.carrierInvoices,
              o.carrierCreditNotes,
              o.customerInvoices
            ).description
          }
        </div>
        <Spacer height={16} />
        <ViewShipmentButton
          shipmentId={o.csq.shipment.shipmentId!!}
          openInNewTab
        />
      </>
    ),
    width: 180,
  });

  columns.push({
    title: "Related to",
    render: (o) => (
      <Stack align="left">
        <CellHeader>Carrier</CellHeader>
        <CarrierLogo
          carrierIdentifier={o.csq.quote.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={90}
          height={70}
        />
        <CellHeader>Customer</CellHeader>
        <div>{o.csq.company.companyName}</div>,
      </Stack>
    ),
    width: 250,
  });
  columns.push({
    title: "✉️ Turns",
    render: (o) => (
      <Stack align="left">
        <CellHeader>Customer</CellHeader>
        <InvoiceAuditCustomerTurnTag
          onRefresh={props.refresh}
          openAuditLine={o}
          turn={o.csq.shipment.invoiceAuditCustomerTurn}
          turnTime={o.csq.shipment.invoiceAuditCustomerTurnTime}
          frontConversationId={
            o.csq.shipment.invoiceAuditCustomerFrontConversationId
          }
        />
        <CellHeader>Carrier</CellHeader>
        <InvoiceAuditCarrierTurnTag
          onRefresh={props.refresh}
          openAuditLine={o}
          turn={o.csq.shipment.invoiceAuditCarrierTurn}
          turnTime={o.csq.shipment.invoiceAuditCarrierTurnTime}
          frontConversationId={
            o.csq.shipment.invoiceAuditCarrierFrontConversationId
          }
        />
      </Stack>
    ),
    width: 180,
  });

  columns.push({
    title: "Margin",
    render: function (o) {
      if (o.csq.shipment.state === ShipmentState.Cancelled) {
        return <>-</>;
      }

      const margin = calculateMargin(
        o.shipmentReport.moneyInCad!!,
        o.shipmentReport.moneyOutCad!!
      );

      const marginWithoutInsurance = calculateMargin(
        o.shipmentReport.moneyInCad!! - (o.csq.quote.insurancePriceCad ?? 0),
        o.shipmentReport.moneyOutCad!!
      );

      return (
        <Stack align="center" style={{ width: "160px" }}>
          <CellHeader>Quote Difference</CellHeader>
          <div>${o.diffCad} CAD</div>
          {o.csq.quote.insurancePriceCad === 0 && (
            <>
              <CellHeader>Margin</CellHeader>
              <Percentage colored>{margin}</Percentage>
            </>
          )}
          {o.csq.quote.insurancePriceCad !== 0 && (
            <>
              <CellHeader>Margin with Insurance</CellHeader>
              <Percentage colored>{margin}</Percentage>
              <CellHeader>Margin without Insurance</CellHeader>
              <Percentage colored>{marginWithoutInsurance}</Percentage>
            </>
          )}
        </Stack>
      );
    },
    width: 120,
  });

  columns.push({
    title: "Invoices",
    render: function (o) {
      return (
        <Stack align="left">
          <CellHeader>Customer Invoices</CellHeader>
          <CustomerInvoiceComponents openAuditLine={o} />
          <CellHeader>Carrier Invoices</CellHeader>
          <CarrierInvoiceComponents openAuditLine={o} />
        </Stack>
      );
    },
    width: 200,
  });

  columns.push({
    title: "Problems",
    render: function (o) {
      if (
        o.csq.shipment.invoiceAuditProblems === undefined ||
        o.csq.shipment.invoiceAuditProblems.length === 0
      ) {
        return (
          <div>
            <WarningComponent /> No problems identified!
          </div>
        );
      } else {
        return (
          <Stack align="left">
            {o.csq.shipment.invoiceAuditProblems.map((p) => (
              <InvoiceAuditProblemComponent problem={p} />
            ))}
          </Stack>
        );
      }
    },
    width: 80,
  });

  columns.push({
    title: "Actions",
    render: (o) => (
      <ButtonRow
        extrasMenu={
          <Menu>
            <ViewShipmentMenuItem shipmentId={o.csq.shipment.shipmentId!!} />
            <MenuDivider />
            <AssignInvoiceAuditMenuItem
              openAuditLine={o}
              refresh={props.refresh}
              shipmentId={o.csq.shipment.shipmentId!!}
              invoiceAuditAssignee={o.csq.shipment.invoiceAuditAssignee}
            />
            <MarkWaitingForCarrierMenuItem
              openAuditLine={o}
              refresh={props.refresh}
            />
            <SnoozeInvoiceAuditMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              currentSnoozeDate={o.csq.shipment.invoiceAuditSnoozedUntilDate}
            />
            <CancelInvoiceAuditSnoozeMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              currentSnoozeDate={o.csq.shipment.invoiceAuditSnoozedUntilDate}
            />
            <AddBillingNoteMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
            />
            <SetCustomerAuditTurnMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              invoiceAuditCustomerTurn={o.csq.shipment.invoiceAuditCustomerTurn}
              invoiceAuditCustomerTurnTime={
                o.csq.shipment.invoiceAuditCustomerTurnTime
              }
            />
            <SetCarrierAuditTurnMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              invoiceAuditCarrierTurn={o.csq.shipment.invoiceAuditCarrierTurn}
              invoiceAuditCarrierTurnTime={
                o.csq.shipment.invoiceAuditCarrierTurnTime
              }
            />
            <SetCustomerAuditFrontConversationIdMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              invoiceAuditCustomerFrontConversationId={
                o.csq.shipment.invoiceAuditCustomerFrontConversationId
              }
            />
            <SetCarrierAuditFrontConversationIdMenuItem
              shipmentId={o.csq.shipment.shipmentId!!}
              onRefresh={props.refresh}
              invoiceAuditCarrierFrontConversationId={
                o.csq.shipment.invoiceAuditCarrierFrontConversationId
              }
            />
          </Menu>
        }
      >
        <></>
      </ButtonRow>
    ),
    width: 120,
  });

  if (props.groupBy !== undefined) {
    const grouped = groupBy(props.auditLines, function (oal: OpenAuditLine) {
      switch (props.groupBy) {
        case OpenInvoiceAuditsGroupType.Carrier:
          return oal.csq.quote.carrierIdentifier;
        case OpenInvoiceAuditsGroupType.Company:
          return oal.csq.company.companyName;
        case OpenInvoiceAuditsGroupType.NextStep:
          return suggestNextStepForInvoiceAudit(
            oal.csq,
            oal.carrierInvoices,
            oal.carrierCreditNotes,
            oal.customerInvoices
          ).description;
        case undefined:
          throw new Error("Should not be here");
        default:
          assertNever(props.groupBy);
      }
    }).sort(function (a, b) {
      return a.key!!.localeCompare(b.key!!);
    });

    return (
      <Stack align="left">
        {grouped.map(function (g) {
          return (
            <>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                {g.key} ({g.value.length})
              </div>
              <DataTable pagination={false} columns={columns} data={g.value} />
              <Spacer height={64} />
            </>
          );
        })}
      </Stack>
    );
  } else {
    return (
      <Stack align="left">
        <DataTable
          pagination={false}
          columns={columns}
          data={props.auditLines}
        />
      </Stack>
    );
  }
}

export enum OpenInvoiceAuditsSortType {
  AuditOpenDate = "AuditOpenDate",
}

export enum OpenInvoiceAuditsFilterType {
  /* Important */
  RequiresAction = "RequiresAction",

  /* Specific Actions */
  RequiresActionNewAudit = "RequiresActionNewAudit",
  RequiresActionNewInvoice = "RequiresActionNewInvoice",
  RequiresActionReadCarrierReply = "RequiresActionReadCarrierReply",
  RequiresActionReadCustomerReply = "RequiresActionReadCustomerReply",
  RequiresActionPingCarrier = "RequiresActionPingCarrier",
  RequiresActionEstablishFault = "RequiresActionEstablishFault",
  RequiresActionSendChallengeToCarrier = "RequiresActionSendChallengeToCarrier",
  RequiresActionPingCustomer = "RequiresActionPingCustomer",
  RequiresActionOther = "RequiresActionOther",
  RequiresActionUnknownNextStep = "RequiresActionUnknownNextStep",
  RequiresActionCloseAudit = "RequiresActionCloseAudit",

  /* Other */
  UnresolvedProblems = "UnresolvedProblems",
  AllProblemsResolved = "AllProblemsResolved",
  OurTurn = "OurTurn",
  NobodysTurn = "NobodysTurn",
  NewInvoices = "NewInvoices",

  NotRequiresAction = "NotRequiresAction",

  Snoozed = "Snoozed",
  HighValue = "HighValue",
}

const GroupByDropdown = OptionalEnumDropdown<
  OpenInvoiceAuditsGroupType,
  typeof OpenInvoiceAuditsGroupType
>(OpenInvoiceAuditsGroupType, "Do not group", function (et) {
  switch (et) {
    case OpenInvoiceAuditsGroupType.Carrier:
      return "Carrier";
    case OpenInvoiceAuditsGroupType.Company:
      return "Company";
    case OpenInvoiceAuditsGroupType.NextStep:
      return "Next Step";
    default:
      assertNever(et);
  }
});

const SortByDropdown = OptionalEnumDropdown<
  OpenInvoiceAuditsSortType,
  typeof OpenInvoiceAuditsSortType
>(OpenInvoiceAuditsSortType, "Do not sort", function (et) {
  switch (et) {
    case OpenInvoiceAuditsSortType.AuditOpenDate:
      return "Audit Open Date";
    default:
      assertNever(et);
  }
});

interface FilterByDropdownProps {
  value: OpenInvoiceAuditsFilterType | undefined;
  setValue: (_: OpenInvoiceAuditsFilterType | undefined) => void;
  disabled: boolean;
  countByFilterType: (_: OpenInvoiceAuditsFilterType) => number;
}

function FilterByDropdown(props: FilterByDropdownProps) {
  const requiresActionNewAuditCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionNewAudit
  );
  const requiresActionNewInvoiceCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionNewInvoice
  );
  const requiresActionReadCarrierReplyCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionReadCarrierReply
  );
  const requiresActionReadCustomerReplyCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionReadCustomerReply
  );
  const requiresActionPingCarrierCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionPingCarrier
  );
  const requiresActionEstablishFaultCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionEstablishFault
  );
  const requiresActionSendChallengeToCarrierCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionSendChallengeToCarrier
  );
  const requiresActionPingCustomerCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionPingCustomer
  );
  const requiresActionOtherCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionOther
  );
  const requiresActionUnknownNextStepCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionUnknownNextStep
  );
  const requiresActionUnknownCloseAuditCount = props.countByFilterType(
    OpenInvoiceAuditsFilterType.RequiresActionCloseAudit
  );

  const showRequiresActionTypesGroup =
    requiresActionNewAuditCount ||
    requiresActionNewInvoiceCount ||
    requiresActionReadCarrierReplyCount ||
    requiresActionReadCustomerReplyCount ||
    requiresActionPingCarrierCount ||
    requiresActionEstablishFaultCount ||
    requiresActionSendChallengeToCarrierCount ||
    requiresActionPingCustomerCount ||
    requiresActionOtherCount ||
    requiresActionUnknownNextStepCount ||
    requiresActionUnknownCloseAuditCount;

  return (
    <Select
      disabled={props.disabled}
      value={props.value}
      onChange={props.setValue}
      style={{ width: "270px" }}
      allowClear={true}
      placeholder="No filter"
    >
      {showRequiresActionTypesGroup && (
        <Select.OptGroup label="Requires Action Types">
          {requiresActionNewAuditCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionNewAudit}
            >
              New Audit ({requiresActionNewAuditCount})
            </Select.Option>
          )}
          {requiresActionNewInvoiceCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionNewInvoice}
            >
              New Invoices ({requiresActionNewInvoiceCount})
            </Select.Option>
          )}
          {requiresActionReadCarrierReplyCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionReadCarrierReply}
            >
              Read Carrier Reply ({requiresActionReadCarrierReplyCount})
            </Select.Option>
          )}
          {requiresActionReadCustomerReplyCount && (
            <Select.Option
              value={
                OpenInvoiceAuditsFilterType.RequiresActionReadCustomerReply
              }
            >
              Read Customer Reply ({requiresActionReadCustomerReplyCount})
            </Select.Option>
          )}
          {requiresActionPingCarrierCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionPingCarrier}
            >
              Ping Carrier ({requiresActionPingCarrierCount})
            </Select.Option>
          )}
          {requiresActionEstablishFaultCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionEstablishFault}
            >
              Establish Fault ({requiresActionEstablishFaultCount})
            </Select.Option>
          )}
          {requiresActionSendChallengeToCarrierCount && (
            <Select.Option
              value={
                OpenInvoiceAuditsFilterType.RequiresActionSendChallengeToCarrier
              }
            >
              Send Challenge to Carrier (
              {requiresActionSendChallengeToCarrierCount})
            </Select.Option>
          )}
          {requiresActionPingCustomerCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionPingCustomer}
            >
              Ping Customer ({requiresActionPingCustomerCount})
            </Select.Option>
          )}
          {requiresActionOtherCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionOther}
            >
              Other ({requiresActionOtherCount})
            </Select.Option>
          )}
          {requiresActionUnknownNextStepCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionUnknownNextStep}
            >
              Unknown Next Step ({requiresActionUnknownNextStepCount})
            </Select.Option>
          )}
          {requiresActionUnknownCloseAuditCount && (
            <Select.Option
              value={OpenInvoiceAuditsFilterType.RequiresActionCloseAudit}
            >
              Unknown Next Step ({requiresActionUnknownCloseAuditCount})
            </Select.Option>
          )}
        </Select.OptGroup>
      )}

      <Select.OptGroup label="Other">
        <Select.Option value={OpenInvoiceAuditsFilterType.AllProblemsResolved}>
          All Problems Resolved (
          {props.countByFilterType(
            OpenInvoiceAuditsFilterType.AllProblemsResolved
          )}
          )
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.NobodysTurn}>
          Nobody's Turn (
          {props.countByFilterType(OpenInvoiceAuditsFilterType.NobodysTurn)})
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.OurTurn}>
          Our Turn (
          {props.countByFilterType(OpenInvoiceAuditsFilterType.OurTurn)})
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.UnresolvedProblems}>
          Unresolved Problems (
          {props.countByFilterType(
            OpenInvoiceAuditsFilterType.UnresolvedProblems
          )}
          )
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.RequiresAction}>
          Requires Action (
          {props.countByFilterType(OpenInvoiceAuditsFilterType.RequiresAction)})
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.NotRequiresAction}>
          Requires No Action (
          {props.countByFilterType(
            OpenInvoiceAuditsFilterType.NotRequiresAction
          )}
          )
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.Snoozed}>
          Snoozed (
          {props.countByFilterType(OpenInvoiceAuditsFilterType.Snoozed)})
        </Select.Option>
        <Select.Option value={OpenInvoiceAuditsFilterType.HighValue}>
          High Value (
          {props.countByFilterType(OpenInvoiceAuditsFilterType.HighValue)})
        </Select.Option>
      </Select.OptGroup>
    </Select>
  );
}

export function OpenInvoiceAuditsScreen() {
  const [auditLines, setAuditLines] = useState<OpenAuditLine[]>();
  const query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groupBy, setGroupBy] = useState<
    undefined | OpenInvoiceAuditsGroupType
  >(defaultGroupBy());
  const [sortBy, setSortBy] = useState<undefined | OpenInvoiceAuditsSortType>(
    defaultSortBy()
  );
  const [filterBy, setFilterBy] = useState<
    undefined | OpenInvoiceAuditsFilterType
  >(defaultFilterBy());
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    undefined | string
  >(defaultCarrierIdentifier());
  const [assigneeFilter, setAssigneeFilter] = useState<undefined | string>(
    defaultAssigneeFilter()
  );

  function buildUrl() {
    const base = `/open-invoice-audits`;

    const parts = [];
    if (groupBy !== undefined) {
      parts.push(`groupBy=${groupBy}`);
    }

    if (sortBy !== undefined) {
      parts.push(`sortBy=${sortBy}`);
    }

    if (filterBy !== undefined) {
      parts.push(`filterBy=${filterBy}`);
    }

    if (carrierIdentifier !== undefined) {
      parts.push(`carrierIdentifier=${carrierIdentifier}`);
    }

    if (assigneeFilter !== undefined) {
      parts.push(`assigneeFilter=${assigneeFilter}`);
    }

    if (parts.length > 0) {
      return base + "?" + parts.join("&");
    }

    return base;
  }

  const url = buildUrl();

  useEffect(
    function () {
      navigate(url, {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  function defaultGroupBy(): undefined | OpenInvoiceAuditsGroupType {
    if (!query.groupBy) {
      return undefined;
    }

    return query.groupBy as OpenInvoiceAuditsGroupType;
  }

  function defaultSortBy(): undefined | OpenInvoiceAuditsSortType {
    if (!query.sortBy) {
      return OpenInvoiceAuditsSortType.AuditOpenDate;
    }

    return query.sortBy as OpenInvoiceAuditsSortType;
  }

  function defaultFilterBy(): undefined | OpenInvoiceAuditsFilterType {
    if (!query.filterBy) {
      return OpenInvoiceAuditsFilterType.RequiresAction;
    }

    return query.filterBy as OpenInvoiceAuditsFilterType;
  }

  function defaultAssigneeFilter(): undefined | string {
    if (!query.assigneeFilter) {
      return undefined;
    }

    return query.assigneeFilter as string;
  }

  function defaultCarrierIdentifier(): undefined | string {
    if (!query.carrierIdentifier) {
      return undefined;
    }

    return query.carrierIdentifier as string;
  }

  const createCarrierInvoiceAuditApi = useCarrierInvoiceAuditApi();

  async function refresh() {
    setLoading(true);
    try {
      const carrierInvoiceAuditApi = await createCarrierInvoiceAuditApi();
      const response = await carrierInvoiceAuditApi.openInvoiceAudits();
      setAuditLines(response);
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setLoading(false);
  }

  useOnce(refresh);

  if (auditLines === undefined) {
    return <Loading />;
  }

  function applyFilterByRequiresActionType(
    requiresActionType: RequiresActionType
  ) {
    return function (oal: OpenAuditLine): boolean {
      return (
        suggestNextStepForInvoiceAudit(
          oal.csq,
          oal.carrierInvoices,
          oal.carrierCreditNotes,
          oal.customerInvoices
        ).requiresActionType === requiresActionType
      );
    };
  }

  function applyFilterByNewInvoices(oal: OpenAuditLine): boolean {
    return oal.carrierInvoices.some((ci) => !ci.problemsIdentified);
  }

  function applyFilterByAllProblemsResolved(oal: OpenAuditLine): boolean {
    return (
      oal.csq.shipment.invoiceAuditProblems!!.length > 0 &&
      oal.csq.shipment.invoiceAuditProblems!!.every(
        (o) => o.resolutionState === InvoiceAuditProblemResolutionState.Resolved
      )
    );
  }
  function applyFilterByNobodysTurn(oal: OpenAuditLine): boolean {
    return (
      oal.csq.shipment.invoiceAuditCarrierTurn === undefined &&
      oal.csq.shipment.invoiceAuditCustomerTurn === undefined
    );
  }
  function applyFilterByOurTurn(oal: OpenAuditLine): boolean {
    return (
      oal.csq.shipment.invoiceAuditCarrierTurn === InvoiceAuditTurn.OurTurn ||
      oal.csq.shipment.invoiceAuditCustomerTurn === InvoiceAuditTurn.OurTurn
    );
  }
  function applyFilterByUnresolvedProblems(oal: OpenAuditLine): boolean {
    return (
      oal.csq.shipment.invoiceAuditProblems!!.length > 0 &&
      oal.csq.shipment.invoiceAuditProblems!!.some(
        (o) =>
          o.resolutionState === InvoiceAuditProblemResolutionState.Unresolved
      )
    );
  }

  function applyFilterByRequiresAction(oal: OpenAuditLine): boolean {
    return suggestNextStepForInvoiceAudit(
      oal.csq,
      oal.carrierInvoices,
      oal.carrierCreditNotes,
      oal.customerInvoices
    ).requiresAction;
  }

  function applyFilterByNotRequiresAction(oal: OpenAuditLine): boolean {
    return !suggestNextStepForInvoiceAudit(
      oal.csq,
      oal.carrierInvoices,
      oal.carrierCreditNotes,
      oal.customerInvoices
    ).requiresAction;
  }

  function applyFilterByUnknownNextStep(oal: OpenAuditLine): boolean {
    return suggestNextStepForInvoiceAudit(
      oal.csq,
      oal.carrierInvoices,
      oal.carrierCreditNotes,
      oal.customerInvoices
    ).description.includes("Unknown next step");
  }

  function applyFilterBySnoozed(oal: OpenAuditLine): boolean {
    return isSnoozed(oal);
  }

  function applyFilterHighValue(oal: OpenAuditLine): boolean {
    return parseFloat(oal.diffCad) >= 300;
  }

  function applyFilterBy(filterType: OpenInvoiceAuditsFilterType | undefined) {
    return function (oal: OpenAuditLine): boolean {
      switch (filterType) {
        /* Requires Action Types*/
        case OpenInvoiceAuditsFilterType.RequiresActionNewAudit:
          return applyFilterByRequiresActionType(RequiresActionType.NewAudit)(
            oal
          );
        case OpenInvoiceAuditsFilterType.RequiresActionNewInvoice:
          return applyFilterByRequiresActionType(RequiresActionType.NewInvoice)(
            oal
          );
        case OpenInvoiceAuditsFilterType.RequiresActionReadCarrierReply:
          return applyFilterByRequiresActionType(
            RequiresActionType.ReadCarrierReply
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionReadCustomerReply:
          return applyFilterByRequiresActionType(
            RequiresActionType.ReadCustomerReply
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionPingCarrier:
          return applyFilterByRequiresActionType(
            RequiresActionType.PingCarrier
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionEstablishFault:
          return applyFilterByRequiresActionType(
            RequiresActionType.EstablishFault
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionSendChallengeToCarrier:
          return applyFilterByRequiresActionType(
            RequiresActionType.SendChallengeToCarrier
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionPingCustomer:
          return applyFilterByRequiresActionType(
            RequiresActionType.PingCustomer
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionOther:
          return applyFilterByRequiresActionType(RequiresActionType.Other)(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionUnknownNextStep:
          return applyFilterByRequiresActionType(
            RequiresActionType.UnknownNextStep
          )(oal);
        case OpenInvoiceAuditsFilterType.RequiresActionCloseAudit:
          return applyFilterByRequiresActionType(RequiresActionType.CloseAudit)(
            oal
          );

        /* Other*/
        case OpenInvoiceAuditsFilterType.NewInvoices:
          return applyFilterByNewInvoices(oal);
        case OpenInvoiceAuditsFilterType.AllProblemsResolved:
          return applyFilterByAllProblemsResolved(oal);
        case OpenInvoiceAuditsFilterType.NobodysTurn:
          return applyFilterByNobodysTurn(oal);
        case OpenInvoiceAuditsFilterType.OurTurn:
          return applyFilterByOurTurn(oal);
        case OpenInvoiceAuditsFilterType.UnresolvedProblems:
          return applyFilterByUnresolvedProblems(oal);
        case OpenInvoiceAuditsFilterType.RequiresAction:
          return applyFilterByRequiresAction(oal);
        case OpenInvoiceAuditsFilterType.NotRequiresAction:
          return applyFilterByNotRequiresAction(oal);

        case OpenInvoiceAuditsFilterType.Snoozed:
          return applyFilterBySnoozed(oal);
        case OpenInvoiceAuditsFilterType.HighValue:
          return applyFilterHighValue(oal);
        case undefined:
          return true;
        default:
          assertNever(filterType);
      }
    };
  }

  function applyCarrierIdentifier(oal: OpenAuditLine): boolean {
    if (carrierIdentifier === undefined) {
      return true;
    }

    // Actually check all the carrier invoices to match this carrier
    return oal.carrierInvoices.some(
      (ci) => ci.carrierIdentifier === carrierIdentifier
    );
  }

  function applyAssigneeFilter(oal: OpenAuditLine): boolean {
    if (assigneeFilter === undefined) {
      return true;
    }

    // Actually check all the carrier invoices to match this carrier
    return oal.csq.shipment.invoiceAuditAssignee === assigneeFilter;
  }

  function applySortBy(sortByType: OpenInvoiceAuditsSortType | undefined) {
    return function (a: OpenAuditLine, b: OpenAuditLine) {
      switch (sortByType) {
        case OpenInvoiceAuditsSortType.AuditOpenDate:
          return (
            moment(a.csq.shipment.invoiceAuditFirstOpenedDate).valueOf() -
            moment(b.csq.shipment.invoiceAuditFirstOpenedDate).valueOf()
          );
        case undefined:
          return 0;
        default:
          assertNever(sortByType);
      }
    };
  }

  const filteredAuditLines = auditLines
    .sort(applySortBy(sortBy))
    .filter(applyFilterBy(filterBy))
    .filter(applyCarrierIdentifier)
    .filter(applyAssigneeFilter);

  function assigneeCounter(email: string) {
    return filteredAuditLines.filter(
      (o) => o.csq.shipment.invoiceAuditAssignee === email
    ).length;
  }

  async function openAllInNewTabs() {
    for (let i = 0; i < filteredAuditLines.length; i++) {
      const url = `/view-shipment?shipmentId=${filteredAuditLines[i].csq.shipment.shipmentId}`;
      window.open(url);
      await sleep(300);
    }
  }

  return (
    <>
      <Page
        title="Open Invoice Audits"
        tags={[]}
        stats={
          <HorizontalStack>
            <Statistic
              title="Open Audits"
              value={auditLines.length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="New Invoices"
              value={
                auditLines.filter((o) =>
                  o.carrierInvoices.some((ci) => !ci.problemsIdentified)
                ).length
              }
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Our Turn"
              value={
                auditLines.filter(
                  (o) =>
                    o.csq.shipment.invoiceAuditCarrierTurn ===
                      InvoiceAuditTurn.OurTurn ||
                    o.csq.shipment.invoiceAuditCustomerTurn ===
                      InvoiceAuditTurn.OurTurn
                ).length
              }
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Requires Action"
              value={auditLines.filter(applyFilterByRequiresAction).length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Requires No Action"
              value={auditLines.filter(applyFilterByNotRequiresAction).length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Unknown Next Step"
              value={auditLines.filter(applyFilterByUnknownNextStep).length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Snoozed"
              value={auditLines.filter(applyFilterBySnoozed).length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="High Value ($300+)"
              value={auditLines.filter(applyFilterHighValue).length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
            <Statistic
              title="Currently Selected"
              value={filteredAuditLines.length}
              style={{
                margin: "0 32px 0 0",
              }}
            />
          </HorizontalStack>
        }
        extra={[
          <Button onClick={openAllInNewTabs}>Open all in new tabs</Button>,
        ]}
      >
        <PageTitle>{`Open Invoice Audits`}</PageTitle>

        <Stack align="left">
          <ButtonRow>
            <Form.Item label="Group">
              <GroupByDropdown
                disabled={loading}
                value={groupBy}
                setValue={setGroupBy}
              />
            </Form.Item>
            <Form.Item label="Filter">
              <FilterByDropdown
                disabled={loading}
                value={filterBy}
                setValue={setFilterBy}
                countByFilterType={function (filterType) {
                  return auditLines.filter(applyFilterBy(filterType)).length;
                }}
              />
            </Form.Item>
            <Form.Item label="Carrier">
              <CarrierFilter
                disabled={loading}
                carrierIdentifier={carrierIdentifier}
                setCarrierIdentifier={setCarrierIdentifier}
              />
            </Form.Item>
            <Form.Item label="Assignee">
              <AdminDropdown
                value={assigneeFilter}
                setValue={setAssigneeFilter}
                allowClear
                counter={assigneeCounter}
              />
            </Form.Item>
            <Form.Item label="Sort">
              <SortByDropdown value={sortBy} setValue={setSortBy} />
            </Form.Item>
          </ButtonRow>
        </Stack>

        <OpenInvoiceAuditsTable
          auditLines={filteredAuditLines}
          groupBy={groupBy}
          refresh={refresh}
        />
      </Page>
    </>
  );
}

import { AutochargeFrequency } from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import { EnumDropdown } from "../Screens/ViewShipmentScreenComponents/EnumDropdown";

export const AutochargeFrequencyDropdown = EnumDropdown<
  AutochargeFrequency,
  typeof AutochargeFrequency
>(AutochargeFrequency, function (et) {
  switch (et) {
    case AutochargeFrequency.Daily:
      return "Daily";
    case AutochargeFrequency.Weekly:
      return "Weekly";
    default:
      assertNever(et);
  }
});

import { Row, Typography } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useProfitLossApi } from "../Apis/Apis";
import Colors from "../Components/Colors";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatsCalendar } from "../Components/StatsCalendar";
import { TimeGraph } from "../Components/TimeGraph";
import { KpiGraphsReport } from "../generated-openapi-client";
import Spacer from "../Spacer";

const { Title } = Typography;

const Subtext = styled.div`
  font-size: 12px;
  color: ${Colors.LightText};
`;

export function KpisScreen() {
  const [report, setReport] = useState<KpiGraphsReport | undefined>();
  const [loading, setLoading] = useState(false);
  const createProfitLossApi = useProfitLossApi();

  async function refresh() {
    setLoading(true);

    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.generateKpiGraphs({});

    setReport(response);
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (report === undefined || loading) {
    return (
      <Loading message="Please wait. This takes around 10 seconds to load" />
    );
  } else {
    return (
      <Page title={`KPIs`} tags={[]} extra={[]}>
        <PageTitle>KPIs</PageTitle>
        <Row style={{ width: "100%" }}>
          <Stack align="left" style={{ width: "100%" }}>
            <Subtext>
              Interesting = Excludes Personal, Brokers, and OCCASSIONAL or
              Single Shipment. This refers to only interesting companies unless
              marked overall
            </Subtext>
            <Title level={4}>Overall Revenue</Title>
            <StatsCalendar data={report.revenue} maxValue={12000} />
            <Spacer height={64} />

            <Title level={4}>Overall Shipments Quoted</Title>
            <StatsCalendar data={report.shipmentsQuoted} maxValue={100} />
            <Spacer height={64} />

            <Title level={4}>Overall Shipments Booked</Title>
            <StatsCalendar data={report.shipmentsBooked} maxValue={30} />
            <Spacer height={64} />

            <Title level={4}>Daily/Weekly Shipper Revenue</Title>
            <StatsCalendar data={report.interestingRevenue} maxValue={12000} />
            <Spacer height={64} />

            <Title level={4}>Daily/Weekly Shipper Quotes</Title>
            <StatsCalendar
              data={report.interestingShipmentsQuoted}
              maxValue={100}
            />
            <Spacer height={64} />

            <Title level={4}>Daily/Weekly Shippers Booked</Title>
            <StatsCalendar
              data={report.interestingShipmentsBooked}
              maxValue={30}
            />
            <Spacer height={64} />

            <Title level={4}>Number Daily/Weekly Companies Quoted</Title>
            <TimeGraph data={report.companiesQuoted} />
            <Spacer height={64} />

            <Title level={4}>Existing Daily/Weekly Companies Quoted</Title>
            <Subtext>
              Companies where this wasn't the first period they quoted
            </Subtext>
            <TimeGraph data={report.companiesNotFirstQuote} />
            <Spacer height={64} />

            <Title level={4}>Daily/Weekly Companies First Quoted</Title>
            <Subtext>
              Companies where this was the first period they quoted
            </Subtext>
            <StatsCalendar data={report.companiesFirstQuote} maxValue={12} />
            <Spacer height={64} />

            <Title level={4}>Number Daily/Weekly Companies Booked</Title>
            <TimeGraph data={report.companiesBooked} />
            <Spacer height={64} />

            <Title level={4}>Daily/Weekly Companies First Booked</Title>
            <Subtext>
              Companies where this was the first period they booked
            </Subtext>
            <StatsCalendar data={report.companiesFirstBooking} maxValue={12} />
            <Spacer height={64} />

            <Title level={4}>Existing Daily/Weekly Companies Booked</Title>
            <Subtext>
              Companies where this wasn't the first period they booked
            </Subtext>
            <TimeGraph data={report.companiesNotFirstBooking} />
            <Spacer height={64} />

            <Title level={4}>Significant Activities</Title>
            <StatsCalendar data={report.significantCalls} maxValue={30} />
            <Spacer height={64} />
          </Stack>
        </Row>
      </Page>
    );
  }
}

import { Tag, TagProps } from "antd";
import { FreightClaimState } from "../../generated-openapi-client";

function describeState(state: FreightClaimState | undefined) {
  switch (state) {
    case FreightClaimState.Created:
      return "Created";
    case FreightClaimState.WaitingForCarrier:
      return "Waiting For Carrier";
    case FreightClaimState.WaitingForCustomer:
      return "Waiting For Customer";
    case FreightClaimState.AcceptedWaitingForPayment:
      return "Accepted Waiting For Payment";
    case FreightClaimState.AcceptedPaymentSentToCustomer:
      return "Accepted Payment Sent To Customer";
    case FreightClaimState.RejectedByCarrier:
      return "Rejected By Carrier";
    case FreightClaimState.Cancelled:
      return "Cancelled";
    default:
      return "Undefined";
  }
}

function colorState(state: FreightClaimState | undefined): TagProps["color"] {
  switch (state) {
    case FreightClaimState.Created:
      return "geekblue";
    case FreightClaimState.WaitingForCarrier:
      return "cyan";
    case FreightClaimState.WaitingForCustomer:
      return "orange";
    case FreightClaimState.AcceptedWaitingForPayment:
      return "processing";
    case FreightClaimState.AcceptedPaymentSentToCustomer:
      return "green";
    case FreightClaimState.RejectedByCarrier:
      return "red";
    case FreightClaimState.Cancelled:
      return "error";
    default:
      return "default";
  }
}

interface ClaimStatusTagProps {
  state: FreightClaimState | undefined;
}

export function ClaimStatusTag({ state }: ClaimStatusTagProps) {
  return (
    <Tag color={colorState(state)}>
      <div style={{ fontSize: "16px", fontWeight: 600, padding: "4px" }}>
        {describeState(state)}
      </div>
    </Tag>
  );
}

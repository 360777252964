import { DataTable, DataTableProps } from "../DataTable";
import { ListComparatorContextProvider } from "../comparators/ListComparatorContext";

interface ChangesDataTableProps<T> extends DataTableProps<T> {
  left: T[];
  right: T[];
}

/**
 * Shows the difference between elements in two lists in a DataTable.
 * Columns should be defined as in other table examples.
 * The only difference is that the column should use a comparator {@link ItemListComparator<T>} or {@link SimpleItemListComparator<T>} to render the component.
 * It uses {@link ListComparatorContextProvider} to avoid passing the lists in each comparator element
 */
export function ChangesDataTable<T extends object>(
  props: ChangesDataTableProps<T>
) {
  return (
    <ListComparatorContextProvider left={props.left} right={props.right}>
      <div
        style={{
          wordBreak: "keep-all",
          overflow: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        <DataTable {...props} />
      </div>
    </ListComparatorContextProvider>
  );
}

import MenuItem, { MenuItemProps } from "antd/lib/menu/MenuItem";

interface DownloadJsonButtonProps {
  data: object;
  fileName: string;
  name: string;
}

export function DownloadJsonMenuItem(props: DownloadJsonButtonProps & MenuItemProps) {
  const downloadJson = () => {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(props.data, null, 4);

    // Create a Blob with the JSON data
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = props.fileName;

    // Append the link to the body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the URL object
    URL.revokeObjectURL(url);
  };

  return <MenuItem onClick={downloadJson} {...props}>{props.name}</MenuItem>;
}

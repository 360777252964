import {
  BoxPlotFilled,
  CloudServerOutlined,
  CommentOutlined,
  MailOutlined,
  MessageOutlined,
  NumberOutlined,
  PhoneOutlined,
  QuestionOutlined,
  RocketOutlined,
  StopOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Collapse, Timeline, Typography } from "antd";
import moment from "moment";
import { CSSProperties, Fragment } from "react";
import { assertNever } from "../Helpers/assertNever";
import { capitalizeFirstLetter } from "../Helpers/capitalizeFirstLetter";
import { describeNoteType } from "../Helpers/describeNoteType";
import { groupBy } from "../Helpers/groupBy";
import { groupCollapsibleElements } from "../Helpers/groupCollapsibleElements";
import { stringToColor } from "../Helpers/stringToColor";
import {
  ViewNoteEmailMenuItem,
  ViewNoteIntercomMenuItem,
} from "../Screens/ViewShipmentScreenComponents/ViewNoteEmailMenuItem";
import Spacer from "../Spacer";
import {
  Note,
  NoteCategory,
  NoteEmailAddress,
  NoteLevel,
  NoteType,
} from "../generated-openapi-client";
import { ButtonRow } from "./ButtonRow";
import { CollapseShowGroupsTimelineItems } from "./CollapsibleTimeline";
import Colors from "./Colors";
import { FallbackErrorBoundary } from "./FallbackErrorBoundary";
import { Json } from "./Json";
import { LeftAlignedTimeline } from "./LeftAlignedTimeline";
import { CollapsibleNote } from "./NotesBase/CollapseNote";
import { NoteHeadline } from "./NotesBase/NoteHeadline";
import { NoteMessage } from "./NotesBase/NoteMessage";
import { NoteSubmessage } from "./NotesBase/NoteSubmessage";
import { ApolloActionNote } from "./NotesBase/shipment/ApolloActionNote";
import { ReferenceNumberChangedNote } from "./NotesBase/shipment/ReferenceNumberChangedNote";
import { ShipmentModifiedNote } from "./NotesBase/shipment/ShipmentModifiedNote";
import Stack from "./Stack";

const { Title } = Typography;

interface IconForNoteProps {
  note: Note;
}

export interface NoteElementProps {
  note: Note;
}

function describeEmail(emailAddress: NoteEmailAddress | undefined) {
  if (emailAddress === undefined) {
    return "";
  }

  return emailAddress.name + " <" + emailAddress.emailAddress + ">";
}

function describeEmails(emailAddresses: NoteEmailAddress[] | undefined) {
  if (emailAddresses === undefined) {
    return "";
  }

  return emailAddresses
    .map(function (em) {
      return describeEmail(em);
    })
    .join(", ");
}

function formatEmailBody(body: string | undefined) {
  if (body === undefined) {
    return "";
  }
  return body
    .split("\n")
    .map((line) => <div>{line}</div>)
    .slice(0, 4);
}

function EmailNoteElement(props: NoteElementProps) {
  const { note } = props;
  return (
    <div
      style={{
        paddingLeft: "8px",
        borderLeft: `4px solid ${stringToColor(note.link ?? "")}`,
      }}
    >
      <NoteHeadline>Subject: {note.emailSubject}</NoteHeadline>
      <NoteHeadline>From: {describeEmail(note.emailFrom)}</NoteHeadline>
      <NoteHeadline>To: {describeEmails(note.emailTo)}</NoteHeadline>
      {note.emailCc && note.emailCc.length > 0 && (
        <NoteHeadline>CC: {describeEmails(note.emailCc)}</NoteHeadline>
      )}
      <NoteMessage>{formatEmailBody(note.emailBodyCleanedUp)}</NoteMessage>
      <Spacer height={16} />
      <ButtonRow>
        <ViewNoteEmailMenuItem note={note} />
      </ButtonRow>
    </div>
  );
}

function DialpadNoteElement(props: NoteElementProps) {
  const { note } = props;
  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
      <Spacer height={16} />
      <ButtonRow>
        <a
          style={{ color: "#1890ff" }}
          target="_blank"
          href={props.note.link}
          rel="noreferrer"
        >
          View Recording
        </a>
      </ButtonRow>
    </>
  );
}

function IntercomNoteElement(props: NoteElementProps) {
  const { note } = props;

  let link = props.note.link;
  const openFrontUrl = "https://app.frontapp.com/open";

  if (!props.note.link?.includes(openFrontUrl)) {
    link = `${openFrontUrl}/${props.note.link}`;
  }

  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{formatEmailBody(note.body)}</NoteMessage>
      <ButtonRow>
        <ViewNoteIntercomMenuItem note={note} />
        {props.note.link && <a href={link}>View in Front</a>}
      </ButtonRow>
    </>
  );
}

export function BaseNote({ note }: NoteElementProps) {
  return (
    <>
      <NoteHeadline>{note.headline}</NoteHeadline>
      <NoteMessage>{note.message}</NoteMessage>
    </>
  );
}

export function NoteElement(props: NoteElementProps) {
  const { note } = props;

  if (note.noteType === NoteType.Email) {
    return <EmailNoteElement {...props} />;
  }

  if (note.noteType === NoteType.Dialpad) {
    return <DialpadNoteElement {...props} />;
  }

  if (note.noteType === NoteType.Intercom) {
    return <IntercomNoteElement {...props} />;
  }

  if (note.noteType === NoteType.ShipmentModified) {
    return <ShipmentModifiedNote note={note} />;
  }

  if (note.noteType === NoteType.ReferenceNumberChanged) {
    return <ReferenceNumberChangedNote note={note} />;
  }

  if (note.noteType === NoteType.ApolloAction) {
    return <ApolloActionNote {...props} />;
  }

  return <BaseNote {...props} />;
}

interface ErrorNoteElementProps {
  metadataError?: boolean;
  note: Note;
}

function ErrorNoteElement({
  note,
  metadataError = false,
}: ErrorNoteElementProps) {
  let beforeMetadata: any;
  let afterMetadata: any;

  if (note.beforeMetadata && note.afterMetadata) {
    try {
      beforeMetadata = JSON.parse(note.beforeMetadata);
      afterMetadata = JSON.parse(note.afterMetadata);
    } catch (error) {
      return (
        <>
          <NoteHeadline>{note.headline}</NoteHeadline>
          <NoteMessage>{note.message}</NoteMessage>
          <NoteSubmessage>
            {metadataError && <div>Could not load additional information</div>}
          </NoteSubmessage>
        </>
      );
    }
  }

  return (
    <>
      <CollapsibleNote
        collapseActive={false}
        header={
          <div>
            <div>
              <NoteHeadline>{note.headline}</NoteHeadline>
              <NoteMessage>{note.message}</NoteMessage>
            </div>
            <div>
              <NoteMessage>{note.body}</NoteMessage>
              <div style={{ color: Colors.Red }}>
                Something went wrong with this note!
              </div>
            </div>
          </div>
        }
      >
        <Collapse ghost>
          <Collapse.Panel
            style={{ padding: 10 }}
            header="Raw metadata"
            key={"raw-metadata-panel"}
          >
            {beforeMetadata && <Json data={beforeMetadata} />}
            {afterMetadata && <Json data={afterMetadata} />}
          </Collapse.Panel>
        </Collapse>
      </CollapsibleNote>
    </>
  );
}

function colorForNoteIcon(note: Note): string {
  const level = note.noteLevel!!;
  switch (level) {
    case NoteLevel.Info:
      return Colors.Blue;
    case NoteLevel.Warning:
      return Colors.Gold;
    case NoteLevel.Error:
      return Colors.Red;
    default:
      assertNever(level);
  }
}

export function IconForNote(props: IconForNoteProps) {
  const iconStyle: CSSProperties = {
    color: colorForNoteIcon(props.note),
    fontSize: "24px",
    marginRight: "8px",
    marginLeft: "8px",
  };
  if (!props.note.noteType) {
    return <QuestionOutlined style={iconStyle} />;
  }
  const noteType = props.note.noteType!!;
  switch (noteType) {
    case NoteType.ShipmentCancelled:
      return <StopOutlined style={iconStyle} />;
    case NoteType.ShipmentModified:
      return <BoxPlotFilled style={iconStyle} />;
    case NoteType.ApolloAction:
      return <RocketOutlined style={iconStyle} />;
    case NoteType.Dialpad:
      return <PhoneOutlined style={iconStyle} />;
    case NoteType.DashboardAction:
      return <UserOutlined style={iconStyle} />;
    case NoteType.SalesforceCall:
      return <TeamOutlined style={iconStyle} />;
    case NoteType.Email:
      return <MailOutlined style={iconStyle} />;
    case NoteType.Intercom:
      return <CommentOutlined style={iconStyle} />;
    case NoteType.MondayComment:
      return <UnorderedListOutlined style={iconStyle} />;
    case NoteType.ReferenceNumberChanged:
      return <NumberOutlined style={iconStyle} />;
    case NoteType.SystemMessage:
      return <CloudServerOutlined style={iconStyle} />;
    case NoteType.InternalNote:
      return <MessageOutlined style={iconStyle} />;
    case NoteType.SystemErrorUnused:
      return <></>;
    default:
      assertNever(noteType);
  }
}

export interface ShowNote {
  show: boolean;
  note: Note;
}

export interface CollapseNote {
  collapse: boolean;
  notes: Note[];
}

export interface GroupedChunks {
  key: string;
  chunks: CollapseNote[];
}

interface NotesTableProps {
  noteTypeFilter: NoteType | NoteType[] | undefined;
  noteAuthorFilter: string | string[] | undefined;
  noteCategoryFilter: NoteCategory | NoteCategory[] | undefined;
  freeFilter: string | undefined;
  showSystemErrors: boolean;
  notes: Note[];
}

export function NotesTable(props: NotesTableProps) {
  function filterByNoteType(note: Note): boolean {
    if (
      props.noteTypeFilter === undefined ||
      props.noteTypeFilter.length === 0
    ) {
      return true;
    }

    return note.noteType ? props.noteTypeFilter.includes(note.noteType) : false;
  }

  function filterByNoteCategory(note: Note): boolean {
    if (
      props.noteCategoryFilter === undefined ||
      props.noteCategoryFilter.length === 0
    ) {
      return true;
    }

    return note.category
      ? props.noteCategoryFilter.includes(note.category)
      : false;
  }

  function filterByAuthor(note: Note): boolean {
    if (
      props.noteAuthorFilter === undefined ||
      props.noteAuthorFilter.length === 0
    ) {
      return true;
    }

    return note.author ? props.noteAuthorFilter.includes(note.author) : false;
  }

  function filterSystemErrors(note: Note): boolean {
    if (props.showSystemErrors) {
      return true;
    }
    return note.headline !== "System Error";
  }

  function applyFreeFilter(note: Note): boolean {
    if (props.freeFilter === undefined || props.freeFilter.trim() === "") {
      return true;
    }

    return JSON.stringify(note)
      .toLowerCase()
      .includes(props.freeFilter.toLowerCase());
  }

  function filterNotes() {
    return props.notes
      .filter(filterByNoteType)
      .filter(filterByNoteCategory)
      .filter(filterByAuthor)
      .filter(applyFreeFilter)
      .filter(filterSystemErrors);
  }

  function sortByDate2(a: Note, b: Note) {
    return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
  }

  function groupByCreationDate2(f: Note) {
    const m = moment.utc(f.createdAt).tz("America/Vancouver");
    return m.format("MMMM Do YYYY");
  }

  /**
   * Notes to show after applying the filters
   */
  const notesToShow = filterNotes();

  const errorFilteredNotes = props.notes.filter(filterSystemErrors);

  const group = groupBy(
    errorFilteredNotes.sort(sortByDate2),
    groupByCreationDate2
  );

  const groupedCollapsibles = groupCollapsibleElements(group, (e) =>
    notesToShow.includes(e)
  );

  return (
    <>
      {groupedCollapsibles.map((group, i) => (
        <Fragment key={i}>
          {i > 0 && <Spacer height={10} />}
          <LeftAlignedTimeline>
            <Timeline.Item
              dot={
                <>
                  <Title level={2} style={{ margin: 0 }}>
                    {group.key}
                  </Title>
                  <Title
                    type="secondary"
                    level={5}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {moment(group.showGroups[0].elements[0].createdAt).format(
                      "dddd"
                    )}
                  </Title>
                </>
              }
            >
              <Spacer height={60} />
            </Timeline.Item>
            <CollapseShowGroupsTimelineItems
              showGroups={group.showGroups}
              renderShowGroupHeader={(chunk) => (
                <>
                  {chunk.elements.length}
                  {chunk.elements.length > 1 ? " notes" : " note"} hidden{" "}
                </>
              )}
              onTimelineItemRender={(e) => ({
                dot: (
                  <span
                    title={`${describeNoteType(
                      e.noteType
                    )} - ${capitalizeFirstLetter(e.category)}`}
                  >
                    <IconForNote note={e} />
                    <Spacer height={4} />
                    <span
                      style={{
                        display: "block",
                        color: colorForNoteIcon(e),
                        fontSize: "10px",
                        fontWeight: "400",
                      }}
                    >
                      {capitalizeFirstLetter(e.category)}
                    </span>
                  </span>
                ),
                label: <NoteTimelineLable note={e} />,
                element: (
                  <div
                    style={{
                      marginLeft: "36px",
                      marginBlock: "16px",
                    }}
                  >
                    <FallbackErrorBoundary
                      fallback={<ErrorNoteElement note={e} />}
                    >
                      <NoteElement note={e} />
                    </FallbackErrorBoundary>
                  </div>
                ),
              })}
            />
          </LeftAlignedTimeline>
        </Fragment>
      ))}
    </>
  );
}

function NoteTimelineLable({ note }: { note: Note }) {
  const date = moment(note.createdAt);
  const delta = capitalizeFirstLetter(date.fromNow());
  return (
    <Stack align="right" style={{ marginRight: "36px" }}>
      <div style={{ fontSize: "14px", fontWeight: "600" }}>
        {date.format("h:mm a")}
      </div>
      <div style={{ fontSize: "12px", color: Colors.LightText }}>{delta}</div>
    </Stack>
  );
}

import { useEffect, useState } from "react";
import { useMapsApi } from "../Apis/Apis";
import { ShipmentReport } from "../generated-openapi-client";
import { ApolloMapQuery } from "../generated-openapi-client/models/ApolloMapQuery";

function pointToLineFeature(o: ShipmentReport) {
  const wasBooked = o.booked;
  const relevantQuote = wasBooked ? o.bookedQuote!! : o.lowestQuote;
  if (relevantQuote === undefined) {
    return {
      type: "Feature",
      properties: {
        shipmentReportId: o.shipmentId,
        url: `/view-shipment?shipmentId=${o.shipmentId}`,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [o.pickupLng, o.pickupLat],
          [o.deliveryLng, o.deliveryLat],
        ],
      },
    };
  }

  return {
    type: "Feature",
    properties: {
      shipmentReportId: o.shipmentId,
      url: `/view-shipment?shipmentId=${o.shipmentId}`,
    },
    geometry: {
      type: "LineString",
      coordinates: [
        [o.pickupLng, o.pickupLat],
        [o.deliveryLng, o.deliveryLat],
      ],
    },
  };
}

function pointToPickupPointFeature(o: ShipmentReport) {
  const wasBooked = o.booked;
  const relevantQuote = wasBooked ? o.bookedQuote!! : o.lowestQuote;
  if (relevantQuote === undefined) {
    return {
      type: "Feature",
      properties: {
        shipmentReportId: o.shipmentId,
        url: `/view-shipment?shipmentId=${o.shipmentId}`,
      },
      geometry: {
        type: "Point",
        coordinates: [o.pickupLng, o.pickupLat],
      },
    };
  }
  return {
    type: "Feature",
    properties: {
      shipmentReportId: o.shipmentId,
      url: `/view-shipment?shipmentId=${o.shipmentId}`,
    },
    geometry: {
      type: "Point",
      coordinates: [o.pickupLng, o.pickupLat],
    },
  };
}

function pointToDestinationPointFeature(o: ShipmentReport) {
  const wasBooked = o.booked;
  const relevantQuote = wasBooked ? o.bookedQuote!! : o.lowestQuote;

  if (relevantQuote === undefined) {
    return {
      type: "Feature",
      properties: {
        shipmentReportId: o.shipmentId,
        url: `/view-shipment?shipmentId=${o.shipmentId}`,
      },
      geometry: {
        type: "Point",
        coordinates: [o.deliveryLng, o.deliveryLat],
      },
    };
  }
  return {
    type: "Feature",
    properties: {
      shipmentReportId: o.shipmentId,
      url: `/view-shipment?shipmentId=${o.shipmentId}`,
    },
    geometry: {
      type: "Point",
      coordinates: [o.deliveryLng, o.deliveryLat],
    },
  };
}

export interface MapData {
  bookedFeatures: any[];
  notBookedFeatures: any[];
  bookedFeaturesCirclePickup: any[];
  notBookedFeaturesCirclePickup: any[];
  bookedFeaturesCircleDelivery: any[];
  notBookedFeaturesCircleDelivery: any[];
  bookedShipmentsDensity: any;
  notBookedShipmentsDensity: any;
  shipmentsDensity: any;
  shipments: ShipmentReport[];
}

interface MapDataOutput {
  data: MapData | undefined;
  loading: boolean;
}

export function convertShipmentReportsToMapData(
  data: ShipmentReport[]
): MapData {
  const bookedShipments = data.filter((o) => o.booked);
  const notBookedShipments = data.filter((o) => !o.booked);
  const allShipments = data;

  const bookedFeatures = bookedShipments.map(pointToLineFeature);
  const notBookedFeatures = notBookedShipments.map(pointToLineFeature);

  const bookedFeaturesCircleDelivery = bookedShipments.map(
    pointToDestinationPointFeature
  );
  const notBookedFeaturesCircleDelivery = notBookedShipments.map(
    pointToDestinationPointFeature
  );

  const bookedFeaturesCirclePickup = bookedShipments.map(
    pointToPickupPointFeature
  );
  const notBookedFeaturesCirclePickup = notBookedShipments.map(
    pointToPickupPointFeature
  );

  function calculateDensity(shipments: Array<ShipmentReport>): number {
    // Is it actually ok to average densities like this?
    let totalDensity = 0;
    shipments.forEach(function (o) {
      totalDensity += o.density!!;
    });
    return totalDensity / shipments.length;
  }

  const bookedShipmentsDensity = calculateDensity(bookedShipments);
  const notBookedShipmentsDensity = calculateDensity(notBookedShipments);
  const shipmentsDensity = calculateDensity(allShipments);

  return {
    bookedFeatures,
    notBookedFeatures,
    bookedFeaturesCirclePickup,
    notBookedFeaturesCirclePickup,
    bookedFeaturesCircleDelivery,
    notBookedFeaturesCircleDelivery,
    bookedShipmentsDensity,
    notBookedShipmentsDensity,
    shipmentsDensity,
    shipments: data,
  };
}

export function useMapData(query: ApolloMapQuery): MapDataOutput {
  const [data, setData] = useState<ShipmentReport[] | undefined>();
  const [loading, setLoading] = useState(false);

  const createMapsApi = useMapsApi();

  async function load() {
    setLoading(true);
    const mapsApi = await createMapsApi();
    const response = await mapsApi.generateMap({ apolloMapQuery: query });
    setData(response);
    setLoading(false);
  }

  useEffect(
    function () {
      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(query)]
  );

  if (data === undefined) {
    return { data: undefined, loading };
  }

  return {
    data: convertShipmentReportsToMapData(data),
    loading: loading,
  };
}

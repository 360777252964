/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DimensionReportSupportEmailInput,
  EmailDescription,
  FailedPaymentSupportEmailType,
  InTransitSupportEmailType,
  InvoiceChallengeType,
  MissedPickupSupportEmailType,
} from '../models/index';
import {
    DimensionReportSupportEmailInputFromJSON,
    DimensionReportSupportEmailInputToJSON,
    EmailDescriptionFromJSON,
    EmailDescriptionToJSON,
    FailedPaymentSupportEmailTypeFromJSON,
    FailedPaymentSupportEmailTypeToJSON,
    InTransitSupportEmailTypeFromJSON,
    InTransitSupportEmailTypeToJSON,
    InvoiceChallengeTypeFromJSON,
    InvoiceChallengeTypeToJSON,
    MissedPickupSupportEmailTypeFromJSON,
    MissedPickupSupportEmailTypeToJSON,
} from '../models/index';

export interface PreviewAdditionalChargeEmailRequest {
    shipmentId: string;
    additionalChargeId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewCarrierClaimEmailRequest {
    shipmentId: string;
    extraText: string;
    freightClaimId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewCarrierInvoiceChallengeEmailRequest {
    shipmentId: string;
    extraText: string;
    carrierInvoiceId: string;
    invoiceChallengeType: InvoiceChallengeType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewCompanyVerifiedEmailRequest {
    companyId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    to: Array<string>;
}

export interface PreviewCustomsDelayNotificationEmailRequest {
    shipmentId: string;
    alsoSendToBroker: boolean;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewDimensionReportEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    dimensionReportSupportEmailInput: DimensionReportSupportEmailInput;
}

export interface PreviewFailedPaymentEmailRequest {
    shipmentId: string;
    customerInvoiceId: string;
    extraText: string;
    failedPaymentSupportEmailType: FailedPaymentSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewGenericEmailRequest {
    shipmentId: string;
    extraText: string;
    subject: string;
    toAddresses: Array<string>;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewInTransitEmailRequest {
    shipmentId: string;
    extraText: string;
    inTransitSupportEmailType: InTransitSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewLogisticsAssistanceEmailRequest {
    shipmentId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewMissedPickupEmailRequest {
    shipmentId: string;
    extraText: string;
    originalPickupDate: string;
    newPickupDate: string;
    missedPickupSupportEmailType: MissedPickupSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewNoQuotesNotificationEmailRequest {
    shipmentId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewQuoteNotificationEmailRequest {
    shipmentId: string;
    quoteId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewRequestPhotosOfMissingFreightSupportEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewUploadCustomsDocsReminderEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewWaivedAdditionalChargeEmailRequest {
    shipmentId: string;
    additionalChargeId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface PreviewWeightReportEmailRequest {
    shipmentId: string;
    carrierInvoiceId: string;
    reportedWeight: number;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
}

export interface SendAdditionalChargeEmailRequest {
    shipmentId: string;
    additionalChargeId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendCarrierClaimEmailRequest {
    shipmentId: string;
    extraText: string;
    freightClaimId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendCarrierInvoiceChallengeEmailRequest {
    shipmentId: string;
    extraText: string;
    carrierInvoiceId: string;
    invoiceChallengeType: InvoiceChallengeType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendCompanyVerifiedEmailRequest {
    companyId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    to: Array<string>;
}

export interface SendCustomsDelayNotificationEmailRequest {
    shipmentId: string;
    alsoSendToBroker: boolean;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendDimensionReportEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
    dimensionReportSupportEmailInput: DimensionReportSupportEmailInput;
}

export interface SendFailedPaymentEmailRequest {
    shipmentId: string;
    customerInvoiceId: string;
    extraText: string;
    failedPaymentSupportEmailType: FailedPaymentSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendGenericEmailRequest {
    shipmentId: string;
    extraText: string;
    subject: string;
    toAddresses: Array<string>;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendInTransitEmailRequest {
    shipmentId: string;
    extraText: string;
    inTransitSupportEmailType: InTransitSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendLogisiticsAssistanceEmailRequest {
    shipmentId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendMissedPickupEmailRequest {
    shipmentId: string;
    extraText: string;
    originalPickupDate: string;
    newPickupDate: string;
    missedPickupSupportEmailType: MissedPickupSupportEmailType;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendNoQuotesNotificationEmailRequest {
    shipmentId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendQuoteNotificationEmailRequest {
    shipmentId: string;
    quoteId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendRequestPhotosOfMissingFreightSupportEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendUploadCustomsDocsReminderEmailRequest {
    shipmentId: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendWaivedAdditionalChargeEmailRequest {
    shipmentId: string;
    additionalChargeId: string;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

export interface SendWeightReportEmailRequest {
    shipmentId: string;
    carrierInvoiceId: string;
    reportedWeight: number;
    extraText: string;
    supportAgentFirstName: string;
    supportAgentLastName: string;
    attachmentDocumentIds: Array<string>;
}

/**
 * 
 */
export class SupportEmailApi extends runtime.BaseAPI {

    /**
     */
    async previewAdditionalChargeEmailRaw(requestParameters: PreviewAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewAdditionalChargeEmail().'
            );
        }

        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling previewAdditionalChargeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewAdditionalChargeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-additional-charge-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewAdditionalChargeEmail(requestParameters: PreviewAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewAdditionalChargeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCarrierClaimEmailRaw(requestParameters: PreviewCarrierClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewCarrierClaimEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewCarrierClaimEmail().'
            );
        }

        if (requestParameters['freightClaimId'] == null) {
            throw new runtime.RequiredError(
                'freightClaimId',
                'Required parameter "freightClaimId" was null or undefined when calling previewCarrierClaimEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewCarrierClaimEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewCarrierClaimEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['freightClaimId'] != null) {
            queryParameters['freightClaimId'] = requestParameters['freightClaimId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-carrier-claim-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewCarrierClaimEmail(requestParameters: PreviewCarrierClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewCarrierClaimEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCarrierInvoiceChallengeEmailRaw(requestParameters: PreviewCarrierInvoiceChallengeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['invoiceChallengeType'] == null) {
            throw new runtime.RequiredError(
                'invoiceChallengeType',
                'Required parameter "invoiceChallengeType" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewCarrierInvoiceChallengeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['invoiceChallengeType'] != null) {
            queryParameters['invoiceChallengeType'] = requestParameters['invoiceChallengeType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-carrier-invoice-challenge-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewCarrierInvoiceChallengeEmail(requestParameters: PreviewCarrierInvoiceChallengeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewCarrierInvoiceChallengeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCompanyVerifiedEmailRaw(requestParameters: PreviewCompanyVerifiedEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling previewCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['to'] == null) {
            throw new runtime.RequiredError(
                'to',
                'Required parameter "to" was null or undefined when calling previewCompanyVerifiedEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = requestParameters['to'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-company-verified-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewCompanyVerifiedEmail(requestParameters: PreviewCompanyVerifiedEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewCompanyVerifiedEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewCustomsDelayNotificationEmailRaw(requestParameters: PreviewCustomsDelayNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['alsoSendToBroker'] == null) {
            throw new runtime.RequiredError(
                'alsoSendToBroker',
                'Required parameter "alsoSendToBroker" was null or undefined when calling previewCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewCustomsDelayNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['alsoSendToBroker'] != null) {
            queryParameters['alsoSendToBroker'] = requestParameters['alsoSendToBroker'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-customs-delay-notification-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewCustomsDelayNotificationEmail(requestParameters: PreviewCustomsDelayNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewCustomsDelayNotificationEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewDimensionReportEmailRaw(requestParameters: PreviewDimensionReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewDimensionReportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewDimensionReportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewDimensionReportEmail().'
            );
        }

        if (requestParameters['dimensionReportSupportEmailInput'] == null) {
            throw new runtime.RequiredError(
                'dimensionReportSupportEmailInput',
                'Required parameter "dimensionReportSupportEmailInput" was null or undefined when calling previewDimensionReportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/support-email/preview-dimension-report-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DimensionReportSupportEmailInputToJSON(requestParameters['dimensionReportSupportEmailInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewDimensionReportEmail(requestParameters: PreviewDimensionReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewDimensionReportEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewFailedPaymentEmailRaw(requestParameters: PreviewFailedPaymentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        if (requestParameters['failedPaymentSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'failedPaymentSupportEmailType',
                'Required parameter "failedPaymentSupportEmailType" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewFailedPaymentEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['failedPaymentSupportEmailType'] != null) {
            queryParameters['failedPaymentSupportEmailType'] = requestParameters['failedPaymentSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-failed-payment-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewFailedPaymentEmail(requestParameters: PreviewFailedPaymentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewFailedPaymentEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewGenericEmailRaw(requestParameters: PreviewGenericEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewGenericEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewGenericEmail().'
            );
        }

        if (requestParameters['subject'] == null) {
            throw new runtime.RequiredError(
                'subject',
                'Required parameter "subject" was null or undefined when calling previewGenericEmail().'
            );
        }

        if (requestParameters['toAddresses'] == null) {
            throw new runtime.RequiredError(
                'toAddresses',
                'Required parameter "toAddresses" was null or undefined when calling previewGenericEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewGenericEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewGenericEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['subject'] != null) {
            queryParameters['subject'] = requestParameters['subject'];
        }

        if (requestParameters['toAddresses'] != null) {
            queryParameters['toAddresses'] = requestParameters['toAddresses'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-generic-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewGenericEmail(requestParameters: PreviewGenericEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewGenericEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewInTransitEmailRaw(requestParameters: PreviewInTransitEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewInTransitEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewInTransitEmail().'
            );
        }

        if (requestParameters['inTransitSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'inTransitSupportEmailType',
                'Required parameter "inTransitSupportEmailType" was null or undefined when calling previewInTransitEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewInTransitEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewInTransitEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['inTransitSupportEmailType'] != null) {
            queryParameters['inTransitSupportEmailType'] = requestParameters['inTransitSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-in-transit-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewInTransitEmail(requestParameters: PreviewInTransitEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewInTransitEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewLogisticsAssistanceEmailRaw(requestParameters: PreviewLogisticsAssistanceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewLogisticsAssistanceEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewLogisticsAssistanceEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewLogisticsAssistanceEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewLogisticsAssistanceEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-logisitics-assistance-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewLogisticsAssistanceEmail(requestParameters: PreviewLogisticsAssistanceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewLogisticsAssistanceEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewMissedPickupEmailRaw(requestParameters: PreviewMissedPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['originalPickupDate'] == null) {
            throw new runtime.RequiredError(
                'originalPickupDate',
                'Required parameter "originalPickupDate" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['newPickupDate'] == null) {
            throw new runtime.RequiredError(
                'newPickupDate',
                'Required parameter "newPickupDate" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['missedPickupSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'missedPickupSupportEmailType',
                'Required parameter "missedPickupSupportEmailType" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewMissedPickupEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['originalPickupDate'] != null) {
            queryParameters['originalPickupDate'] = requestParameters['originalPickupDate'];
        }

        if (requestParameters['newPickupDate'] != null) {
            queryParameters['newPickupDate'] = requestParameters['newPickupDate'];
        }

        if (requestParameters['missedPickupSupportEmailType'] != null) {
            queryParameters['missedPickupSupportEmailType'] = requestParameters['missedPickupSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-missed-pickup-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewMissedPickupEmail(requestParameters: PreviewMissedPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewMissedPickupEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewNoQuotesNotificationEmailRaw(requestParameters: PreviewNoQuotesNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewNoQuotesNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-no-quotes-notification-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewNoQuotesNotificationEmail(requestParameters: PreviewNoQuotesNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewNoQuotesNotificationEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewQuoteNotificationEmailRaw(requestParameters: PreviewQuoteNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewQuoteNotificationEmail().'
            );
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling previewQuoteNotificationEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewQuoteNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewQuoteNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewQuoteNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-quote-notification-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewQuoteNotificationEmail(requestParameters: PreviewQuoteNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewQuoteNotificationEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewRequestPhotosOfMissingFreightSupportEmailRaw(requestParameters: PreviewRequestPhotosOfMissingFreightSupportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-request-photos-of-missing-freight-support-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewRequestPhotosOfMissingFreightSupportEmail(requestParameters: PreviewRequestPhotosOfMissingFreightSupportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewRequestPhotosOfMissingFreightSupportEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewUploadCustomsDocsReminderEmailRaw(requestParameters: PreviewUploadCustomsDocsReminderEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewUploadCustomsDocsReminderEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewUploadCustomsDocsReminderEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewUploadCustomsDocsReminderEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-upload-customs-docs-reminder-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewUploadCustomsDocsReminderEmail(requestParameters: PreviewUploadCustomsDocsReminderEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewUploadCustomsDocsReminderEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewWaivedAdditionalChargeEmailRaw(requestParameters: PreviewWaivedAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling previewWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewWaivedAdditionalChargeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-waived-additional-charge-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewWaivedAdditionalChargeEmail(requestParameters: PreviewWaivedAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewWaivedAdditionalChargeEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewWeightReportEmailRaw(requestParameters: PreviewWeightReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        if (requestParameters['reportedWeight'] == null) {
            throw new runtime.RequiredError(
                'reportedWeight',
                'Required parameter "reportedWeight" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling previewWeightReportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['reportedWeight'] != null) {
            queryParameters['reportedWeight'] = requestParameters['reportedWeight'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/preview-weight-report-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewWeightReportEmail(requestParameters: PreviewWeightReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewWeightReportEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendAdditionalChargeEmailRaw(requestParameters: SendAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendAdditionalChargeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-additional-charge-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendAdditionalChargeEmail(requestParameters: SendAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendAdditionalChargeEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendCarrierClaimEmailRaw(requestParameters: SendCarrierClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        if (requestParameters['freightClaimId'] == null) {
            throw new runtime.RequiredError(
                'freightClaimId',
                'Required parameter "freightClaimId" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendCarrierClaimEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['freightClaimId'] != null) {
            queryParameters['freightClaimId'] = requestParameters['freightClaimId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-carrier-claim-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendCarrierClaimEmail(requestParameters: SendCarrierClaimEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCarrierClaimEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendCarrierInvoiceChallengeEmailRaw(requestParameters: SendCarrierInvoiceChallengeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['invoiceChallengeType'] == null) {
            throw new runtime.RequiredError(
                'invoiceChallengeType',
                'Required parameter "invoiceChallengeType" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendCarrierInvoiceChallengeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['invoiceChallengeType'] != null) {
            queryParameters['invoiceChallengeType'] = requestParameters['invoiceChallengeType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-carrier-invoice-challenge-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendCarrierInvoiceChallengeEmail(requestParameters: SendCarrierInvoiceChallengeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCarrierInvoiceChallengeEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendCompanyVerifiedEmailRaw(requestParameters: SendCompanyVerifiedEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling sendCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendCompanyVerifiedEmail().'
            );
        }

        if (requestParameters['to'] == null) {
            throw new runtime.RequiredError(
                'to',
                'Required parameter "to" was null or undefined when calling sendCompanyVerifiedEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = requestParameters['to'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-company-verified-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendCompanyVerifiedEmail(requestParameters: SendCompanyVerifiedEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCompanyVerifiedEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendCustomsDelayNotificationEmailRaw(requestParameters: SendCustomsDelayNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['alsoSendToBroker'] == null) {
            throw new runtime.RequiredError(
                'alsoSendToBroker',
                'Required parameter "alsoSendToBroker" was null or undefined when calling sendCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendCustomsDelayNotificationEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendCustomsDelayNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['alsoSendToBroker'] != null) {
            queryParameters['alsoSendToBroker'] = requestParameters['alsoSendToBroker'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-customs-delay-notification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendCustomsDelayNotificationEmail(requestParameters: SendCustomsDelayNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendCustomsDelayNotificationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendDimensionReportEmailRaw(requestParameters: SendDimensionReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendDimensionReportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendDimensionReportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendDimensionReportEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendDimensionReportEmail().'
            );
        }

        if (requestParameters['dimensionReportSupportEmailInput'] == null) {
            throw new runtime.RequiredError(
                'dimensionReportSupportEmailInput',
                'Required parameter "dimensionReportSupportEmailInput" was null or undefined when calling sendDimensionReportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/support-email/send-dimension-report-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DimensionReportSupportEmailInputToJSON(requestParameters['dimensionReportSupportEmailInput']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendDimensionReportEmail(requestParameters: SendDimensionReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendDimensionReportEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendFailedPaymentEmailRaw(requestParameters: SendFailedPaymentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['customerInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'customerInvoiceId',
                'Required parameter "customerInvoiceId" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['failedPaymentSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'failedPaymentSupportEmailType',
                'Required parameter "failedPaymentSupportEmailType" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendFailedPaymentEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['customerInvoiceId'] != null) {
            queryParameters['customerInvoiceId'] = requestParameters['customerInvoiceId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['failedPaymentSupportEmailType'] != null) {
            queryParameters['failedPaymentSupportEmailType'] = requestParameters['failedPaymentSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-failed-payment-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendFailedPaymentEmail(requestParameters: SendFailedPaymentEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendFailedPaymentEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendGenericEmailRaw(requestParameters: SendGenericEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['subject'] == null) {
            throw new runtime.RequiredError(
                'subject',
                'Required parameter "subject" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['toAddresses'] == null) {
            throw new runtime.RequiredError(
                'toAddresses',
                'Required parameter "toAddresses" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendGenericEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendGenericEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['subject'] != null) {
            queryParameters['subject'] = requestParameters['subject'];
        }

        if (requestParameters['toAddresses'] != null) {
            queryParameters['toAddresses'] = requestParameters['toAddresses'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-generic-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendGenericEmail(requestParameters: SendGenericEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendGenericEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendInTransitEmailRaw(requestParameters: SendInTransitEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendInTransitEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendInTransitEmail().'
            );
        }

        if (requestParameters['inTransitSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'inTransitSupportEmailType',
                'Required parameter "inTransitSupportEmailType" was null or undefined when calling sendInTransitEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendInTransitEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendInTransitEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendInTransitEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['inTransitSupportEmailType'] != null) {
            queryParameters['inTransitSupportEmailType'] = requestParameters['inTransitSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-in-transit-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendInTransitEmail(requestParameters: SendInTransitEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendInTransitEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendLogisiticsAssistanceEmailRaw(requestParameters: SendLogisiticsAssistanceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendLogisiticsAssistanceEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendLogisiticsAssistanceEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendLogisiticsAssistanceEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendLogisiticsAssistanceEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendLogisiticsAssistanceEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-logistics-assistance-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendLogisiticsAssistanceEmail(requestParameters: SendLogisiticsAssistanceEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendLogisiticsAssistanceEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendMissedPickupEmailRaw(requestParameters: SendMissedPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['originalPickupDate'] == null) {
            throw new runtime.RequiredError(
                'originalPickupDate',
                'Required parameter "originalPickupDate" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['newPickupDate'] == null) {
            throw new runtime.RequiredError(
                'newPickupDate',
                'Required parameter "newPickupDate" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['missedPickupSupportEmailType'] == null) {
            throw new runtime.RequiredError(
                'missedPickupSupportEmailType',
                'Required parameter "missedPickupSupportEmailType" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendMissedPickupEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['originalPickupDate'] != null) {
            queryParameters['originalPickupDate'] = requestParameters['originalPickupDate'];
        }

        if (requestParameters['newPickupDate'] != null) {
            queryParameters['newPickupDate'] = requestParameters['newPickupDate'];
        }

        if (requestParameters['missedPickupSupportEmailType'] != null) {
            queryParameters['missedPickupSupportEmailType'] = requestParameters['missedPickupSupportEmailType'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-missed-pickup-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendMissedPickupEmail(requestParameters: SendMissedPickupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendMissedPickupEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendNoQuotesNotificationEmailRaw(requestParameters: SendNoQuotesNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendNoQuotesNotificationEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendNoQuotesNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-no-quotes-notification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendNoQuotesNotificationEmail(requestParameters: SendNoQuotesNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendNoQuotesNotificationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendQuoteNotificationEmailRaw(requestParameters: SendQuoteNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendQuoteNotificationEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-quote-notification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendQuoteNotificationEmail(requestParameters: SendQuoteNotificationEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendQuoteNotificationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendRequestPhotosOfMissingFreightSupportEmailRaw(requestParameters: SendRequestPhotosOfMissingFreightSupportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendRequestPhotosOfMissingFreightSupportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-request-photos-of-missing-freight-support-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendRequestPhotosOfMissingFreightSupportEmail(requestParameters: SendRequestPhotosOfMissingFreightSupportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendRequestPhotosOfMissingFreightSupportEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendUploadCustomsDocsReminderEmailRaw(requestParameters: SendUploadCustomsDocsReminderEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendUploadCustomsDocsReminderEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendUploadCustomsDocsReminderEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendUploadCustomsDocsReminderEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendUploadCustomsDocsReminderEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-upload-customs-docs-reminder-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendUploadCustomsDocsReminderEmail(requestParameters: SendUploadCustomsDocsReminderEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendUploadCustomsDocsReminderEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendWaivedAdditionalChargeEmailRaw(requestParameters: SendWaivedAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['additionalChargeId'] == null) {
            throw new runtime.RequiredError(
                'additionalChargeId',
                'Required parameter "additionalChargeId" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendWaivedAdditionalChargeEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['additionalChargeId'] != null) {
            queryParameters['additionalChargeId'] = requestParameters['additionalChargeId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-waived-additional-charge-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendWaivedAdditionalChargeEmail(requestParameters: SendWaivedAdditionalChargeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendWaivedAdditionalChargeEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sendWeightReportEmailRaw(requestParameters: SendWeightReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['carrierInvoiceId'] == null) {
            throw new runtime.RequiredError(
                'carrierInvoiceId',
                'Required parameter "carrierInvoiceId" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['reportedWeight'] == null) {
            throw new runtime.RequiredError(
                'reportedWeight',
                'Required parameter "reportedWeight" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['supportAgentFirstName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentFirstName',
                'Required parameter "supportAgentFirstName" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['supportAgentLastName'] == null) {
            throw new runtime.RequiredError(
                'supportAgentLastName',
                'Required parameter "supportAgentLastName" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        if (requestParameters['attachmentDocumentIds'] == null) {
            throw new runtime.RequiredError(
                'attachmentDocumentIds',
                'Required parameter "attachmentDocumentIds" was null or undefined when calling sendWeightReportEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['carrierInvoiceId'] != null) {
            queryParameters['carrierInvoiceId'] = requestParameters['carrierInvoiceId'];
        }

        if (requestParameters['reportedWeight'] != null) {
            queryParameters['reportedWeight'] = requestParameters['reportedWeight'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['supportAgentFirstName'] != null) {
            queryParameters['supportAgentFirstName'] = requestParameters['supportAgentFirstName'];
        }

        if (requestParameters['supportAgentLastName'] != null) {
            queryParameters['supportAgentLastName'] = requestParameters['supportAgentLastName'];
        }

        if (requestParameters['attachmentDocumentIds'] != null) {
            queryParameters['attachmentDocumentIds'] = requestParameters['attachmentDocumentIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/support-email/send-weight-report-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendWeightReportEmail(requestParameters: SendWeightReportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendWeightReportEmailRaw(requestParameters, initOverrides);
    }

}

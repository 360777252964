import { Button, Modal, Select } from "antd";
import moment from "moment";
import { ReactNode, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import xmlFormat from "xml-formatter";
import { useShipmentsApi } from "../Apis/Apis";
import { CarrierFilter } from "../Components/CarrierFilter";
import CarrierLogo from "../Components/CarrierLogo";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { ViewHtmlButton } from "../Components/ViewHtmlButton";
import { CarrierRequestAudit } from "../generated-openapi-client/models/CarrierRequestAudit";
import { useCopyToClipboard } from "../Hooks/useCopyToClipboard";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";

interface ViewAuditButtonProps {
  audit: string;
  children: ReactNode;
}

export function ViewAuditButton(props: ViewAuditButtonProps) {
  const { audit } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function isHtml(): boolean {
    if (audit.startsWith("<")) {
      return true;
    }

    return false;
  }

  function formatAudit() {
    try {
      if (audit && audit.startsWith("{")) {
        return JSON.stringify(JSON.parse(audit), null, "\t");
      }
      if (audit && audit.startsWith("<")) {
        return xmlFormat(audit);
      }
    } catch (e) {
      console.error(`Something went wrong ${e}`);
    }
    return audit;
  }

  const formatted = formatAudit();

  const onCopy = useCopyToClipboard(formatted || "");

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="View Audit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        {isModalVisible && (
          <>
            <Button type="link" onClick={onCopy}>
              Copy to clipboard
            </Button>
            {isHtml() && <ViewHtmlButton data={audit} title="View HTML" />}
            <SyntaxHighlighter language="json" style={docco}>
              {formatted}
            </SyntaxHighlighter>
          </>
        )}
      </Modal>
      <Button onClick={showModal} type="primary">
        {props.children}
      </Button>
    </>
  );
}

interface AuditsTableProps {
  audits: CarrierRequestAudit[];
}

export function AuditsTable(props: AuditsTableProps) {
  const columns: DataTableColumn<CarrierRequestAudit>[] = [
    {
      title: "Created At",
      key: "createdAt",
      render: (o) => <CreatedAt timestamp={o.createdAt} />,
      sorter: function (a, b) {
        return moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf();
      },
    },
    {
      title: "Audit Type",
      key: "auditType",
      render: function (o) {
        return <div>{o.auditType}</div>;
      },
    },
    {
      title: "Carrier",
      key: "carrierIdentifier",
      render: function (o) {
        return (
          <CarrierLogo
            carrierIdentifier={o.carrierIdentifier}
            brokeredCarrierIdentifier={undefined}
            width={40}
            height={30}
          />
        );
      },
    },
    {
      title: "Service",
      key: "serviceIdentifier",
      render: function (o) {
        return <div>{o.serviceIdentifier}</div>;
      },
    },
    {
      title: "Subcommand",
      key: "subcommand",
      render: function (o) {
        return <div>{o.subcommand}</div>;
      },
    },
    {
      title: "HTTP Status",
      key: "httpStatus",
      render: function (o) {
        return <div>{o.httpStatus}</div>;
      },
    },
    {
      title: "Actions",
      key: "auditId",
      render: function (audit: CarrierRequestAudit) {
        return (
          <HorizontalStack>
            <ViewAuditButton audit={audit.requestString!!}>
              View Request
            </ViewAuditButton>
            <Spacer width={8} />
            <ViewAuditButton audit={audit.responseString!!}>
              View Response
            </ViewAuditButton>
          </HorizontalStack>
        );
      },
    },
  ];
  return <DataTable columns={columns} data={props.audits} />;
}

interface AuditTypeFilterProps {
  auditType: string | undefined;
  setAuditType: (_: string | undefined) => void;
}

function AuditTypeFilter(props: AuditTypeFilterProps) {
  return (
    <Select
      value={props.auditType}
      style={{ width: 220 }}
      onChange={props.setAuditType}
      placeholder="Filter by audit type"
    >
      <Select.Option value="RunQuote">Run Quote</Select.Option>;
      <Select.Option value="BookShipment">Book Shipment</Select.Option>;
      <Select.Option value="TrackShipment">Track Shipment</Select.Option>;
    </Select>
  );
}

export function CarrierRequestAuditsScreen() {
  const createShipmentsApi = useShipmentsApi();
  const [audits, setAudits] = useState<CarrierRequestAudit[] | undefined>();
  const query = useQuery();
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >(undefined);
  const [auditType, setAuditType] = useState<string | undefined>();

  const shipmentId = query.shipmentId as string;

  useOnce(async function () {
    const shipmentsApi = await createShipmentsApi();
    const response = await shipmentsApi.carrierRequestAuditsForShipment({
      shipmentId,
    });
    setAudits(response);
  });

  if (audits === undefined) {
    return <Loading />;
  } else {
    return (
      <>
        <Page
          title="Carrier Request Audits"
          extra={[
            <AuditTypeFilter
              auditType={auditType}
              setAuditType={setAuditType}
            />,
            <CarrierFilter
              carrierIdentifier={carrierIdentifier}
              setCarrierIdentifier={setCarrierIdentifier}
            />,
          ]}
          tags={[]}
        >
          <PageTitle>Carrier Request Audits</PageTitle>
          <AuditsTable
            audits={audits
              .filter(function (audit) {
                if (carrierIdentifier !== undefined) {
                  return carrierIdentifier === audit.carrierIdentifier;
                } else {
                  return true;
                }
              })
              .filter(function (audit) {
                if (auditType !== undefined) {
                  return auditType === audit.auditType;
                } else {
                  return true;
                }
              })
              .sort(function (a, b) {
                return (
                  moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
                );
              })}
          />
        </Page>
      </>
    );
  }
}

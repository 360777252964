import { PlusCircleTwoTone } from "@ant-design/icons";
import { DatePicker, Form, Menu, Row, Statistic, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useProfitLossApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import CarrierLogo from "../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Dollar } from "../Components/Dollar";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import { Percentage } from "../Components/Percentage";
import Stack from "../Components/Stack";
import { ViewShipmentMenuItem } from "../Components/ViewShipmentButton";
import { calculateMargin } from "../Helpers/calculateMargin";
import Spacer from "../Spacer";
import { ShipmentReport } from "../generated-openapi-client";
import { ProfitLossReport } from "../generated-openapi-client/models/ProfitLossReport";

interface ProfitLossReportProps {
  report: ProfitLossReport;
}

interface ProfitLossTableProps {
  data: ShipmentReport[];
}

function ProfitLossTable(props: ProfitLossTableProps) {
  const columns: DataTableColumn<ShipmentReport>[] = [
    {
      title: "Company",
      render: (o) => <div>{o.companyName}</div>,
    },
    {
      title: "Lead Source",
      render: (o) => <div>{o.companyLeadSource}</div>,
    },
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={o.bookedQuote?.brokeredCarrierIdentifier}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Money In",
      render: (o) => (
        <HorizontalStack>
          <Dollar>{o.moneyInCad!!}</Dollar>
          {o.numberCustomerInvoices!! > 1 && (
            <Tooltip
              overlay={
                <div>
                  Additional charges: Customer paid this over{" "}
                  {o.numberCustomerInvoices} invoices
                </div>
              }
            >
              <PlusCircleTwoTone style={{ marginLeft: "4px" }} />
            </Tooltip>
          )}
        </HorizontalStack>
      ),
    },
    {
      title: "Money Out",
      render: (o) => <Dollar>{o.moneyOutCad!!}</Dollar>,
    },
    {
      title: "Margin",
      render: (o) => (
        <Percentage colored>
          {calculateMargin(o.moneyInCad!!, o.moneyOutCad!!)}
        </Percentage>
      ),
    },
    {
      title: "Profit",
      render: (o) => <Dollar colored>{o.profit!!}</Dollar>,
    },

    {
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow
            extrasMenu={
              <Menu>
                <ViewShipmentMenuItem shipmentId={o.shipmentId!!} />
              </Menu>
            }
          >
            <></>
          </ButtonRow>
        );
      },
    },
  ];

  return <DataTable pagination={false} columns={columns} data={props.data} />;
}

function ProfitLossReportComponent(props: ProfitLossReportProps) {
  const { report } = props;
  return (
    <div style={{ width: "100%" }}>
      <HorizontalStack width="100%" align="spread">
        <Statistic title="Total Shipments" value={report.numberShipments} />

        <Statistic
          title="Total Incoming"
          value={report.totalIncomingDollars}
          prefix="$"
          suffix="CAD"
        />
        <Spacer height={32} />
        <Statistic
          title="Total Outgoing"
          value={report.totalOutgoingDollars}
          prefix="$"
          suffix="CAD"
        />

        <Statistic
          title="Profit"
          value={(
            report.totalIncomingDollars - report.totalOutgoingDollars
          ).toLocaleString()}
          prefix="$"
          suffix="CAD"
        />
      </HorizontalStack>
      <Spacer height={32} />

      <ProfitLossTable data={report.shipmentLines} />
    </div>
  );
}

export function TodaysBookingsScreen() {
  const [loading, setLoading] = useState(false);

  const [report, setReport] = useState<ProfitLossReport | undefined>();
  const createProfitLossApi = useProfitLossApi();

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  async function refresh() {
    setLoading(true);

    const profitLossApi = await createProfitLossApi();
    const response = await profitLossApi.getTodaysBookings({ date });

    setReport(response);
    setLoading(false);
  }

  useEffect(
    function () {
      refresh();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  if (report === undefined || loading) {
    return <Loading />;
  } else {
    return (
      <Page
        title={`Today's Bookings`}
        tags={[]}
        extra={[
          <HorizontalStack align="right" width="100%">
            <ButtonRow>
              <Form.Item label="Date Range">
                <DatePicker
                  style={{ width: "200px" }}
                  // This is tricky because we need it to represent the date in PST
                  // but if you give moment a raw date it assumes that it is GMT
                  value={moment(date)}
                  onChange={async function (
                    newMomentDate: moment.Moment | null
                  ) {
                    if (newMomentDate !== null) {
                      const newDate = newMomentDate.format("YYYY-MM-DD");
                      setDate(newDate);
                    }
                  }}
                />
              </Form.Item>
            </ButtonRow>
          </HorizontalStack>,
        ]}
      >
        <PageTitle>Today's Bookings</PageTitle>
        <Row style={{ width: "100%" }}>
          <Stack align="left" style={{ width: "100%" }}>
            <ProfitLossReportComponent report={report} />
          </Stack>
        </Row>
      </Page>
    );
  }
}

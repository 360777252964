import { DatePicker, Form, Radio } from "antd";
import moment from "moment";
import { useState } from "react";

const { RangePicker } = DatePicker;

interface MonthlyWeeklyRangePickerProps {
  startDate: string;
  endDate: string;
  filterByDateRange: (
    startDate: string | undefined,
    endDate: string | undefined
  ) => void;
}

export function MonthlyWeeklyRangePicker(props: MonthlyWeeklyRangePickerProps) {
  const [weekly, setWeekly] = useState(false);
  const period = weekly ? "week" : "month";
  const sd = moment(props.startDate).startOf(period);
  const ed = moment(props.endDate).endOf(period);

  if (period === null) {
    throw new Error("period is null");
  }

  return (
    <>
      <Form.Item label="Date Range">
        <RangePicker
          value={[sd, ed]}
          picker={period as any}
          onChange={function (e) {
            if (e === null) {
              props.filterByDateRange(undefined, undefined);
            } else {
              props.filterByDateRange(
                e[0] === null
                  ? undefined
                  : e[0].startOf(period).format("YYYY-MM-DD"),
                e[1] === null
                  ? undefined
                  : e[1].endOf(period).format("YYYY-MM-DD")
              );
            }
          }}
        />
      </Form.Item>
      <Form.Item label="Period">
        <Radio.Group
          value={weekly}
          onChange={function (e) {
            setWeekly(e.target.value);
          }}
        >
          <Radio.Button value={false}>Monthly</Radio.Button>
          <Radio.Button value={true}>Weekly</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </>
  );
}

import { Tag } from "antd";
import { CarrierInvoiceState } from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import { describeCarrierInvoiceState } from "../Screens/ViewShipmentScreenComponents/CarrierInvoicesTab";
import Colors from "./Colors";

interface CarrierInvoiceStateTagProps {
  state: CarrierInvoiceState;
}

function color(state: CarrierInvoiceState) {
  switch (state) {
    case CarrierInvoiceState.Approved:
      return Colors.VeryLightBlue;
    case CarrierInvoiceState.Issued:
      return Colors.Gold;
    case CarrierInvoiceState.Voided:
      return Colors.LightGray;
    case CarrierInvoiceState.PaymentPending:
      return Colors.LightGreen;
    case CarrierInvoiceState.PaymentConfirmed:
      return Colors.Green;
    default:
      assertNever(state);
  }
}

function foregroundColor(state: CarrierInvoiceState) {
  switch (state) {
    case CarrierInvoiceState.Approved:
      return "black";
    case CarrierInvoiceState.Issued:
      return "black";
    case CarrierInvoiceState.Voided:
      return "black";
    case CarrierInvoiceState.PaymentPending:
      return "black";
    case CarrierInvoiceState.PaymentConfirmed:
      return "black";
    default:
      assertNever(state);
  }
}

export function CarrierInvoiceStateTag(props: CarrierInvoiceStateTagProps) {
  return (
    <Tag color={color(props.state)}>
      <div style={{ color: foregroundColor(props.state) }}>
        {describeCarrierInvoiceState(props.state)}
      </div>
    </Tag>
  );
}

import moment from "moment";
import { assertNever } from "../../Helpers/assertNever";
import { groupBy } from "../../Helpers/groupBy";
import { mapNotUndefined } from "../../Helpers/mapNotUndefined";
import { sum } from "../../Helpers/sum";
import {
  ListCompaniesRow,
  ShipmentReport,
} from "../../generated-openapi-client";
import { Period } from "../ViewShipmentScreenComponents/RecentCompanySalesReportScreen";
import { ReportType } from "./ReportType";
import {
  PeriodFormats,
  PeriodInfo,
  generateMonthInfo,
  generateQuarterInfo,
  generateYearInfo,
} from "./generatePeriodInfo";

interface RecentCompanySalesReportRow {
  companyName: string;
  companyId: string;
  company: ListCompaniesRow;
  shipments: ShipmentReport[];
}

export interface CellValue {
  revenue: number;
  shipmentsBooked: number;
  quotes: number;
  acceptance: number | undefined;
}

export function valueForCell(
  reportType: ReportType,
  cellValue: CellValue
): number {
  switch (reportType) {
    case ReportType.Revenue:
      return cellValue.revenue;
    case ReportType.ShipmentsBooked:
      return cellValue.shipmentsBooked;
    case ReportType.Quotes:
      return cellValue.quotes;
    case ReportType.Acceptance:
      return cellValue.acceptance ?? 0;
    default:
      assertNever(reportType);
  }
}

export interface CompiledRecentCompanySalesReportRow {
  companyName: string;
  companyId: string;
  company: ListCompaniesRow;
  perMonthValues: CellValue[];
  perQuarterValues: CellValue[];
  perYearValues: CellValue[];
  companyLast3Months: CellValue;
  companyTotal: CellValue;
}

function sumForReportMonthly(
  title: string,
  o: RecentCompanySalesReportRow
): CellValue {
  const filteredShipments = o.shipments.filter(
    (s) => moment(s.quotedAt).format("MMMM YYYY") === title
  );

  return {
    revenue: sumForMoneyIn(filteredShipments),
    shipmentsBooked: sumForShipmentsBooked(filteredShipments),
    quotes: sumForQuotes(filteredShipments),
    acceptance: sumAcceptance(filteredShipments),
  };
}

function sumForReportQuarterly(
  title: string,
  o: RecentCompanySalesReportRow
): CellValue {
  const filteredShipments = o.shipments.filter(
    (s) => moment(s.quotedAt).format("YYYY [Q]Q") === title
  );

  return {
    revenue: sumForMoneyIn(filteredShipments),
    shipmentsBooked: sumForShipmentsBooked(filteredShipments),
    quotes: sumForQuotes(filteredShipments),
    acceptance: sumAcceptance(filteredShipments),
  };
}

function sumForReportYearly(
  title: string,
  o: RecentCompanySalesReportRow
): CellValue {
  const filteredShipments = o.shipments.filter(
    (s) => moment(s.quotedAt).format("YYYY") === title
  );

  return {
    revenue: sumForMoneyIn(filteredShipments),
    shipmentsBooked: sumForShipmentsBooked(filteredShipments),
    quotes: sumForQuotes(filteredShipments),
    acceptance: sumAcceptance(filteredShipments),
  };
}

function buildRow(
  months: PeriodInfo[],
  quarters: PeriodInfo[],
  years: PeriodInfo[],
  row: RecentCompanySalesReportRow
): CompiledRecentCompanySalesReportRow {
  const compiledRow = {
    companyName: row.companyName,
    companyId: row.companyId,
    company: row.company,
    perMonthValues: months.map(function (period) {
      return sumForReportMonthly(period.title, row) ?? 0;
    }),
    perQuarterValues: quarters.map(function (period) {
      return sumForReportQuarterly(period.title, row) ?? 0;
    }),
    perYearValues: years.map(function (period) {
      return sumForReportYearly(period.title, row) ?? 0;
    }),
    companyLast3Months: sumLast3Months(row),
    companyTotal: sumAllTime(row),
  };

  return compiledRow;
}

function sumForMoneyIn(lines: ShipmentReport[]) {
  return sum(lines, (s) => s.moneyInCad!!);
}

function sumForShipmentsBooked(lines: ShipmentReport[]) {
  return sum(lines, (s) => (s.booked ? 1 : 0));
}

function sumForQuotes(lines: ShipmentReport[]) {
  const groupedFilteredShipments = groupBy(lines, (o) => o.shipmentHash ?? "-");
  const count = sum(groupedFilteredShipments, () => 1);
  return count;
}

function sumAcceptance(filteredShipments: ShipmentReport[]) {
  const numQuotes = sumForQuotes(filteredShipments);
  if (numQuotes === 0) {
    return undefined;
  }
  return (sumForShipmentsBooked(filteredShipments) / numQuotes) * 100;
}

function totalForReport(
  ungroupedLines: ShipmentReport[],
  periodTitle: string,
  periodFormat: string
): CellValue {
  const filteredShipments = ungroupedLines.filter(
    (s) => moment(s.quotedAt).format(periodFormat) === periodTitle
  );

  return {
    revenue: sumForMoneyIn(filteredShipments),
    shipmentsBooked: sumForShipmentsBooked(filteredShipments),
    quotes: sumForQuotes(filteredShipments),
    acceptance: sumAcceptance(filteredShipments),
  };
}

function overallTotalMoneyIn(ungroupedLines: ShipmentReport[]) {
  const moneyIn = sumForMoneyIn(ungroupedLines);
  return moneyIn;
}

function filterForLast3Months(
  ungroupedLines: ShipmentReport[]
): ShipmentReport[] {
  return ungroupedLines.filter(
    // The start of the month that is 2 months ago
    // eg if we are mid June, then go back to start of April
    (s) =>
      moment(s.quotedAt).isAfter(
        moment().subtract(2, "months").startOf("month")
      )
  );
}

function overallLast3MonthsMoneyIn(ungroupedLines: ShipmentReport[]) {
  const filteredShipments = filterForLast3Months(ungroupedLines);

  const moneyIn = sumForMoneyIn(filteredShipments);
  return moneyIn;
}

function overallLast3MonthsShipmentsBooked(ungroupedLines: ShipmentReport[]) {
  const filteredShipments = filterForLast3Months(ungroupedLines);

  const moneyIn = sumForShipmentsBooked(filteredShipments);
  return moneyIn;
}

function overallTotalShipmentsBooked(ungroupedLines: ShipmentReport[]) {
  const count = sumForShipmentsBooked(ungroupedLines);
  return count;
}

function overallLast3MonthsQuotes(ungroupedLines: ShipmentReport[]) {
  const filteredShipments = filterForLast3Months(ungroupedLines);
  return sumForQuotes(filteredShipments);
}

function overallTotalQuotes(ungroupedLines: ShipmentReport[]) {
  return sumForQuotes(ungroupedLines);
}

function overallTotalAcceptance(ungroupedLines: ShipmentReport[]) {
  const numQuotes = overallTotalQuotes(ungroupedLines);
  if (numQuotes === 0) {
    return undefined;
  }
  return (overallTotalShipmentsBooked(ungroupedLines) / numQuotes) * 100;
}

function overallLast3MonthsAcceptance(ungroupedLines: ShipmentReport[]) {
  const filteredShipments = filterForLast3Months(ungroupedLines);
  const numQuotes = overallTotalQuotes(filteredShipments);
  if (numQuotes === 0) {
    return undefined;
  }
  return (overallTotalShipmentsBooked(filteredShipments) / numQuotes) * 100;
}

function generateOverallTotalForReport(
  ungroupedLines: ShipmentReport[]
): CellValue {
  return {
    revenue: overallTotalMoneyIn(ungroupedLines),
    shipmentsBooked: overallTotalShipmentsBooked(ungroupedLines),
    quotes: overallTotalQuotes(ungroupedLines),
    acceptance: overallTotalAcceptance(ungroupedLines) ?? 0,
  };
}

function generateOverallLast3MonthsForReport(
  ungroupedLines: ShipmentReport[]
): CellValue {
  return {
    revenue: overallLast3MonthsMoneyIn(ungroupedLines),
    shipmentsBooked: overallLast3MonthsShipmentsBooked(ungroupedLines),
    quotes: overallLast3MonthsQuotes(ungroupedLines),
    acceptance: overallLast3MonthsAcceptance(ungroupedLines) ?? 0,
  };
}

function sumAllTime(o: RecentCompanySalesReportRow): CellValue {
  function acceptance() {
    const numQuotes = sumForQuotes(o.shipments);
    if (numQuotes === 0) {
      return 0;
    }
    return (sumForShipmentsBooked(o.shipments) / numQuotes) * 100;
  }

  return {
    revenue: sumForMoneyIn(o.shipments),
    shipmentsBooked: sumForShipmentsBooked(o.shipments),
    quotes: sumForQuotes(o.shipments),
    acceptance: acceptance(),
  };
}

function sumLast3Months(o: RecentCompanySalesReportRow): CellValue {
  const filteredShipments = filterForLast3Months(o.shipments);
  function acceptance() {
    const numQuotes = sumForQuotes(filteredShipments);
    if (numQuotes === 0) {
      return 0;
    }
    return (sumForShipmentsBooked(filteredShipments) / numQuotes) * 100;
  }

  return {
    revenue: sumForMoneyIn(filteredShipments),
    shipmentsBooked: sumForShipmentsBooked(filteredShipments),
    quotes: sumForQuotes(filteredShipments),
    acceptance: acceptance(),
  };
}

export function generateCompiledData(
  report: ShipmentReport[],
  companyIdFilter: string | undefined,
  leadSourceFilter: string | undefined,
  shipmentFrequencyFilter: string | undefined,
  onlyShowLost: boolean,
  periodSelected: Period,
  companyCreationFilter: string | undefined,
  companies: ListCompaniesRow[]
) {
  const months = generateMonthInfo();
  const quarters = generateQuarterInfo();
  const years = generateYearInfo();

  function applyFilter(lines: ShipmentReport[]): ShipmentReport[] {
    if (companyIdFilter === undefined || companyIdFilter === "") {
      return lines;
    }
    return lines.filter((o) => o.companyId === companyIdFilter);
  }

  function lookupCompany(companyId: string): ListCompaniesRow {
    return companies.find((c) => c.companyId === companyId)!!;
  }

  function applyLeadSourceFilter(value: {
    key: string;
    value: ShipmentReport[];
  }) {
    if (leadSourceFilter) {
      return lookupCompany(value.key).leadSource === leadSourceFilter;
    } else {
      return true;
    }
  }

  function applyOnlyShowLostFilter(value: {
    key: string;
    value: ShipmentReport[];
  }) {
    if (onlyShowLost) {
      const filteredShipments = filterForLast3Months(value.value);

      const revenue = sumForMoneyIn(filteredShipments);
      return revenue === 0;
    } else {
      return true;
    }
  }

  function applyShipmentFrequencyFilter(value: {
    key: string;
    value: ShipmentReport[];
  }) {
    if (shipmentFrequencyFilter) {
      return (
        lookupCompany(value.key).shipmentFrequency === shipmentFrequencyFilter
      );
    } else {
      return true;
    }
  }

  function applyCompanyCreationFilter(value: {
    key: string;
    value: ShipmentReport[];
  }) {
    if (companyCreationFilter) {
      const company = lookupCompany(value.key);
      if (company === undefined) {
        console.error(
          `#### Delete ShipmentReports with companyId= ${value.key}`
        );
        return false;
      }
      if (periodSelected === Period.Monthly) {
        return (
          moment(company.firstQuoteDate).format(PeriodFormats.Monthly) ===
          companyCreationFilter
        );
      } else if (periodSelected === Period.Quarterly) {
        return (
          moment(company.firstQuoteDate).format(PeriodFormats.Quarterly) ===
          companyCreationFilter
        );
      } else if (periodSelected === Period.Yearly) {
        return (
          moment(company.firstQuoteDate).format(PeriodFormats.Yearly) ===
          companyCreationFilter
        );
      }
    } else {
      return true;
    }
  }

  function applyZeroRevenueFilter(value: {
    key: string;
    value: ShipmentReport[];
  }): boolean {
    return sumForMoneyIn(value.value) !== 0.0;
  }

  const groupedByCompany = groupBy(applyFilter(report), function (o) {
    return o.companyId!!;
  })
    .filter(applyOnlyShowLostFilter)
    .filter(applyLeadSourceFilter)
    .filter(applyShipmentFrequencyFilter)
    .filter(applyCompanyCreationFilter)
    .filter(applyZeroRevenueFilter);

  const ungroupedLines = groupedByCompany.flatMap((o) => o.value);

  const data = mapNotUndefined(groupedByCompany, function (o) {
    const company = lookupCompany(o.key);
    return {
      companyName: o.value[0].companyName!!,
      companyId: o.key,
      company: company,
      shipments: o.value,
    };
  });

  const rows = data.map((row) => buildRow(months, quarters, years, row));

  const monthTotals = months.map(function (m) {
    return totalForReport(ungroupedLines, m.title, PeriodFormats.Monthly);
  });
  const quarterTotals = quarters.map(function (m) {
    return totalForReport(ungroupedLines, m.title, PeriodFormats.Quarterly);
  });
  const yearTotals = years.map(function (m) {
    return totalForReport(ungroupedLines, m.title, PeriodFormats.Yearly);
  });

  const overallTotalForReport = generateOverallTotalForReport(ungroupedLines);
  const overallLast3MonthsForReport =
    generateOverallLast3MonthsForReport(ungroupedLines);

  return {
    rows: rows,
    months,
    quarters,
    years,
    monthTotals,
    quarterTotals,
    yearTotals,
    overallTotalForReport,
    overallLast3MonthsForReport,
  };
}

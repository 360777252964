/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApolloMapLocationFilterType } from './ApolloMapLocationFilterType';
import {
    ApolloMapLocationFilterTypeFromJSON,
    ApolloMapLocationFilterTypeFromJSONTyped,
    ApolloMapLocationFilterTypeToJSON,
} from './ApolloMapLocationFilterType';
import type { ApolloMapQueryBookingStatus } from './ApolloMapQueryBookingStatus';
import {
    ApolloMapQueryBookingStatusFromJSON,
    ApolloMapQueryBookingStatusFromJSONTyped,
    ApolloMapQueryBookingStatusToJSON,
} from './ApolloMapQueryBookingStatus';
import type { GeoFilter } from './GeoFilter';
import {
    GeoFilterFromJSON,
    GeoFilterFromJSONTyped,
    GeoFilterToJSON,
} from './GeoFilter';
import type { QuoteFilterType } from './QuoteFilterType';
import {
    QuoteFilterTypeFromJSON,
    QuoteFilterTypeFromJSONTyped,
    QuoteFilterTypeToJSON,
} from './QuoteFilterType';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface ApolloMapQuery
 */
export interface ApolloMapQuery {
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    freeTextQuery: string;
    /**
     * 
     * @type {ApolloMapQueryBookingStatus}
     * @memberof ApolloMapQuery
     */
    bookingStatus: ApolloMapQueryBookingStatus;
    /**
     * 
     * @type {ShipmentState}
     * @memberof ApolloMapQuery
     */
    shipmentState?: ShipmentState;
    /**
     * 
     * @type {QuoteFilterType}
     * @memberof ApolloMapQuery
     */
    quoteFilter?: QuoteFilterType;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    companyFirstQuotedDateStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    companyFirstQuotedDateEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    companyShipmentFrequency?: string;
    /**
     * 
     * @type {GeoFilter}
     * @memberof ApolloMapQuery
     */
    geoFilter?: GeoFilter;
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ApolloMapQuery
     */
    pickupLocationFilterType?: ApolloMapLocationFilterType;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    pickupCity?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    pickupLocationType?: string;
    /**
     * 
     * @type {ApolloMapLocationFilterType}
     * @memberof ApolloMapQuery
     */
    deliveryLocationFilterType?: ApolloMapLocationFilterType;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    deliveryCity?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    deliveryLocationType?: string;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumShipmentWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumShipmentWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumNumberHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumNumberHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    minimumVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof ApolloMapQuery
     */
    maximumVolume?: number;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    bookedCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    lowestRateCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    hadQuotesFromCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ApolloMapQuery
     */
    inCarrierCoverageAreaCarrierIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    pickupLiftGateRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    deliveryLiftGateRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    pickupInsideRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    deliveryInsideRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    dangerous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    protectFromFreezingRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApolloMapQuery
     */
    deliveryAppointmentRequired?: boolean;
}

/**
 * Check if a given object implements the ApolloMapQuery interface.
 */
export function instanceOfApolloMapQuery(value: object): boolean {
    if (!('freeTextQuery' in value)) return false;
    if (!('bookingStatus' in value)) return false;
    return true;
}

export function ApolloMapQueryFromJSON(json: any): ApolloMapQuery {
    return ApolloMapQueryFromJSONTyped(json, false);
}

export function ApolloMapQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApolloMapQuery {
    if (json == null) {
        return json;
    }
    return {
        
        'freeTextQuery': json['freeTextQuery'],
        'bookingStatus': ApolloMapQueryBookingStatusFromJSON(json['bookingStatus']),
        'shipmentState': json['shipmentState'] == null ? undefined : ShipmentStateFromJSON(json['shipmentState']),
        'quoteFilter': json['quoteFilter'] == null ? undefined : QuoteFilterTypeFromJSON(json['quoteFilter']),
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'companyFirstQuotedDateStartDate': json['companyFirstQuotedDateStartDate'] == null ? undefined : json['companyFirstQuotedDateStartDate'],
        'companyFirstQuotedDateEndDate': json['companyFirstQuotedDateEndDate'] == null ? undefined : json['companyFirstQuotedDateEndDate'],
        'companyShipmentFrequency': json['companyShipmentFrequency'] == null ? undefined : json['companyShipmentFrequency'],
        'geoFilter': json['geoFilter'] == null ? undefined : GeoFilterFromJSON(json['geoFilter']),
        'pickupLocationFilterType': json['pickupLocationFilterType'] == null ? undefined : ApolloMapLocationFilterTypeFromJSON(json['pickupLocationFilterType']),
        'pickupCity': json['pickupCity'] == null ? undefined : json['pickupCity'],
        'pickupLocationType': json['pickupLocationType'] == null ? undefined : json['pickupLocationType'],
        'deliveryLocationFilterType': json['deliveryLocationFilterType'] == null ? undefined : ApolloMapLocationFilterTypeFromJSON(json['deliveryLocationFilterType']),
        'deliveryCity': json['deliveryCity'] == null ? undefined : json['deliveryCity'],
        'deliveryLocationType': json['deliveryLocationType'] == null ? undefined : json['deliveryLocationType'],
        'minimumShipmentWeight': json['minimumShipmentWeight'] == null ? undefined : json['minimumShipmentWeight'],
        'maximumShipmentWeight': json['maximumShipmentWeight'] == null ? undefined : json['maximumShipmentWeight'],
        'minimumNumberHandlingUnits': json['minimumNumberHandlingUnits'] == null ? undefined : json['minimumNumberHandlingUnits'],
        'maximumNumberHandlingUnits': json['maximumNumberHandlingUnits'] == null ? undefined : json['maximumNumberHandlingUnits'],
        'minimumVolume': json['minimumVolume'] == null ? undefined : json['minimumVolume'],
        'maximumVolume': json['maximumVolume'] == null ? undefined : json['maximumVolume'],
        'bookedCarrierIdentifier': json['bookedCarrierIdentifier'] == null ? undefined : json['bookedCarrierIdentifier'],
        'lowestRateCarrierIdentifier': json['lowestRateCarrierIdentifier'] == null ? undefined : json['lowestRateCarrierIdentifier'],
        'hadQuotesFromCarrierIdentifier': json['hadQuotesFromCarrierIdentifier'] == null ? undefined : json['hadQuotesFromCarrierIdentifier'],
        'inCarrierCoverageAreaCarrierIdentifier': json['inCarrierCoverageAreaCarrierIdentifier'] == null ? undefined : json['inCarrierCoverageAreaCarrierIdentifier'],
        'pickupLiftGateRequired': json['pickupLiftGateRequired'] == null ? undefined : json['pickupLiftGateRequired'],
        'deliveryLiftGateRequired': json['deliveryLiftGateRequired'] == null ? undefined : json['deliveryLiftGateRequired'],
        'pickupInsideRequired': json['pickupInsideRequired'] == null ? undefined : json['pickupInsideRequired'],
        'deliveryInsideRequired': json['deliveryInsideRequired'] == null ? undefined : json['deliveryInsideRequired'],
        'dangerous': json['dangerous'] == null ? undefined : json['dangerous'],
        'protectFromFreezingRequired': json['protectFromFreezingRequired'] == null ? undefined : json['protectFromFreezingRequired'],
        'deliveryAppointmentRequired': json['deliveryAppointmentRequired'] == null ? undefined : json['deliveryAppointmentRequired'],
    };
}

export function ApolloMapQueryToJSON(value?: ApolloMapQuery | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'freeTextQuery': value['freeTextQuery'],
        'bookingStatus': ApolloMapQueryBookingStatusToJSON(value['bookingStatus']),
        'shipmentState': ShipmentStateToJSON(value['shipmentState']),
        'quoteFilter': QuoteFilterTypeToJSON(value['quoteFilter']),
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'companyId': value['companyId'],
        'companyFirstQuotedDateStartDate': value['companyFirstQuotedDateStartDate'],
        'companyFirstQuotedDateEndDate': value['companyFirstQuotedDateEndDate'],
        'companyShipmentFrequency': value['companyShipmentFrequency'],
        'geoFilter': GeoFilterToJSON(value['geoFilter']),
        'pickupLocationFilterType': ApolloMapLocationFilterTypeToJSON(value['pickupLocationFilterType']),
        'pickupCity': value['pickupCity'],
        'pickupLocationType': value['pickupLocationType'],
        'deliveryLocationFilterType': ApolloMapLocationFilterTypeToJSON(value['deliveryLocationFilterType']),
        'deliveryCity': value['deliveryCity'],
        'deliveryLocationType': value['deliveryLocationType'],
        'minimumShipmentWeight': value['minimumShipmentWeight'],
        'maximumShipmentWeight': value['maximumShipmentWeight'],
        'minimumNumberHandlingUnits': value['minimumNumberHandlingUnits'],
        'maximumNumberHandlingUnits': value['maximumNumberHandlingUnits'],
        'minimumVolume': value['minimumVolume'],
        'maximumVolume': value['maximumVolume'],
        'bookedCarrierIdentifier': value['bookedCarrierIdentifier'],
        'lowestRateCarrierIdentifier': value['lowestRateCarrierIdentifier'],
        'hadQuotesFromCarrierIdentifier': value['hadQuotesFromCarrierIdentifier'],
        'inCarrierCoverageAreaCarrierIdentifier': value['inCarrierCoverageAreaCarrierIdentifier'],
        'pickupLiftGateRequired': value['pickupLiftGateRequired'],
        'deliveryLiftGateRequired': value['deliveryLiftGateRequired'],
        'pickupInsideRequired': value['pickupInsideRequired'],
        'deliveryInsideRequired': value['deliveryInsideRequired'],
        'dangerous': value['dangerous'],
        'protectFromFreezingRequired': value['protectFromFreezingRequired'],
        'deliveryAppointmentRequired': value['deliveryAppointmentRequired'],
    };
}


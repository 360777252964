import { InfoCircleTwoTone } from "@ant-design/icons";
import { Button, Statistic, Tag, Tooltip } from "antd";
import moment from "moment";
import { ButtonRow } from "../Components/ButtonRow";
import { CarrierLogoSquare } from "../Components/CarrierLogoSquare";
import Colors from "../Components/Colors";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Dollar } from "../Components/Dollar";
import HorizontalStack from "../Components/HorizontalStack";
import { InfoBubble } from "../Components/InfoBubble";
import { Percentage } from "../Components/Percentage";
import Stack from "../Components/Stack";
import { StatsRow } from "../Components/StatsRow";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { Volume } from "../Components/Volume";
import { Weight } from "../Components/Weight";
import {
  QuoteFilterType,
  ShipmentReport,
  ShipmentReportInvoiceWarningState,
  ShipmentReportQuoteSummary,
} from "../generated-openapi-client";
import { LineItem } from "../generated-openapi-client/models/LineItem";
import { assertNever } from "../Helpers/assertNever";
import { calculatePercentageOfTrailer } from "../Helpers/calculatePercentageOfTrailer";
import { describeInvoiceWarningState } from "../Helpers/describeInvoiceWarningState";
import { getMapUrlForViewQuoteScreenDataRow } from "../Helpers/mapUtils";
import { stringToColor } from "../Helpers/stringToColor";
import Spacer from "../Spacer";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

export const QuoteFilterDropdown = OptionalEnumDropdown<
  QuoteFilterType,
  typeof QuoteFilterType
>(
  QuoteFilterType,
  "All Quotes",
  function (et) {
    switch (et) {
      case QuoteFilterType.NoQuotes:
        return "No Quotes";
      case QuoteFilterType.NotManyQuotes:
        return "Not Many Quotes";
      case QuoteFilterType.SuspiciouslyLow:
        return "Suspiciously Low";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeks:
        return "Recent Signups (14 days)";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksDaily:
        return "Recent Signups (14 days) - Daily";
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksWeekly:
        return "Recent Signups (14 days) - Weekly";
      case QuoteFilterType.ShippingRookies:
        return "Shipping Rookies (1-5 shipments)";
      case QuoteFilterType.NeverBooked:
        return "Company Never Booked";
      case QuoteFilterType.NeverBookedDaily:
        return "Company Never Booked - Daily";
      case QuoteFilterType.NeverBookedWeekly:
        return "Company Never Booked - Weekly";

      case QuoteFilterType.IncludesFlaggedServices:
        return "Includes Flagged Services";
      case QuoteFilterType.FlaggedServiceIsInTwoCheapest:
        return "Flagged Service is in two cheapest";

      case QuoteFilterType.LargeShipment:
        return "Large/Volume Shipment";
      default:
        assertNever(et);
    }
  },
  280
);

function MoreDimensionsInfoTable(props: MoreDimensionsInfoProps) {
  const columns: DataTableColumn<LineItem>[] = [
    {
      title: "Handling",
      render: (o) => (
        <div>
          {o.numberHandlingUnits} x {o.handlingUnitType}
        </div>
      ),
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "HU Weight",
      render: (o) => <Weight>{o.weightPerHandlingUnit!!}</Weight>,
    },
    {
      title: "Total Weight",
      render: (o) => (
        <Weight>{o.weightPerHandlingUnit!! * o.numberHandlingUnits!!}</Weight>
      ),
    },
    {
      title: "Dimensions",
      render: (o) => (
        <div>
          {o.length}"x{o.width}"x{o.height}"
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "white" }}>
      <Stack align="left" style={{ backgroundColor: "white" }}>
        <Spacer height={8} />
        <HorizontalStack align="spread" style={{ width: "100%" }}>
          <StatsRow>
            <Statistic
              title="Pickup Date"
              prefix=""
              value={moment(props.shipmentReport.pickupDate).format(
                "MMM Do YYYY"
              )}
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title="Density"
              prefix=""
              value={props.shipmentReport.density!!.toFixed(1)}
              suffix="pcf"
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title={
                <>
                  Std Skid Equivalents{" "}
                  <InfoBubble overlay="Basically how many standard ie. 48x40x48 skids is this equivalent to" />
                </>
              }
              prefix=""
              suffix="skids"
              value={(props.shipmentReport.totalVolume!! / 53.33).toFixed(1)}
              style={{
                margin: "0 32px",
              }}
            />
            {props.shipmentReport.quotes!!.length > 0 && (
              <Statistic
                title={<>Price per cuft per mile</>}
                prefix="$"
                suffix=""
                value={(
                  props.shipmentReport.quotes!![0].priceCad!! /
                  props.shipmentReport.totalVolume!! /
                  props.shipmentReport.distanceMiles!!
                ).toFixed(1)}
                style={{
                  margin: "0 32px",
                }}
              />
            )}
            {props.shipmentReport.insuranceRequested && (
              <Statistic
                title={<>Insurance Requested </>}
                prefix="$"
                suffix={props.shipmentReport.insuranceCurrency}
                value={props.shipmentReport.insuranceAmount!!}
                style={{
                  margin: "0 32px",
                }}
              />
            )}
            {!props.shipmentReport.insuranceRequested && (
              <Statistic
                title={<>Insurance Not Requested</>}
                prefix=""
                suffix=""
                value="-"
                style={{
                  margin: "0 32px",
                }}
              />
            )}
          </StatsRow>
          <ButtonRow>
            <Button
              href={getMapUrlForViewQuoteScreenDataRow(props.shipmentReport)}
              type="ghost"
              target="_new"
            >
              View Map
            </Button>
          </ButtonRow>
        </HorizontalStack>
        <Spacer height={8} />
        <DataTable
          pagination={false}
          columns={columns}
          data={props.shipmentReport.lineItems!!}
        />
      </Stack>
    </div>
  );
}
interface MoreDimensionsInfoProps {
  shipmentReport: ShipmentReport;
}

export function MoreDimensionsInfo(props: MoreDimensionsInfoProps) {
  return (
    <Tooltip
      placement="right"
      overlay={<MoreDimensionsInfoTable {...props} />}
      overlayStyle={{ minWidth: "1000px" }}
    >
      <Button
        type="link"
        icon={<InfoCircleTwoTone twoToneColor={Colors.LightBlue} />}
        disabled={true}
      ></Button>
    </Tooltip>
  );
}

interface QuoteSummaryComponentProps {
  quote: ShipmentReportQuoteSummary;
}

function borderStyle(quote: ShipmentReportQuoteSummary) {
  if (quote.booked) {
    return "1px solid #389e0d";
  } else {
    return "none";
  }
}

function backgroundColor(quote: ShipmentReportQuoteSummary) {
  if (quote.booked) {
    return "#b7eb8f";
  } else {
    return "transparent";
  }
}

function opacity(quote: ShipmentReportQuoteSummary) {
  if (quote.hiddenFromCustomer) {
    return 0.5;
  } else {
    return 1.0;
  }
}

export function QuoteSummaryComponent(props: QuoteSummaryComponentProps) {
  const quote = props.quote;
  return (
    <Stack
      align="center"
      style={{
        width: "90px",
        border: borderStyle(quote),
        backgroundColor: backgroundColor(quote),
        padding: "4px",
        opacity: opacity(quote),
      }}
    >
      <div style={{ border: borderStyle(quote) }}>
        <CarrierLogoSquare
          carrierIdentifier={quote.carrierIdentifier}
          brokeredCarrierIdentifier={quote.brokeredCarrierIdentifier}
          width={64}
          height={64}
        />
      </div>

      <div style={{ fontSize: "9px", maxWidth: "130px", textAlign: "center" }}>
        {quote.serviceIdentifier!!}
      </div>

      <Spacer height={8} />
      <div style={{ fontSize: "12px", fontWeight: "600" }}>
        <Dollar>{quote.priceCad!!}</Dollar>
      </div>
      <Spacer height={2} />
      <div style={{ fontSize: "11px" }}>
        {quote.transitWindowDescription} Days
      </div>
      <Spacer height={4} />
      <div
        style={{
          fontSize: "10px",
          fontWeight: "500",
          maxWidth: "100px",
          textAlign: "center",
        }}
      >
        {quote.serviceDescription}
      </div>
    </Stack>
  );
}

interface AndMoreProps {
  quotes: ShipmentReportQuoteSummary[];
}

export function AndMore(props: AndMoreProps) {
  if (props.quotes.length === 0) {
    return <></>;
  }

  return (
    <Tooltip
      placement="bottom"
      overlay={
        <HorizontalStack spacing={8} style={{ marginLeft: "-8px" }}>
          {props.quotes.map(function (q) {
            return <QuoteSummaryComponent quote={q} />;
          })}
        </HorizontalStack>
      }
      overlayStyle={{ minWidth: `${props.quotes.length * 90}px` }}
    >
      <div>And {props.quotes.length} more</div>
    </Tooltip>
  );
}

interface QuoteRowCompanyColumnProps {
  shipmentReport: ShipmentReport;
}

export function QuoteRowCompanyColumn(props: QuoteRowCompanyColumnProps) {
  const o = props.shipmentReport;
  return (
    <Stack
      align="left"
      style={{
        borderLeft: `8px solid ${stringToColor(o.shipmentHash!!)}`,
        paddingLeft: "16px",
        maxWidth: "500px",
        width: "500px",
      }}
    >
      <div style={{ fontWeight: 500 }}>
        <HorizontalStack>
          {o.companyName}
          <Spacer width={4} />
          <span>{o.booked ? <Tag color="green">Booked</Tag> : ""}</span>
          <span>
            {o.insuranceRequested ? <Tag color="purple">Insurance</Tag> : ""}
          </span>
          <span>
            {o.deletedDescription ? (
              <Tag color="red">Deleted : {o.deletedDescription}</Tag>
            ) : (
              ""
            )}
          </span>
          <span>
            {o.tags?.map((s) => (
              <Tag color="orange">{s}</Tag>
            ))}
            {o.problems?.map((s) => (
              <Tag color="magenta">🚩 {s}</Tag>
            ))}
          </span>
          <span>
            {o.manualQuotingRequested ? (
              <Tag color="blue">🦸‍♀️ Manual Quoting Requested</Tag>
            ) : (
              ""
            )}
          </span>
          <span>
            {o.invoiceWarningState !==
            ShipmentReportInvoiceWarningState.NoWarning ? (
              <Tag color="red">
                {describeInvoiceWarningState(o.invoiceWarningState)}
              </Tag>
            ) : (
              ""
            )}
          </span>
        </HorizontalStack>
      </div>
      <div>
        {o.pickupCityState} to {o.deliveryCityState}{" "}
      </div>
      <Spacer height={8} />
      <HorizontalStack style={{ fontSize: "12px" }}>
        <div>Units:</div>
        <Spacer width={2} />
        <div>{o.totalHandlingUnits!!}</div>
        <Spacer width={8} />
        <div>Weight:</div>
        <Spacer width={2} />
        <Weight>{o.totalWeight!!}</Weight>
        <Spacer width={8} />
        <div>Volume:</div>
        <Spacer width={2} />
        <Volume>{o.totalVolume!!}</Volume>
        <Spacer width={8} />
        <div>Trailer:</div>
        <Percentage>{calculatePercentageOfTrailer(o.lineItems!!)}</Percentage>
        <MoreDimensionsInfo shipmentReport={o} />
      </HorizontalStack>
      <Spacer height={8} />
      <div>{o.lineItems!![0].description}</div>
      <Spacer height={8} />
      <div style={{ fontSize: "12px", color: Colors.LightText }}>
        <CreatedAt timestamp={o.quotedAt} />
      </div>
    </Stack>
  );
}

interface QuoteRowQuotesColumnProps {
  shipmentReport: ShipmentReport;
}

export function QuoteRowQuotesColumn(props: QuoteRowQuotesColumnProps) {
  const o = props.shipmentReport;
  return (
    <HorizontalStack
      spacing={16}
      style={{
        marginLeft: "-16px",
        maxWidth: "800px",
        width: "800px",
      }}
    >
      {o.quotes!!.length === 0 && <div>No quotes</div>}
      {o.quotes!!.slice(0, 6).map(function (q) {
        return <QuoteSummaryComponent quote={q} key={`quote-${q.quoteId}`} />;
      })}
      <AndMore quotes={o.quotes!!.slice(6)} />
    </HorizontalStack>
  );
}

interface QuotesTableProps {
  quotes: ShipmentReport[];
  hideHeaderAndFooter?: boolean;
}

export function QuotesTable(props: QuotesTableProps) {
  const { hideHeaderAndFooter = false } = props;

  const columns: DataTableColumn<ShipmentReport>[] = [
    {
      title: "Company",
      render: (o) => <QuoteRowCompanyColumn shipmentReport={o} />,
      sorter: function (a, b) {
        return a.companyName!!.localeCompare(b.companyName!!);
      },
    },
    {
      title: "Quotes",
      render: (o) => <QuoteRowQuotesColumn shipmentReport={o} />,
    },

    {
      title: "Actions",
      render: function (o) {
        return (
          <ButtonRow>
            <ViewShipmentButton shipmentId={o.shipmentId!!} />
          </ButtonRow>
        );
      },
    },
  ];

  return (
    <DataTable
      pagination={
        !hideHeaderAndFooter
          ? {
              pageSize: 100,
              total: props.quotes.length,
            }
          : false
      }
      showHeader={!hideHeaderAndFooter}
      columns={columns}
      data={props.quotes.sort(function (a, b) {
        return moment(a.quotedAt).valueOf() - moment(b.quotedAt).valueOf();
      })}
    />
  );
}

import { Line, Serie } from "@nivo/line";
import { Form } from "antd";
import { useState } from "react";
import {
  TimeGraphData,
  TimeGraphPeriodData,
} from "../generated-openapi-client";
import { assertNever } from "../Helpers/assertNever";
import {
  Period,
  PeriodDropdown,
} from "../Screens/ViewShipmentScreenComponents/RecentCompanySalesReportScreen";
import { ButtonRow } from "./ButtonRow";
import Stack from "./Stack";
import { CustomSymbol } from "./StatsCalendar";

interface TimeGraphProps {
  data: TimeGraphData;
  prefix?: string;
}

export function TimeGraph(props: TimeGraphProps) {
  const { data } = props;
  const [period, setPeriod] = useState<Period>(Period.Monthly);

  function generateSeries(periodData: TimeGraphPeriodData[]) {
    const data2 = periodData.map(function (pi) {
      return {
        x: pi.firstDate,
        y: pi.value,
      };
    });

    
    const series: Serie[] = [
      {
        id: "test",
        data: data2,
      },
    ];
    return series;
  }

  function getDataForMonthly() {
    const series = generateSeries(data.monthly);

    const xScalePrecision = "month";
    const axisBottom = {
      format: "%b %Y",
      tickValues: "every 1 month",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForQuarterly() {
    const series = generateSeries(data.quarterly);

    const xScalePrecision = "month";
    const axisBottom = {
      format: (value: any) => {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = date.getMonth();
        const quarter = Math.floor(month / 3) + 1;
        return `${year} Q${quarter}`;
      },
      tickValues: "every 3 month",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForYearly() {
    const series = generateSeries(data.yearly);

    const xScalePrecision = "year";
    const axisBottom = {
      format: "%Y",
      tickValues: "every 1 year",
      tickRotation: -45,
      tickSize: 5,
      tickPadding: 10,
      legendOffset: -1,
    };

    return { series, xScalePrecision, axisBottom };
  }

  function getDataForPeriod() {
    switch (period) {
      case Period.Monthly:
        return getDataForMonthly();
      case Period.Quarterly:
        return getDataForQuarterly();
      case Period.Yearly:
        return getDataForYearly();
      default:
        assertNever(period);
    }
  }

  const output = getDataForPeriod();

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Type">
          <PeriodDropdown value={period} setValue={setPeriod} />
        </Form.Item>
      </ButtonRow>
      <Line
        data={output.series}
        // Make sure we have enough height to render all the years of data
        height={350}
        width={1200}
        margin={{ top: 20, right: 20, bottom: 100, left: 80 }}
        animate={true}
        enableSlices={false}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          // @ts-ignore
          precision: output.xScalePrecision,
        }}
        xFormat="time:%Y-%m"
        axisBottom={output.axisBottom}
        useMesh={true}
        enablePointLabel={true}
        pointSymbol={CustomSymbol}
        pointSize={16}
        pointBorderWidth={1}
        pointBorderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
        pointLabel={function (e) {
          return (props.prefix ?? "") + e.y.toLocaleString();
        }}
      />
    </Stack>
  );
}

import { Alert, Form, Statistic, Switch, Tabs } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomerInvoiceApi } from "../Apis/Apis";
import { AdditionalChargeStateTag } from "../Components/AdditionalChargeStateTag";
import { ButtonRow } from "../Components/ButtonRow";
import CarrierLogo from "../Components/CarrierLogo";
import { CustomerInvoiceStateTag } from "../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Date } from "../Components/Date";
import { Dollar } from "../Components/Dollar";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { ViewCompanyButton } from "../Components/ViewCompanyButton";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { groupBy } from "../Helpers/groupBy";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { sum } from "../Helpers/sum";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import { CurrencyCode, CustomerInvoiceData } from "../generated-openapi-client";
import { CompanyAdditionalCharge } from "../generated-openapi-client/models/CompanyAdditionalCharge";
import { CompanyCustomerInvoice } from "../generated-openapi-client/models/CompanyCustomerInvoice";
import { CompanyShipmentQuote } from "../generated-openapi-client/models/CompanyShipmentQuote";

const { TabPane } = Tabs;

interface ShipmentsTableProps {
  shipments: CompanyShipmentQuote[];
}

function ShipmentsTable(props: ShipmentsTableProps) {
  const columns: DataTableColumn<CompanyShipmentQuote>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.quote.carrierIdentifier!!}
          brokeredCarrierIdentifier={o.quote.brokeredCarrierIdentifier}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.shipment.proNumber}</div>,
    },
    {
      title: "Quoted Price",
      render: (o) => <div>${o.quote.quotedPriceByCarrierCad} 'CAD'</div>,
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.shipments} />
  );
}

interface AdditionalChargesTableProps {
  additionalCharges: CompanyAdditionalCharge[];
}

function AdditionalChargesTable(props: AdditionalChargesTableProps) {
  const columns: DataTableColumn<CompanyAdditionalCharge>[] = [
    {
      title: "Company Name",
      render: (o) => <div>{o.company.companyName}</div>,
    },
    {
      title: "State",
      render: (o) => (
        <AdditionalChargeStateTag status={o.additionalCharge.state!!} />
      ),
    },
    {
      title: "Reason",
      render: (o) => <div>{o.additionalCharge.reason}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <Dollar currency={o.additionalCharge.currency}>
          {o.additionalCharge.amount!!}
        </Dollar>
      ),
    },
    {
      title: "Created At",
      render: (o) => (
        <div>{moment(o.additionalCharge.createdAt).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.additionalCharge.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.additionalCharges}
    />
  );
}

interface CustomerInvoicesTableProps {
  customerInvoices: CompanyCustomerInvoice[];
}

function CustomerInvoicesTable(props: CustomerInvoicesTableProps) {
  const [groupByCustomer, setGroupByCustomer] = useState(false);
  return (
    <Stack align="right">
      <Form.Item label="Group by Customer">
        <Switch checked={groupByCustomer} onChange={setGroupByCustomer} />
      </Form.Item>
      {!groupByCustomer && <CustomerInvoicesTableUngrouped {...props} />}
      {groupByCustomer && <CustomerInvoicesTableGroupedByCustomer {...props} />}
    </Stack>
  );
}

interface CustomerInvoicesTableProps {
  customerInvoices: CompanyCustomerInvoice[];
}

function CustomerInvoicesTableUngrouped(props: CustomerInvoicesTableProps) {
  const columns: DataTableColumn<CompanyCustomerInvoice>[] = [
    {
      title: "Company Name",
      render: (o) => <div>{o.company.companyName}</div>,
      sorter: function (a, b) {
        return (b.company.companyName ?? "").localeCompare(
          a.company.companyName ?? ""
        );
      },
    },
    {
      title: "State",
      render: (o) => (
        <CustomerInvoiceStateTag customerInvoice={o.customerInvoice} />
      ),
      sorter: function (a, b) {
        return (b.customerInvoice.customerInvoiceState ?? "").localeCompare(
          a.customerInvoice.customerInvoiceState ?? ""
        );
      },
    },
    {
      title: "Payment Method",
      render: (o) => <div>{o.customerInvoice.paymentMethod}</div>,
      sorter: function (a, b) {
        return (b.customerInvoice.paymentMethod ?? "").localeCompare(
          a.customerInvoice.paymentMethod ?? ""
        );
      },
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.customerInvoice.invoiceIdentifier}</div>,
      sorter: function (a, b) {
        return (b.customerInvoice.invoiceIdentifier ?? "").localeCompare(
          a.customerInvoice.invoiceIdentifier ?? ""
        );
      },
    },
    {
      title: "Amount",
      render: (o) => (
        <Dollar currency={o.customerInvoice.currency}>
          {o.customerInvoice.amount!!}
        </Dollar>
      ),
      sorter: function (a, b) {
        return b.customerInvoice.amount!! - a.customerInvoice.amount!!;
      },
    },
    {
      title: "Created At",
      render: (o) => (
        <div>{moment(o.customerInvoice.createdAt).format("YYYY-MM-DD")}</div>
      ),
      sorter: function (a, b) {
        return (
          moment(a.customerInvoice.createdAt).valueOf() -
          moment(b.customerInvoice.createdAt).valueOf()
        );
      },
    },
    {
      title: "Due Date",
      render: (o) => <Date>{o.customerInvoice.dueDate}</Date>,
      sorter: function (a, b) {
        return (
          moment(a.customerInvoice.dueDate).valueOf() -
          moment(b.customerInvoice.dueDate).valueOf()
        );
      },
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton shipmentId={o.customerInvoice.shipmentId!!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.customerInvoices}
    />
  );
}

function CustomerInvoicesTableGroupedByCustomer(
  props: CustomerInvoicesTableProps
) {
  const groupedInvoices = groupBy(
    props.customerInvoices,
    (o) => o.company.companyId
  );

  const columns: DataTableColumn<{
    key: string | undefined;
    value: CompanyCustomerInvoice[];
  }>[] = [
    {
      title: "Company Name",
      render: (o) => <div>{o.value[0].company.companyName}</div>,
    },

    {
      title: "Amount",
      render: function (o) {
        return (
          // Currency can't vary by customer company
          <Dollar currency={o.value[0].customerInvoice.currency}>
            {sum(o.value, (o2) => o2.customerInvoice.amount!!)}
          </Dollar>
        );
      },
    },

    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewCompanyButton companyId={o.key!!} />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={groupedInvoices} />
  );
}

export function CustomerInvoicesScreen() {
  const [customerInvoices, setCustomerInvoices] = useState<
    CustomerInvoiceData | undefined
  >();
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function refresh() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const response = await customerInvoiceApi.viewCustomerInvoices();
    setCustomerInvoices(response);
  }

  const query = useQuery();
  const navigate = useNavigate();

  const tab = query.tab as string;

  useOnce(refresh);

  function callback(key: string) {
    navigate(`/customer-invoices?tab=${key}`, {
      replace: true,
    });
  }

  const shipmentsWithoutInvoicesBadge =
    customerInvoices?.shipmentsWithoutInvoices.length.toString();

  if (customerInvoices === undefined) {
    return <Loading />;
  }

  const totalShipmentsWithoutInvoices = sum(
    customerInvoices.shipmentsWithoutInvoices,
    function (s) {
      return s.quote.quotedPriceByCarrierCad!!;
    }
  );

  function invoicePriceInCad(i: CompanyCustomerInvoice) {
    if (i.customerInvoice.currency === CurrencyCode.Cad)
      return i.customerInvoice.amount!!;
    // TODO: We don't currency do USD customer invoices
    else return i.customerInvoice.amount!! * 1.32;
  }

  const totalIssuedInvoices = sum(
    customerInvoices.issuedInvoices,
    invoicePriceInCad
  );
  const totalSettlementPendingInvoices = sum(
    customerInvoices.settlementPending,
    invoicePriceInCad
  );

  const totalSettledInvoices = sum(customerInvoices.settled, invoicePriceInCad);

  const overdueInvoices = customerInvoices.issuedInvoices.filter(function (ci) {
    const isInThePast = moment(ci.customerInvoice.dueDate)
      .startOf("day")
      .isBefore(moment().startOf("day"));

    return isInThePast;
  });

  return (
    <>
      <Page
        title={`Customer Invoices`}
        tags={[]}
        stats={
          <HorizontalStack>
            <Statistic
              title="Missing Invoices"
              prefix=""
              value={totalShipmentsWithoutInvoices}
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title="Issued Invoices"
              value={formatAsCurrency(totalIssuedInvoices)}
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title="Settlement Pending Invoices"
              value={formatAsCurrency(totalSettlementPendingInvoices)}
              style={{
                margin: "0 32px",
              }}
            />
            <Statistic
              title="Settled Invoices"
              value={formatAsCurrency(totalSettledInvoices)}
              style={{
                margin: "0 32px",
              }}
            />
          </HorizontalStack>
        }
        extra={[]}
      >
        <PageTitle>{`Customer Invoices`}</PageTitle>
        <Tabs
          defaultActiveKey={tab}
          onChange={callback}
          style={{ width: "100%" }}
        >
          <TabPane
            tab={`Shipments without Invoices  (${shipmentsWithoutInvoicesBadge})`}
            key="shipmentsWithoutInvoices"
          >
            <ShipmentsTable
              shipments={customerInvoices.shipmentsWithoutInvoices}
            />
          </TabPane>
          <TabPane
            tab={`Additional Charges  (${customerInvoices?.proposedAdditionalCharges.length})`}
            key="additionalCharges"
          >
            <AdditionalChargesTable
              additionalCharges={customerInvoices.proposedAdditionalCharges}
            />
          </TabPane>
          <TabPane
            tab={`Issued Invoices  (${customerInvoices?.issuedInvoices.length})`}
            key="issuedInvoices"
          >
            <Alert
              type="info"
              description="An issued invoice is one where we don't know of a current attempt at payment. When a credit card payment fails the invoice is still in the issued state."
            ></Alert>
            <CustomerInvoicesTable
              customerInvoices={customerInvoices.issuedInvoices}
            />
          </TabPane>
          <TabPane
            tab={`Overdue Invoices  (${overdueInvoices.length})`}
            key="overdueInvoices"
          >
            <CustomerInvoicesTable customerInvoices={overdueInvoices} />
          </TabPane>
          <TabPane
            tab={`Settlement Pending Invoices (${customerInvoices?.settlementPending.length})`}
            key="settlementPendingInvoices"
          >
            <Alert
              type="info"
              description="Settled pending invoices are basically we have been informed that the money is on the way. Credit card payments should never end up here since they settle instantly. Direct debits take a few days to settle after being initiated. In the cheque case a customer would be able to mark that they have sent payment, and it would be in this state until we actually received and paid the cheque in."
            ></Alert>
            <CustomerInvoicesTable
              customerInvoices={customerInvoices.settlementPending}
            />
          </TabPane>
          <TabPane
            tab={`Settled Invoices (${customerInvoices?.settled.length})`}
            key="settled"
          >
            <Alert
              type="info"
              description="Settled invoices have been paid. In the credit card case, Stripe
              has the money, but it has not yet been reconciled in Xero. For the
              cheque case it might mean a cheque was received and paid into the
              account"
            ></Alert>
            <CustomerInvoicesTable
              customerInvoices={customerInvoices.settled}
            />
          </TabPane>
        </Tabs>
      </Page>
    </>
  );
}

import { ReactNode } from "react";
import { Note } from "../../../generated-openapi-client";
import { describeEquipmentType } from "../../../Helpers/describeEquipmentType";
import { describeAccessorial } from "../../../Screens/ViewShipmentScreenComponents/LocationInfo";
import { ShipmentModifiedMetadata } from "../../../Types/ShipmentModifiedMetadata";
import { CollapsibleNote } from "../CollapseNote";
import { ModifiedGenericNote } from "../ModifiedGenericNote";
import { NoteAuthorHeadline, NoteHeadline } from "../NoteHeadline";
import { NoteMessage } from "../NoteMessage";
import { ShipmentModifiedLineItemsNote } from "./ShipmentModifiedLineItemsNote";

interface ShipmentModifiedNoteProps {
  note: Note;
}

export function ShipmentModifiedNote({ note }: ShipmentModifiedNoteProps) {
  if (note.beforeMetadata === undefined && note.afterMetadata === undefined) {
    return (
      <>
        <NoteHeadline>{note.headline}</NoteHeadline>
        <NoteMessage>{note.message}</NoteMessage>
      </>
    );
  }

  const beforeMetadata = JSON.parse(
    note.beforeMetadata!
  ) as ShipmentModifiedMetadata;
  const afterMetadata = JSON.parse(
    note.afterMetadata!
  ) as ShipmentModifiedMetadata;

  if (beforeMetadata.type === "UpdatedLineItems") {
    return (
      <ShipmentModifiedLineItemsNote
        note={note}
        beforeData={beforeMetadata.data}
        afterData={afterMetadata.data}
      />
    );
  }

  const beforeData = JSON.parse(beforeMetadata.data);
  const afterData = JSON.parse(afterMetadata.data);

  function render(value: any, key: string): ReactNode {
    if (key.toLocaleLowerCase() === "accessorials" && Array.isArray(value)) {
      if (value.length === 0) {
        return "None";
      }

      return value
        .map(function (accessorial) {
          return describeAccessorial(accessorial);
        })
        .join(", ");
    }

    if (key === "equipmentType") {
      return describeEquipmentType(value);
    }
  }

  return (
    <CollapsibleNote
      collapseActive={false}
      header={
        <NoteAuthorHeadline headline={note.headline} author={note.author} />
      }
    >
      <ModifiedGenericNote
        beforeData={beforeData}
        afterData={afterData}
        customTextRender={render}
      />
    </CollapsibleNote>
  );
}

import { Button, message, Modal, Tag, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import { useShipmentsApi } from "../../Apis/Apis";
import AdaptiveButton from "../../Components/AdaptiveButton";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { IconForNote } from "../../Components/NotesTable";
import Stack from "../../Components/Stack";
import {
  InvoiceAuditTurn,
  Note,
  NoteType,
} from "../../generated-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { daysAgo } from "../../Helpers/daysAgo";
import { isBlank, isNotBlank } from "../../Helpers/isNotBlank";
import { stringToColor } from "../../Helpers/stringToColor";
import Spacer from "../../Spacer";
import { InvoiceAuditContext } from "../OpenInvoiceAuditsScreen";

export const TurnTagInner = styled.div`
  font-size: 16px;
  padding: 4px;
  font-weight: 600;
`;

interface NoteComponentProps {
  note: Note;
}

const NoteEmailComponentContainer = styled.div`
  &:hover {
    filter: brightness(60%);
  }
`;

function NoteEmailComponent(props: NoteComponentProps) {
  function openFront() {
    if (isNotBlank(props.note.link)) {
      window.open(props.note.link, "_blank");
    }
  }

  return (
    <NoteEmailComponentContainer>
      <Stack
        onClick={openFront}
        align="left"
        style={{
          cursor: "pointer",
          marginBottom: "32px",
          width: "100%",
          paddingLeft: "8px",
          borderLeft: `4px solid ${stringToColor(props.note.link ?? "")}`,
        }}
      >
        <strong>
          {moment(props.note.createdAt).format("dddd, MMM Do YYYY, h:mma")}
        </strong>
        <strong>From: {props.note.emailFrom?.emailAddress}</strong>
        <strong>
          To: {props.note.emailTo!!.map((o) => o.emailAddress).join(", ")}
        </strong>
        <strong>Subject: {props.note.emailSubject}</strong>
        <div>{props.note.emailBodyCleanedUp}</div>
      </Stack>
    </NoteEmailComponentContainer>
  );
}

function NoteComponent(props: NoteComponentProps) {
  return (
    <Stack align="left" style={{ marginBottom: "32px" }}>
      <div>By: {props.note.author}</div>
      <div>{props.note.headline}</div>
      <div>{props.note.message}</div>
    </Stack>
  );
}

function sortNotes(a: Note, b: Note) {
  return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
}

interface AuditNotesModalProps {
  notes: Note[];
  turn: InvoiceAuditTurn | undefined;
  turnTime: string | undefined;
  turnContext: InvoiceAuditContext;
  frontConversationId: string | undefined;
  shipmentId: string;
  onRefresh: () => Promise<void>;
}

interface AuditNotesModalTagProps {
  turn: InvoiceAuditTurn | undefined;
  turnTime: string | undefined;
  context: InvoiceAuditContext;
  onClick?: () => void;
}

function AuditNotesModalTag(props: AuditNotesModalTagProps) {
  const turn = props.turn;
  function backgroundColorForTag(): string {
    switch (turn) {
      case InvoiceAuditTurn.OurTurn:
        return "#B0DC50";
      case InvoiceAuditTurn.TheirTurn: {
        switch (props.context) {
          case InvoiceAuditContext.Carrier:
            return "#6645A9";
          case InvoiceAuditContext.Customer:
            return "#74AFCC";
          default:
            assertNever(props.context);
        }
        break;
      }
      case undefined:
        return "#eee";
      default:
        assertNever(turn);
    }
  }

  function colorForTag(): string {
    switch (turn) {
      case InvoiceAuditTurn.OurTurn: {
        switch (props.context) {
          case InvoiceAuditContext.Carrier:
            return "black";
          case InvoiceAuditContext.Customer:
            return "white";
          default:
            assertNever(props.context);
        }
        break;
      }
      case InvoiceAuditTurn.TheirTurn:
        return "white";
      case undefined:
        return "black";
      default:
        assertNever(turn);
    }
  }

  function title() {
    switch (props.context) {
      case InvoiceAuditContext.Carrier:
        return `Carrier`;
      case InvoiceAuditContext.Customer:
        return `Customer`;
      default:
        assertNever(props.context);
    }
  }

  function describeTag(): string {
    switch (turn) {
      case InvoiceAuditTurn.OurTurn:
        return `Our Turn (${daysAgo(props.turnTime)} days ago)`;
      case InvoiceAuditTurn.TheirTurn:
        return `${title()} Turn (${daysAgo(props.turnTime)} days ago)`;
      case undefined:
        return "No Turn";
      default:
        assertNever(turn);
    }
  }
  return (
    <Tag
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
      color={backgroundColorForTag()}
    >
      <TurnTagInner style={{ color: colorForTag() }}>
        {" "}
        {describeTag()}
      </TurnTagInner>
    </Tag>
  );
}

export function AuditNotesModal(props: AuditNotesModalProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const shipmentId = props.shipmentId;
  const [markTheirTurnLoading, setMarkTheirTurnLoading] = useState(false);
  const [markReadLoading, setMarkReadLoading] = useState(false);
  const [markTurnClearLoading, setMarkTurnClearLoading] = useState(false);

  function doCarrierFilter(note: Note) {
    if (note.noteType !== NoteType.Email) {
      return true;
    }

    const emailAddresses = [
      note.emailFrom!!.emailAddress,
      ...note.emailTo!!.map((o) => o.emailAddress),
      ...note.emailCc!!.map((o) => o.emailAddress),
    ];

    return (
      emailAddresses.includes("amy@freightsimple.com") ||
      emailAddresses.includes("carrier-billing@freightsimple.com")
    );
  }

  function doCustomerFilter(note: Note) {
    if (note.noteType !== NoteType.Email) {
      return true;
    }

    const emailAddresses = [
      note.emailFrom!!.emailAddress,
      ...note.emailTo!!.map((o) => o.emailAddress),
      ...note.emailCc!!.map((o) => o.emailAddress),
    ];
    return (
      emailAddresses.includes("support@freightsimple.com") ||
      emailAddresses.includes("billing@freightsimple.com")
    );
  }

  function doFilter(note: Note) {
    switch (props.turnContext) {
      case InvoiceAuditContext.Carrier:
        return doCarrierFilter(note);
      case InvoiceAuditContext.Customer:
        return doCustomerFilter(note);
      default:
        assertNever(props.turnContext);
    }
  }

  const filteredNotes = props.notes.filter(doFilter).sort(sortNotes);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function openFront() {
    window.open(
      `https://app.frontapp.com/open/${props.frontConversationId}`,
      "_blank"
    );
  }

  function notesToShow() {
    if (showMore) {
      return filteredNotes;
    } else {
      return filteredNotes.slice(0, 6);
    }
  }

  function title() {
    switch (props.turnContext) {
      case InvoiceAuditContext.Carrier:
        return `Carrier`;
      case InvoiceAuditContext.Customer:
        return `Customer`;
      default:
        assertNever(props.turnContext);
    }
  }

  async function markTheirTurn() {
    setMarkTheirTurnLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      switch (props.turnContext) {
        case InvoiceAuditContext.Carrier: {
          await shipmentApi.setInvoiceAuditCarrierTurn({
            shipmentId,
            turn: InvoiceAuditTurn.TheirTurn,
            date: moment().format("YYYY-MM-DD"),
          });
          break;
        }
        case InvoiceAuditContext.Customer: {
          await shipmentApi.setInvoiceAuditCustomerTurn({
            shipmentId,
            turn: InvoiceAuditTurn.TheirTurn,
            date: moment().format("YYYY-MM-DD"),
          });
          break;
        }
        default:
          assertNever(props.turnContext);
      }

      await props.onRefresh();
      message.success("Success");
      setIsModalVisible(false);
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setMarkTheirTurnLoading(false);
  }

  async function markRead() {
    setMarkReadLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      switch (props.turnContext) {
        case InvoiceAuditContext.Carrier: {
          await shipmentApi.setInvoiceAuditCarrierTurn({
            shipmentId,
            turn: undefined,
            date: undefined,
          });
          break;
        }
        case InvoiceAuditContext.Customer: {
          await shipmentApi.setInvoiceAuditCustomerTurn({
            shipmentId,
            turn: undefined,
            date: undefined,
          });
          break;
        }
        default:
          assertNever(props.turnContext);
      }

      await props.onRefresh();
      message.success("Success");
      setIsModalVisible(false);
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setMarkReadLoading(false);
  }

  async function markTurnClear() {
    setMarkTurnClearLoading(true);

    try {
      const shipmentApi = await createShipmentApi();
      switch (props.turnContext) {
        case InvoiceAuditContext.Carrier: {
          await shipmentApi.setInvoiceAuditCarrierTurn({
            shipmentId,
            turn: undefined,
            date: undefined,
          });
          break;
        }
        case InvoiceAuditContext.Customer: {
          await shipmentApi.setInvoiceAuditCustomerTurn({
            shipmentId,
            turn: undefined,
            date: undefined,
          });
          break;
        }
        default:
          assertNever(props.turnContext);
      }

      await props.onRefresh();
      message.success("Success");
      setIsModalVisible(false);
    } catch (e) {
      message.error("Oops. Something went wrong");
    }
    setMarkTurnClearLoading(false);
  }

  function showMarkTheirTurn() {
    return !(
      props.turn === InvoiceAuditTurn.TheirTurn &&
      moment(props.turnTime).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    );
  }

  function showMarkRead() {
    return props.turn === InvoiceAuditTurn.OurTurn;
  }

  function showClearTurn() {
    return props.turn === InvoiceAuditTurn.TheirTurn;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={
          <HorizontalStack align="spread" style={{ marginRight: "32px" }}>
            <HorizontalStack>
              <div style={{ marginRight: "8px" }}>{title()}</div>
              <AuditNotesModalTag
                turn={props.turn}
                turnTime={props.turnTime}
                context={props.turnContext}
              />
            </HorizontalStack>
            <ButtonRow>
              {/* Not clear turn and mark turn do logically the same thing - but reads better to have them separate*/}
              {showClearTurn() && (
                <Tooltip title="Use this if we sent something but we're not waiting for a reply. In our minds the conversation is over">
                  <Button
                    loading={markTurnClearLoading}
                    onClick={markTurnClear}
                  >
                    Mark Not Longer Waiting
                  </Button>
                </Tooltip>
              )}
              {showMarkRead() && (
                <Tooltip title="Use this if we received a reply from them but there is no need to reply">
                  <Button loading={markReadLoading} onClick={markRead}>
                    Mark Read
                  </Button>
                </Tooltip>
              )}
              {showMarkTheirTurn() && (
                <Tooltip title="Use this either if we emailed them and waiting for a reply, or if they replied but it was something trivial like forwarding it to the right team and doesn't need an answer">
                  <Button
                    loading={markTheirTurnLoading}
                    onClick={markTheirTurn}
                  >
                    Mark Their Turn
                  </Button>
                </Tooltip>
              )}
              <AdaptiveButton
                style={{
                  backgroundColor: props.frontConversationId
                    ? stringToColor(
                        `https://app.frontapp.com/open/${props.frontConversationId}`
                      )
                    : "gray",
                }}
                disabled={isBlank(props.frontConversationId)}
                onClick={openFront}
              >
                View Front Conversation
              </AdaptiveButton>
            </ButtonRow>
          </HorizontalStack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Stack
          align="left"
          style={{
            backgroundColor: "white",
            color: Colors.NormalText,
            padding: "32px",
            width: "800px",
          }}
        >
          {notesToShow().map(function (note) {
            return (
              <HorizontalStack verticalAlign="top">
                <div style={{ marginTop: "4px", minWidth: "60px" }}>
                  <IconForNote note={note} />
                </div>
                <Spacer width={8} />
                <div>
                  {note.noteType === NoteType.Email && (
                    <NoteEmailComponent note={note} />
                  )}
                  {note.noteType !== NoteType.Email && (
                    <NoteComponent note={note} />
                  )}
                </div>
              </HorizontalStack>
            );
          })}
          {filteredNotes.length > 2 && (
            <Button
              onClick={function () {
                setShowMore(!showMore);
              }}
              type="link"
            >
              {showMore ? "Show Fewer" : "Show More"}
            </Button>
          )}
        </Stack>
      </Modal>
      <AuditNotesModalTag
        onClick={showModal}
        turn={props.turn}
        turnTime={props.turnTime}
        context={props.turnContext}
      />
    </>
  );
}

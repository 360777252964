import { Select } from "antd";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import {
  describeLocationType,
  getLocationTypes,
  nameLocationType,
} from "../../Helpers/locationTypes";

interface LocationTypeDropdownProps {
  value: string | undefined;
  setValue: (_: string) => void;
}

export function LocationTypeDropdown(props: LocationTypeDropdownProps) {
  const filterOption = (input: string, option: any) => {
    const type = option?.value;
    if (!type) return false;

    const name = nameLocationType(type).toLowerCase();
    const description = describeLocationType(type).toLowerCase();
    const searchTerm = input.toLowerCase();

    return name.includes(searchTerm) || description.includes(searchTerm);
  };

  return (
    <Select
      value={props.value}
      style={{ width: 450 }}
      onChange={function (e) {
        props.setValue(e);
      }}
      showSearch
      filterOption={filterOption}
    >
      {getLocationTypes().map(function (type) {
        return (
          <Select.Option key={type} value={type}>
            <Stack align="left">
              <div>{nameLocationType(type)}</div>
              <div style={{ color: Colors.LightText, fontSize: "12px" }}>
                {describeLocationType(type)}
              </div>
            </Stack>
          </Select.Option>
        );
      })}
    </Select>
  );
}

import * as Sentry from "@sentry/react";
import { getEnvironment } from "../App";
import { Environment } from "../Helpers/environment";

if (getEnvironment() === Environment.Production) {
  Sentry.init({
    dsn: "https://28a64b35b5988c31cf5b06feebef78d3@o444510.ingest.us.sentry.io/4508241105059840",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api\.freightsimple\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../../Apis/Apis";
import Stack from "../../../Components/Stack";
import { CompanyNoteType } from "../../../generated-openapi-client";
import { useDisplay } from "../../../Hooks/useDisplay";
import Spacer from "../../../Spacer";

interface AddCompanyNoteButtonProps {
  companyId: string | undefined;
  onRefresh: () => Promise<void>;
}

interface CreateCompanyNoteFormValues {
  notes: string;
  subMessage: string | undefined;
}

export function AddInternalCompanyNoteButton({
  companyId,
  onRefresh,
}: AddCompanyNoteButtonProps) {
  const [form] = Form.useForm<CreateCompanyNoteFormValues>();
  const [loading, setLoading] = useState(false);
  const display = useDisplay();

  const createCompaniesApi = useCompaniesApi();

  async function postCreateCompanyNote({
    notes,
    subMessage,
  }: CreateCompanyNoteFormValues) {
    if (companyId === undefined) {
      console.error("Unexpected company id");
      message.error("Something went wrong!");
      return;
    }

    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      companiesApi.createCompanyNote({
        createCompanyNoteInput: {
          companyId: companyId,
          type: CompanyNoteType.InternalNote,
          subject: "Internal Note",
          body: notes.trim(),
          submessage: subMessage?.trim(),
        },
      });
      await onRefresh();
      display.hide();
      message.success("Internal note successfully created!");
    } catch (e) {
      console.error(e);
      message.error("Something went wrong!");
    }
    setLoading(false);
  }

  function handleOk() {
    form
      .validateFields()
      .then((values) => postCreateCompanyNote(values))
      .catch((e) => console.error(e));
  }

  function handleCancel() {
    display.hide();
  }

  return (
    <>
      <Modal
        title={"Add Internal Note"}
        visible={display.status}
        okText="Save"
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={950}
      >
        <Stack align="left">
          <Spacer height={8} />
        </Stack>

        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20, offset: 1 }}
          layout="horizontal"
          colon={false}
          requiredMark={"optional"}
        >
          <Form.Item
            label="Notes"
            name="notes"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input some notes",
              },
            ]}
          >
            <Input.TextArea
              style={{ width: "600px", height: "180px" }}
              placeholder="Enter note"
            />
          </Form.Item>

          <Form.Item label="Additional information" name="subMessage">
            <Input.TextArea
              style={{ width: "600px", height: "60px" }}
              placeholder="e.g. Temperature changed..."
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={display.show}>Add Internal Note</Button>
    </>
  );
}

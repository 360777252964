import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";
import { TabProps } from "./TabProps";

export function GenerateScenarioButton(props: TabProps) {
  const createShipmentsApi = useShipmentsApi();
  const [scenario, setScenario] = useState<string | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const shipmentId = props.shipmentData.shipmentId;

  async function refresh() {
    const shipmentsApi = await createShipmentsApi();
    const response = await shipmentsApi.generateScenarioCodeForShipment({
      shipmentId,
    });

    setScenario(response.scenario);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        refresh();
      }
    },
    [isModalVisible]
  );

  const onCopy = useCopyToClipboard(scenario || "", {
    doNotDisplayCopiedTextInMessage: true,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Generate Scenario"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <ButtonRow>
          <Button onClick={onCopy}>Copy to clipboard</Button>
        </ButtonRow>
        <Spacer height={16} />
        <pre>{scenario}</pre>
      </Modal>
      <Button onClick={showModal}>Generate Scenario</Button>
    </>
  );
}

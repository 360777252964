import mapboxgl from "mapbox-gl";
import { ApolloMapQuery, ShipmentReport } from "../../generated-openapi-client";
import { MapComponent } from "../../Components/MapComponent";

// https://github.com/alex3165/react-mapbox-gl/issues/931
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// This is our public key, so not a big deal being in the code
// since it's public on the website anyway
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2hyaXN0b3BoZXJzdG90dCIsImEiOiJjazdmZnZ1eDIwMmR0M25wcnMxOWMzNTIzIn0.LBCxe4yd1d0SohHTSvzSmg";
interface AllShipmentsScreenMapTabProps {
  query: ApolloMapQuery;
  shipments: ShipmentReport[];
}

export function AllShipmentsScreenMapTab(props: AllShipmentsScreenMapTabProps) {
  return (
    <div>
      <MapComponent shipments={props.shipments} />
    </div>
  );
}

import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import { useSignalsApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { ConfirmButton } from "../Components/ConfirmButton";
import { CreatedAt } from "../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import HorizontalStack from "../Components/HorizontalStack";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import { WarningComponent } from "../Components/WarningComponent";
import { assertNever } from "../Helpers/assertNever";
import { useOnce } from "../Hooks/useOnce";
import { Signals } from "../generated-openapi-client";
import { OptionalEnumDropdown } from "./ViewShipmentScreenComponents/EnumDropdown";

export enum SignalFilter {
  NotFiredInLastMonth = "NotFiredInLastMonth",
}

export const NoteTypeDropdown = OptionalEnumDropdown<
  SignalFilter,
  typeof SignalFilter
>(SignalFilter, "Do not Filter", function (et) {
  switch (et) {
    case SignalFilter.NotFiredInLastMonth:
      return "Not Fired In Last Month";
    default:
      assertNever(et);
  }
});

interface DeleteSignalButtonProps {
  signal: Signals;
  onRefresh: () => Promise<void>;
}

function DeleteSignalButton(props: DeleteSignalButtonProps) {
  const createSignalsApi = useSignalsApi();
  const [loading, setLoading] = useState(false);

  async function onConfirm() {
    setLoading(true);
    try {
      const signalsApi = await createSignalsApi();

      await signalsApi.deleteSignal({
        signalName: props.signal.name!!,
      });
      await props.onRefresh();
      message.success("Done!");
    } catch (e) {
      message.error("Eek. Something went wrong");
    }
    setLoading(false);
  }

  return (
    <ConfirmButton
      type="default"
      loading={loading}
      tooltip="Use this if the signal is no longer valid. eg we stopped working with a carrier"
      okText="Yes, do it."
      onConfirm={onConfirm}
      question="Are you sure you want to delete this signal"
    >
      <WarningComponent /> Delete Signal
    </ConfirmButton>
  );
}

export function SignalsScreen() {
  const createSignalsApi = useSignalsApi();
  const [signals, setSignals] = useState<Signals[] | undefined>();
  const [noteTypeFilter, setNoteTypeFilter] = useState<
    SignalFilter | undefined
  >();

  async function refresh() {
    const signalsApi = await createSignalsApi();
    const response = await signalsApi.getSignals();
    setSignals(response);
  }

  useOnce(refresh);

  if (signals === undefined) {
    return <Loading />;
  }

  const filteredSignals = signals.filter(function (s) {
    if (noteTypeFilter === undefined) {
      return true;
    }

    switch (noteTypeFilter) {
      case SignalFilter.NotFiredInLastMonth:
        return (
          s.lastSuccessTimestamp === undefined ||
          moment(s.lastSuccessTimestamp).isBefore(moment().subtract(1, "month"))
        );
      default: {
        assertNever(noteTypeFilter);
      }
    }

    // Should not be here
    return false;
  });

  const columns: DataTableColumn<Signals>[] = [
    { title: "Name", render: (o) => <div>{o.name}</div> },
    {
      title: "Last Success",
      render: (o) => <CreatedAt timestamp={o.lastSuccessTimestamp!!} />,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <DeleteSignalButton signal={o} onRefresh={refresh} />
        </ButtonRow>
      ),
    },
  ];
  // last successf
  // error threshold
  //alarm silenced until
  //
  return (
    <Page
      title={`Signals`}
      tags={[]}
      extra={[
        <HorizontalStack>
          <NoteTypeDropdown
            value={noteTypeFilter}
            setValue={setNoteTypeFilter}
          />
        </HorizontalStack>,
      ]}
    >
      <DataTable pagination={false} columns={columns} data={filteredSignals} />
    </Page>
  );
}

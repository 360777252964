/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddQuoteBody,
  CarrierRequestAudit,
  CarrierServiceType,
  EmailDescription,
  EquipmentType,
  GetYrcTransitTimeResponse,
  LookUpPostalCodeResponse,
  Quote,
  QuoteContactsData,
  QuoteLog,
  RequestAdditionalQuotesBody,
  SelectQuoteInput,
  SelectQuotePlan,
  SelectQuoteResult,
  ShipmentReport,
} from '../models/index';
import {
    AddQuoteBodyFromJSON,
    AddQuoteBodyToJSON,
    CarrierRequestAuditFromJSON,
    CarrierRequestAuditToJSON,
    CarrierServiceTypeFromJSON,
    CarrierServiceTypeToJSON,
    EmailDescriptionFromJSON,
    EmailDescriptionToJSON,
    EquipmentTypeFromJSON,
    EquipmentTypeToJSON,
    GetYrcTransitTimeResponseFromJSON,
    GetYrcTransitTimeResponseToJSON,
    LookUpPostalCodeResponseFromJSON,
    LookUpPostalCodeResponseToJSON,
    QuoteFromJSON,
    QuoteToJSON,
    QuoteContactsDataFromJSON,
    QuoteContactsDataToJSON,
    QuoteLogFromJSON,
    QuoteLogToJSON,
    RequestAdditionalQuotesBodyFromJSON,
    RequestAdditionalQuotesBodyToJSON,
    SelectQuoteInputFromJSON,
    SelectQuoteInputToJSON,
    SelectQuotePlanFromJSON,
    SelectQuotePlanToJSON,
    SelectQuoteResultFromJSON,
    SelectQuoteResultToJSON,
    ShipmentReportFromJSON,
    ShipmentReportToJSON,
} from '../models/index';

export interface AddQuoteRequest {
    addQuoteBody: AddQuoteBody;
}

export interface AdjustQuotePriceRequest {
    quoteId: string;
    amountCad: number;
}

export interface AdjustTransitDaysRequest {
    quoteId: string;
    earliestTransitDays: number;
    latestTransitDays: number;
}

export interface CarrierRequestAuditsForQuoteRequest {
    shipmentId: string;
    quoteId: string;
}

export interface DeleteQuoteRequest {
    quoteId: string;
}

export interface DeleteQuoteGroupRequest {
    shipmentId: string;
    quoteGroupName: string;
}

export interface FetchCarriersThatProvideEmailQuotesRequest {
    shipmentId: string;
    ignoreDimensionChecks: boolean;
}

export interface GetQuoteByIdRequest {
    quoteId: string;
}

export interface GetQuoteContactsDataRequest {
    quoteId: string;
}

export interface GetQuoteLogsForCarrierRequest {
    carrierIdentifier: string;
    serviceIdentifier: string;
}

export interface GetQuoteLogsForShipmentRequest {
    shipmentId: string;
}

export interface GetTruckloadTransitTimeRequest {
    shipmentId: string;
}

export interface GetYrcTransitTimeRequest {
    shipmentId: string;
}

export interface LookupPostalCode1Request {
    postalCode: string;
}

export interface MarkEmailQuoteRequestNoServiceRequest {
    emailQuoteRequestId: string;
}

export interface MarkEmailQuoteRequestPotentialRequest {
    emailQuoteRequestId: string;
}

export interface MarkEmailQuoteRequestTooHighRequest {
    emailQuoteRequestId: string;
}

export interface MarkEmailQuoteResponsesReadRequest {
    emailQuoteRequestId: string;
}

export interface MarkQuoteCustomerHiddenRequest {
    quoteId: string;
}

export interface MarkQuoteCustomerVisibleRequest {
    quoteId: string;
}

export interface MarkQuoteGroupCustomerHiddenRequest {
    shipmentId: string;
    quoteGroupName: string;
}

export interface MarkQuoteGroupCustomerVisibleRequest {
    shipmentId: string;
    quoteGroupName: string;
}

export interface MarkShipmentManualQuotingCompleteRequest {
    shipmentId: string;
}

export interface MarkShipmentManualQuotingOpenRequest {
    shipmentId: string;
}

export interface OpenShipmentForManualQuotesRequest {
    shipmentId: string;
    equipmentType: EquipmentType;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    notes: string;
    linearFeetOverride?: number;
}

export interface PreviewRequestQuoteEmailRequest {
    shipmentId: string;
    extraText: string;
    carrierIdentifier: string;
    serviceIdentifier: string;
    overrideCarriers: boolean;
    overrideEmailAddress: string;
}

export interface PreviewSelectQuoteRequest {
    quoteId: string;
    selectQuoteInput: SelectQuoteInput;
}

export interface RequestAdditionalQuotesRequest {
    requestAdditionalQuotesBody: RequestAdditionalQuotesBody;
}

export interface RequestQuoteViaEmailRequest {
    shipmentId: string;
    extraText: string;
    carrierIdentifier: string;
    serviceIdentifier: string;
    overrideCarriers: boolean;
    overrideEmailAddress: string;
}

export interface SelectQuoteRequest {
    quoteId: string;
    selectQuoteInput: SelectQuoteInput;
}

export interface SelectQuoteBeforeBookingRequest {
    quoteId: string;
}

export interface ViewQuotesScreenDataRequest {
    startDate: string;
    endDate: string;
}

/**
 * 
 */
export class QuotesApi extends runtime.BaseAPI {

    /**
     */
    async addQuoteRaw(requestParameters: AddQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['addQuoteBody'] == null) {
            throw new runtime.RequiredError(
                'addQuoteBody',
                'Required parameter "addQuoteBody" was null or undefined when calling addQuote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/quotes/add-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddQuoteBodyToJSON(requestParameters['addQuoteBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addQuote(requestParameters: AddQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addQuoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async adjustQuotePriceRaw(requestParameters: AdjustQuotePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling adjustQuotePrice().'
            );
        }

        if (requestParameters['amountCad'] == null) {
            throw new runtime.RequiredError(
                'amountCad',
                'Required parameter "amountCad" was null or undefined when calling adjustQuotePrice().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['amountCad'] != null) {
            queryParameters['amountCad'] = requestParameters['amountCad'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/adjust-quote-price`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async adjustQuotePrice(requestParameters: AdjustQuotePriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.adjustQuotePriceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async adjustTransitDaysRaw(requestParameters: AdjustTransitDaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling adjustTransitDays().'
            );
        }

        if (requestParameters['earliestTransitDays'] == null) {
            throw new runtime.RequiredError(
                'earliestTransitDays',
                'Required parameter "earliestTransitDays" was null or undefined when calling adjustTransitDays().'
            );
        }

        if (requestParameters['latestTransitDays'] == null) {
            throw new runtime.RequiredError(
                'latestTransitDays',
                'Required parameter "latestTransitDays" was null or undefined when calling adjustTransitDays().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        if (requestParameters['earliestTransitDays'] != null) {
            queryParameters['earliestTransitDays'] = requestParameters['earliestTransitDays'];
        }

        if (requestParameters['latestTransitDays'] != null) {
            queryParameters['latestTransitDays'] = requestParameters['latestTransitDays'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/adjust-transit-days`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async adjustTransitDays(requestParameters: AdjustTransitDaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.adjustTransitDaysRaw(requestParameters, initOverrides);
    }

    /**
     */
    async carrierRequestAuditsForQuoteRaw(requestParameters: CarrierRequestAuditsForQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierRequestAudit>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling carrierRequestAuditsForQuote().'
            );
        }

        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling carrierRequestAuditsForQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/carrier-request-audits-for-quote`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierRequestAuditFromJSON));
    }

    /**
     */
    async carrierRequestAuditsForQuote(requestParameters: CarrierRequestAuditsForQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierRequestAudit>> {
        const response = await this.carrierRequestAuditsForQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteQuoteRaw(requestParameters: DeleteQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling deleteQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/delete-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteQuote(requestParameters: DeleteQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteQuoteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteQuoteGroupRaw(requestParameters: DeleteQuoteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling deleteQuoteGroup().'
            );
        }

        if (requestParameters['quoteGroupName'] == null) {
            throw new runtime.RequiredError(
                'quoteGroupName',
                'Required parameter "quoteGroupName" was null or undefined when calling deleteQuoteGroup().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteGroupName'] != null) {
            queryParameters['quoteGroupName'] = requestParameters['quoteGroupName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/delete-quote-group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteQuoteGroup(requestParameters: DeleteQuoteGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteQuoteGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fetchCarriersThatProvideEmailQuotesRaw(requestParameters: FetchCarriersThatProvideEmailQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierServiceType>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling fetchCarriersThatProvideEmailQuotes().'
            );
        }

        if (requestParameters['ignoreDimensionChecks'] == null) {
            throw new runtime.RequiredError(
                'ignoreDimensionChecks',
                'Required parameter "ignoreDimensionChecks" was null or undefined when calling fetchCarriersThatProvideEmailQuotes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['ignoreDimensionChecks'] != null) {
            queryParameters['ignoreDimensionChecks'] = requestParameters['ignoreDimensionChecks'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/fetch-carriers-that-provide-email-quotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierServiceTypeFromJSON));
    }

    /**
     */
    async fetchCarriersThatProvideEmailQuotes(requestParameters: FetchCarriersThatProvideEmailQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierServiceType>> {
        const response = await this.fetchCarriersThatProvideEmailQuotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuoteByIdRaw(requestParameters: GetQuoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Quote>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling getQuoteById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-quote-by-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuoteFromJSON(jsonValue));
    }

    /**
     */
    async getQuoteById(requestParameters: GetQuoteByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Quote> {
        const response = await this.getQuoteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuoteContactsDataRaw(requestParameters: GetQuoteContactsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QuoteContactsData>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling getQuoteContactsData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-quote-contacts-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuoteContactsDataFromJSON(jsonValue));
    }

    /**
     */
    async getQuoteContactsData(requestParameters: GetQuoteContactsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QuoteContactsData> {
        const response = await this.getQuoteContactsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuoteLogsForCarrierRaw(requestParameters: GetQuoteLogsForCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<QuoteLog>>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling getQuoteLogsForCarrier().'
            );
        }

        if (requestParameters['serviceIdentifier'] == null) {
            throw new runtime.RequiredError(
                'serviceIdentifier',
                'Required parameter "serviceIdentifier" was null or undefined when calling getQuoteLogsForCarrier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['serviceIdentifier'] != null) {
            queryParameters['serviceIdentifier'] = requestParameters['serviceIdentifier'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-quote-logs-for-carrier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuoteLogFromJSON));
    }

    /**
     */
    async getQuoteLogsForCarrier(requestParameters: GetQuoteLogsForCarrierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<QuoteLog>> {
        const response = await this.getQuoteLogsForCarrierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getQuoteLogsForShipmentRaw(requestParameters: GetQuoteLogsForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<QuoteLog>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getQuoteLogsForShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-quote-logs-for-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuoteLogFromJSON));
    }

    /**
     */
    async getQuoteLogsForShipment(requestParameters: GetQuoteLogsForShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<QuoteLog>> {
        const response = await this.getQuoteLogsForShipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTruckloadTransitTimeRaw(requestParameters: GetTruckloadTransitTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetYrcTransitTimeResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getTruckloadTransitTime().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-truckload-transit-time`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetYrcTransitTimeResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTruckloadTransitTime(requestParameters: GetTruckloadTransitTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetYrcTransitTimeResponse> {
        const response = await this.getTruckloadTransitTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getYrcTransitTimeRaw(requestParameters: GetYrcTransitTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetYrcTransitTimeResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getYrcTransitTime().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/get-yrc-transit-time`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetYrcTransitTimeResponseFromJSON(jsonValue));
    }

    /**
     */
    async getYrcTransitTime(requestParameters: GetYrcTransitTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetYrcTransitTimeResponse> {
        const response = await this.getYrcTransitTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listCarrierAndServiceTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierServiceType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/list-carrier-and-service-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierServiceTypeFromJSON));
    }

    /**
     */
    async listCarrierAndServiceTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierServiceType>> {
        const response = await this.listCarrierAndServiceTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listCarrierAndServiceTypesThatSupportInstantQuotingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CarrierServiceType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/list-carrier-and-service-types-that-support-instant-quoting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CarrierServiceTypeFromJSON));
    }

    /**
     */
    async listCarrierAndServiceTypesThatSupportInstantQuoting(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CarrierServiceType>> {
        const response = await this.listCarrierAndServiceTypesThatSupportInstantQuotingRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async lookupPostalCode1Raw(requestParameters: LookupPostalCode1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookUpPostalCodeResponse>> {
        if (requestParameters['postalCode'] == null) {
            throw new runtime.RequiredError(
                'postalCode',
                'Required parameter "postalCode" was null or undefined when calling lookupPostalCode1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['postalCode'] != null) {
            queryParameters['postalCode'] = requestParameters['postalCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/lookup-postal-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookUpPostalCodeResponseFromJSON(jsonValue));
    }

    /**
     */
    async lookupPostalCode1(requestParameters: LookupPostalCode1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookUpPostalCodeResponse> {
        const response = await this.lookupPostalCode1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markEmailQuoteRequestNoServiceRaw(requestParameters: MarkEmailQuoteRequestNoServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailQuoteRequestId'] == null) {
            throw new runtime.RequiredError(
                'emailQuoteRequestId',
                'Required parameter "emailQuoteRequestId" was null or undefined when calling markEmailQuoteRequestNoService().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailQuoteRequestId'] != null) {
            queryParameters['emailQuoteRequestId'] = requestParameters['emailQuoteRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-email-quote-request-no-service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markEmailQuoteRequestNoService(requestParameters: MarkEmailQuoteRequestNoServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markEmailQuoteRequestNoServiceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markEmailQuoteRequestPotentialRaw(requestParameters: MarkEmailQuoteRequestPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailQuoteRequestId'] == null) {
            throw new runtime.RequiredError(
                'emailQuoteRequestId',
                'Required parameter "emailQuoteRequestId" was null or undefined when calling markEmailQuoteRequestPotential().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailQuoteRequestId'] != null) {
            queryParameters['emailQuoteRequestId'] = requestParameters['emailQuoteRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-email-quote-request-potential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markEmailQuoteRequestPotential(requestParameters: MarkEmailQuoteRequestPotentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markEmailQuoteRequestPotentialRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markEmailQuoteRequestTooHighRaw(requestParameters: MarkEmailQuoteRequestTooHighRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailQuoteRequestId'] == null) {
            throw new runtime.RequiredError(
                'emailQuoteRequestId',
                'Required parameter "emailQuoteRequestId" was null or undefined when calling markEmailQuoteRequestTooHigh().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailQuoteRequestId'] != null) {
            queryParameters['emailQuoteRequestId'] = requestParameters['emailQuoteRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-email-quote-request-too-high`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markEmailQuoteRequestTooHigh(requestParameters: MarkEmailQuoteRequestTooHighRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markEmailQuoteRequestTooHighRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markEmailQuoteResponsesReadRaw(requestParameters: MarkEmailQuoteResponsesReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailQuoteRequestId'] == null) {
            throw new runtime.RequiredError(
                'emailQuoteRequestId',
                'Required parameter "emailQuoteRequestId" was null or undefined when calling markEmailQuoteResponsesRead().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailQuoteRequestId'] != null) {
            queryParameters['emailQuoteRequestId'] = requestParameters['emailQuoteRequestId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-email-quote-responses-read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markEmailQuoteResponsesRead(requestParameters: MarkEmailQuoteResponsesReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markEmailQuoteResponsesReadRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuoteCustomerHiddenRaw(requestParameters: MarkQuoteCustomerHiddenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling markQuoteCustomerHidden().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-quote-customer-hidden`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuoteCustomerHidden(requestParameters: MarkQuoteCustomerHiddenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuoteCustomerHiddenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuoteCustomerVisibleRaw(requestParameters: MarkQuoteCustomerVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling markQuoteCustomerVisible().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-quote-customer-visible`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuoteCustomerVisible(requestParameters: MarkQuoteCustomerVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuoteCustomerVisibleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuoteGroupCustomerHiddenRaw(requestParameters: MarkQuoteGroupCustomerHiddenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuoteGroupCustomerHidden().'
            );
        }

        if (requestParameters['quoteGroupName'] == null) {
            throw new runtime.RequiredError(
                'quoteGroupName',
                'Required parameter "quoteGroupName" was null or undefined when calling markQuoteGroupCustomerHidden().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteGroupName'] != null) {
            queryParameters['quoteGroupName'] = requestParameters['quoteGroupName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-quote-group-customer-hidden`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuoteGroupCustomerHidden(requestParameters: MarkQuoteGroupCustomerHiddenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuoteGroupCustomerHiddenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markQuoteGroupCustomerVisibleRaw(requestParameters: MarkQuoteGroupCustomerVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markQuoteGroupCustomerVisible().'
            );
        }

        if (requestParameters['quoteGroupName'] == null) {
            throw new runtime.RequiredError(
                'quoteGroupName',
                'Required parameter "quoteGroupName" was null or undefined when calling markQuoteGroupCustomerVisible().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['quoteGroupName'] != null) {
            queryParameters['quoteGroupName'] = requestParameters['quoteGroupName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-quote-group-customer-visible`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markQuoteGroupCustomerVisible(requestParameters: MarkQuoteGroupCustomerVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markQuoteGroupCustomerVisibleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markShipmentManualQuotingCompleteRaw(requestParameters: MarkShipmentManualQuotingCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markShipmentManualQuotingComplete().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-shipment-manual-quoting-complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markShipmentManualQuotingComplete(requestParameters: MarkShipmentManualQuotingCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markShipmentManualQuotingCompleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async markShipmentManualQuotingOpenRaw(requestParameters: MarkShipmentManualQuotingOpenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling markShipmentManualQuotingOpen().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/mark-shipment-manual-quoting-open`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markShipmentManualQuotingOpen(requestParameters: MarkShipmentManualQuotingOpenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markShipmentManualQuotingOpenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async openShipmentForManualQuotesRaw(requestParameters: OpenShipmentForManualQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling openShipmentForManualQuotes().'
            );
        }

        if (requestParameters['equipmentType'] == null) {
            throw new runtime.RequiredError(
                'equipmentType',
                'Required parameter "equipmentType" was null or undefined when calling openShipmentForManualQuotes().'
            );
        }

        if (requestParameters['exclusiveUse'] == null) {
            throw new runtime.RequiredError(
                'exclusiveUse',
                'Required parameter "exclusiveUse" was null or undefined when calling openShipmentForManualQuotes().'
            );
        }

        if (requestParameters['tarpRequired'] == null) {
            throw new runtime.RequiredError(
                'tarpRequired',
                'Required parameter "tarpRequired" was null or undefined when calling openShipmentForManualQuotes().'
            );
        }

        if (requestParameters['notes'] == null) {
            throw new runtime.RequiredError(
                'notes',
                'Required parameter "notes" was null or undefined when calling openShipmentForManualQuotes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['equipmentType'] != null) {
            queryParameters['equipmentType'] = requestParameters['equipmentType'];
        }

        if (requestParameters['exclusiveUse'] != null) {
            queryParameters['exclusiveUse'] = requestParameters['exclusiveUse'];
        }

        if (requestParameters['tarpRequired'] != null) {
            queryParameters['tarpRequired'] = requestParameters['tarpRequired'];
        }

        if (requestParameters['linearFeetOverride'] != null) {
            queryParameters['linearFeetOverride'] = requestParameters['linearFeetOverride'];
        }

        if (requestParameters['notes'] != null) {
            queryParameters['notes'] = requestParameters['notes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/open-shipment-for-manual-quotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async openShipmentForManualQuotes(requestParameters: OpenShipmentForManualQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.openShipmentForManualQuotesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async previewRequestQuoteEmailRaw(requestParameters: PreviewRequestQuoteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDescription>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        if (requestParameters['serviceIdentifier'] == null) {
            throw new runtime.RequiredError(
                'serviceIdentifier',
                'Required parameter "serviceIdentifier" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        if (requestParameters['overrideCarriers'] == null) {
            throw new runtime.RequiredError(
                'overrideCarriers',
                'Required parameter "overrideCarriers" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        if (requestParameters['overrideEmailAddress'] == null) {
            throw new runtime.RequiredError(
                'overrideEmailAddress',
                'Required parameter "overrideEmailAddress" was null or undefined when calling previewRequestQuoteEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['serviceIdentifier'] != null) {
            queryParameters['serviceIdentifier'] = requestParameters['serviceIdentifier'];
        }

        if (requestParameters['overrideCarriers'] != null) {
            queryParameters['overrideCarriers'] = requestParameters['overrideCarriers'];
        }

        if (requestParameters['overrideEmailAddress'] != null) {
            queryParameters['overrideEmailAddress'] = requestParameters['overrideEmailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/preview-request-quote-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async previewRequestQuoteEmail(requestParameters: PreviewRequestQuoteEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDescription> {
        const response = await this.previewRequestQuoteEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async previewSelectQuoteRaw(requestParameters: PreviewSelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SelectQuotePlan>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling previewSelectQuote().'
            );
        }

        if (requestParameters['selectQuoteInput'] == null) {
            throw new runtime.RequiredError(
                'selectQuoteInput',
                'Required parameter "selectQuoteInput" was null or undefined when calling previewSelectQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/quotes/preview-select-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectQuoteInputToJSON(requestParameters['selectQuoteInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SelectQuotePlanFromJSON(jsonValue));
    }

    /**
     */
    async previewSelectQuote(requestParameters: PreviewSelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SelectQuotePlan> {
        const response = await this.previewSelectQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requestAdditionalQuotesRaw(requestParameters: RequestAdditionalQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['requestAdditionalQuotesBody'] == null) {
            throw new runtime.RequiredError(
                'requestAdditionalQuotesBody',
                'Required parameter "requestAdditionalQuotesBody" was null or undefined when calling requestAdditionalQuotes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/quotes/request-additional-quotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestAdditionalQuotesBodyToJSON(requestParameters['requestAdditionalQuotesBody']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestAdditionalQuotes(requestParameters: RequestAdditionalQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestAdditionalQuotesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async requestQuoteViaEmailRaw(requestParameters: RequestQuoteViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        if (requestParameters['extraText'] == null) {
            throw new runtime.RequiredError(
                'extraText',
                'Required parameter "extraText" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        if (requestParameters['serviceIdentifier'] == null) {
            throw new runtime.RequiredError(
                'serviceIdentifier',
                'Required parameter "serviceIdentifier" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        if (requestParameters['overrideCarriers'] == null) {
            throw new runtime.RequiredError(
                'overrideCarriers',
                'Required parameter "overrideCarriers" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        if (requestParameters['overrideEmailAddress'] == null) {
            throw new runtime.RequiredError(
                'overrideEmailAddress',
                'Required parameter "overrideEmailAddress" was null or undefined when calling requestQuoteViaEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['extraText'] != null) {
            queryParameters['extraText'] = requestParameters['extraText'];
        }

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['serviceIdentifier'] != null) {
            queryParameters['serviceIdentifier'] = requestParameters['serviceIdentifier'];
        }

        if (requestParameters['overrideCarriers'] != null) {
            queryParameters['overrideCarriers'] = requestParameters['overrideCarriers'];
        }

        if (requestParameters['overrideEmailAddress'] != null) {
            queryParameters['overrideEmailAddress'] = requestParameters['overrideEmailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/request-quote-via-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestQuoteViaEmail(requestParameters: RequestQuoteViaEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestQuoteViaEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async selectQuoteRaw(requestParameters: SelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SelectQuoteResult>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling selectQuote().'
            );
        }

        if (requestParameters['selectQuoteInput'] == null) {
            throw new runtime.RequiredError(
                'selectQuoteInput',
                'Required parameter "selectQuoteInput" was null or undefined when calling selectQuote().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/apollo/quotes/select-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectQuoteInputToJSON(requestParameters['selectQuoteInput']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SelectQuoteResultFromJSON(jsonValue));
    }

    /**
     */
    async selectQuote(requestParameters: SelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SelectQuoteResult> {
        const response = await this.selectQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async selectQuoteBeforeBookingRaw(requestParameters: SelectQuoteBeforeBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['quoteId'] == null) {
            throw new runtime.RequiredError(
                'quoteId',
                'Required parameter "quoteId" was null or undefined when calling selectQuoteBeforeBooking().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['quoteId'] != null) {
            queryParameters['quoteId'] = requestParameters['quoteId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/select-quote-before-booking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async selectQuoteBeforeBooking(requestParameters: SelectQuoteBeforeBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.selectQuoteBeforeBookingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async viewQuotesScreenDataRaw(requestParameters: ViewQuotesScreenDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShipmentReport>>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling viewQuotesScreenData().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling viewQuotesScreenData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/quotes/view-quotes-screen-data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShipmentReportFromJSON));
    }

    /**
     */
    async viewQuotesScreenData(requestParameters: ViewQuotesScreenDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShipmentReport>> {
        const response = await this.viewQuotesScreenDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageResponse
 */
export interface ImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageResponse
     */
    base64Image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImageResponse
     */
    noImage: boolean;
}

/**
 * Check if a given object implements the ImageResponse interface.
 */
export function instanceOfImageResponse(value: object): boolean {
    if (!('noImage' in value)) return false;
    return true;
}

export function ImageResponseFromJSON(json: any): ImageResponse {
    return ImageResponseFromJSONTyped(json, false);
}

export function ImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'base64Image': json['base64Image'] == null ? undefined : json['base64Image'],
        'noImage': json['noImage'],
    };
}

export function ImageResponseToJSON(value?: ImageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'base64Image': value['base64Image'],
        'noImage': value['noImage'],
    };
}


import { Select } from "antd";
import { isPhone } from "../Helpers/isPhone";

const { Option } = Select;

export interface NameAndId {
  id: string;
  name: string;
}

interface CompanyFilterProps {
  companies: NameAndId[];

  onFilter: (companyId: string | undefined) => void;
}

export function CompanyFilter(props: CompanyFilterProps) {
  return (
    <Select
      allowClear
      showSearch
      style={{ width: isPhone ? 170 : 220 }}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (companyId: string) {
        props.onFilter(companyId);
      }}
      onClear={function () {
        props.onFilter(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.children
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.children.toLowerCase())
      }
    >
      {props.companies.map(function (c) {
        return <Option value={c.id}>{c.name}</Option>;
      })}
    </Select>
  );
}

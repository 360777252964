import { Select } from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import { User } from "../../generated-openapi-client";
import { useOnce } from "../../Hooks/useOnce";

const { Option } = Select;

interface UsersDropdownProps {
  companyId: string;
  onChange: (_: User | undefined) => void;
}

export function UsersDropdown(props: UsersDropdownProps) {
  const createCompaniesApi = useCompaniesApi();
  const [users, setUsers] = useState<User[] | undefined>();
  const { companyId } = props;
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

  useOnce(async function () {
    const companiesApi = await createCompaniesApi();
    const response = await companiesApi.getCompanyUsers({ companyId });
    setUsers(response);
  });

  if (users === undefined) {
    return <>Loading...</>;
  }

  return (
    <Select
      style={{ width: 220 }}
      placeholder="User"
      value={selectedUserId}
      onSelect={function (userId: string) {
        setSelectedUserId(userId);
        if (users) {
          const selectedUser = users.find((u) => u.userId === userId);

          props.onChange(selectedUser);
        }
      }}
      onClear={function () {
        setSelectedUserId(undefined);
        props.onChange(undefined);
      }}
    >
      {users.map(function (user) {
        return (
          <Option key={user.userId} value={user.userId}>
            {user.name}
          </Option>
        );
      })}
    </Select>
  );
}

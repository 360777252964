import { ShipmentReport } from "../../generated-openapi-client";
import { QuotesTable } from "../ViewQuotesScreen";

interface AllShipmentsScreenListTabProps {
  shipments: ShipmentReport[];
}

export function AllShipmentsScreenListTab(
  props: AllShipmentsScreenListTabProps
) {
  return (
    <div style={{ width: "100%", marginTop: "16px" }}>
      <QuotesTable quotes={props.shipments} />
    </div>
  );
}

import {
  CalendarOutlined,
  EnvironmentOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Segmented } from "antd";

export enum AllShipmentsScreenViewType {
  Map = "Map",
  List = "List",
  Calendar = "Calendar",
}

interface AllShipmentsScreenViewToggleProps {
  currentView: AllShipmentsScreenViewType;
  setCurrentView: (view: AllShipmentsScreenViewType) => void;
}

export function AllShipmentsScreenViewToggle(
  props: AllShipmentsScreenViewToggleProps
) {
  const options = [
    {
      value: AllShipmentsScreenViewType.List,
      icon: <UnorderedListOutlined />,
      label: "List",
    },
    {
      value: AllShipmentsScreenViewType.Map,
      icon: <EnvironmentOutlined />,
      label: "Map",
    },
    {
      value: AllShipmentsScreenViewType.Calendar,
      icon: <CalendarOutlined />,
      label: "Calendar",
    },
  ];

  return (
    <Segmented
      options={options}
      value={props.currentView}
      onChange={(value) => {
        props.setCurrentView(value as AllShipmentsScreenViewType);
      }}
    />
  );
}

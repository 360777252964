import { Button } from "antd";
import moment from "moment";
import { ReactNode } from "react";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import Stack from "../../Components/Stack";
import { WithClipboardLink } from "../../Components/WithClipboardLink";
import { Location, Quote } from "../../generated-openapi-client";
import { Address } from "../../generated-openapi-client/models/Address";
import { Contact } from "../../generated-openapi-client/models/Contact";
import { Hours } from "../../generated-openapi-client/models/Hours";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";
import { formatPhoneNumberWithExtension } from "../../Helpers/formatPhoneNumber";
import { isPhone } from "../../Helpers/isPhone";
import {
  describeLocationType,
  nameLocationType,
} from "../../Helpers/locationTypes";
import { getMapUrlForAddress } from "../../Helpers/mapUtils";
import { useCopyToClipboard } from "../../Hooks/useCopyToClipboard";
import Spacer from "../../Spacer";

interface QuoteGeneralInfoProps {
  quote: Quote;
}

export function QuoteGeneralInfo(props: QuoteGeneralInfoProps) {
  const data: Record<string, any> = {};

  const quote = props.quote;

  data["Transit Days"] = (
    <>
      {quote.transitBusinessDays}-{quote.latestTransitBusinessDays}
    </>
  );

  data["Estimated Delivery"] = (
    <>
      {quote.expectedDeliveryDate} to {quote.latestEstimatedDeliveryDate}
    </>
  );

  data["Earliest Permitted Pickup Date"] = (
    <CreatedAt timestamp={quote.earliestPermittedPickupDate!!} />
  );
  data["Latest Permitted Pickup Date"] = (
    <CreatedAt timestamp={quote.latestPermittedPickupDate!!} />
  );

  data["Quoted At"] = <CreatedAt timestamp={quote.createdAt!!} />;

  data["Equipment Type"] = describeEquipmentType(quote.equipmentType);

  data["Exclusive Use"] = quote.exclusiveUseNeeded ? "Yes" : "No";

  if (quote.addInsuranceToShipment) {
    data["Insurance"] = `${quote.insuranceAmount} ${quote.insuranceCurrency}`;
  }

  data["Internal Note"] = <>{quote.internalNote}</>;
  data["Customer Visible Note"] = <>{quote.customerVisibleNote}</>;

  return <KeyValues data={data} />;
}

interface LocationInfoProps {
  deadline: string | undefined;
  location: Location;
  contact: Contact;
  context: string;
  referenceNumber: string;
}

function renderLines(lines: Array<string | undefined>): Array<ReactNode> {
  return lines
    .filter((line) => line !== undefined && line !== "")
    .map(function (line) {
      return <div>{line}</div>;
    });
}

function DescribeAddress(props: LocationInfoProps) {
  const address = props.location.address!!;
  const cityStatePostalCode = `${address.city}, ${address.stateOrProvinceCode}, ${address.postalCode}`;

  const lines = [
    address.addressLine,
    address.addressLine2,
    cityStatePostalCode,
  ];

  const onCopyPostalCode = useCopyToClipboard(address.postalCode ?? "");
  const onCopyFullAddress = useCopyToClipboard(
    lines.filter((o) => o).join(", ")
  );

  return (
    <Stack align="left">
      {lines.map(function (l) {
        return <div>{l}</div>;
      })}
      <Spacer height={8} />
      {!isPhone && (
        <HorizontalStack style={{ marginLeft: "-16px" }}>
          <Button
            type="link"
            href={getMapUrlForAddress(address)}
            target="_blank"
          >
            View Map
          </Button>
          <Button type="link" onClick={onCopyPostalCode}>
            Copy Postal Code
          </Button>
          <Button type="link" onClick={onCopyFullAddress}>
            Copy Full Address
          </Button>
        </HorizontalStack>
      )}
    </Stack>
  );
}

function describeCityStatePostalCode(address: Address): Array<ReactNode> {
  const cityStatePostalCode = `${address.city}, ${address.stateOrProvinceCode}, ${address.postalCode}`;

  const lines = [cityStatePostalCode];
  return [
    ...renderLines(lines),
    <Spacer height={4} />,
    <Button href={getMapUrlForAddress(address)} type="link" target="_blank">
      View Map
    </Button>,
  ];
}

function DescribeContact(props: LocationInfoProps) {
  const contact = props.contact;

  function getPhone() {
    if (contact === undefined) {
      return "";
    }

    const phone = formatPhoneNumberWithExtension(
      contact.phoneNumber,
      contact.phoneNumberExtension
    );
    return phone;
  }

  function getNameandPhone() {
    if (contact === undefined) {
      return "";
    }
    const name = contact.contactName;
    const phone = getPhone();

    const nameAndPhone = `${name}, ${phone}`;
    return nameAndPhone;
  }

  const onCopyNameAndPhone = useCopyToClipboard(getNameandPhone());
  const onCopyPhone = useCopyToClipboard(getPhone());

  if (contact === undefined) {
    return <>-</>;
  }

  const lines = [
    contact.contactName,
    formatPhoneNumberWithExtension(
      contact.phoneNumber,
      contact.phoneNumberExtension
    ),
    contact.emailAddress,
  ];

  return (
    <Stack align="left">
      {lines.map(function (l) {
        return <div>{l}</div>;
      })}
      <Spacer height={8} />
      {!isPhone && (
        <HorizontalStack style={{ marginLeft: "-16px" }}>
          <Button type="link" onClick={onCopyNameAndPhone}>
            Copy Name and Phone
          </Button>
          <Button type="link" onClick={onCopyPhone}>
            Copy Phone
          </Button>
        </HorizontalStack>
      )}
    </Stack>
  );
}

export function describeAccessorial(accessorial: string): string {
  switch (accessorial) {
    case "LOGISTICS_LIFT_GATE_REQUIRED":
      return `Lift Gate Required`;
    case "LOGISTICS_INSIDE":
      return `Inside`;
    case "SCHEDULING_APPOINTMENT_REQUIRED":
      return `Appointment Required`;
    case "SCHEDULING_AFTER_HOURS":
      return `After Hours`;
    default:
      return accessorial;
  }
}

export function describeAccessorials(accessorials: Array<string>): ReactNode {
  if (accessorials.length === 0) {
    return <>-</>;
  }

  const lines = accessorials.map(function (accessorial) {
    return describeAccessorial(accessorial);
  });
  const nodes = lines.map(function (line) {
    return <div>{line}</div>;
  });
  const copyText = lines.join(", ");

  return <WithClipboardLink copyText={copyText}>{nodes}</WithClipboardLink>;
}

function describeHours(hours: Hours | undefined): string {
  if (hours === undefined) {
    return "-";
  }

  return `${hours.openFrom} - ${hours.openUntil}`;
}

interface DescribeLocationTypeProps {
  locationType: string | undefined;
}

function DescribeLocationType(props: DescribeLocationTypeProps) {
  return (
    <>
      {props.locationType !== undefined && (
        <Stack align="left">
          <WithClipboardLink copyText={nameLocationType(props.locationType)}>
            <div>{nameLocationType(props.locationType)}</div>
          </WithClipboardLink>
          <div style={{ fontSize: "12px", color: Colors.LightText }}>
            {describeLocationType(props.locationType)}
          </div>
        </Stack>
      )}
      {props.locationType === undefined && <>Unknown</>}
    </>
  );
}

export function LocationInfo(props: LocationInfoProps) {
  const data = {
    [`${props.context} Business Name`]: (
      <WithClipboardLink>{props.location.businessName}</WithClipboardLink>
    ),
    [`${props.context} Address`]: <DescribeAddress {...props} />,
    [`${props.context} Contact`]: <DescribeContact {...props} />,
    [`${props.context} Hours`]: describeHours(props.location.hours),
    [`${props.context} Notes`]: props.location.notes,
    [`${props.context} Accessorials`]: describeAccessorials(
      props.location.accessorials!!
    ),
    [`${props.context} Reference Number`]: (
      <WithClipboardLink>{props.referenceNumber}</WithClipboardLink>
    ),
    [`${props.context} Location Type`]: (
      <DescribeLocationType locationType={props.location.locationType} />
    ),
  };

  if (props.deadline) {
    data["Delivery deadline"] = moment(props.deadline).format(
      "dddd, MMM Do YYYY"
    );
  }

  try {
    return <KeyValues data={data} />;
  } catch (e: any) {
    return <div>Oops. Somethign went wrong {e.toString()}</div>;
  }
}

interface LocationInfoForQuotingProps {
  location: Location;
  context: string;
}

export function LocationInfoForQuoting(props: LocationInfoForQuotingProps) {
  try {
    return (
      <KeyValues
        data={{
          [`${props.context} Location`]: describeCityStatePostalCode(
            props.location.address!!
          ),
          [`${props.context} Accessorials`]: describeAccessorials(
            props.location.accessorials!!
          ),
          [`${props.context} Location Type`]: (
            <DescribeLocationType locationType={props.location.locationType} />
          ),
        }}
      />
    );
  } catch (e: any) {
    return <div>Oops. Somethign went wrong {e.toString()}</div>;
  }
}

import { Tag, TagProps } from "antd";
import { CSSProperties, HTMLAttributes } from "react";
import styled from "styled-components";
import Colors from "../Components/Colors";
import Admins from "../GeneratedFixtures/Admins.json";
import {
  stringToColor,
  stringToForegroundColor,
} from "../Helpers/stringToColor";

export const TagInner = styled.div`
  font-size: 16px;
  padding: 4px;
  font-weight: 600;
`;

interface AdminTagProps extends HTMLAttributes<HTMLDivElement> {
  email: string | undefined;
  showFirstNameOnly?: boolean;
}
export function AdminTag({
  email,
  showFirstNameOnly = true,
  ...rest
}: AdminTagProps) {
  const background: TagProps["color"] =
    email !== undefined ? stringToColor(email) : Colors.Gray[50];

  const foreground: CSSProperties["color"] =
    email !== undefined ? stringToForegroundColor(email) : Colors.LightText;

  let name =
    Admins.find((a) => a.emailAddress === email)?.fullName ?? "Unassigned";

  if (showFirstNameOnly) {
    name = name?.split(" ")[0];
  }

  return (
    <div style={{ cursor: "pointer" }} {...rest}>
      <Tag color={background}>
        <TagInner style={{ color: foreground }}>{name}</TagInner>
      </Tag>
    </div>
  );
}

import { Button, Modal, Spin, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCarrierInvoiceApi, useShipmentsApi } from "../../Apis/Apis";
import { CarrierFilter } from "../../Components/CarrierFilter";
import CarrierLogo from "../../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { ViewShipmentButton } from "../../Components/ViewShipmentButton";
import {
  Shipment,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { HistoricLineItem } from "../../generated-openapi-client/models/HistoricLineItem";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";

const { Text } = Typography;

interface ViewHistoricLineItemsButtonProps {
  shipmentData: ViewShipmentDataForApollo;
}

interface HistoricLineItemsTableProps {
  carrierIdentifier: string | undefined;
  historicLineItems: Array<HistoricLineItem>;
  shipments: Array<Shipment>;
}

function HistoricLineItemsTable(props: HistoricLineItemsTableProps) {
  function filterData() {
    if (props.carrierIdentifier === undefined) {
      return props.historicLineItems;
    } else {
      return props.historicLineItems.filter(function (o) {
        return o.carrierIdentifier === props.carrierIdentifier;
      });
    }
  }
  const columns: DataTableColumn<HistoricLineItem>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Pro Number",
      render: (o) => <div>{o.proNumber}</div>,
    },
    {
      title: "Pickup Date",
      render: (o) => <div>{o.pickupDate}</div>,
      sorter: function (a, b) {
        return moment(a.pickupDate).valueOf() - moment(b.pickupDate).valueOf();
      },
    },

    {
      title: "Route",
      render: (o) => (
        <Stack align="center">
          <div>{o.pickupCityState}</div>
          <div style={{ margin: "-8px" }}>↓</div>
          <div>{o.deliveryCityState}</div>
        </Stack>
      ),
    },
    {
      title: "Handling Units",
      render: (o) => (
        <Stack align="left">
          <div>
            {o.numberHandlingUnits} x {o.handlingUnitType}
          </div>
          <div>{o.description}</div>
        </Stack>
      ),
    },
    {
      title: "Dimensions",
      render: (o) => (
        <Stack align="left">
          <div>
            {o.length}"x{o.width}"x{o.height}"
          </div>
          <div>{o.weightPerHandlingUnit}lb each</div>
        </Stack>
      ),
    },
    {
      title: "Class",
      render: (o) => (
        <div>
          {o.freightClass}
          {o.nmfcCode ? `, ${o.nmfcCode}` : ""}
        </div>
      ),
    },
    {
      title: "Shipment Density (pcf)",
      render: (o) => (
        <div>
          {props.shipments
            .find((s) => s.shipmentId === o.shipmentId)
            ?.density?.toFixed(1)}
        </div>
      ),
    },
    {
      title: "Stackable",
      render: (o) => <div>{o.stackable ? "✅" : "❌"}</div>,
    },

    {
      title: "Actions",
      render: function (o) {
        return <ViewShipmentButton shipmentId={o.shipmentId} />;
      },
    },
  ];

  return <DataTable pagination={false} columns={columns} data={filterData()} />;
}

export function ViewHistoricLineItemsButton(
  props: ViewHistoricLineItemsButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [historicLineItems, setHistoricLineItems] = useState<
    Array<HistoricLineItem> | undefined
  >();
  const createCarrierInvoiceApi = useCarrierInvoiceApi();
  const [carrierIdentifier, setCarrierIdentifier] = useState<
    string | undefined
  >();

  const createShipmentsApi = useShipmentsApi();
  const [shipments, setShipments] = useState<Shipment[]>([]);

  useOnce(async function () {
    const carrierInvoiceApi = await createCarrierInvoiceApi();
    const companyId = props.shipmentData.shipment.company.companyId!!;
    const response = await carrierInvoiceApi.getHistoricLineItems({
      companyId,
    });

    setHistoricLineItems(response);
  });

  async function fetchShipmentsData() {
    const shipmentApi = await createShipmentsApi();

    if (!historicLineItems) {
      return;
    }

    historicLineItems.forEach(async (historic) => {
      const shipment = shipments.find(
        (s) => s.shipmentId === historic.shipmentId
      );

      if (shipment) {
        return;
      }

      const fetchedShipment = await shipmentApi.getShipmentById({
        shipmentId: historic.shipmentId,
      });

      setShipments((prevShipments) => {
        const prev = [...prevShipments];

        prev.push(fetchedShipment.shipment.shipment);

        return prev;
      });
    });
  }

  useEffect(() => {
    fetchShipmentsData();
  }, [historicLineItems]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Historic Line Items"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1800}
      >
        <Text>
          These are line items from other shipments this customer has shipped
          with FreightSimple. You can use it to understand if a carrier's weight
          report actually makes sense. eg. The carrier is saying something is
          freight class 150, when they always ship class 50. Or if they are
          saying it's 50x50 when the customer always ships 48x40 pallets
        </Text>
        <Spacer height={32} />
        {!historicLineItems && <Spin />}
        {historicLineItems && (
          <>
            <HorizontalStack align="right">
              <CarrierFilter
                carrierIdentifier={carrierIdentifier}
                setCarrierIdentifier={setCarrierIdentifier}
              />
            </HorizontalStack>
            <Spacer height={16} />
            <HistoricLineItemsTable
              historicLineItems={historicLineItems}
              carrierIdentifier={carrierIdentifier}
              shipments={shipments}
            />
          </>
        )}
      </Modal>
      <Button onClick={showModal}>🕰 Historic Line Items</Button>
    </>
  );
}

import { Select } from "antd";
import moment from "moment-timezone";
import { PeriodFormats } from "../Screens/FinanceComponents/generatePeriodInfo";

interface QuarterSelectProps {
  quarter: string | undefined;
  setQuarter: (_: string | undefined) => void;
}

export function QuarterSelect(props: QuarterSelectProps) {
  const { quarter, setQuarter } = props;

  const startingMonth = moment().startOf("quarter");

  const months = [
    startingMonth.clone(),
    startingMonth.clone().subtract(1, "quarter"),
    startingMonth.clone().subtract(2, "quarter"),
    startingMonth.clone().subtract(3, "quarter"),
    startingMonth.clone().subtract(4, "quarter"),
    startingMonth.clone().subtract(5, "quarter"),
    startingMonth.clone().subtract(6, "quarter"),
    startingMonth.clone().subtract(7, "quarter"),
    startingMonth.clone().subtract(8, "quarter"),
    startingMonth.clone().subtract(9, "quarter"),
    startingMonth.clone().subtract(10, "quarter"),
    startingMonth.clone().subtract(11, "quarter"),
  ];

  

  return (
    <Select
      allowClear
      value={quarter}
      style={{ width: 220 }}
      onChange={function (e) {
        setQuarter(e);
      }}
      placeholder="Filter by quarter"
    >
      {months.map(function (m) {
        return (
          <Select.Option value={m.format(PeriodFormats.Quarterly)}>
            {m.format(PeriodFormats.Quarterly)}
          </Select.Option>
        );
      })}
    </Select>
  );
}

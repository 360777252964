import { Select, Tag } from "antd";
import styled from "styled-components";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import { SalesContact } from "../../generated-openapi-client";

interface ManageRecipientsDropdownProps {
  contacts: SalesContact[];
  selectedContactIds: string[];
  setSelectedContactIds: (_: string[]) => void;
}

const ContactName = styled.div``;
const ContactEmail = styled.div`
  color: ${Colors.LightText};
  font-size: 11px;
  margin-top: -5px;
`;

export function ManageRecipientsDropdown(props: ManageRecipientsDropdownProps) {
  return (
    <Select
      value={props.selectedContactIds}
      onChange={function (_selectedContactIds) {
        props.setSelectedContactIds(_selectedContactIds);
      }}
      mode="multiple"
      allowClear
      style={{ width: "300px" }}
    >
      {props.contacts.map(function (c) {
        if (c.email!!.trim() !== "") {
          return (
            <Select.Option value={c.salesContactId}>
              <Stack align="left">
                <ContactName>{c.name ?? "Unknown"}</ContactName>
                <ContactEmail>{c.email}</ContactEmail>
              </Stack>
            </Select.Option>
          );
        } else {
          return (
            <Select.Option value={c.salesContactId} disabled>
              {c.name} <Tag color="red">Missing Email</Tag>
            </Select.Option>
          );
        }
      })}
    </Select>
  );
}

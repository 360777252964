import { Menu } from "antd";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import { CreatedAt } from "../../Components/CreatedAt";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { FreightClaim } from "../../generated-openapi-client";
import { DeleteClaimMenuItem } from "./DeleteClaimMenuItem";
import { TabProps } from "./TabProps";
import { ViewClaimButton } from "./ViewClaimButton";

interface ClaimsTableProps extends TabProps {
  claims: FreightClaim[];
}

function ClaimsTable(props: ClaimsTableProps) {
  const columns: DataTableColumn<FreightClaim>[] = [
    {
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.carrierIdentifier!!}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    },
    {
      title: "Created At",
      render: (o) => <CreatedAt timestamp={o.createdAt} />,
    },
    {
      title: "State",
      render: (o) => <>{o.freightClaimState}</>,
    },
    {
      title: "Pro Number",
      render: (o) => <>{o.proNumber}</>,
    },
    {
      title: "Description",
      render: (o) => <>{o.claimDescription}</>,
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow
          extrasMenu={
            <Menu>
              <DeleteClaimMenuItem
                claimId={o.freightClaimId}
                onRefresh={props.onRefresh}
              />
            </Menu>
          }
        >
          <ViewClaimButton freightClaim={o} data={props.shipmentData} />
        </ButtonRow>
      ),
    },
  ];
  return <DataTable pagination={false} columns={columns} data={props.claims} />;
}

export function ClaimsTab(props: TabProps) {
  return (
    <ClaimsTable claims={props.shipmentData.claimsTab.claims} {...props} />
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const QuoteFilterType = {
    NoQuotes: 'NoQuotes',
    NotManyQuotes: 'NotManyQuotes',
    SuspiciouslyLow: 'SuspiciouslyLow',
    CompanySignedUpInLastTwoWeeks: 'CompanySignedUpInLastTwoWeeks',
    CompanySignedUpInLastTwoWeeksDaily: 'CompanySignedUpInLastTwoWeeksDaily',
    CompanySignedUpInLastTwoWeeksWeekly: 'CompanySignedUpInLastTwoWeeksWeekly',
    ShippingRookies: 'ShippingRookies',
    NeverBooked: 'NeverBooked',
    NeverBookedWeekly: 'NeverBookedWeekly',
    NeverBookedDaily: 'NeverBookedDaily',
    IncludesFlaggedServices: 'IncludesFlaggedServices',
    FlaggedServiceIsInTwoCheapest: 'FlaggedServiceIsInTwoCheapest',
    LargeShipment: 'LargeShipment'
} as const;
export type QuoteFilterType = typeof QuoteFilterType[keyof typeof QuoteFilterType];


export function instanceOfQuoteFilterType(value: any): boolean {
    return Object.values(QuoteFilterType).includes(value);
}

export function QuoteFilterTypeFromJSON(json: any): QuoteFilterType {
    return QuoteFilterTypeFromJSONTyped(json, false);
}

export function QuoteFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteFilterType {
    return json as QuoteFilterType;
}

export function QuoteFilterTypeToJSON(value?: QuoteFilterType | null): any {
    return value as any;
}


import { Col, Row } from "antd";
import moment from "moment";
import styled from "styled-components";
import Colors from "../../Components/Colors";
import {
  FreightClaim,
  FreightClaimType,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { useDisplay } from "../../Hooks/useDisplay";
import { AdminTag } from "../AdminTag";
import { ChangeClaimAssigneeModal } from "./ChangeClaimAssigneeModal";
import { ClaimStatusTag } from "./ClaimStatusTag";
import { calculateFreightClaimTotal } from "./ViewClaimButton";

const WidgetTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const WidgetSubtitle = styled.div`
  font-size: 12px;
`;

interface ClaimWidgetsProps {
  shipmentData: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
}

export function ClaimWidgets({ shipmentData, onRefresh }: ClaimWidgetsProps) {
  const claims = shipmentData.claimsTab.claims;
  const openedClaims = claims;

  if (openedClaims.length === 0) {
    return null;
  }

  return (
    <>
      {openedClaims.map((claim) => (
        <ClaimWidget
          shipmentData={shipmentData}
          onRefresh={onRefresh}
          claim={claim}
        />
      ))}
    </>
  );
}

interface ClaimWidgetProps extends ClaimWidgetsProps {
  claim: FreightClaim;
}

function ClaimWidget({ claim, onRefresh }: ClaimWidgetProps) {
  const display = useDisplay();
  const piecesDamaged = countPiecesAffected(claim, FreightClaimType.Damage);
  const piecesLost = countPiecesAffected(claim, FreightClaimType.Loss);
  const totalAmount = calculateFreightClaimTotal(claim);

  function countPiecesAffected(
    claim: FreightClaim,
    claimType: FreightClaimType
  ): number | undefined {
    return claim.freightClaimLineItems?.reduce(
      (count, item) =>
        item.claimType === claimType
          ? count + (item.piecesAffected ?? 0)
          : count,
      0
    );
  }

  return (
    <>
      <ChangeClaimAssigneeModal
        display={display}
        claim={claim}
        onRefresh={onRefresh}
      />
      <Row
        style={{ padding: 16, background: Colors.LightOrange }}
        gutter={[36, 0]}
      >
        <Col>
          <WidgetTitle>Claim Open</WidgetTitle>
          <WidgetSubtitle>
            Claim Opened: {moment(claim.createdAt).format("dddd, MMM Do YYYY")}
          </WidgetSubtitle>
        </Col>
        <Col>
          <WidgetSubtitle>Assigned</WidgetSubtitle>
          <AdminTag email={claim.assignee} onClick={display.show} />
        </Col>
        <Col>
          <WidgetSubtitle>State</WidgetSubtitle>
          <ClaimStatusTag state={claim.freightClaimState} />
        </Col>
        <Col>
          <WidgetSubtitle>Total Amount</WidgetSubtitle>
          <WidgetTitle>
            ${totalAmount} {claim.claimCurrency}
          </WidgetTitle>
        </Col>
        <Col>
          <WidgetSubtitle>Line Items</WidgetSubtitle>
          <WidgetTitle>{claim.freightClaimLineItems?.length ?? 0}</WidgetTitle>
        </Col>
        <Col>
          <WidgetSubtitle>Pieces Damaged</WidgetSubtitle>
          <WidgetTitle> {piecesDamaged} </WidgetTitle>
        </Col>
        <Col>
          <WidgetSubtitle>Pieces Lost</WidgetSubtitle>
          <WidgetTitle>{piecesLost}</WidgetTitle>
        </Col>
      </Row>
    </>
  );
}

import { Button, Popover } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { DataTableColumn } from "./DataTable";

interface ShowHideColumnsPopoverProps<T> {
  children: ReactNode;
  columns: DataTableColumn<T>[];
  setColumns: Dispatch<SetStateAction<DataTableColumn<T>[]>>;
}

export function ShowHideColumnsPopover<T>({
  children,
  columns,
  setColumns,
}: ShowHideColumnsPopoverProps<T>) {
  const onChange = (e: CheckboxChangeEvent, key: string) => {
    const col = [...columns];
    col.forEach((c) => {
      if (c.key === key) {
        c.show = e.target.checked;
      }
    });
    setColumns(col);
  };

  return (
    <Popover
      title="Show/Hide Columns"
      placement="rightBottom"
      trigger="click"
      content={
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {columns
            .filter((c) => c.key !== "actions")
            .map((c, i) => (
              <Checkbox
                key={c.key!}
                onChange={(e: CheckboxChangeEvent) => onChange(e, c.key!)}
                checked={c.show}
                style={{ marginLeft: i === 0 ? "8px" : "" }}
              >
                {c.title} {c.subtitle && <>({c.subtitle})</>}
              </Checkbox>
            ))}
        </div>
      }
    >
      <Button style={{ marginTop: "10px", marginBottom: "10px" }}>
        {children}
      </Button>
    </Popover>
  );
}

import React from "react";
import { PaymentMethod } from "../generated-openapi-client";

interface CreditCardLogoProps {
  brand: string;
  size: "small" | "regular";
  paymentMethod?: PaymentMethod;
}

interface BrandedLogoProps {
  brand: string;
  size: "small" | "regular";
}

function BrandedLogo(props: BrandedLogoProps) {
  const style =
    props.size === "small"
      ? { width: "28px", height: "16px" }
      : { width: "32px", height: "20px" };

  return (
    <img
      style={style}
      src={`https://dashboard.freightsimple.com/images/credit-card-brands/${props.brand}.png`}
      alt={props.brand}
    />
  );
}

interface GenericLogoProps {
  paymentMethod: PaymentMethod;
  size: "small" | "regular";
}

function GenericLogo(props: GenericLogoProps) {
  let icon;

  switch (props.paymentMethod) {
    case PaymentMethod.CreditCard:
      icon = "generic-credit-card";
      break;
    case PaymentMethod.BankAccount:
      icon = "generic-bank";
      break;
    default:
      throw new Error(`Unknown payment method: ${props.paymentMethod}`);
  }

  const style =
    props.size === "small"
      ? {
          marginLeft: "5px",
          marginRight: "5px",
          width: "20px",
          height: "16px",
        }
      : {
          marginLeft: "3px",
          marginRight: "2px",
          width: "25px",
          height: "20px",
        };

  return (
    <img
      style={style}
      src={`https://dashboard.freightsimple.com/images/credit-card-brands/${icon}.png`}
      alt={props.paymentMethod}
    />
  );
}

const CreditCardLogo: React.FC<CreditCardLogoProps> = (
  props: CreditCardLogoProps
) => {
  const paymentMethod = props.paymentMethod ?? PaymentMethod.CreditCard;

  if (
    paymentMethod === PaymentMethod.CreditCard &&
    ["visa", "mastercard", "amex"].includes(props.brand)
  ) {
    return <BrandedLogo brand={props.brand} size={props.size} />;
  } else if (
    paymentMethod === PaymentMethod.BankAccount &&
    ["001", "002", "003", "004", "006", "010", "016", "030"].includes(
      props.brand
    )
  ) {
    return <BrandedLogo brand={props.brand} size={props.size} />;
  } else {
    return <GenericLogo paymentMethod={paymentMethod} size={props.size} />;
  }
};
export default CreditCardLogo;

import { DataTable, DataTableColumn } from "../../Components/DataTable";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { Weight } from "../../Components/Weight";
import { LineItem } from "../../generated-openapi-client";
import Spacer from "../../Spacer";
import { DeleteLineItemButton } from "./DeleteLineItemButton";
import { EditLineItemButton } from "./EditLineItemButton";

interface LineItemsTableProps {
  lineItems: Array<LineItem>;
  editable?: boolean;
  updateLineItems?: (lineItems: LineItem[]) => void;
}

interface DangerousCellProps {
  lineItem: LineItem;
}

export function DangerousCell(props: DangerousCellProps) {
  if (props.lineItem.isDangerous) {
    return (
      <Stack align="left">
        <strong>☠️ Dangerous</strong>
        <div>UN{props.lineItem.dangerousUnNumber}</div>
        <div>Haz Class : {props.lineItem.dangerousClassification}</div>
        <div>PG {props.lineItem.dangerousPackingGroup}</div>
        <div>
          {props.lineItem.dangerousNumberPackages} x{" "}
          {props.lineItem.dangerousPackagingType}
        </div>
        <div>{props.lineItem.dangerousProperShippingName}</div>
        <div>{props.lineItem.dangerousTechnicalName}</div>
      </Stack>
    );
  } else {
    return <div>❌</div>;
  }
}

function boolToEmoji(value: boolean): string {
  return value ? "✅" : "❌";
}

export function LineItemsTable(props: LineItemsTableProps) {
  
  const columns: DataTableColumn<LineItem>[] = [
    {
      title: "HUs",
      render: (o) => (
        <div>
          {o.numberHandlingUnits} x {o.handlingUnitType}
        </div>
      ),
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Length",
      render: (o) => <div>{o.length}"</div>,
    },
    {
      title: "Width",
      render: (o) => <div>{o.width}"</div>,
    },
    {
      title: "Height",
      render: (o) => <div>{o.height}"</div>,
    },
    {
      title: "lb/HU",
      render: (o) => <Weight>{o.weightPerHandlingUnit!!}</Weight>,
    },
    {
      title: "Total",
      render: (o) => (
        <Weight>{o.weightPerHandlingUnit!! * o.numberHandlingUnits!!}</Weight>
      ),
    },
    {
      title: "Class",
      render: (o) => <div>{o.freightClass}</div>,
    },
    {
      title: "Temp",
      render: (o) => <div>{o.temperatureHandling}</div>,
    },
    {
      title: "Dangerous",
      render: (o) => <DangerousCell lineItem={o} />,
    },
    {
      title: "NMFC",
      render: (o) => <div>{o.nmfcItemNumber}</div>,
    },
    {
      title: "Stackable",
      render: (o) => <div>{boolToEmoji(o.isStackable!!)}</div>,
    },
  ];

  const updateLineItems = props.updateLineItems;
  if (props.editable && updateLineItems !== undefined) {
    columns.push({
      title: "Actions",
      render: (o, index) => (
        <HorizontalStack>
          <EditLineItemButton
            index={index}
            updateLineItems={updateLineItems}
            lineItems={props.lineItems}
          />
          <Spacer width={8} />
          <DeleteLineItemButton
            index={index}
            updateLineItems={updateLineItems}
            lineItems={props.lineItems}
          />
        </HorizontalStack>
      ),
    });
  }
  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.lineItems}
      style={{ border: "1px solid #eee" }}
    />
  );
}

import { Alert, Tabs } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomerInvoiceApi } from "../Apis/Apis";
import { ButtonRow } from "../Components/ButtonRow";
import { CustomerInvoiceStateTag } from "../Components/CustomerInvoiceStateTag";
import { DataTable, DataTableColumn } from "../Components/DataTable";
import { Loading } from "../Components/Loading";
import { Page } from "../Components/Page";
import PageTitle from "../Components/PageTitle";
import Stack from "../Components/Stack";
import { StatisticText } from "../Components/StatisticText";
import { StatsRow } from "../Components/StatsRow";
import { ViewShipmentButton } from "../Components/ViewShipmentButton";
import { diffSets } from "../Helpers/diffSets";
import { formatAsCurrency } from "../Helpers/numberFormatting";
import { useOnce } from "../Hooks/useOnce";
import useQuery from "../Hooks/useQuery";
import Spacer from "../Spacer";
import { CompanyCustomerInvoice } from "../generated-openapi-client/models/CompanyCustomerInvoice";
import { CompanyShipmentQuote } from "../generated-openapi-client/models/CompanyShipmentQuote";
import { MismatchedCustomerInvoices } from "../generated-openapi-client/models/MismatchedCustomerInvoices";
import { XeroInvoice } from "../generated-openapi-client/models/XeroInvoice";

const { TabPane } = Tabs;

interface XeroInvoicesTableProps {
  xeroInvoices: XeroInvoice[];
}

interface ShipmentsTableProps {
  shipments: CompanyShipmentQuote[];
}

interface CustomerInvoicesTableProps {
  customerInvoices: CompanyCustomerInvoice[];
}

export function CustomerInvoicesTable(props: CustomerInvoicesTableProps) {
  const columns: DataTableColumn<CompanyCustomerInvoice>[] = [
    {
      title: "Company",
      render: (o) => <div>{o.company.companyName}</div>,
    },
    {
      title: "Invoice Identifier",
      render: (o) => <div>{o.customerInvoice.invoiceIdentifier}</div>,
    },
    {
      title: "State",
      render: (o) => (
        <CustomerInvoiceStateTag customerInvoice={o.customerInvoice} />
      ),
    },
    {
      title: "Due Date",
      render: (o) => <div>{o.customerInvoice.dueDate}</div>,
    },
    {
      title: "Type",
      render: (o) => <div>{o.customerInvoice.invoiceType}</div>,
    },
    {
      title: "Payment Method",
      render: (o) => <div>{o.customerInvoice.paymentMethod}</div>,
    },
    {
      title: "Amount",
      render: (o) => (
        <div>
          ${o.customerInvoice.amount} {o.customerInvoice.currency}
        </div>
      ),
    },
    {
      title: "Actions",
      render: (o) => (
        <ButtonRow>
          <ViewShipmentButton
            shipmentId={o.customerInvoice.shipmentId!!}
            openInNewTab
          />
        </ButtonRow>
      ),
    },
  ];

  return (
    <DataTable
      pagination={false}
      columns={columns}
      data={props.customerInvoices}
    />
  );
}

interface MismatchedInvoicesAlertProps {
  mismatchedInvoices: MismatchedCustomerInvoices;
}

function MismatchedInvoicesAlert(props: MismatchedInvoicesAlertProps) {
  const { mismatchedInvoices } = props;
  const inXero = mismatchedInvoices.outstandingXeroInvoices.map(function (xi) {
    return xi.invoiceNumber as string;
  });

  const inDB = mismatchedInvoices.outstandingDbInvoices.map(function (ci) {
    return ci.customerInvoice.invoiceIdentifier!!;
  });

  const r = diffSets(inDB, inXero);

  const missingFromXero = r.aNotInB;
  const extraInXero = r.bNotInA;

  const shipmentsMissingInvoices =
    props.mismatchedInvoices.shipmentsWithoutCustomerInvoices;

  if (
    missingFromXero.length === 0 &&
    extraInXero.length === 0 &&
    shipmentsMissingInvoices.length === 0
  ) {
    return (
      <Alert
        type="success"
        description={`Xero and our system match! All outstanding invoices line up. All shipments have invoices.`}
        style={{ width: "100%" }}
      />
    );
  }

  return (
    <Alert
      type="error"
      description={`Shipments missing invoices=${
        props.mismatchedInvoices.shipmentsWithoutCustomerInvoices.length
      } Missing from Xero=${missingFromXero.join(
        ", "
      )}, Extra in Xero=${extraInXero.join(", ")}`}
      style={{ width: "100%" }}
    />
  );
}

export function ShipmentsTable(props: ShipmentsTableProps) {
  const columns: DataTableColumn<CompanyShipmentQuote>[] = [
    {
      title: "Bill of Lading Number",
      render: (o) => <div>{o.shipment.billOfLadingNumber}</div>,
    },
    {
      title: "Quote Amount",
      render: (o) => (
        <div>{formatAsCurrency(o.quote.quotedPriceByCarrierCad, "CAD")}</div>
      ),
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.shipments} />
  );
}

export function XeroInvoicesTable(props: XeroInvoicesTableProps) {
  const columns: DataTableColumn<XeroInvoice>[] = [
    {
      title: "Invoice Number",
      render: (o) => <div>{o.invoiceNumber}</div>,
    },
    {
      title: "Amount Due",
      render: (o) => <>{formatAsCurrency(o.amountDue, o.currencyCode)}</>,
    },
    {
      title: "Amount Credited",
      render: (o) => <>{formatAsCurrency(o.amountCredited, o.currencyCode)}</>,
    },
    {
      title: "Amount Paid",
      render: (o) => <>{formatAsCurrency(o.amountPaid, o.currencyCode)}</>,
    },
    {
      title: "Contact",
      render: (o) => <>{o.contactName}</>,
    },
    {
      title: "Date",
      render: (o) => <>{o.date}</>,
    },
    {
      title: "Due Date",
      render: (o) => <>{o.dueDate}</>,
    },
    {
      title: "Type",
      render: (o) => <>{o.type}</>,
    },
    {
      title: "Status",
      render: (o) => <>{o.status}</>,
    },
    {
      title: "Url",
      render: (o) => <>{o.url}</>,
    },
  ];

  return (
    <DataTable pagination={false} columns={columns} data={props.xeroInvoices} />
  );
}

export function MismatchedCustomerInvoicesScreen() {
  const [mismatchedInvoices, setMismatchedInvoices] = useState<
    MismatchedCustomerInvoices | undefined
  >();
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function refresh() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const response = await customerInvoiceApi.getMismatchedCustomerInvoices();
    setMismatchedInvoices(response);
  }

  const query = useQuery();
  const navigate = useNavigate();

  const tab = query.tab as string;

  useOnce(refresh);

  function callback(key: string) {
    navigate(`/mismatched-customer-invoices?tab=${key}`, {
      replace: true,
    });
  }

  if (mismatchedInvoices === undefined) {
    return <Loading />;
  }

  const numberShipmentsMissingInvoices =
    mismatchedInvoices.shipmentsWithoutCustomerInvoices.length;

  const outstandingXeroInvoices = mismatchedInvoices.outstandingXeroInvoices;

  return (
    <>
      <Page
        title={`Mismatched Customer Invoices`}
        subtitle="Use this to dianogse if there are any mapping issues between Xero and our DB for customer invoices"
        tags={[]}
        stats={
          <StatsRow>
            <StatisticText
              title="# Shipments Missing Invoices"
              value={numberShipmentsMissingInvoices}
            ></StatisticText>
            <StatisticText
              title="# Outstanding Invoices in Xero"
              value={outstandingXeroInvoices.length}
            ></StatisticText>
            <StatisticText
              title="# Outstanding Invoices in DB"
              value={mismatchedInvoices.outstandingDbInvoices.length}
            ></StatisticText>
          </StatsRow>
        }
        extra={[]}
      >
        <PageTitle>{`Mismatched Customer Invoices`}</PageTitle>
        <Stack align="left">
          <MismatchedInvoicesAlert mismatchedInvoices={mismatchedInvoices} />
          <Spacer height={16} />
          <Tabs
            defaultActiveKey={tab}
            onChange={callback}
            style={{ width: "100%" }}
          >
            {mismatchedInvoices.shipmentsWithoutCustomerInvoices.length > 0 && (
              <TabPane
                tab={`🚨 Shipments missing invoices  (${mismatchedInvoices.shipmentsWithoutCustomerInvoices.length})`}
                key="shipmentsWithoutInvoices"
              >
                <ShipmentsTable
                  shipments={
                    mismatchedInvoices.shipmentsWithoutCustomerInvoices
                  }
                />
              </TabPane>
            )}
            {mismatchedInvoices.xeroInvoicesNotInDb.length > 0 && (
              <TabPane
                tab={`🚨 Xero Invoices not in DB  (${mismatchedInvoices.xeroInvoicesNotInDb.length})`}
                key="xeroInvoicesNotInDb"
              >
                <XeroInvoicesTable
                  xeroInvoices={mismatchedInvoices.xeroInvoicesNotInDb}
                />
              </TabPane>
            )}
            {mismatchedInvoices.dbInvoicesNotInXero.length > 0 && (
              <TabPane
                tab={`🚨 DB Invoices not in Xero (${mismatchedInvoices.dbInvoicesNotInXero.length})`}
                key="dbInvoicesNotInXero"
              >
                <CustomerInvoicesTable
                  customerInvoices={mismatchedInvoices.dbInvoicesNotInXero}
                />
              </TabPane>
            )}

            <TabPane
              tab={`Outstanding Xero Invoices  (${mismatchedInvoices.outstandingXeroInvoices.length})`}
              key="outstandingXeroInvoices"
            >
              <XeroInvoicesTable
                xeroInvoices={mismatchedInvoices.outstandingXeroInvoices}
              />
            </TabPane>
            <TabPane
              tab={`Outstanding DB Invoices (${mismatchedInvoices.outstandingDbInvoices.length})`}
              key="outstandingDbInvoices"
            >
              <CustomerInvoicesTable
                customerInvoices={mismatchedInvoices.outstandingDbInvoices}
              />
            </TabPane>
          </Tabs>
        </Stack>
      </Page>
    </>
  );
}

import { Button } from "antd";
import { LineItem } from "../../generated-openapi-client/models/LineItem";

interface DeleteLineItemButtonProps {
  index: number;
  updateLineItems: (lineItems: LineItem[]) => void;
  lineItems: LineItem[];
}

export function DeleteLineItemButton(props: DeleteLineItemButtonProps) {
  const lineItems = props.lineItems!!;
  function onDelete() {
    const updated = lineItems.filter(function (li, index) {
      return index !== props.index;
    });

    props.updateLineItems(updated);
  }

  return (
    <>
      <Button onClick={onDelete} danger>
        Delete
      </Button>
    </>
  );
}

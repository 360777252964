import { Table, TablePaginationConfig } from "antd";
import { GetRowKey, TableRowSelection } from "antd/lib/table/interface";
import { CSSProperties, ReactNode, useState } from "react";
import { CSVLink } from "react-csv";
import { safeColumns } from "../Helpers/safeColumns";
import Spacer from "../Spacer";
import Colors from "./Colors";
import HorizontalStack from "./HorizontalStack";
import { ShowHideColumnsPopover } from "./ShowHideColumnsPopover";
import Stack from "./Stack";

export interface DataTableColumn<T> {
  key?: string;
  show?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  render: (item: T, index: number) => ReactNode;
  sorter?: (a: T, b: T) => number;
  width?: number;
  fixed?: "left" | "right";
}

export interface DataTableCsvColumn<T> {
  title: string;
  render: (item: T, index: number) => ReactNode;
}

export interface DataTableProps<T> {
  data: Array<T>;
  columns: Array<DataTableColumn<T>>;
  pagination?: false | TablePaginationConfig;
  csvColumns?: DataTableCsvColumn<T>[];
  rowKey?: string | GetRowKey<T>;
  rowSelection?: TableRowSelection<T>;
  scroll?: { x: string };
  style?: CSSProperties;
  showHeader?: boolean;
  showColumnPicker?: boolean;
}

type CsvRow = { [key: string]: any };

// If you want to show the column pickup, then mark at least one column with field 'show=false' and it will automatically appear
export function DataTable<T extends object>(props: DataTableProps<T>) {
  const [columns, setColumns] = useState<Array<DataTableColumn<T>>>(
    props.columns.map(function (c) {
      return {
        ...c,
        show: c.show === undefined ? true : c.show,
      };
    })
  );

  function showColumnPicker() {
    // You can either set it by setting a property to show it
    if (props.showColumnPicker === true) {
      return true;
    }

    if (props.showHeader)
      return props.columns.some(function (c) {
        return c.show === false;
      });
  }

  const transformCsvData = (
    data: Array<T>,
    columns: DataTableCsvColumn<T>[]
  ) => {
    return data.map((row) => {
      const newRow: CsvRow = {};
      columns.forEach((column, index) => {
        newRow[column.title] = column.render(row, index);
      });
      return newRow;
    });
  };

  return (
    <div style={{ marginTop: "-15px", width: "100%" }}>
      <HorizontalStack align="spread" width="100%" verticalAlign="middle">
        {showColumnPicker() && (
          <ShowHideColumnsPopover columns={columns} setColumns={setColumns}>
            Show/Hide Columns
            <span style={{ color: Colors.Gray[500], marginLeft: "8px" }}>
              ({columns.filter((c) => !c.show).length} hidden)
            </span>
          </ShowHideColumnsPopover>
        )}
      </HorizontalStack>
      <Stack align="left" width="100%">
        <Table
          style={{ width: "100%", ...props.style }}
          pagination={props.pagination}
          columns={safeColumns<T>(
            columns
              .filter((c) => (c.show === undefined ? true : c.show))
              .map(function (col) {
                return {
                  title: (
                    <Stack align="left">
                      <div>{col.title}</div>
                      {col.subtitle && (
                        <div
                          style={{ color: Colors.LightText, fontSize: "7px" }}
                        >
                          {col.subtitle}
                        </div>
                      )}
                    </Stack>
                  ),
                  dataIndex: "",
                  key: col.title as string,
                  render: (ignore, item, index) => col.render(item, index),
                  sorter: col.sorter,
                  defaultSortOrder: "descend",
                  sortDirections: ["descend", "ascend", "descend"],
                  width: col.width,
                  fixed: col.fixed,
                };
              })
          )}
          dataSource={props.data}
          rowKey={props.rowKey}
          rowSelection={props.rowSelection}
          scroll={props.scroll}
          showHeader={props.showHeader}
        />

        {props.csvColumns && (
          <HorizontalStack>
            <Spacer width={32} />
            <CSVLink
              data={transformCsvData(props.data, props.csvColumns)}
              headers={props.csvColumns.map((column) => ({
                label: column.title,
                key: column.title,
              }))}
            >
              Download CSV
            </CSVLink>
          </HorizontalStack>
        )}
      </Stack>
    </div>
  );
}

import { message, Switch } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  ShipmentState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { assertNever } from "../../Helpers/assertNever";
import { SupportAgent } from "../ViewCompanyScreen";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

interface CustomsDelayNotificastionSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function CustomsDelayNotificastionSupportEmailButton(
  props: CustomsDelayNotificastionSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const shipment = props.data.shipment.shipment;
  const shipmentState = shipment.state!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [alsoSendToBroker, setAlsoSendToBroker] = useState(true);

  function showButton() {
    switch (shipmentState) {
      case ShipmentState.BookingConfirmed:
        return true;
      case ShipmentState.BookingFailed:
        return false;
      case ShipmentState.BookingRequested:
        return false;
      case ShipmentState.Cancelled:
        return false;
      case ShipmentState.Delivered:
        return false;
      case ShipmentState.InTransit:
        return true;
      case ShipmentState.QuoteRequested:
        return false;
      case ShipmentState.Quoted:
        return false;
      case ShipmentState.Lost:
        return false;
      case ShipmentState.OnHold:
        return false;
      default:
        assertNever(shipmentState);
    }
  }

  if (!showButton()) {
    return <></>;
  }

  const hash = JSON.stringify({
    shipmentId,
    alsoSendToBroker,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewCustomsDelayNotificationEmail({
        shipmentId,
        alsoSendToBroker,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    if (attachmentDocumentIds.length === 0) {
      message.warn(
        `No attachments. Please attach bill of lading and customs documents`
      );
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendCustomsDelayNotificationEmail({
        shipmentId,
        alsoSendToBroker,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Customs Delay Notification"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <SupportEmailBodyExtraItem label="Also send to broker">
            <Switch checked={alsoSendToBroker} onChange={setAlsoSendToBroker} />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}

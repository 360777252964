import { CustomerInvoiceState } from "../../generated-openapi-client";
import { assertNever } from "../../Helpers/assertNever";
import { OptionalEnumDropdown } from "./EnumDropdown";

export const CustomerInvoiceStateDropdown = OptionalEnumDropdown<
  CustomerInvoiceState,
  typeof CustomerInvoiceState
>(CustomerInvoiceState, "Do not Filter", function (et) {
  switch (et) {
    case CustomerInvoiceState.Deleted:
      return "Deleted";
    case CustomerInvoiceState.Issued:
      return "Issued";
    case CustomerInvoiceState.Reconciled:
      return "Reconciled";
    case CustomerInvoiceState.Settled:
      return "Settled";
    case CustomerInvoiceState.SettlementPending:
      return "Settlement Pending";
    case CustomerInvoiceState.Voided:
      return "Voided";
    default:
      assertNever(et);
  }
});

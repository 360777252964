import { Button, Modal } from "antd";
import { useState } from "react";
import { CarrierInvoice } from "../../generated-openapi-client";
import { TabProps } from "./TabProps";
import { ViewCarrierInvoiceComponent } from "./ViewCarrierInvoiceComponent";

interface QuickViewCarrierInvoiceButtonProps extends TabProps {
  carrierInvoice: CarrierInvoice | undefined;
}

export function QuickViewCarrierInvoiceButton(
  props: QuickViewCarrierInvoiceButtonProps
) {
  const title = `Quick View Carrier Invoice`;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.carrierInvoice === undefined) {
    return <></>;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1600}
      >
        <ViewCarrierInvoiceComponent
          {...props}
          carrierInvoice={props.carrierInvoice}
        />
      </Modal>
      <Button onClick={showModal}>{title}</Button>
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface DeleteFreightClaimRequest {
    claimId: string;
    reason: string;
    keepDocuments: boolean;
}

export interface SetFreightClaimAssigneeRequest {
    claimId: string;
    assignee: string;
}

/**
 * 
 */
export class FreightClaimsApi extends runtime.BaseAPI {

    /**
     */
    async deleteFreightClaimRaw(requestParameters: DeleteFreightClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['claimId'] == null) {
            throw new runtime.RequiredError(
                'claimId',
                'Required parameter "claimId" was null or undefined when calling deleteFreightClaim().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling deleteFreightClaim().'
            );
        }

        if (requestParameters['keepDocuments'] == null) {
            throw new runtime.RequiredError(
                'keepDocuments',
                'Required parameter "keepDocuments" was null or undefined when calling deleteFreightClaim().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['claimId'] != null) {
            queryParameters['claimId'] = requestParameters['claimId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        if (requestParameters['keepDocuments'] != null) {
            queryParameters['keepDocuments'] = requestParameters['keepDocuments'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/freight-claims/delete-freight-claim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteFreightClaim(requestParameters: DeleteFreightClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFreightClaimRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setFreightClaimAssigneeRaw(requestParameters: SetFreightClaimAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['claimId'] == null) {
            throw new runtime.RequiredError(
                'claimId',
                'Required parameter "claimId" was null or undefined when calling setFreightClaimAssignee().'
            );
        }

        if (requestParameters['assignee'] == null) {
            throw new runtime.RequiredError(
                'assignee',
                'Required parameter "assignee" was null or undefined when calling setFreightClaimAssignee().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['claimId'] != null) {
            queryParameters['claimId'] = requestParameters['claimId'];
        }

        if (requestParameters['assignee'] != null) {
            queryParameters['assignee'] = requestParameters['assignee'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/freight-claims/set-freight-claim-assignee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setFreightClaimAssignee(requestParameters: SetFreightClaimAssigneeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setFreightClaimAssigneeRaw(requestParameters, initOverrides);
    }

}

import { Select, Spin } from "antd";
import { useState } from "react";
import { useMapsApi } from "../../Apis/Apis";
import HorizontalStack from "../../Components/HorizontalStack";
import { ApolloMapCompany } from "../../generated-openapi-client/models/ApolloMapCompany";
import { useOnce } from "../../Hooks/useOnce";

const { Option } = Select;

interface CompaniesDropdownProps {
  companyId: string | undefined | null;
  setCompanyId: (_: string | undefined) => void;
}

export function CompaniesDropdown(props: CompaniesDropdownProps) {
  const createMapsApi = useMapsApi();
  const [companies, setCompanies] = useState<ApolloMapCompany[] | undefined>();

  useOnce(async function () {
    const mapsApi = await createMapsApi();
    const response = await mapsApi.getCompanies();
    setCompanies(response);
  });

  if (companies === undefined) {
    return <Spin size="small" />;
  }

  return (
    <Select
      allowClear={true}
      showSearch
      style={{ width: 300 }}
      value={props.companyId}
      placeholder="Company"
      optionFilterProp="children"
      onSelect={function (_companyId: string) {
        props.setCompanyId(_companyId);
      }}
      onClear={function () {
        props.setCompanyId(undefined);
      }}
      filterOption={(input, option) =>
        // @ts-ignore
        option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        // @ts-ignore
        optionA.value
          // @ts-ignore
          .toLowerCase()
          // @ts-ignore
          .localeCompare(optionB.value.toLowerCase())
      }
    >
      {companies.map(function (c) {
        return (
          <Option value={c.companyId}>
            <HorizontalStack verticalAlign="middle" align="left">
              <div>{c.companyName}</div>
            </HorizontalStack>
          </Option>
        );
      })}
    </Select>
  );
}

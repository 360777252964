import { Button, Menu, MenuItemProps, message, Tooltip } from "antd";
import { TabProps } from "./TabProps";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import { useShipmentsApi } from "../../Apis/Apis";
import { ReactNode, useState } from "react";
import {
  CustomsDocsState,
  ShipmentState,
} from "../../generated-openapi-client";

export function useChangePauseCustomsDocsReminders(props: TabProps) {
  const createShipmentApi = useShipmentsApi();
  const [isLoading, setIsLoading] = useState(false);

  const shipment = props.shipmentData.shipment.shipment;

  const pause = !!shipment.pauseCustomsDocsReminders;

  const allowOption =
    shipment.customsDocsState === CustomsDocsState.Missing &&
    (shipment.state === ShipmentState.BookingConfirmed ||
      shipment.state === ShipmentState.InTransit);

  async function onClick() {
    if (!shipment.shipmentId) {
      message.error("Oops. Something went wrong!");
      return;
    }
    try {
      setIsLoading(() => true);
      const shipmentApi = await createShipmentApi();
      await shipmentApi.changePauseCustomsDocsReminder({
        shipmentId: shipment.shipmentId,
        pause: !pause,
      });
      await props.onRefresh();
      message.success(
        `${pause ? "Paused" : "Activated"} Customs Docs Reminders`
      );
      setIsLoading(() => false);
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
      setIsLoading(() => false);
    }
  }

  return { shipment, pause, onClick, isLoading, allowOption };
}

export function PauseCustomsDocsRemindersMenuItem(
  props: TabProps & MenuItemProps
) {
  const { onClick, pause, allowOption } =
    useChangePauseCustomsDocsReminders(props);

  if (!allowOption) {
    return null;
  }

  return (
    <PauseCustomsDocsRemindersTooltip pause={pause}>
      <Menu.Item
        onClick={onClick}
        icon={pause ? <BellFilled /> : <BellOutlined />}
        {...props}
      >
        {pause ? "Pause" : "Activate"} Customs Documents Reminders
      </Menu.Item>
    </PauseCustomsDocsRemindersTooltip>
  );
}

export function PauseCustomsDocsRemindersButton(props: TabProps) {
  const { onClick, pause, allowOption } =
    useChangePauseCustomsDocsReminders(props);

  if (!allowOption) {
    return null;
  }
  return (
    <PauseCustomsDocsRemindersTooltip pause={pause}>
      <Button
        onClick={onClick}
        icon={pause ? <BellFilled /> : <BellOutlined />}
      >
        {pause ? "Pause" : "Activate"} Customs Documents Reminders
      </Button>
    </PauseCustomsDocsRemindersTooltip>
  );
}

interface PauseCustomsDocsRemindersTooltipProps {
  pause: boolean;
  children: ReactNode;
}
function PauseCustomsDocsRemindersTooltip(
  props: PauseCustomsDocsRemindersTooltipProps
) {
  const { pause, children } = props;

  return (
    <>
      <Tooltip
        title={
          pause ? (
            <>
              Currently active.
              <br /> Pause automatic emails requesting customs documents from
              the customer.
            </>
          ) : (
            <>
              Currently paused.
              <br /> Activate automatic emails requesting customs documents from
              the customer.
            </>
          )
        }
      >
        {children}
      </Tooltip>
    </>
  );
}

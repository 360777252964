import { Button, Form, Input, message, Modal } from "antd";
import { useState } from "react";
import { useTechnicalApi } from "../Apis/Apis";
import { isBlank } from "../Helpers/isNotBlank";

interface AddEmailTemplateImageButtonProps {
  onRefresh: () => Promise<void>;
}

export function AddEmailTemplateImageButton(
  props: AddEmailTemplateImageButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const createTechnicalApi = useTechnicalApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (isBlank(url) || isBlank(title)) {
      message.warn("Missing");
      return;
    }

    if (!url.startsWith("https://")) {
      message.warn("Url should start with https://");
    }

    setLoading(true);
    try {
      const technicalApi = await createTechnicalApi();
      await technicalApi.insertEmailTemplateImage({
        title,
        url,
      });
      await props.onRefresh();
      message.success(`Successfully added!`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
        confirmLoading={loading}
        okText="Save"
      >
        <div style={{ marginBottom: "8px" }}>
          To add images, go to WebFlow and upload the images to the library.
          Then get the url from WebFlow
        </div>
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 8, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Title">
            <Input
              value={title}
              onChange={function (e) {
                setTitle(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
          <Form.Item label="Url">
            <Input
              value={url}
              onChange={function (e) {
                setUrl(e.target.value);
              }}
              style={{ width: "600px" }}
            />
            {url && (
              <img
                src={url}
                style={{ marginTop: "32px", width: "400px" }}
                alt={title}
              />
            )}
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal}>Add Image</Button>
    </>
  );
}

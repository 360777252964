import { Alert, Button, Menu, message, Modal, Statistic, Tag } from "antd";
import { useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { ConfirmMenuItem } from "../../Components/ConfirmButton";
import { CopyMenuItem } from "../../Components/CopyMenuItem";
import { CustomerInvoiceStateTag } from "../../Components/CustomerInvoiceStateTag";
import HorizontalStack from "../../Components/HorizontalStack";
import { Pdf } from "../../Components/Pdf";
import Stack from "../../Components/Stack";
import { StatisticText } from "../../Components/StatisticText";
import { ViewRawButton } from "../../Components/ViewRawButton";
import {
  CustomerInvoice,
  CustomerInvoiceState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { CustomerInvoiceType } from "../../generated-openapi-client/models/CustomerInvoiceType";
import { assertNever } from "../../Helpers/assertNever";
import { isCustomerInvoiceOverdue } from "../../Helpers/isCustomerInvoiceOverdue";
import { formatAsCurrency } from "../../Helpers/numberFormatting";
import Spacer from "../../Spacer";
import { ChangeCustomerInvoiceAmountMenuItem } from "./ChangeCustomerInvoiceAmountMenuItem";
import { ChangeCustomerInvoiceDescriptionMenuItem } from "./ChangeCustomerInvoiceDescriptionMenuItem";
import { ChangeCustomerInvoiceDueDateMenuItem } from "./ChangeCustomerInvoiceDueDateMenuItem";
import { FailedPaymentSupportEmailButton } from "./FailedPaymentSupportEmailButton";
import { FullCustomerRefundButton } from "./FullCustomerRefundButton";
import { MarkCustomerInvoicePaymentPendingMenuItem } from "./MarkCustomerInvoicePaymentPendingMenuItem";
import { MarkCustomerInvoiceSettledMenuItem } from "./MarkCustomerInvoiceSettledMenuItem";
import { MarkIssuedSettlementPendingCustomerInvoiceMenuItem } from "./MarkIssuedSettlementPendingCustomerInvoiceMenuItem";
import { PartialCustomerRefundButton } from "./PartialCustomerRefundButton";
import { PayCustomerInvoiceButton } from "./PayCustomerInvoiceButton";
import { SetAutochargeFrequencyMenuItem } from "./SetAutochargeFrequencyMenuItem";
import { ToggleCustomerInvoiceDisputedButton } from "./ToggleCustomerInvoiceDisputedButton";
import { ViewCustomerInvoiceInXeroMenuItem } from "./ViewCustomerInvoiceInXeroMenuItem";
import { VoidCustomerInvoiceButton } from "./VoidCustomerInvoiceButton";
import { VoidPaymentPendingCustomerInvoiceMenuItem } from "./VoidPaymentPendingCustomerInvoiceMenuItem";

export function describeCustomerInvoiceType(
  customerInvoice: CustomerInvoice
): string {
  const invoiceType = customerInvoice.invoiceType!!;
  switch (invoiceType) {
    case CustomerInvoiceType.ShipmentCharge:
      return customerInvoice.disputed
        ? "Disputed Shipment Charge"
        : "Shipment Charge";
    case CustomerInvoiceType.AdditionalCharge:
      return customerInvoice.disputed
        ? "Disputed Additional Charge"
        : "Additional Charge";
    case CustomerInvoiceType.ShipmentRefund:
      return "Shipment Refund";
    case CustomerInvoiceType.AdditionalChargeRefund:
      return "Additional Charge Refund";
    default:
      assertNever(invoiceType);
  }
}

interface ViewCustomerInvoiceButtonProps {
  customerInvoice: CustomerInvoice;
  data: ViewShipmentDataForApollo;
  onRefresh: () => Promise<void>;
  onPatchShipmentData: (_: ViewShipmentDataForApollo) => void;
}

interface RegenerateInvoicePdfMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

function RegenerateInvoicePdfMenuItem(
  props: RegenerateInvoicePdfMenuItemProps
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function regenerateInvoicePdf() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
    try {
      await customerInvoiceApi.generateAndStoreInvoicePdf({
        customerInvoiceId,
      });
      await props.onRefresh();
      message.success("Regenerated");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={regenerateInvoicePdf}>
      <Tag color="red">Advanced</Tag> Regenerate Invoice PDF
    </Menu.Item>
  );
}

interface CreateXeroInvoiceMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

function CreateXeroInvoiceMenuItem(props: CreateXeroInvoiceMenuItemProps) {
  const disabled = props.customerInvoice.xeroInvoiceId !== undefined;
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function createXeroInvoice() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
    try {
      await customerInvoiceApi.createXeroInvoice({
        customerInvoiceId,
      });
      await props.onRefresh();
      message.success("Created");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <Menu.Item onClick={createXeroInvoice} disabled={disabled}>
      <Tag color="red">Advanced</Tag> Create Xero Invoice
    </Menu.Item>
  );
}

interface SendCustomerInvoiceNotificationMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

function SendCustomerInvoiceCreatedNotificationMenuItem(
  props: SendCustomerInvoiceNotificationMenuItemProps
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function sendNotification() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
    try {
      await customerInvoiceApi.sendInvoiceCreatedNotification({
        customerInvoiceId,
      });
      await props.onRefresh();
      message.success("Email sent");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <ConfirmMenuItem
      question="Are you sure you want to send this notification?"
      okText={"Yes, Send!"}
      tooltip={"This will send an invoice notification"}
      onConfirm={sendNotification}
    >
      <Tag color="orange">For Testing</Tag> Send Invoice Created Notification
      Email
    </ConfirmMenuItem>
  );
}

interface SendCustomerInvoiceNotificationMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

function SendCustomerOverdueInvoiceNotificationMenuItem(
  props: SendCustomerInvoiceNotificationMenuItemProps
) {
  const customerInvoice = props.customerInvoice;
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  if (!isCustomerInvoiceOverdue(customerInvoice)) {
    return <></>;
  }

  async function sendNotification() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
    try {
      await customerInvoiceApi.sendOverdueInvoiceNotification({
        customerInvoiceId,
      });
      await props.onRefresh();
      message.success("Email sent");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  return (
    <ConfirmMenuItem
      question="Are you sure you want to send this notification?"
      okText={"Yes, Send!"}
      tooltip={"This will send an overdue invoice notification"}
      onConfirm={sendNotification}
    >
      <Tag color="red">Advanced</Tag> Send Overdue Invoice Notification Email
    </ConfirmMenuItem>
  );
}

interface DeleteVoidedCustomerInvoiceMenuItemProps {
  customerInvoice: CustomerInvoice;
  onRefresh: () => Promise<void>;
}

function DeleteVoidedCustomerInvoiceMenuItem(
  props: DeleteVoidedCustomerInvoiceMenuItemProps
) {
  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  async function deleteCustomerInvoice() {
    const customerInvoiceApi = await createCustomerInvoiceApi();
    const customerInvoiceId = props.customerInvoice.customerInvoiceId!!;
    try {
      await customerInvoiceApi.deleteVoidedCustomerInvoice({
        customerInvoiceId,
      });
      await props.onRefresh();
      message.success("Deleted");
    } catch (e) {
      message.error(`Oops. Something went wrong : ${e}`);
    }
  }

  if (
    props.customerInvoice.customerInvoiceState !== CustomerInvoiceState.Voided
  ) {
    return <></>;
  }

  return (
    <ConfirmMenuItem
      onConfirm={deleteCustomerInvoice}
      question="Are you sure you want to delete the voided customer invoice. Only do this if it was created as a mistake."
      okText={"Yes, delete"}
      tooltip={"If we created this by mistake, we can delete it"}
    >
      <Tag color="red">Advanced</Tag> Delete Customer Invoice
    </ConfirmMenuItem>
  );
}

export function ViewCustomerInvoiceButton(
  props: ViewCustomerInvoiceButtonProps
) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const invoiceIdentifier = props.customerInvoice.invoiceIdentifier;
  const filename = `invoice-${invoiceIdentifier}.pdf`;
  const stripeUrl = `https://dashboard.stripe.com/payments/${props.customerInvoice.stripePaymentIntent}`;
  const disableStripe =
    props.customerInvoice.stripePaymentIntent === undefined ||
    props.customerInvoice.stripePaymentIntent === "";

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Customer Invoice"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
      >
        <HorizontalStack>
          <StatisticText
            title="Type"
            value={describeCustomerInvoiceType(props.customerInvoice)}
            style={{ marginRight: "16px" }}
          />
          <StatisticText
            title="State"
            value={
              <CustomerInvoiceStateTag
                customerInvoice={props.customerInvoice}
              />
            }
          />
          <Statistic
            title="Amount"
            value={formatAsCurrency(props.customerInvoice.amount)}
            suffix={props.customerInvoice.currency}
            style={{
              margin: "0 32px",
            }}
          />
          <StatisticText
            title="Payment Method"
            value={props.customerInvoice.paymentMethod}
          />
          <Statistic
            title="Payment Received Amount"
            value={formatAsCurrency(
              props.customerInvoice.paymentReceivedAmount
            )}
            suffix={props.customerInvoice.currency}
            style={{
              margin: "0 32px",
            }}
          />
          <Statistic
            title="Identifier"
            value={props.customerInvoice.invoiceIdentifier}
            style={{
              margin: "0 32px",
            }}
          />
        </HorizontalStack>

        <Spacer height={16} />

        {props.customerInvoice.lastPaymentFailureMessage && (
          <>
            <Alert
              message={
                <Stack align="left">
                  <strong>Last payment attempt failed :</strong>
                  <div>{props.customerInvoice.lastPaymentFailureMessage}</div>
                  <div>
                    The last method used was{" "}
                    {props.customerInvoice.lastPaymentFailurePaymentMethod},{" "}
                    {props.customerInvoice.lastPaymentFailureCardholderName},{" "}
                    {props.customerInvoice.lastPaymentFailurePaymentMethodBrand}
                    , Ending{" "}
                    {props.customerInvoice.lastPaymentFailureLastFourDigits}
                  </div>
                </Stack>
              }
              type="error"
            />
            <Spacer height={16} />
          </>
        )}

        <ButtonRow
          extrasMenu={
            <Menu>
              <CopyMenuItem
                title="Copy customerInvoiceId"
                value={props.customerInvoice.customerInvoiceId!!}
              />
              <ViewCustomerInvoiceInXeroMenuItem
                customerInvoice={props.customerInvoice}
              />
              <Menu.Divider />
              <ChangeCustomerInvoiceDueDateMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <ChangeCustomerInvoiceAmountMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <ChangeCustomerInvoiceDescriptionMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <MarkCustomerInvoicePaymentPendingMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <MarkCustomerInvoiceSettledMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <VoidPaymentPendingCustomerInvoiceMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <MarkIssuedSettlementPendingCustomerInvoiceMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
              <Menu.Divider />
              <RegenerateInvoicePdfMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
              />
              <CreateXeroInvoiceMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
              />
              <SendCustomerInvoiceCreatedNotificationMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
              />
              <SendCustomerOverdueInvoiceNotificationMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
              />
              <DeleteVoidedCustomerInvoiceMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
              />
              <SetAutochargeFrequencyMenuItem
                customerInvoice={props.customerInvoice}
                onRefresh={props.onRefresh}
                onCloseParent={handleCancel}
              />
            </Menu>
          }
        >
          <PayCustomerInvoiceButton
            customerInvoice={props.customerInvoice}
            onRefresh={props.onRefresh}
          />
          <FullCustomerRefundButton
            customerInvoice={props.customerInvoice}
            onRefresh={props.onRefresh}
          />
          <PartialCustomerRefundButton
            customerInvoice={props.customerInvoice}
            shipmentData={props.data}
            onRefresh={props.onRefresh}
            onPatchShipmentData={props.onPatchShipmentData}
          />
          <VoidCustomerInvoiceButton
            customerInvoice={props.customerInvoice}
            onRefresh={props.onRefresh}
          />
          <Button disabled={disableStripe} href={stripeUrl} target="_new">
            💵 View in Stripe
          </Button>
          <ViewRawButton data={props.customerInvoice} />
          {/* <DeleteCustomerInvoiceButton
            customerInvoice={props.customerInvoice}
            onRefresh={props.onRefresh}
          /> */}
          <FailedPaymentSupportEmailButton
            data={props.data}
            customerInvoice={props.customerInvoice}
          />
          <ToggleCustomerInvoiceDisputedButton
            customerInvoice={props.customerInvoice}
            onRefresh={props.onRefresh}
          />
        </ButtonRow>

        <Spacer height={32} />
        <Pdf
          filename={filename}
          documentS3Path={props.customerInvoice.invoiceS3Key!!}
          companyId={props.data.shipment.company.companyId}
          shipmentId={props.data.shipmentId}
          onRefresh={props.onRefresh}
        />
      </Modal>
      <Button onClick={showModal}>View</Button>
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvoiceAuditProblemType } from './InvoiceAuditProblemType';
import {
    InvoiceAuditProblemTypeFromJSON,
    InvoiceAuditProblemTypeFromJSONTyped,
    InvoiceAuditProblemTypeToJSON,
} from './InvoiceAuditProblemType';

/**
 * 
 * @export
 * @interface InvoiceAuditProblemNote
 */
export interface InvoiceAuditProblemNote {
    /**
     * 
     * @type {InvoiceAuditProblemType}
     * @memberof InvoiceAuditProblemNote
     */
    invoiceAuditProblemType?: InvoiceAuditProblemType;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditProblemNote
     */
    notes?: string;
}

/**
 * Check if a given object implements the InvoiceAuditProblemNote interface.
 */
export function instanceOfInvoiceAuditProblemNote(value: object): boolean {
    return true;
}

export function InvoiceAuditProblemNoteFromJSON(json: any): InvoiceAuditProblemNote {
    return InvoiceAuditProblemNoteFromJSONTyped(json, false);
}

export function InvoiceAuditProblemNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditProblemNote {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceAuditProblemType': json['invoiceAuditProblemType'] == null ? undefined : InvoiceAuditProblemTypeFromJSON(json['invoiceAuditProblemType']),
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function InvoiceAuditProblemNoteToJSON(value?: InvoiceAuditProblemNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceAuditProblemType': InvoiceAuditProblemTypeToJSON(value['invoiceAuditProblemType']),
        'notes': value['notes'],
    };
}


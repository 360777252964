/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GenerateProfitLossReportFilterOptionsSettledStatus } from './GenerateProfitLossReportFilterOptionsSettledStatus';
import {
    GenerateProfitLossReportFilterOptionsSettledStatusFromJSON,
    GenerateProfitLossReportFilterOptionsSettledStatusFromJSONTyped,
    GenerateProfitLossReportFilterOptionsSettledStatusToJSON,
} from './GenerateProfitLossReportFilterOptionsSettledStatus';
import type { GeoFilter } from './GeoFilter';
import {
    GeoFilterFromJSON,
    GeoFilterFromJSONTyped,
    GeoFilterToJSON,
} from './GeoFilter';

/**
 * 
 * @export
 * @interface GenerateProfitLossReportFilterOptions
 */
export interface GenerateProfitLossReportFilterOptions {
    /**
     * 
     * @type {GenerateProfitLossReportFilterOptionsSettledStatus}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    settledStatus: GenerateProfitLossReportFilterOptionsSettledStatus;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    onlyIncludeLosses: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    leadSource?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    campaign?: string;
    /**
     * 
     * @type {GeoFilter}
     * @memberof GenerateProfitLossReportFilterOptions
     */
    geoFilter?: GeoFilter;
}

/**
 * Check if a given object implements the GenerateProfitLossReportFilterOptions interface.
 */
export function instanceOfGenerateProfitLossReportFilterOptions(value: object): boolean {
    if (!('settledStatus' in value)) return false;
    if (!('onlyIncludeLosses' in value)) return false;
    return true;
}

export function GenerateProfitLossReportFilterOptionsFromJSON(json: any): GenerateProfitLossReportFilterOptions {
    return GenerateProfitLossReportFilterOptionsFromJSONTyped(json, false);
}

export function GenerateProfitLossReportFilterOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateProfitLossReportFilterOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'settledStatus': GenerateProfitLossReportFilterOptionsSettledStatusFromJSON(json['settledStatus']),
        'onlyIncludeLosses': json['onlyIncludeLosses'],
        'leadSource': json['leadSource'] == null ? undefined : json['leadSource'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'startDate': json['startDate'] == null ? undefined : json['startDate'],
        'endDate': json['endDate'] == null ? undefined : json['endDate'],
        'campaign': json['campaign'] == null ? undefined : json['campaign'],
        'geoFilter': json['geoFilter'] == null ? undefined : GeoFilterFromJSON(json['geoFilter']),
    };
}

export function GenerateProfitLossReportFilterOptionsToJSON(value?: GenerateProfitLossReportFilterOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'settledStatus': GenerateProfitLossReportFilterOptionsSettledStatusToJSON(value['settledStatus']),
        'onlyIncludeLosses': value['onlyIncludeLosses'],
        'leadSource': value['leadSource'],
        'companyId': value['companyId'],
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
        'startDate': value['startDate'],
        'endDate': value['endDate'],
        'campaign': value['campaign'],
        'geoFilter': GeoFilterToJSON(value['geoFilter']),
    };
}


import { CloseOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Row, Tag, Tooltip, Typography } from "antd";
import { ReactNode } from "react";
import { LineItemsChangesTable } from "../../Components/changes/LineItemsChangesTable";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { KeyValues } from "../../Components/KeyValues";
import { Pdf } from "../../Components/Pdf";
import Stack from "../../Components/Stack";
import {
  CarrierInvoice,
  Location,
  Shipment,
} from "../../generated-openapi-client";
import { describeEquipmentType } from "../../Helpers/describeEquipmentType";
import { getQuoteShipmentKeyDifferences } from "../../Helpers/getQuoteShipmentKeyDifferences";
import { isBlank } from "../../Helpers/isNotBlank";
import { getMapUrlForAddress } from "../../Helpers/mapUtils";
import { ObjectDifference } from "../../Helpers/objectDifferences";
import { useDisplay } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";
import { IsolatedHTML } from "./IsolatedHtml";
import { describeAccessorials } from "./LocationInfo";
import { LineItemsSummary } from "./ManagePickupTab";
import { QuoteShipmentDifferencesModal } from "./QuoteShipmentDifferences";

import { WarningComponent } from "../../Components/WarningComponent";
import { CommonCarrierProblemsSection } from "./CommonCarrierProblemsSection";
import { CommonCompanyProblemsSection } from "./CommonCompanyProblemsSection";
import { TabProps } from "./TabProps";

const { Title } = Typography;

interface LocationInfo {
  location: Location;
  context: string;
  differences: ObjectDifference[];
}

function LocationOverview(props: LocationInfo) {

  const { address } = props.location;
  const cityStatePostalCode = `${address?.city}, ${address?.stateOrProvinceCode}, ${address?.postalCode}`;

  const locationDiff = ["city", "stateOrProvinceCode", "postalCode"]
    .map((i) => props.differences.find((d) => d.key === i))
    .filter((i) => !!i);

  const accessorialsDiff = props.differences.find(
    (d) => d.key === "accessorials"
  );

  const locationTypeDiff = props.differences.find(
    (d) => d.key === "locationType"
  );

  try {
    return (
      <KeyValues
        data={{
          [`${props.context} Location`]: (
            <>
              <DifferenceWarning
                isDifferent={locationDiff.length > 0}
                quoteValue={locationDiff.map((d) => d?.left).join(", ")}
              >
                <Stack align="left">
                  <div>{props.location.businessName}</div>
                  <div>{address?.addressLine}</div>
                  <div>{address?.addressLine2}</div>
                  <div>{cityStatePostalCode}</div>
                </Stack>
              </DifferenceWarning>
              <Spacer height={4} />
              <Button
                style={{ padding: 0 }}
                href={getMapUrlForAddress(address!)}
                type="link"
                target="_blank"
              >
                View Map
              </Button>
            </>
          ),
          [`${props.context} Accessorials`]: (
            <DifferenceWarning
              isDifferent={!!accessorialsDiff}
              quoteValue={describeAccessorials(accessorialsDiff?.left ?? [])}
            >
              {describeAccessorials(props.location.accessorials ?? [])}
            </DifferenceWarning>
          ),
          [`${props.context} Location Type`]: (
            <DifferenceWarning
              isDifferent={!!locationTypeDiff}
              quoteValue={locationTypeDiff?.left}
            >
              {props.location.locationType}
            </DifferenceWarning>
          ),
        }}
      />
    );
  } catch (e: any) {
    return <div>Oops. Somethign went wrong {e.toString()}</div>;
  }
}

function DifferenceWarning({
  isDifferent,
  quoteValue,
  children,
}: {
  isDifferent: boolean;
  quoteValue?: ReactNode;
  children: ReactNode;
}) {
  if (!isDifferent) {
    return <>{children}</>;
  }

  return (
    <HorizontalStack>
      {children}
      <Spacer width={16} />
      <Tooltip
        color={"white"}
        overlayInnerStyle={{ color: Colors.NormalText }}
        title={
          quoteValue !== undefined && (
            <div>
              <span
                style={{
                  color: Colors.LightText,
                  fontWeight: "400",
                  fontSize: "12px",
                  display: "block",
                }}
              >
                Quote
              </span>{" "}
              {quoteValue}
            </div>
          )
        }
        style={{ display: "flex" }}
      >
        <span>
          <WarningComponent />{" "}
        </span>
        <span style={{ color: Colors.LightText, fontSize: "12px" }}>
          Differs from quote
        </span>
      </Tooltip>
    </HorizontalStack>
  );
}

function GeneralInfoOverview({
  shipment,
  differences,
}: {
  shipment: Shipment;
  differences: ObjectDifference[];
}) {
  const [
    exclusiveDiff,
    equipmentDiff,
    addInsuranceDiff,
    insuranceAmountDiff,
    insuranceCurrencyDiff,
  ] = [
    "exclusiveUseNeeded",
    "equipmentType",
    "addInsuranceToShipment",
    "insuranceAmount",
    "insuranceCurrency",
  ].map((i) => differences.find((d) => d.key === i));

  return (
    <KeyValues
      data={{
        "Exclusive Use Needed": (
          <DifferenceWarning
            isDifferent={!!exclusiveDiff}
            quoteValue={exclusiveDiff?.left}
          >
            {shipment.exclusiveUseNeeded ? "Yes" : "No"}
          </DifferenceWarning>
        ),
        "Equipment Type": (
          <DifferenceWarning
            isDifferent={!!equipmentDiff}
            quoteValue={describeEquipmentType(equipmentDiff?.left)}
          >
            {describeEquipmentType(shipment.equipmentType)}
          </DifferenceWarning>
        ),
        "Add Insurance To Shipment": (
          <DifferenceWarning
            isDifferent={!!addInsuranceDiff}
            quoteValue={addInsuranceDiff?.left}
          >
            {shipment.addInsuranceToShipment ? "Yes" : "No"}
          </DifferenceWarning>
        ),
        "Insurance Amount": (
          <DifferenceWarning
            isDifferent={!!insuranceAmountDiff}
            quoteValue={insuranceAmountDiff?.left}
          >
            {shipment.insuranceAmount}
          </DifferenceWarning>
        ),
        "Insurance Currency": (
          <DifferenceWarning
            isDifferent={!!insuranceCurrencyDiff}
            quoteValue={insuranceCurrencyDiff?.left}
          >
            {shipment.insuranceCurrency}
          </DifferenceWarning>
        ),
      }}
    />
  );
}

interface ViewCarrierInvoiceComponentProps extends TabProps {
  carrierInvoice: CarrierInvoice;
}

function InvoiceWithProblems(props: ViewCarrierInvoiceComponentProps) {
  const quote = props.shipmentData.shipment.quote;
  const carrierIdentifier = quote.carrierIdentifier;
  const proNumber = props.shipmentData.shipment.shipment.proNumber;

  const filename = `${carrierIdentifier}-invoice-${proNumber}.pdf`;
  return (
    <div>
      <CommonCompanyProblemsSection {...props} />
      <CommonCarrierProblemsSection
        carrierIdentifier={props.carrierInvoice.carrierIdentifier!!}
      />

      <Title level={4}>Invoice Document</Title>
      <Pdf
        filename={filename}
        documentS3Path={props.carrierInvoice.s3Key!!}
        companyId={props.shipmentData.shipment.company.companyId}
        shipmentId={props.shipmentData.shipmentId}
        onRefresh={props.onRefresh}
        width={700}
      />
    </div>
  );
}

export function ViewCarrierInvoiceComponent(
  props: ViewCarrierInvoiceComponentProps
) {
  const quoteShipmentDifferences = getQuoteShipmentKeyDifferences(
    props.shipmentData.shipment.quote,
    props.shipmentData.shipment.shipment
  );

  const differencesDisplay = useDisplay();

  return (
    <>
      <Title level={4}>Shipment Overview</Title>
      <div style={{ border: "1px solid #ccc", padding: "16px" }}>
        {quoteShipmentDifferences.totalDifferencesCount > 0 && (
          <Alert
            type="warning"
            showIcon
            icon={
              <span>
                <WarningComponent />
              </span>
            }
            message={
              <span>
                <strong style={{ fontSize: "16px" }}>
                  {quoteShipmentDifferences.totalDifferencesCount}
                </strong>{" "}
                key difference
                {quoteShipmentDifferences.totalDifferencesCount > 1 && "s"}{" "}
                between the quote and the shipment.
                <Button onClick={differencesDisplay.show} type="link">
                  See differences
                </Button>
                <QuoteShipmentDifferencesModal
                  quote={props.shipmentData.shipment.quote}
                  shipment={props.shipmentData.shipment.shipment}
                  differences={quoteShipmentDifferences}
                  display={differencesDisplay}
                />
              </span>
            }
          />
        )}
        <Spacer height={12} />
        <Row justify="space-evenly" gutter={[12, 12]}>
          <Col flex={1}>
            <GeneralInfoOverview
              shipment={props.shipmentData.shipment.shipment}
              differences={quoteShipmentDifferences.generalDifferences}
            />
          </Col>
          <Col flex={1}>
            <LocationOverview
              location={props.shipmentData.shipment.shipment.pickupLocation!!}
              context="Pickup"
              differences={quoteShipmentDifferences.pickupDifferences}
            />
          </Col>
          <Col flex={1}>
            <LocationOverview
              location={props.shipmentData.shipment.shipment.deliveryLocation!!}
              context="Delivery"
              differences={quoteShipmentDifferences.deliveryDifferences}
            />
          </Col>
        </Row>
        <Spacer height={32} />
        <Stack align="left">
          <HorizontalStack width="100%" align="spread">
            <HorizontalStack
              style={{ columnGap: "8px" }}
              verticalAlign="middle"
            >
              <DifferenceWarning
                isDifferent={
                  quoteShipmentDifferences.lineItemsDifferencesCount > 0
                }
              >
                <Title level={4}>Line Items</Title>
              </DifferenceWarning>
              {quoteShipmentDifferences.lineItemsDifferencesCount > 0 && (
                <>
                  <Spacer width={8} />
                  <Tag color="orange">Quote</Tag>
                  <CloseOutlined />
                  <Tag color="green">Shipment</Tag>
                </>
              )}
            </HorizontalStack>
            <HorizontalStack>
              <LineItemsSummary
                shipment={props.shipmentData.shipment.shipment}
              />
              <Spacer width={16} />
            </HorizontalStack>
          </HorizontalStack>
          <LineItemsChangesTable
            inBetween={<CloseOutlined />}
            left={props.shipmentData.shipment.quote.lineItems!}
            right={props.shipmentData.shipment.shipment.lineItems!}
          />
        </Stack>
      </div>
      <Spacer height={32} />
      <>
        {props.shipmentData.shipment.quote.loadingDiagramSvg !== undefined && (
          <>
            <div>Loading Diagram:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: props.shipmentData.shipment.quote.loadingDiagramSvg,
              }}
            />
          </>
        )}
      </>
      {!isBlank(
        props.shipmentData.shipment.quote.carrierPriceExplanationInternal
      ) && (
        <HorizontalStack verticalAlign="top">
          <div>
            <Title level={4}>Carrier Quote Explanation (Internal)</Title>
            <IsolatedHTML
              html={
                props.shipmentData.shipment.quote
                  .carrierPriceExplanationInternal ?? ""
              }
            />
          </div>
          <InvoiceWithProblems {...props} />
        </HorizontalStack>
      )}
      {isBlank(
        props.shipmentData.shipment.quote.carrierPriceExplanationInternal
      ) && <InvoiceWithProblems {...props} />}
    </>
  );
}

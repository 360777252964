import {
  Form,
  Input,
  Menu,
  MenuItemProps,
  message,
  Modal,
  Typography,
} from "antd";
import { useState } from "react";
import { useCompaniesApi } from "../../Apis/Apis";
import Stack from "../../Components/Stack";
import Spacer from "../../Spacer";
import { CompanyTabProps } from "../ViewCompanyScreen";

const { Text } = Typography;

export function MoveAllShipmentToDifferentCompanyMenuItem(
  props: CompanyTabProps & MenuItemProps
) {
  const originalCompanyId = props.data.company.companyId!!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const createCompaniesApi = useCompaniesApi();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (!newCompanyId) {
      message.warn("Select a new company");
      return;
    }
    setLoading(true);
    try {
      const companiesApi = await createCompaniesApi();
      await companiesApi.moveAllShipmentToAnotherCompany({
        originalCompanyId,
        newCompanyId,
      });
      await props.onRefresh();
      message.success(`Successfully moved!`);
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops. Something went wrong ${e}`);
    }

    setLoading(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Move all shipments to a different company"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        confirmLoading={loading}
        width={1200}
      >
        <div>
          This is useful if we are trying to merge companies together, eg. if a
          new company was created by mistake
        </div>
        <Spacer height={32} />
        <Form
          style={{ width: "800px" }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Stack align="left">
            <Text>
              Enter the UUID of the company you want to move this used to. A
              UUID looks like
            </Text>
            <Text style={{ color: "#888" }}>
              d62a64e3-ba8f-4118-8458-c03753936350
            </Text>
          </Stack>
          <Spacer height={32} />
          <Form.Item label="New Company UUID">
            <Input
              value={newCompanyId}
              onChange={function (e) {
                setNewCompanyId(e.target.value);
              }}
              style={{ width: "600px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Menu.Item onClick={showModal} {...props}>
        Move all shipment to a different company
      </Menu.Item>
    </>
  );
}

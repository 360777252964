import { LRUCache } from "lru-cache";
import { useEffect, useState } from "react";
import { useDocumentsApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import { ShipmentDocument } from "../../generated-openapi-client";

interface DocumentThumbnailProps {
  document: ShipmentDocument;
  onClick: () => void;
}

// In-memory LRU cache
// In-memory LRU cache
const memoryCache = new LRUCache<string, string>({
  max: 100, // Limit to 100 items
  maxSize: 5000000, // Limit total size to ~5MB (adjust as needed)
  sizeCalculation: (value, key) => value.length + key.length,
});

// Helper function to get from cache (memory or localStorage)
function getCachedThumbnail(key: string): string | null {
  const memoryItem = memoryCache.get(key);
  if (memoryItem) return memoryItem;

  const localStorageItem = localStorage.getItem(key);
  if (localStorageItem) {
    memoryCache.set(key, localStorageItem);
    return localStorageItem;
  }

  return null;
}

// Helper function to set cache (both memory and localStorage)
function setCachedThumbnail(key: string, value: string): void {
  memoryCache.set(key, value);
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // Handle localStorage quota exceeded
    console.warn("localStorage quota exceeded, clearing old items");
    for (let i = 0; i < localStorage.length && i < 10; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith("thumbnail_")) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem(key, value);
  }
}

export function DocumentThumbnail(props: DocumentThumbnailProps) {
  const document = props.document;
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const createDocumentsApi = useDocumentsApi();

  useEffect(() => {
    const fetchThumbnail = async () => {
      setIsLoading(true);
      const cacheKey = `thumbnail_${document.shipmentDocumentId}`;

      try {
        const cachedThumbnail = getCachedThumbnail(cacheKey);
        if (cachedThumbnail) {
          setThumbnailUrl(cachedThumbnail);
          setIsLoading(false);
          return;
        }

        const documentsApi = await createDocumentsApi();

        const response = await documentsApi.downloadThumbnailByS3Path({
          documentPath: `${props.document.shipmentDocumentId}.pdf`,
        });

        const thumbnailData = response.noImage
          ? "no-image"
          : `data:image/png;base64,${response.base64Image}`;

        // Cache the result
        setCachedThumbnail(cacheKey, thumbnailData);

        setThumbnailUrl(thumbnailData);
      } catch (err) {
        setError("Error loading thumbnail");
        console.error("Error fetching thumbnail:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchThumbnail();
  }, [document.shipmentDocumentId]);

  if (isLoading) {
    return (
      <div style={{ fontSize: "12px", color: Colors.LightText }}>Loading</div>
    );
  }

  if (error) {
    return (
      <div style={{ fontSize: "12px", color: Colors.LightText }}>{error}</div>
    );
  }

  if (thumbnailUrl) {
    if (thumbnailUrl === "no-image") {
      return <></>;
    }

    return (
      <img
        onClick={props.onClick}
        src={thumbnailUrl}
        style={{ border: "1px solid #ccc", width: "100px", cursor: "pointer" }}
        alt="thumbnail"
      />
    );
  }

  return <></>;
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShipmentReportQuoteSummary
 */
export interface ShipmentReportQuoteSummary {
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    serviceDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReportQuoteSummary
     */
    priceCad?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReportQuoteSummary
     */
    transitTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    transitWindowDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReportQuoteSummary
     */
    booked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReportQuoteSummary
     */
    carrierIntegrationFlagged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReportQuoteSummary
     */
    hiddenFromCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    quoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReportQuoteSummary
     */
    brokeredCarrierIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReportQuoteSummary
     */
    quotedPriceByCarrierWithoutTaxCad?: number;
}

/**
 * Check if a given object implements the ShipmentReportQuoteSummary interface.
 */
export function instanceOfShipmentReportQuoteSummary(value: object): boolean {
    return true;
}

export function ShipmentReportQuoteSummaryFromJSON(json: any): ShipmentReportQuoteSummary {
    return ShipmentReportQuoteSummaryFromJSONTyped(json, false);
}

export function ShipmentReportQuoteSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReportQuoteSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'serviceDescription': json['serviceDescription'] == null ? undefined : json['serviceDescription'],
        'priceCad': json['priceCad'] == null ? undefined : json['priceCad'],
        'transitTime': json['transitTime'] == null ? undefined : json['transitTime'],
        'transitWindowDescription': json['transitWindowDescription'] == null ? undefined : json['transitWindowDescription'],
        'booked': json['booked'] == null ? undefined : json['booked'],
        'carrierIntegrationFlagged': json['carrierIntegrationFlagged'] == null ? undefined : json['carrierIntegrationFlagged'],
        'hiddenFromCustomer': json['hiddenFromCustomer'] == null ? undefined : json['hiddenFromCustomer'],
        'quoteId': json['quoteId'] == null ? undefined : json['quoteId'],
        'brokeredCarrierIdentifier': json['brokeredCarrierIdentifier'] == null ? undefined : json['brokeredCarrierIdentifier'],
        'quotedPriceByCarrierWithoutTaxCad': json['quotedPriceByCarrierWithoutTaxCad'] == null ? undefined : json['quotedPriceByCarrierWithoutTaxCad'],
    };
}

export function ShipmentReportQuoteSummaryToJSON(value?: ShipmentReportQuoteSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceIdentifier': value['serviceIdentifier'],
        'serviceDescription': value['serviceDescription'],
        'priceCad': value['priceCad'],
        'transitTime': value['transitTime'],
        'transitWindowDescription': value['transitWindowDescription'],
        'booked': value['booked'],
        'carrierIntegrationFlagged': value['carrierIntegrationFlagged'],
        'hiddenFromCustomer': value['hiddenFromCustomer'],
        'quoteId': value['quoteId'],
        'brokeredCarrierIdentifier': value['brokeredCarrierIdentifier'],
        'quotedPriceByCarrierWithoutTaxCad': value['quotedPriceByCarrierWithoutTaxCad'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvoiceAuditCarrierNote,
  InvoiceAuditProblemNote,
  InvoiceAuditProblemType,
  OpenAuditLine,
} from '../models/index';
import {
    InvoiceAuditCarrierNoteFromJSON,
    InvoiceAuditCarrierNoteToJSON,
    InvoiceAuditProblemNoteFromJSON,
    InvoiceAuditProblemNoteToJSON,
    InvoiceAuditProblemTypeFromJSON,
    InvoiceAuditProblemTypeToJSON,
    OpenAuditLineFromJSON,
    OpenAuditLineToJSON,
} from '../models/index';

export interface SetNoteForCarrierIdentifierRequest {
    carrierIdentifier: string;
    notes: string;
}

export interface SetNoteForInvoiceAuditProblemTypeRequest {
    invoiceAuditProblemType: InvoiceAuditProblemType;
    notes: string;
}

/**
 * 
 */
export class CarrierInvoiceAuditApi extends runtime.BaseAPI {

    /**
     */
    async getAllInvoiceCarrierNotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvoiceAuditCarrierNote>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice-audit/get-all-invoice-carrier-notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceAuditCarrierNoteFromJSON));
    }

    /**
     */
    async getAllInvoiceCarrierNotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvoiceAuditCarrierNote>> {
        const response = await this.getAllInvoiceCarrierNotesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllInvoiceProblemNotesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvoiceAuditProblemNote>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice-audit/get-all-invoice-problem-notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceAuditProblemNoteFromJSON));
    }

    /**
     */
    async getAllInvoiceProblemNotes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvoiceAuditProblemNote>> {
        const response = await this.getAllInvoiceProblemNotesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async openInvoiceAuditsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OpenAuditLine>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice-audit/open-invoice-audits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OpenAuditLineFromJSON));
    }

    /**
     */
    async openInvoiceAudits(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OpenAuditLine>> {
        const response = await this.openInvoiceAuditsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setNoteForCarrierIdentifierRaw(requestParameters: SetNoteForCarrierIdentifierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['carrierIdentifier'] == null) {
            throw new runtime.RequiredError(
                'carrierIdentifier',
                'Required parameter "carrierIdentifier" was null or undefined when calling setNoteForCarrierIdentifier().'
            );
        }

        if (requestParameters['notes'] == null) {
            throw new runtime.RequiredError(
                'notes',
                'Required parameter "notes" was null or undefined when calling setNoteForCarrierIdentifier().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['carrierIdentifier'] != null) {
            queryParameters['carrierIdentifier'] = requestParameters['carrierIdentifier'];
        }

        if (requestParameters['notes'] != null) {
            queryParameters['notes'] = requestParameters['notes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice-audit/set-note-for-carrier-identifier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setNoteForCarrierIdentifier(requestParameters: SetNoteForCarrierIdentifierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setNoteForCarrierIdentifierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setNoteForInvoiceAuditProblemTypeRaw(requestParameters: SetNoteForInvoiceAuditProblemTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['invoiceAuditProblemType'] == null) {
            throw new runtime.RequiredError(
                'invoiceAuditProblemType',
                'Required parameter "invoiceAuditProblemType" was null or undefined when calling setNoteForInvoiceAuditProblemType().'
            );
        }

        if (requestParameters['notes'] == null) {
            throw new runtime.RequiredError(
                'notes',
                'Required parameter "notes" was null or undefined when calling setNoteForInvoiceAuditProblemType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['invoiceAuditProblemType'] != null) {
            queryParameters['invoiceAuditProblemType'] = requestParameters['invoiceAuditProblemType'];
        }

        if (requestParameters['notes'] != null) {
            queryParameters['notes'] = requestParameters['notes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/apollo/carrier-invoice-audit/set-note-for-invoice-audit-problem-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setNoteForInvoiceAuditProblemType(requestParameters: SetNoteForInvoiceAuditProblemTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setNoteForInvoiceAuditProblemTypeRaw(requestParameters, initOverrides);
    }

}

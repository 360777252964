import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import showdown from "showdown";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { LinkButton } from "../../Components/LinkButton";
import Stack from "../../Components/Stack";
import { EmailTemplateImage } from "../../generated-openapi-client";
import { addEmailSignature } from "../../Helpers/addEmailSignature";
import Spacer from "../../Spacer";
import { InsertImageButton } from "./InsertImageButton";

interface EditSalesEmailBodyButtonProps {
  emailBody: string;
  setEmailBody: (_: string) => void;
  senderFirstName: string;
  senderLastName: string;
  senderPhoneNumber: string;
  emailTemplateImages: EmailTemplateImage[];
}

export function EditSalesEmailBodyButton(props: EditSalesEmailBodyButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const converter = new showdown.Converter();
  const [cursor, setCursor] = useState<number>(0);
  converter.setFlavor("github");
  const emailBody = props.emailBody;
  const setEmailBody = props.setEmailBody;

  const insertText = (textToInsert: string) => {
    const newText =
      emailBody.substring(0, cursor) +
      textToInsert +
      emailBody.substring(cursor);
    setEmailBody(newText);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderedEmailBody = addEmailSignature(
    converter.makeHtml(props.emailBody),
    props.senderFirstName,
    props.senderLastName,
    props.senderPhoneNumber,
    ""
  );

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={
          <Stack align="left">
            <div>Edit Email Body</div>
            <div style={{ color: Colors.LightText, fontSize: "12px" }}>
              Use markdown format to apply formatting to the email. You can
              learn about this here:{" "}
              <a
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                View Markdown Guide
              </a>
            </div>
          </Stack>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
      >
        <HorizontalStack style={{ minHeight: "700px" }} verticalAlign="top">
          <Stack align="left">
            <TextArea
              style={{ width: "570px", height: "700px" }}
              value={props.emailBody}
              onChange={function (e) {
                props.setEmailBody(e.target.value);
              }}
              onSelect={function (e) {
                setCursor(e.currentTarget.selectionStart);
              }}
              placeholder="Type something here!"
            ></TextArea>
            <InsertImageButton
              images={props.emailTemplateImages}
              onInsertText={(_text) => insertText(_text)}
            />
          </Stack>
          <Spacer width={32} />
          <div
            style={{
              border: "1px solid #eee",
              padding: "16px",
              width: "570px",
              height: "700px",
              overflow: "scroll",
            }}
          >
            <div
              title="Email Preview"
              dangerouslySetInnerHTML={{
                __html: renderedEmailBody,
              }}
            ></div>
          </div>
        </HorizontalStack>
      </Modal>
      <LinkButton style={{ fontSize: "11px" }} onClick={showModal}>
        Edit
      </LinkButton>
    </>
  );
}

import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { DateInput } from "../../Components/DateInput";
import {
  MissedPickupSupportEmailType,
  ShipmentState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { EnumDropdown } from "./EnumDropdown";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

export const MissedPickupSupportEmailTypeDropdown = EnumDropdown<
  MissedPickupSupportEmailType,
  typeof MissedPickupSupportEmailType
>(MissedPickupSupportEmailType);

interface MissedPickupSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function MissedPickupSupportEmailButton(
  props: MissedPickupSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");
  const [missedPickupSupportEmailType, setMissedPickupSupportEmailType] =
    useState<MissedPickupSupportEmailType>(
      MissedPickupSupportEmailType.NotReadyPickupRebooked
    );
  const [originalPickupDate, setOriginalPickupDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD")
  );
  const [newPickupDate, setNewPickupDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD")
  );

  const state = props.data.shipment.shipment.state;

  if (
    state !== ShipmentState.BookingConfirmed &&
    state !== ShipmentState.OnHold
  ) {
    return <></>;
  }

  const input = {
    extraText,
    originalPickupDate,
    newPickupDate,
    missedPickupSupportEmailType,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewMissedPickupEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      if (originalPickupDate === newPickupDate) {
        message.warn(
          "Original pickup date should not be the same as new pickup date"
        );
        return false;
      }

      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendMissedPickupEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Missed Pickup Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Original">
            <DateInput
              date={originalPickupDate}
              setDate={setOriginalPickupDate}
              compactErrorsAndWarnings
              doNotErrorOnPastDate
            />
          </SupportEmailBodyExtraItem>
          <SupportEmailBodyExtraItem label="New">
            <DateInput
              date={newPickupDate}
              setDate={setNewPickupDate}
              compactErrorsAndWarnings
              doNotErrorOnPastDate
            />
          </SupportEmailBodyExtraItem>
          <SupportEmailBodyExtraItem label="Type">
            <MissedPickupSupportEmailTypeDropdown
              value={missedPickupSupportEmailType}
              setValue={setMissedPickupSupportEmailType}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}

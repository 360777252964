import { Form, Input, message, Modal, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { useCustomerInvoiceApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import Colors from "../../Components/Colors";
import { CreatedAt } from "../../Components/CreatedAt";
import { CurrencyInput } from "../../Components/CurrencyInput";
import Stack from "../../Components/Stack";
import { WarningComponent } from "../../Components/WarningComponent";
import {
  AdditionalCharge,
  InvoiceAuditProblem,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceAuditProblemType,
  ShipmentDocumentVisibilty,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { isNotBlank } from "../../Helpers/isNotBlank";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import { DocumentType } from "..//../generated-openapi-client";
import { DocumentSourceTag } from "./DocumentSourceTag";
import { DocumentTypeTag } from "./DocumentTypeTag";
import { InvoiceAuditProblemsTable } from "./IdentifyInvoiceAuditProblemsSection";
import {
  describeInvoiceAuditProblemType,
  isInvoiceAuditProblemTypeAllowedForAdditionalCharge,
} from "./InvoiceAuditProblemTypeDescription";
import { TabProps } from "./TabProps";
import { ViewDocumentButton } from "./ViewDocumentButton";

const { Title, Text } = Typography;

interface DocumentsTableProps {
  data: ViewShipmentDataForApollo;
}

function DocumentsTable(props: DocumentsTableProps) {
  const relevantDocuments = props.data.documentsTab.documents.filter(
    (d) =>
      d.shipmentDocument.documentType === DocumentType.ProofOfDelivery ||
      d.shipmentDocument.documentType === DocumentType.CarrierWeightReport ||
      d.shipmentDocument.documentType === DocumentType.Unknown ||
      // Do any of the audit problems list this document as related to the problem?
      props.data.shipment.shipment.invoiceAuditProblems!!.some((p) =>
        p.customerVisibleDetail?.includes(
          d.shipmentDocument.shipmentDocumentId!!
        )
      )
  );

  return (
    <Table
      columns={safeColumns<ShipmentDocumentVisibilty>([
        {
          title: "Document Type",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <DocumentTypeTag documentType={o.shipmentDocument.documentType!!} />
          ),
        },
        {
          title: "Source",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <DocumentSourceTag source={o.shipmentDocument.source} />
          ),
        },
        {
          title: "Created At",
          render: (_, o: ShipmentDocumentVisibilty) => (
            <CreatedAt timestamp={o.shipmentDocument.createdAt} />
          ),
        },
        {
          title: "Actions",
          render: function (_, document: ShipmentDocumentVisibilty) {
            return (
              <ButtonRow>
                <ViewDocumentButton
                  document={document.shipmentDocument}
                  shipmentData={props.data}
                />
              </ButtonRow>
            );
          },
        },
      ])}
      dataSource={relevantDocuments}
      pagination={false}
    />
  );
}

interface ApproveAdditionalChargeComponentProps extends TabProps {
  additionalCharge: AdditionalCharge;
  isApproveModalVisible: boolean;
  setIsApproveModalVisible: (_: boolean) => void;
  showEmailModal: (_: string[]) => void;
}

export function ApproveAdditionalChargeComponent(
  props: ApproveAdditionalChargeComponentProps
) {
  const { isApproveModalVisible, setIsApproveModalVisible } = props;
  const [reason, setReason] = useState<string>("");
  const [amount, setAmount] = useState<number>(props.additionalCharge.amount!!);
  const [saving, setSaving] = useState(false);
  const [associatedProblemIds, setAssociatedProblemIds] = useState<string[]>(
    []
  );

  const createCustomerInvoiceApi = useCustomerInvoiceApi();

  function problemNotAllowedAsAdditionalChargeErrorMessage(
    problem: InvoiceAuditProblem
  ): string | undefined {
    if (problem.fault !== InvoiceAuditProblemFault.Customer) {
      return "Not a customer issue";
    }

    if (
      problem.resolutionState !== InvoiceAuditProblemResolutionState.Unresolved
    ) {
      return "Must be unresolved to be an additional charge";
    }

    if (!isInvoiceAuditProblemTypeAllowedForAdditionalCharge(problem.type!!)) {
      return "This problem type is not allowed to be an additional charge";
    }
  }

  function isAllowedCustomerProblemId(problemId: string): boolean {
    const problems =
      props.shipmentData.shipment.shipment.invoiceAuditProblems!!;
    const problem = problems.find(
      (p) => p.invoiceAuditProblemId === problemId
    )!!;
    return (
      problemNotAllowedAsAdditionalChargeErrorMessage(problem) === undefined
    );
  }

  function isCheckboxDisabled(problem: InvoiceAuditProblem): boolean {
    return (
      problemNotAllowedAsAdditionalChargeErrorMessage(problem) !== undefined
    );
  }

  function isCheckboxDisabledDescription(
    problem: InvoiceAuditProblem
  ): string | undefined {
    return problemNotAllowedAsAdditionalChargeErrorMessage(problem);
  }

  function defaultAssociatedProblemIds(): string[] {
    return props.shipmentData.shipment.shipment
      .invoiceAuditProblems!!.map((p) => p.invoiceAuditProblemId!!)
      .filter(isAllowedCustomerProblemId);
  }

  function defaultAdditionalChargeReason(_associatedProblemIds: string[]) {
    function generateAdditionalChargeDescription(
      o: InvoiceAuditProblem
    ): string {
      if (isNotBlank(o.customerVisibleDetail)) {
        if (o.type === InvoiceAuditProblemType.Other) {
          return o.customerVisibleDetail ?? "-";
        } else {
          return `${describeInvoiceAuditProblemType(o)} (${
            o.customerVisibleDetail
          })`;
        }
      } else {
        return describeInvoiceAuditProblemType(o);
      }
    }

    const parts = _associatedProblemIds.map(function (problemId) {
      const problems =
        props.shipmentData.shipment.shipment.invoiceAuditProblems!!;
      const problem = problems.find(
        (p) => p.invoiceAuditProblemId === problemId
      )!!;
      return generateAdditionalChargeDescription(problem);
    });

    return parts.join(", ");
  }

  const additionalChargeId = props.additionalCharge.additionalChargeId!!;

  useEffect(
    function () {
      if (isApproveModalVisible) {
        const problemIds = defaultAssociatedProblemIds();
        const _reason = defaultAdditionalChargeReason(problemIds);

        setAssociatedProblemIds(problemIds);
        setReason(_reason);
      }
    },
    [isApproveModalVisible]
  );

  useEffect(
    function () {
      if (isApproveModalVisible) {
        setReason(defaultAdditionalChargeReason(associatedProblemIds));
      }
    },
    [associatedProblemIds]
  );

  async function handleOk() {
    if (reason === "") {
      message.warn("No reason given. Please enter an explanation");
      return;
    }

    if (amount < 10.0) {
      message.warn("Amount too low");
      return;
    }

    if (associatedProblemIds.length === 0) {
      message.warn("No associated problems");
      return;
    }

    try {
      setSaving(true);
      const customerInvoiceApi = await createCustomerInvoiceApi();

      await customerInvoiceApi.approveAdditionalCharge({
        additionalChargeId,
        reason,
        amount,
        associatedProblemIds,
      });

      message.success("Approved");
      // Next send the email
      setIsApproveModalVisible(false);
      await props.showEmailModal(associatedProblemIds);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
    setSaving(false);
  }

  const handleCancel = () => {
    setIsApproveModalVisible(false);
  };

  function onSelectProblems(_associatedProblemIds: string[]) {
    setAssociatedProblemIds(
      _associatedProblemIds.filter(isAllowedCustomerProblemId)
    );
  }

  const noProblems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems!!.length === 0;

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="👍 Approve Additional Charge"
        visible={isApproveModalVisible}
        onOk={handleOk}
        okText={`Approve Additional Charge ($${amount} ${props.shipmentData
          .shipment.company.currency!!})`}
        onCancel={handleCancel}
        width={1400}
        confirmLoading={saving}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            This additional charge was proposed due to an invoice which was
            higher than we expected. If you 'Approve' the additional charge, it
            will create a customer invoice, you must then email the customer to
            let them know about this charge
          </Text>
          <Spacer height={32} />
          <Title level={4}>Relevant Documents</Title>
          <DocumentsTable data={props.shipmentData} />
          <Spacer height={32} />
          <Title level={4}>
            Select Relevant Customer Invoice Audit Problems
          </Title>
          <div>
            Select the unresolved customer problems that you want to associate
            with this additional charge
          </div>
          {noProblems && (
            <div
              style={{
                backgroundColor: "#fafafa",
                marginTop: "16px",
                border: "1px solid #ddd",
                width: "100%",
                padding: "24px",
              }}
            >
              <WarningComponent /> No problems identified
            </div>
          )}
          {!noProblems && (
            <InvoiceAuditProblemsTable
              selected={associatedProblemIds}
              onSelect={onSelectProblems}
              isCheckboxDisabled={isCheckboxDisabled}
              isCheckboxDisabledDescription={isCheckboxDisabledDescription}
              problems={
                props.shipmentData.shipment.shipment.invoiceAuditProblems!!
              }
              carrierIdentifier={
                props.shipmentData.shipment.quote.carrierIdentifier!!
              }
              {...props}
            />
          )}
          <Spacer height={32} />
          <Title level={4}>Additional Charge Details</Title>
          <Form
            style={{ width: "1200px" }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 15, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            <Form.Item
              label={
                <Stack align="right">
                  <div>Additional Charge Reason</div>
                  <div style={{ color: Colors.LightText, fontSize: "12px" }}>
                    Visible on Customer Invoice
                  </div>
                </Stack>
              }
            >
              <Input
                value={reason}
                onChange={function (e) {
                  setReason(e.target.value);
                }}
                width={1000}
              />
            </Form.Item>
            <Form.Item label="Amount">
              <CurrencyInput
                amount={amount}
                setAmount={setAmount}
                currency={props.shipmentData.shipment.company.currency!!}
              />
            </Form.Item>
          </Form>
        </Stack>
      </Modal>
    </>
  );
}

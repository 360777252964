/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { ShipmentReportInvoiceWarningState } from './ShipmentReportInvoiceWarningState';
import {
    ShipmentReportInvoiceWarningStateFromJSON,
    ShipmentReportInvoiceWarningStateFromJSONTyped,
    ShipmentReportInvoiceWarningStateToJSON,
} from './ShipmentReportInvoiceWarningState';
import type { ShipmentReportQuoteSummary } from './ShipmentReportQuoteSummary';
import {
    ShipmentReportQuoteSummaryFromJSON,
    ShipmentReportQuoteSummaryFromJSONTyped,
    ShipmentReportQuoteSummaryToJSON,
} from './ShipmentReportQuoteSummary';
import type { ShipmentReportSettlementStatus } from './ShipmentReportSettlementStatus';
import {
    ShipmentReportSettlementStatusFromJSON,
    ShipmentReportSettlementStatusFromJSONTyped,
    ShipmentReportSettlementStatusToJSON,
} from './ShipmentReportSettlementStatus';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface ShipmentReport
 */
export interface ShipmentReport {
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    shipmentHash?: string;
    /**
     * 
     * @type {ShipmentState}
     * @memberof ShipmentReport
     */
    state?: ShipmentState;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    booked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deletedDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    proNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    quotedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupDateDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    distanceMiles?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyLeadSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyUtmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyCreationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    numberOfShipmentsCompanyBooked?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyShipmentFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    numberCustomerInvoices?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    numberCarrierInvoices?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    numberCarrierCreditNotes?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    moneyInCad?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    moneyOutCad?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    originalQuote?: number;
    /**
     * 
     * @type {ShipmentReportSettlementStatus}
     * @memberof ShipmentReport
     */
    settlementStatus?: ShipmentReportSettlementStatus;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    profit?: number;
    /**
     * 
     * @type {ShipmentReportInvoiceWarningState}
     * @memberof ShipmentReport
     */
    invoiceWarningState?: ShipmentReportInvoiceWarningState;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupCity?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupState?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupCityState?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof ShipmentReport
     */
    pickupCountry?: CountryCode;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    pickupLat?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    pickupLng?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryCityState?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryState?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof ShipmentReport
     */
    deliveryCountry?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryCity?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    deliveryLat?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    deliveryLng?: number;
    /**
     * 
     * @type {Array<ShipmentReportQuoteSummary>}
     * @memberof ShipmentReport
     */
    quotes?: Array<ShipmentReportQuoteSummary>;
    /**
     * 
     * @type {ShipmentReportQuoteSummary}
     * @memberof ShipmentReport
     */
    fastestQuote?: ShipmentReportQuoteSummary;
    /**
     * 
     * @type {ShipmentReportQuoteSummary}
     * @memberof ShipmentReport
     */
    lowestQuote?: ShipmentReportQuoteSummary;
    /**
     * 
     * @type {ShipmentReportQuoteSummary}
     * @memberof ShipmentReport
     */
    bookedQuote?: ShipmentReportQuoteSummary;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    manualQuotingRequested?: boolean;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof ShipmentReport
     */
    lineItems?: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    lineItemDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    totalHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    totalWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    totalVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    density?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    freightClass?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    insuranceRequested?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    insuranceAmount?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof ShipmentReport
     */
    insuranceCurrency?: CurrencyCode;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    standardDryVanLinearFeet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsDeliveryAppointment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsLiftgatePickup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsLiftgateDelivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsResidentialPickup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsResidentialDelivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsInsidePickup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    needsInsideDelivery?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    bookedAt?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShipmentReport
     */
    problems?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShipmentReport
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    insurancePriceCad?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    serviceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    billOfLadingNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    linearFeetOverride?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    margin?: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentReport
     */
    marginInsuranceFree?: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    carrierPickupNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    trackingId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    emergencyContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    emergencyContactPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupBoothNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryBoothNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    advanceInterlineProNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    beyondInterlineProNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    bookedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    actualDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupBusinessName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupLocationType?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    pickupAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryBusinessName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryLocationType?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    deliveryAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentReport
     */
    companyFirstQuotedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    dangerous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentReport
     */
    protectFromFreezing?: boolean;
}

/**
 * Check if a given object implements the ShipmentReport interface.
 */
export function instanceOfShipmentReport(value: object): boolean {
    return true;
}

export function ShipmentReportFromJSON(json: any): ShipmentReport {
    return ShipmentReportFromJSONTyped(json, false);
}

export function ShipmentReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReport {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'shipmentHash': json['shipmentHash'] == null ? undefined : json['shipmentHash'],
        'state': json['state'] == null ? undefined : ShipmentStateFromJSON(json['state']),
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'booked': json['booked'] == null ? undefined : json['booked'],
        'deletedDescription': json['deletedDescription'] == null ? undefined : json['deletedDescription'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'quotedAt': json['quotedAt'] == null ? undefined : json['quotedAt'],
        'pickupDate': json['pickupDate'] == null ? undefined : json['pickupDate'],
        'pickupDateDescription': json['pickupDateDescription'] == null ? undefined : json['pickupDateDescription'],
        'distanceMiles': json['distanceMiles'] == null ? undefined : json['distanceMiles'],
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'companyLeadSource': json['companyLeadSource'] == null ? undefined : json['companyLeadSource'],
        'companyUtmCampaign': json['companyUtmCampaign'] == null ? undefined : json['companyUtmCampaign'],
        'companyCreationDate': json['companyCreationDate'] == null ? undefined : json['companyCreationDate'],
        'numberOfShipmentsCompanyBooked': json['numberOfShipmentsCompanyBooked'] == null ? undefined : json['numberOfShipmentsCompanyBooked'],
        'companyShipmentFrequency': json['companyShipmentFrequency'] == null ? undefined : json['companyShipmentFrequency'],
        'numberCustomerInvoices': json['numberCustomerInvoices'] == null ? undefined : json['numberCustomerInvoices'],
        'numberCarrierInvoices': json['numberCarrierInvoices'] == null ? undefined : json['numberCarrierInvoices'],
        'numberCarrierCreditNotes': json['numberCarrierCreditNotes'] == null ? undefined : json['numberCarrierCreditNotes'],
        'moneyInCad': json['moneyInCad'] == null ? undefined : json['moneyInCad'],
        'moneyOutCad': json['moneyOutCad'] == null ? undefined : json['moneyOutCad'],
        'originalQuote': json['originalQuote'] == null ? undefined : json['originalQuote'],
        'settlementStatus': json['settlementStatus'] == null ? undefined : ShipmentReportSettlementStatusFromJSON(json['settlementStatus']),
        'profit': json['profit'] == null ? undefined : json['profit'],
        'invoiceWarningState': json['invoiceWarningState'] == null ? undefined : ShipmentReportInvoiceWarningStateFromJSON(json['invoiceWarningState']),
        'pickupPostalCode': json['pickupPostalCode'] == null ? undefined : json['pickupPostalCode'],
        'pickupCity': json['pickupCity'] == null ? undefined : json['pickupCity'],
        'pickupState': json['pickupState'] == null ? undefined : json['pickupState'],
        'pickupCityState': json['pickupCityState'] == null ? undefined : json['pickupCityState'],
        'pickupCountry': json['pickupCountry'] == null ? undefined : CountryCodeFromJSON(json['pickupCountry']),
        'pickupLat': json['pickupLat'] == null ? undefined : json['pickupLat'],
        'pickupLng': json['pickupLng'] == null ? undefined : json['pickupLng'],
        'deliveryPostalCode': json['deliveryPostalCode'] == null ? undefined : json['deliveryPostalCode'],
        'deliveryCityState': json['deliveryCityState'] == null ? undefined : json['deliveryCityState'],
        'deliveryState': json['deliveryState'] == null ? undefined : json['deliveryState'],
        'deliveryCountry': json['deliveryCountry'] == null ? undefined : CountryCodeFromJSON(json['deliveryCountry']),
        'deliveryCity': json['deliveryCity'] == null ? undefined : json['deliveryCity'],
        'deliveryLat': json['deliveryLat'] == null ? undefined : json['deliveryLat'],
        'deliveryLng': json['deliveryLng'] == null ? undefined : json['deliveryLng'],
        'quotes': json['quotes'] == null ? undefined : ((json['quotes'] as Array<any>).map(ShipmentReportQuoteSummaryFromJSON)),
        'fastestQuote': json['fastestQuote'] == null ? undefined : ShipmentReportQuoteSummaryFromJSON(json['fastestQuote']),
        'lowestQuote': json['lowestQuote'] == null ? undefined : ShipmentReportQuoteSummaryFromJSON(json['lowestQuote']),
        'bookedQuote': json['bookedQuote'] == null ? undefined : ShipmentReportQuoteSummaryFromJSON(json['bookedQuote']),
        'manualQuotingRequested': json['manualQuotingRequested'] == null ? undefined : json['manualQuotingRequested'],
        'lineItems': json['lineItems'] == null ? undefined : ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'lineItemDescription': json['lineItemDescription'] == null ? undefined : json['lineItemDescription'],
        'totalHandlingUnits': json['totalHandlingUnits'] == null ? undefined : json['totalHandlingUnits'],
        'totalWeight': json['totalWeight'] == null ? undefined : json['totalWeight'],
        'totalVolume': json['totalVolume'] == null ? undefined : json['totalVolume'],
        'density': json['density'] == null ? undefined : json['density'],
        'freightClass': json['freightClass'] == null ? undefined : json['freightClass'],
        'insuranceRequested': json['insuranceRequested'] == null ? undefined : json['insuranceRequested'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'insuranceCurrency': json['insuranceCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['insuranceCurrency']),
        'standardDryVanLinearFeet': json['standardDryVanLinearFeet'] == null ? undefined : json['standardDryVanLinearFeet'],
        'needsDeliveryAppointment': json['needsDeliveryAppointment'] == null ? undefined : json['needsDeliveryAppointment'],
        'needsLiftgatePickup': json['needsLiftgatePickup'] == null ? undefined : json['needsLiftgatePickup'],
        'needsLiftgateDelivery': json['needsLiftgateDelivery'] == null ? undefined : json['needsLiftgateDelivery'],
        'needsResidentialPickup': json['needsResidentialPickup'] == null ? undefined : json['needsResidentialPickup'],
        'needsResidentialDelivery': json['needsResidentialDelivery'] == null ? undefined : json['needsResidentialDelivery'],
        'needsInsidePickup': json['needsInsidePickup'] == null ? undefined : json['needsInsidePickup'],
        'needsInsideDelivery': json['needsInsideDelivery'] == null ? undefined : json['needsInsideDelivery'],
        'bookedAt': json['bookedAt'] == null ? undefined : json['bookedAt'],
        'problems': json['problems'] == null ? undefined : json['problems'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'insurancePriceCad': json['insurancePriceCad'] == null ? undefined : json['insurancePriceCad'],
        'serviceIdentifier': json['serviceIdentifier'] == null ? undefined : json['serviceIdentifier'],
        'billOfLadingNumber': json['billOfLadingNumber'] == null ? undefined : json['billOfLadingNumber'],
        'linearFeetOverride': json['linearFeetOverride'] == null ? undefined : json['linearFeetOverride'],
        'margin': json['margin'] == null ? undefined : json['margin'],
        'marginInsuranceFree': json['marginInsuranceFree'] == null ? undefined : json['marginInsuranceFree'],
        'pickupReferenceNumber': json['pickupReferenceNumber'] == null ? undefined : json['pickupReferenceNumber'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'] == null ? undefined : json['deliveryReferenceNumber'],
        'carrierPickupNumber': json['carrierPickupNumber'] == null ? undefined : json['carrierPickupNumber'],
        'trackingId': json['trackingId'] == null ? undefined : json['trackingId'],
        'emergencyContactName': json['emergencyContactName'] == null ? undefined : json['emergencyContactName'],
        'emergencyContactPhoneNumber': json['emergencyContactPhoneNumber'] == null ? undefined : json['emergencyContactPhoneNumber'],
        'pickupBoothNumber': json['pickupBoothNumber'] == null ? undefined : json['pickupBoothNumber'],
        'deliveryBoothNumber': json['deliveryBoothNumber'] == null ? undefined : json['deliveryBoothNumber'],
        'advanceInterlineProNumber': json['advanceInterlineProNumber'] == null ? undefined : json['advanceInterlineProNumber'],
        'beyondInterlineProNumber': json['beyondInterlineProNumber'] == null ? undefined : json['beyondInterlineProNumber'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'bookedBy': json['bookedBy'] == null ? undefined : json['bookedBy'],
        'actualDeliveryDate': json['actualDeliveryDate'] == null ? undefined : json['actualDeliveryDate'],
        'pickupBusinessName': json['pickupBusinessName'] == null ? undefined : json['pickupBusinessName'],
        'pickupLocationType': json['pickupLocationType'] == null ? undefined : json['pickupLocationType'],
        'pickupNotes': json['pickupNotes'] == null ? undefined : json['pickupNotes'],
        'pickupAddress1': json['pickupAddress1'] == null ? undefined : json['pickupAddress1'],
        'pickupAddress2': json['pickupAddress2'] == null ? undefined : json['pickupAddress2'],
        'deliveryBusinessName': json['deliveryBusinessName'] == null ? undefined : json['deliveryBusinessName'],
        'deliveryLocationType': json['deliveryLocationType'] == null ? undefined : json['deliveryLocationType'],
        'deliveryNotes': json['deliveryNotes'] == null ? undefined : json['deliveryNotes'],
        'deliveryAddress1': json['deliveryAddress1'] == null ? undefined : json['deliveryAddress1'],
        'deliveryAddress2': json['deliveryAddress2'] == null ? undefined : json['deliveryAddress2'],
        'companyFirstQuotedAt': json['companyFirstQuotedAt'] == null ? undefined : json['companyFirstQuotedAt'],
        'dangerous': json['dangerous'] == null ? undefined : json['dangerous'],
        'protectFromFreezing': json['protectFromFreezing'] == null ? undefined : json['protectFromFreezing'],
    };
}

export function ShipmentReportToJSON(value?: ShipmentReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'shipmentHash': value['shipmentHash'],
        'state': ShipmentStateToJSON(value['state']),
        'createdAt': value['createdAt'],
        'booked': value['booked'],
        'deletedDescription': value['deletedDescription'],
        'proNumber': value['proNumber'],
        'carrierIdentifier': value['carrierIdentifier'],
        'quotedAt': value['quotedAt'],
        'pickupDate': value['pickupDate'],
        'pickupDateDescription': value['pickupDateDescription'],
        'distanceMiles': value['distanceMiles'],
        'companyName': value['companyName'],
        'companyId': value['companyId'],
        'companyLeadSource': value['companyLeadSource'],
        'companyUtmCampaign': value['companyUtmCampaign'],
        'companyCreationDate': value['companyCreationDate'],
        'numberOfShipmentsCompanyBooked': value['numberOfShipmentsCompanyBooked'],
        'companyShipmentFrequency': value['companyShipmentFrequency'],
        'numberCustomerInvoices': value['numberCustomerInvoices'],
        'numberCarrierInvoices': value['numberCarrierInvoices'],
        'numberCarrierCreditNotes': value['numberCarrierCreditNotes'],
        'moneyInCad': value['moneyInCad'],
        'moneyOutCad': value['moneyOutCad'],
        'originalQuote': value['originalQuote'],
        'settlementStatus': ShipmentReportSettlementStatusToJSON(value['settlementStatus']),
        'profit': value['profit'],
        'invoiceWarningState': ShipmentReportInvoiceWarningStateToJSON(value['invoiceWarningState']),
        'pickupPostalCode': value['pickupPostalCode'],
        'pickupCity': value['pickupCity'],
        'pickupState': value['pickupState'],
        'pickupCityState': value['pickupCityState'],
        'pickupCountry': CountryCodeToJSON(value['pickupCountry']),
        'pickupLat': value['pickupLat'],
        'pickupLng': value['pickupLng'],
        'deliveryPostalCode': value['deliveryPostalCode'],
        'deliveryCityState': value['deliveryCityState'],
        'deliveryState': value['deliveryState'],
        'deliveryCountry': CountryCodeToJSON(value['deliveryCountry']),
        'deliveryCity': value['deliveryCity'],
        'deliveryLat': value['deliveryLat'],
        'deliveryLng': value['deliveryLng'],
        'quotes': value['quotes'] == null ? undefined : ((value['quotes'] as Array<any>).map(ShipmentReportQuoteSummaryToJSON)),
        'fastestQuote': ShipmentReportQuoteSummaryToJSON(value['fastestQuote']),
        'lowestQuote': ShipmentReportQuoteSummaryToJSON(value['lowestQuote']),
        'bookedQuote': ShipmentReportQuoteSummaryToJSON(value['bookedQuote']),
        'manualQuotingRequested': value['manualQuotingRequested'],
        'lineItems': value['lineItems'] == null ? undefined : ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'lineItemDescription': value['lineItemDescription'],
        'totalHandlingUnits': value['totalHandlingUnits'],
        'totalWeight': value['totalWeight'],
        'totalVolume': value['totalVolume'],
        'density': value['density'],
        'freightClass': value['freightClass'],
        'insuranceRequested': value['insuranceRequested'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyCodeToJSON(value['insuranceCurrency']),
        'standardDryVanLinearFeet': value['standardDryVanLinearFeet'],
        'needsDeliveryAppointment': value['needsDeliveryAppointment'],
        'needsLiftgatePickup': value['needsLiftgatePickup'],
        'needsLiftgateDelivery': value['needsLiftgateDelivery'],
        'needsResidentialPickup': value['needsResidentialPickup'],
        'needsResidentialDelivery': value['needsResidentialDelivery'],
        'needsInsidePickup': value['needsInsidePickup'],
        'needsInsideDelivery': value['needsInsideDelivery'],
        'bookedAt': value['bookedAt'],
        'problems': value['problems'],
        'tags': value['tags'],
        'insurancePriceCad': value['insurancePriceCad'],
        'serviceIdentifier': value['serviceIdentifier'],
        'billOfLadingNumber': value['billOfLadingNumber'],
        'linearFeetOverride': value['linearFeetOverride'],
        'margin': value['margin'],
        'marginInsuranceFree': value['marginInsuranceFree'],
        'pickupReferenceNumber': value['pickupReferenceNumber'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'carrierPickupNumber': value['carrierPickupNumber'],
        'trackingId': value['trackingId'],
        'emergencyContactName': value['emergencyContactName'],
        'emergencyContactPhoneNumber': value['emergencyContactPhoneNumber'],
        'pickupBoothNumber': value['pickupBoothNumber'],
        'deliveryBoothNumber': value['deliveryBoothNumber'],
        'advanceInterlineProNumber': value['advanceInterlineProNumber'],
        'beyondInterlineProNumber': value['beyondInterlineProNumber'],
        'createdBy': value['createdBy'],
        'bookedBy': value['bookedBy'],
        'actualDeliveryDate': value['actualDeliveryDate'],
        'pickupBusinessName': value['pickupBusinessName'],
        'pickupLocationType': value['pickupLocationType'],
        'pickupNotes': value['pickupNotes'],
        'pickupAddress1': value['pickupAddress1'],
        'pickupAddress2': value['pickupAddress2'],
        'deliveryBusinessName': value['deliveryBusinessName'],
        'deliveryLocationType': value['deliveryLocationType'],
        'deliveryNotes': value['deliveryNotes'],
        'deliveryAddress1': value['deliveryAddress1'],
        'deliveryAddress2': value['deliveryAddress2'],
        'companyFirstQuotedAt': value['companyFirstQuotedAt'],
        'dangerous': value['dangerous'],
        'protectFromFreezing': value['protectFromFreezing'],
    };
}


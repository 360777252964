/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingTabData } from './BillingTabData';
import {
    BillingTabDataFromJSON,
    BillingTabDataFromJSONTyped,
    BillingTabDataToJSON,
} from './BillingTabData';
import type { ClaimsTabData } from './ClaimsTabData';
import {
    ClaimsTabDataFromJSON,
    ClaimsTabDataFromJSONTyped,
    ClaimsTabDataToJSON,
} from './ClaimsTabData';
import type { CompanyShipmentQuote } from './CompanyShipmentQuote';
import {
    CompanyShipmentQuoteFromJSON,
    CompanyShipmentQuoteFromJSONTyped,
    CompanyShipmentQuoteToJSON,
} from './CompanyShipmentQuote';
import type { ConfigurationData } from './ConfigurationData';
import {
    ConfigurationDataFromJSON,
    ConfigurationDataFromJSONTyped,
    ConfigurationDataToJSON,
} from './ConfigurationData';
import type { DocumentsTabData } from './DocumentsTabData';
import {
    DocumentsTabDataFromJSON,
    DocumentsTabDataFromJSONTyped,
    DocumentsTabDataToJSON,
} from './DocumentsTabData';
import type { DurationInfo } from './DurationInfo';
import {
    DurationInfoFromJSON,
    DurationInfoFromJSONTyped,
    DurationInfoToJSON,
} from './DurationInfo';
import type { ManageQuotesTabData } from './ManageQuotesTabData';
import {
    ManageQuotesTabDataFromJSON,
    ManageQuotesTabDataFromJSONTyped,
    ManageQuotesTabDataToJSON,
} from './ManageQuotesTabData';
import type { NotesTabData } from './NotesTabData';
import {
    NotesTabDataFromJSON,
    NotesTabDataFromJSONTyped,
    NotesTabDataToJSON,
} from './NotesTabData';
import type { ShipmentReport } from './ShipmentReport';
import {
    ShipmentReportFromJSON,
    ShipmentReportFromJSONTyped,
    ShipmentReportToJSON,
} from './ShipmentReport';
import type { TrackingLine } from './TrackingLine';
import {
    TrackingLineFromJSON,
    TrackingLineFromJSONTyped,
    TrackingLineToJSON,
} from './TrackingLine';

/**
 * 
 * @export
 * @interface ViewShipmentDataForApollo
 */
export interface ViewShipmentDataForApollo {
    /**
     * 
     * @type {CompanyShipmentQuote}
     * @memberof ViewShipmentDataForApollo
     */
    shipment: CompanyShipmentQuote;
    /**
     * 
     * @type {Array<TrackingLine>}
     * @memberof ViewShipmentDataForApollo
     */
    trackingLines: Array<TrackingLine>;
    /**
     * 
     * @type {ManageQuotesTabData}
     * @memberof ViewShipmentDataForApollo
     */
    manageQuotesTab: ManageQuotesTabData;
    /**
     * 
     * @type {DocumentsTabData}
     * @memberof ViewShipmentDataForApollo
     */
    documentsTab: DocumentsTabData;
    /**
     * 
     * @type {BillingTabData}
     * @memberof ViewShipmentDataForApollo
     */
    billingTab: BillingTabData;
    /**
     * 
     * @type {NotesTabData}
     * @memberof ViewShipmentDataForApollo
     */
    notesTab: NotesTabData;
    /**
     * 
     * @type {string}
     * @memberof ViewShipmentDataForApollo
     */
    suggestedTrackingLine: string;
    /**
     * 
     * @type {string}
     * @memberof ViewShipmentDataForApollo
     */
    accountNumber: string;
    /**
     * 
     * @type {ConfigurationData}
     * @memberof ViewShipmentDataForApollo
     */
    _configuration: ConfigurationData;
    /**
     * 
     * @type {ShipmentReport}
     * @memberof ViewShipmentDataForApollo
     */
    shipmentReport: ShipmentReport;
    /**
     * 
     * @type {string}
     * @memberof ViewShipmentDataForApollo
     */
    carrierDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewShipmentDataForApollo
     */
    shipmentId: string;
    /**
     * 
     * @type {DurationInfo}
     * @memberof ViewShipmentDataForApollo
     */
    durationInfo: DurationInfo;
    /**
     * 
     * @type {ClaimsTabData}
     * @memberof ViewShipmentDataForApollo
     */
    claimsTab: ClaimsTabData;
}

/**
 * Check if a given object implements the ViewShipmentDataForApollo interface.
 */
export function instanceOfViewShipmentDataForApollo(value: object): boolean {
    if (!('shipment' in value)) return false;
    if (!('trackingLines' in value)) return false;
    if (!('manageQuotesTab' in value)) return false;
    if (!('documentsTab' in value)) return false;
    if (!('billingTab' in value)) return false;
    if (!('notesTab' in value)) return false;
    if (!('suggestedTrackingLine' in value)) return false;
    if (!('accountNumber' in value)) return false;
    if (!('_configuration' in value)) return false;
    if (!('shipmentReport' in value)) return false;
    if (!('shipmentId' in value)) return false;
    if (!('durationInfo' in value)) return false;
    if (!('claimsTab' in value)) return false;
    return true;
}

export function ViewShipmentDataForApolloFromJSON(json: any): ViewShipmentDataForApollo {
    return ViewShipmentDataForApolloFromJSONTyped(json, false);
}

export function ViewShipmentDataForApolloFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewShipmentDataForApollo {
    if (json == null) {
        return json;
    }
    return {
        
        'shipment': CompanyShipmentQuoteFromJSON(json['shipment']),
        'trackingLines': ((json['trackingLines'] as Array<any>).map(TrackingLineFromJSON)),
        'manageQuotesTab': ManageQuotesTabDataFromJSON(json['manageQuotesTab']),
        'documentsTab': DocumentsTabDataFromJSON(json['documentsTab']),
        'billingTab': BillingTabDataFromJSON(json['billingTab']),
        'notesTab': NotesTabDataFromJSON(json['notesTab']),
        'suggestedTrackingLine': json['suggestedTrackingLine'],
        'accountNumber': json['accountNumber'],
        '_configuration': ConfigurationDataFromJSON(json['configuration']),
        'shipmentReport': ShipmentReportFromJSON(json['shipmentReport']),
        'carrierDisplayName': json['carrierDisplayName'] == null ? undefined : json['carrierDisplayName'],
        'shipmentId': json['shipmentId'],
        'durationInfo': DurationInfoFromJSON(json['durationInfo']),
        'claimsTab': ClaimsTabDataFromJSON(json['claimsTab']),
    };
}

export function ViewShipmentDataForApolloToJSON(value?: ViewShipmentDataForApollo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipment': CompanyShipmentQuoteToJSON(value['shipment']),
        'trackingLines': ((value['trackingLines'] as Array<any>).map(TrackingLineToJSON)),
        'manageQuotesTab': ManageQuotesTabDataToJSON(value['manageQuotesTab']),
        'documentsTab': DocumentsTabDataToJSON(value['documentsTab']),
        'billingTab': BillingTabDataToJSON(value['billingTab']),
        'notesTab': NotesTabDataToJSON(value['notesTab']),
        'suggestedTrackingLine': value['suggestedTrackingLine'],
        'accountNumber': value['accountNumber'],
        'configuration': ConfigurationDataToJSON(value['_configuration']),
        'shipmentReport': ShipmentReportToJSON(value['shipmentReport']),
        'carrierDisplayName': value['carrierDisplayName'],
        'shipmentId': value['shipmentId'],
        'durationInfo': DurationInfoToJSON(value['durationInfo']),
        'claimsTab': ClaimsTabDataToJSON(value['claimsTab']),
    };
}


import { Radio } from "antd";
import { CurrencyCode } from "../generated-openapi-client/models/CurrencyCode";
import { assertNever } from "../Helpers/assertNever";

interface CurrencySelectorProps {
  currencies?: CurrencyCode[];
  currency: CurrencyCode | undefined;
  setCurrency: (_: CurrencyCode) => void;
}

function getCurrencyText(currency: CurrencyCode): string {
  switch (currency) {
    case CurrencyCode.Cad:
      return "🇨🇦 CAD ";
    case CurrencyCode.Usd:
      return "🇺🇸 USD";
    default:
      assertNever(currency);
  }
}

export function CurrencySelector({
  currency,
  setCurrency,
  currencies = [CurrencyCode.Cad, CurrencyCode.Usd],
}: CurrencySelectorProps) {
  if (currencies.length === 1) {
    return <span>{getCurrencyText(currencies[0])}</span>;
  }

  return (
    <Radio.Group
      value={currency}
      onChange={function (e) {
        setCurrency(e.target.value);
      }}
    >
      {currencies.map((c) => (
        <Radio.Button value={c}>{getCurrencyText(c)}</Radio.Button>
      ))}
    </Radio.Group>
  );
}

interface WarningComponentProps {
  marginRight?: number;
}

export function WarningComponent(props: WarningComponentProps) {
  return (
    <span
      style={{
        fontFamily:
          '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        marginRight: props.marginRight,
      }}
    >
      {"\u26A0\uFE0F"}
    </span>
  );
}

interface ConditionalWarningComponentProps {
  condition: boolean;
}

export function ConditionalWarningComponent(
  props: ConditionalWarningComponentProps
) {
  if (props.condition) {
    return <WarningComponent marginRight={4} />;
  } else {
    return <></>;
  }
}

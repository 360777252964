import { MailOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { ReactNode, useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import Spacer from "../../Spacer";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { ExtraTextButton } from "./ExtraTextButton";
import { ManageRecipientsButton } from "./ManageRecipientsButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButtonBase,
} from "./SupportEmailButton";
import { SupportAgent } from "../ViewCompanyScreen";

interface GenericSupportEmailBaseProps {
  data: ViewShipmentDataForApollo;
  button: (label: string, onClick: () => void) => ReactNode;
  email?: string;
}

export function GenericSupportBaseButton(props: GenericSupportEmailBaseProps) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");
  const shipment = props.data.shipment.shipment;

  function defaultSubject() {
    if (shipment.bookedAt !== undefined) {
      return `${shipment.pickupLocation!!.businessName} to ${
        shipment.deliveryLocation!!.businessName
      } (${shipment.billOfLadingNumber})`;
    } else {
      return `${shipment.pickupLocation!!.address!!.city}, ${
        shipment.pickupLocation!!.address!!.stateOrProvinceCode
      } to ${shipment.deliveryLocation?.address?.city}, ${
        shipment.deliveryLocation?.address?.stateOrProvinceCode
      } (${shipment.billOfLadingNumber})`;
    }
  }

  const [subject, setSubject] = useState(defaultSubject());

  const [toAddresses, setToAddresses] = useState<string[]>([
    props.email ??
      props.data.shipment.shipment.bookedByEmail ??
      props.data.shipment.shipment.quotedByEmail!!,
  ]);

  const input = {
    extraText,
    subject,
    toAddresses,
  };

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewGenericEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    if (subject.trim() === "" || subject.length < 10) {
      message.warn("Subject too short");
      return false;
    }

    if (extraText.trim() === "" || extraText.length < 30) {
      message.warn("Extra text too short");
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendGenericEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButtonBase
      label="✉️ Generic Support Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      button={props.button}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Subject">
            <ExtraTextButton
              extraText={subject}
              setExtraText={setSubject}
              title="Subject"
            />
            <Spacer width={16} />
            <ManageRecipientsButton
              toAddresses={toAddresses}
              setToAddresses={setToAddresses}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}

interface GenericSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function GenericSupportEmailButton(
  props: GenericSupportEmailButtonProps
) {
  return (
    <GenericSupportBaseButton
      data={props.data}
      button={(label, onClick) => <Button onClick={onClick}>{label}</Button>}
    />
  );
}

interface SendMailLinkProps {
  data: ViewShipmentDataForApollo;
  email: string;
}

export function SendMailLink(props: SendMailLinkProps) {
  return (
    <GenericSupportBaseButton
      email={props.email}
      data={props.data}
      button={(label, onClick) => (
        <Button
          onClick={onClick}
          style={{ color: "white" }}
          type="link"
          icon={<MailOutlined />}
        >
          Send Mail
        </Button>
      )}
    />
  );
}

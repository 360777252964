import { Menu, MenuItemProps, Modal } from "antd";
import { useState } from "react";
import { Json } from "./Json";

interface ViewJsonMenuItemProps {
  data: any;
  title: string;
}

export function ViewJsonMenuItem(props: ViewJsonMenuItemProps & MenuItemProps) {
  const { title, data } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1400}
      >
        <Json data={data} />
      </Modal>
      <Menu.Item onClick={showModal} {...props}>{title}</Menu.Item>
    </>
  );
}

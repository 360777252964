import {
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { useFreightClaimsApi } from "../../Apis/Apis";
import { MenuItemProps } from "rc-menu";
import { useState } from "react";
import { useDisplay } from "../../Hooks/useDisplay";
import Spacer from "../../Spacer";

interface DeleteClaimMenuItemProps extends MenuItemProps {
  claimId: string | undefined;
  onRefresh: () => Promise<void>;
}

export function DeleteClaimMenuItem({
  claimId,
  onRefresh,
  ...rest
}: DeleteClaimMenuItemProps) {
  const [loading, setLoading] = useState(false);
  const createFreightClaimsApi = useFreightClaimsApi();
  const [selectedReason, setSelectedReason] = useState<string>();
  const [notes, setNotes] = useState<string>("");
  const [keepDocuments, setKeepDocuments] = useState(false);
  const display = useDisplay();

  if (!claimId) {
    return null;
  }

  const reasonOptions: { label: any; value: any }[] = [
    { label: "Duplicate claim", value: "duplicate" },
    { label: "Other", value: "other" },
  ];

  function handleReasonChange(value: string) {
    setSelectedReason(value);
  }

  async function handleOk() {
    let reason = reasonOptions.find((r) => r.value === selectedReason)?.label;

    if (!reason) {
      message.warn("Please select a reason for deletion!");
      return;
    }

    if (selectedReason === "other" && notes.trim() === "") {
      message.warn("Please provide additional notes!");
      return;
    }

    reason = selectedReason === "other" ? notes : reason;

    try {
      setLoading(true);
      const freightApi = await createFreightClaimsApi();
      await freightApi.deleteFreightClaim({
        claimId: claimId!,
        reason,
        keepDocuments,
      });
      display.hide();
      await onRefresh();
      message.success("Claim deleted successfully.");
    } catch (e) {
      console.error(e);
      message.error("Oops. Something went wrong!");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Menu.Item {...rest} onClick={display.show}>
        <Tag color={"red"}>Advanced</Tag>
        Delete Claim
      </Menu.Item>
      <Modal
        confirmLoading={loading}
        onCancel={display.hide}
        title="Delete Claim Confirmation"
        okText="Delete"
        okType="danger"
        open={display.status}
        width="700px"
        onOk={handleOk}
      >
        <p>This action cannot be undone.</p>
        <p>
          Deleting this claim will also remove all attached documents unless you
          choose to keep them.
        </p>
        <Spacer height={24} />
        <Form
          style={{ width: "600px" }}
          layout="horizontal"
          colon={false}
          labelCol={{ span: 7 }}
        >
          <Form.Item label="Keep Claim Documents">
            <Tooltip title="Select to keep any documents associated with this claim.">
              <Switch checked={keepDocuments} onChange={setKeepDocuments} />
            </Tooltip>
          </Form.Item>
          <Form.Item label="Reason">
            <Select
              style={{ width: 300 }}
              placeholder="Select Reason"
              value={selectedReason}
              onChange={handleReasonChange}
              options={reasonOptions}
            />
          </Form.Item>
          {selectedReason === "other" && (
            <Form.Item label="Notes" required>
              <Input.TextArea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Provide additional details..."
                rows={4}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

import { createContext, ReactNode, useContext } from "react";
import { useRefreshFunction } from "../Hooks/useRefreshFunction";

export interface RefreshContextProps {
  isRefreshing: boolean;
  refresh: () => Promise<any>;
  setRefresh: (fn: () => Promise<any>) => void;
}

export const initRefreshContext: RefreshContextProps = {
  isRefreshing: false,
  refresh: async () => {},
  setRefresh: () => {},
};

export const RefreshContext = createContext<RefreshContextProps>({
  ...initRefreshContext,
});

export function RefreshContextProvider({
  children,
  onRefresh = async () => {},
}: {
  children: ReactNode;
  onRefresh?: () => Promise<any>;
}) {
  const { isRefreshing, setRefresh, refresh } = useRefreshFunction(onRefresh);
  return (
    <RefreshContext.Provider value={{ refresh, isRefreshing, setRefresh }}>
      {children}
    </RefreshContext.Provider>
  );
}

export function useRefreshContext() {
  return useContext(RefreshContext);
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceAuditCarrierNote
 */
export interface InvoiceAuditCarrierNote {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditCarrierNote
     */
    carrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAuditCarrierNote
     */
    notes?: string;
}

/**
 * Check if a given object implements the InvoiceAuditCarrierNote interface.
 */
export function instanceOfInvoiceAuditCarrierNote(value: object): boolean {
    return true;
}

export function InvoiceAuditCarrierNoteFromJSON(json: any): InvoiceAuditCarrierNote {
    return InvoiceAuditCarrierNoteFromJSONTyped(json, false);
}

export function InvoiceAuditCarrierNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAuditCarrierNote {
    if (json == null) {
        return json;
    }
    return {
        
        'carrierIdentifier': json['carrierIdentifier'] == null ? undefined : json['carrierIdentifier'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

export function InvoiceAuditCarrierNoteToJSON(value?: InvoiceAuditCarrierNote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'carrierIdentifier': value['carrierIdentifier'],
        'notes': value['notes'],
    };
}


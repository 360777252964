import moment from "moment";
import { GraphType, StatsCalendar } from "../../Components/StatsCalendar";
import {
  ApolloMapQuery,
  CalendarDataPoint,
  CalendarDataResponse,
  ShipmentReport,
} from "../../generated-openapi-client";

interface AllShipmentsScreenCalendarTabProps {
  query: ApolloMapQuery;
  shipments: ShipmentReport[];
}

export function AllShipmentsScreenCalendarTab(
  props: AllShipmentsScreenCalendarTabProps
) {
  function convertShipmentReportsToCalendarDataQuoted(): CalendarDataResponse {
    const data = props.shipments.map(function (sr): CalendarDataPoint {
      return {
        day: moment(sr.quotedAt).format("YYYY-MM-DD"),
        value: 1,
      };
    });

    const earliestDate = moment(
      props.shipments.sort(function (a, b) {
        return moment(a.quotedAt).valueOf() - moment(b.quotedAt).valueOf();
      })[0].quotedAt
    ).format("YYYY-MM-DD");

    return { earliestDate, data };
  }

  const dataQuoted = convertShipmentReportsToCalendarDataQuoted();
  return (
    <div style={{ marginLeft: "24px" }}>
      <StatsCalendar
        data={dataQuoted}
        maxValue={1}
        defaultGraphType={GraphType.Calendar}
      />
    </div>
  );
}

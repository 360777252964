import { Form, message, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import Colors from "../../Components/Colors";
import HorizontalStack from "../../Components/HorizontalStack";
import { Loading } from "../../Components/Loading";
import Stack from "../../Components/Stack";
import SupportAgents from "../../GeneratedFixtures/SupportAgents.json";
import {
  AdditionalCharge,
  EmailDescription,
  ShipmentDocument,
} from "../../generated-openapi-client";
import { SupportAgent } from "../ViewCompanyScreen";
import { ManageAttachmentsButton } from "../ViewCompanyScreenComponents/ManageAttachmentsButton";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportAgentDropdown } from "./SupportAgentDropdown";
import { SupportEmailBodyExtraItem } from "./SupportEmailButton";
import { TabProps } from "./TabProps";

const { Text } = Typography;

interface AdditionalChargeEmailComponentProps extends TabProps {
  additionalCharge: AdditionalCharge;
  isEmailModalVisible: boolean;
  setIsEmailModalVisible: (_: boolean) => void;

  // When this is called, additionalCharge has not yet been updated with the associatedProblemIds - so we pass the new ones
  // in separately
  associatedProblemIds: string[];
}

// For various reasons - this is somewhat a duplicate of the button code - but all condensed into a single component
export function AdditionalChargeEmailComponent(
  props: AdditionalChargeEmailComponentProps
) {
  const { isEmailModalVisible, setIsEmailModalVisible } = props;
  const shipmentId = props.shipmentData.shipmentId;
  const additionalCharge = props.additionalCharge;
  const additionalChargeId = additionalCharge.additionalChargeId!!;

  const associatedInvoiceAuditProblems =
    props.shipmentData.shipment.shipment.invoiceAuditProblems!!.filter(
      function (iap) {
        return props.associatedProblemIds.includes(iap.invoiceAuditProblemId!!);
      }
    );
  const relatedDocumentIds = associatedInvoiceAuditProblems.flatMap(
    (iap) => iap.relatedCustomerVisibleDocumentIds!!
  );

  const relatedDocuments = props.shipmentData.documentsTab.documents
    .filter(function (sd) {
      return relatedDocumentIds.includes(
        sd.shipmentDocument.shipmentDocumentId!!
      );
    })
    .map((sd) => sd.shipmentDocument);

  const [sending, setSending] = useState(false);
  const [extraText, setExtraText] = useState("");
  const [supportAgent, setSupportAgent] = useState<SupportAgent>(
    SupportAgents[0]
  );
  const [attachments, setAttachments] =
    useState<ShipmentDocument[]>(relatedDocuments);
  const attachmentDocumentIds = attachments.map((o) => o.shipmentDocumentId!!);

  const [emailDescription, setEmailDescription] = useState<
    EmailDescription | undefined
  >();
  const [hasGotFirstPreview, setHasGotFirstPreview] = useState(false);
  const createSupportEmailApi = useSupportEmailApi();

  const previewUpdateHash = JSON.stringify({
    additionalChargeId,
    extraText,
  });

  useEffect(
    function () {
      setAttachments(relatedDocuments);
    },
    [JSON.stringify(relatedDocuments)]
  );

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function fetchPreview() {
    const response = await onFetchPreview(supportAgent);

    setEmailDescription(response);
    setHasGotFirstPreview(true);
  }

  useEffect(
    function () {
      if (isEmailModalVisible) {
        fetchPreview();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previewUpdateHash, supportAgent, isEmailModalVisible]
  );

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendAdditionalChargeEmail({
        shipmentId,
        additionalChargeId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  async function handleOk() {
    setSending(true);
    try {
      const success = await onSend(supportAgent, attachmentDocumentIds);
      if (success) {
        await props.onRefresh();
        message.success("Email sent!");
        setIsEmailModalVisible(false);
      }
    } catch (e: any) {
      message.error(`Oops. Something went wrong ${e}`);
    }
    setSending(false);
  }

  async function handleCancel() {
    await props.onRefresh();
    setIsEmailModalVisible(false);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="✉️ Additional Charge Email"
        visible={isEmailModalVisible}
        onOk={handleOk}
        okText={`Send Email`}
        onCancel={handleCancel}
        width={1400}
        confirmLoading={sending}
        destroyOnClose
      >
        <Stack align="left">
          <HorizontalStack
            verticalAlign="middle"
            spacing={16}
            width="100%"
            style={{
              backgroundColor: Colors.VeryVeryLightBlue,
              padding: "8px",
              position: "relative",
              top: "-16px",
            }}
          >
            <SupportEmailBodyExtraItem label="Agent">
              <SupportAgentDropdown
                supportAgent={supportAgent}
                onSelect={setSupportAgent}
              />
            </SupportEmailBodyExtraItem>

            <ExtraTextButton
              extraText={extraText}
              setExtraText={setExtraText}
            />
            <ManageAttachmentsButton
              buttonText="Manage Email Attachments"
              buttonType="link"
              shipmentData={props.shipmentData}
              attachments={attachments}
              onUpdateAttachments={setAttachments}
              showThumbnails={false}
            />
          </HorizontalStack>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14, offset: 1 }}
            layout="horizontal"
            colon={false}
          >
            {emailDescription !== undefined && (
              <>
                <Form.Item label="Email Addresses">
                  <Text>{emailDescription?.addresses.join(", ")}</Text>
                </Form.Item>
                <Form.Item label="Subject">
                  <Text>{emailDescription?.subject}</Text>
                </Form.Item>
                <Form.Item label="Email Body">
                  <div
                    style={{
                      border: "1px solid #eee",
                      padding: "16px",
                      width: "1100px",
                    }}
                  >
                    {!hasGotFirstPreview && <Loading />}
                    {hasGotFirstPreview && (
                      <div
                        title="Email Preview"
                        dangerouslySetInnerHTML={{
                          __html: emailDescription.emailBody,
                        }}
                      ></div>
                    )}
                  </div>
                </Form.Item>
              </>
            )}
          </Form>
        </Stack>
      </Modal>
    </>
  );
}

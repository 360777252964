import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import {
  CarrierInvoice,
  CarrierInvoiceState,
  InvoiceAuditProblemFault,
  InvoiceAuditProblemResolutionState,
  InvoiceChallengeType,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { sleep } from "../../Helpers/sleep";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { InvoiceChallengeTypeDropdown } from "./InvoiceChallengeTypeDropdown";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

interface ChallengeCarrierInvoiceButton2Props {
  carrierInvoice: CarrierInvoice;
  data: ViewShipmentDataForApollo;
  refresh: () => Promise<void>;
}

export function ChallengeCarrierInvoiceButton2(
  props: ChallengeCarrierInvoiceButton2Props
) {
  const shipmentId = props.data.shipmentId;
  const carrierQuoteReference = props.data.shipment.quote.carrierQuoteReference;
  const invoiceAuditProblems =
    props.data.shipment.shipment.invoiceAuditProblems ?? [];
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");
  const [invoiceChallengeType, setInvoiceChallengeType] =
    useState<InvoiceChallengeType>(InvoiceChallengeType.ApplyQuote);

  const carrierInvoiceId = props.carrierInvoice.carrierInvoiceId!!;

  const input = {
    extraText,
    carrierInvoiceId,
    invoiceChallengeType,
  };

  const SupportAgentAmy: SupportAgent = {
    firstName: "Amy",
    lastName: "Martin",
  };

  async function onFetchPreview(): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewCarrierInvoiceChallengeEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: SupportAgentAmy.firstName,
        supportAgentLastName: SupportAgentAmy.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendCarrierInvoiceChallengeEmail({
        ...input,
        shipmentId,
        supportAgentFirstName: SupportAgentAmy.firstName,
        supportAgentLastName: SupportAgentAmy.lastName,
        attachmentDocumentIds,
      });
      // We also want the webhook to arrive and be processed - so give it a few seconds
      // Not perfect - but not a huge deal if it doesn't work
      await sleep(3000);
      await props.refresh();
      message.success(`Sent!`);
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  function generateDisabledMessage(): string | undefined {
    if (!props.carrierInvoice.problemsIdentified) {
      return "Identify all problems before sending a challenge";
    }

    // Issued are non-approved and so can be challenged
    // Also maybe the carrier already billed us for an invoice and it's PaymentConfirmed state - but we still need to challenge it
    if (
      props.carrierInvoice.invoiceState !== CarrierInvoiceState.Issued &&
      props.carrierInvoice.invoiceState !== CarrierInvoiceState.PaymentConfirmed
    ) {
      return "Only Issued / Payment Confirmed Invoices can be challenged";
    }

    const anyCarrierOrUnknownProblems =
      props.data.shipment.shipment.invoiceAuditProblems!!.some(function (iap) {
        return (
          iap.resolutionState ===
            InvoiceAuditProblemResolutionState.Unresolved &&
          (iap.fault === InvoiceAuditProblemFault.Carrier ||
            iap.fault === InvoiceAuditProblemFault.Unknown)
        );
      });

    if (!anyCarrierOrUnknownProblems) {
      return "There are no carrier/unknown problems identified";
    }

    return undefined;
  }

  const disabledMessage = generateDisabledMessage();

  return (
    <SupportEmailButton
      disabled={disabledMessage !== undefined}
      tooltip={disabledMessage}
      label="Challenge Invoice"
      onFetchPreview={onFetchPreview}
      useAmy
      onSend={onSend}
      previewUpdateHash={JSON.stringify(input)}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Challenge Type">
            <InvoiceChallengeTypeDropdown
              invoiceChallengeType={invoiceChallengeType}
              onSelect={setInvoiceChallengeType}
              invoiceAuditProblems={invoiceAuditProblems}
              carrierQuoteReference={carrierQuoteReference}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}

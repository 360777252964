import { message } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import { SupportEmailButton } from "./SupportEmailButton";

interface LogisticsAssistanceSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function LogisticsAssistanceSupportEmailButton(
  props: LogisticsAssistanceSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const createSupportEmailApi = useSupportEmailApi();
  const [extraText, setExtraText] = useState("");

  const hash = JSON.stringify({
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewLogisticsAssistanceEmail({
        shipmentId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendLogisiticsAssistanceEmail({
        shipmentId,
        extraText,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="✉️ Logistics Assistance"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
        </>
      }
      shipmentData={props.data}
    />
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { CustomsDocsState } from './CustomsDocsState';
import {
    CustomsDocsStateFromJSON,
    CustomsDocsStateFromJSONTyped,
    CustomsDocsStateToJSON,
} from './CustomsDocsState';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { InvoiceAuditProblem } from './InvoiceAuditProblem';
import {
    InvoiceAuditProblemFromJSON,
    InvoiceAuditProblemFromJSONTyped,
    InvoiceAuditProblemToJSON,
} from './InvoiceAuditProblem';
import type { InvoiceAuditTurn } from './InvoiceAuditTurn';
import {
    InvoiceAuditTurnFromJSON,
    InvoiceAuditTurnFromJSONTyped,
    InvoiceAuditTurnToJSON,
} from './InvoiceAuditTurn';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    createdBy?: string;
    /**
     * 
     * @type {ShipmentState}
     * @memberof Shipment
     */
    state?: ShipmentState;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryHours?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    actualDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    actualDeliveryTime?: string;
    /**
     * 
     * @type {Location}
     * @memberof Shipment
     */
    pickupLocation?: Location;
    /**
     * 
     * @type {Contact}
     * @memberof Shipment
     */
    pickupContact?: Contact;
    /**
     * 
     * @type {Location}
     * @memberof Shipment
     */
    deliveryLocation?: Location;
    /**
     * 
     * @type {Contact}
     * @memberof Shipment
     */
    deliveryContact?: Contact;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof Shipment
     */
    lineItems?: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    stripePaymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    selectedQuoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    billOfLadingDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    proNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    carrierPickupNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryReferenceNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    cancellationRequested?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    trackingId?: string;
    /**
     * 
     * @type {Broker}
     * @memberof Shipment
     */
    broker?: Broker;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    isReviewingPriorToBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    trackingNote?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deletionReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    emergencyContactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    emergencyContactPhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    emergencyContactPhoneNumberExtension?: string;
    /**
     * 
     * @type {CustomsDocsState}
     * @memberof Shipment
     */
    customsDocsState?: CustomsDocsState;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryDateFromApi?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    requestTrackingUpdateMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    requestDeliveryDateUpdateMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    handleMissedPickupMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    billOfLadingNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Shipment
     */
    finalizeBookingStages?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    deliveryAppointmentMade?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryAppointmentHours?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    outForDelivery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    late?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    numberDaysLate?: number;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    missingInvoiceMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    handleMissedDeliveryMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceNeedsApprovalMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    originalPickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    handleBookPickupMondayId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    totalWeight?: number;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    totalVolume?: number;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    density?: number;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    totalHandlingUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    declaredValue?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Shipment
     */
    declaredValueCurrency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    suggestedTrackingLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryDateAtPickup?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    latestPredictedDeliveryDateAtPickup?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pauseAmyEmailsUntilDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentHash?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedByPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedByEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    quotedByPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    quotedByEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    quotedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    spotQuoteMondayId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    manualQuoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    manualQuotingNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    manualQuotingOpen?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    standardDryVanLinearFeet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    freightMissing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    apolloQuotingTriaged?: boolean;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Shipment
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    exclusiveUseNeeded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    addInsuranceToShipment?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    insuranceAmount?: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Shipment
     */
    insuranceCurrency?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    outForDeliveryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    hotFreight?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupPackageDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    appointmentDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    apolloQuotingFlagged?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    freightMissingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    freightMissingMondayId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    challengeAllInvoices?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    branchId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    tarpRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryDeadline?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    doNotFetchFurtherCarrierInvoices?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupBoothNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryBoothNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    isPickupBooked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    advanceInterlineProNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    advanceInterlineCarrierPickupNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    advanceInterlineCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    advanceInterlineCarrierDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    beyondInterlineProNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    beyondInterlineCarrierPickupNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    beyondInterlineCarrierIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    beyondInterlineCarrierDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    customDocsDeliveredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    customDocsSentTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupDeadline?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Shipment
     */
    problems?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Shipment
     */
    tags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    invoiceAuditOpen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditAssignee?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    invoiceAuditExisted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditCustomerFrontConversationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditCarrierFrontConversationId?: string;
    /**
     * 
     * @type {InvoiceAuditTurn}
     * @memberof Shipment
     */
    invoiceAuditCarrierTurn?: InvoiceAuditTurn;
    /**
     * 
     * @type {InvoiceAuditTurn}
     * @memberof Shipment
     */
    invoiceAuditCustomerTurn?: InvoiceAuditTurn;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditCarrierTurnTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditCustomerTurnTime?: string;
    /**
     * 
     * @type {Array<InvoiceAuditProblem>}
     * @memberof Shipment
     */
    invoiceAuditProblems?: Array<InvoiceAuditProblem>;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditFirstOpenedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    invoiceAuditSnoozedUntilDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    standardDryVanLinearFeetSvg?: string;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    linearFeetOverride?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    pauseCustomsDocsReminders?: boolean;
}

/**
 * Check if a given object implements the Shipment interface.
 */
export function instanceOfShipment(value: object): boolean {
    return true;
}

export function ShipmentFromJSON(json: any): Shipment {
    return ShipmentFromJSONTyped(json, false);
}

export function ShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipment {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'state': json['state'] == null ? undefined : ShipmentStateFromJSON(json['state']),
        'pickupDate': json['pickupDate'] == null ? undefined : json['pickupDate'],
        'expectedDeliveryDate': json['expectedDeliveryDate'] == null ? undefined : json['expectedDeliveryDate'],
        'expectedDeliveryHours': json['expectedDeliveryHours'] == null ? undefined : json['expectedDeliveryHours'],
        'actualDeliveryDate': json['actualDeliveryDate'] == null ? undefined : json['actualDeliveryDate'],
        'actualDeliveryTime': json['actualDeliveryTime'] == null ? undefined : json['actualDeliveryTime'],
        'pickupLocation': json['pickupLocation'] == null ? undefined : LocationFromJSON(json['pickupLocation']),
        'pickupContact': json['pickupContact'] == null ? undefined : ContactFromJSON(json['pickupContact']),
        'deliveryLocation': json['deliveryLocation'] == null ? undefined : LocationFromJSON(json['deliveryLocation']),
        'deliveryContact': json['deliveryContact'] == null ? undefined : ContactFromJSON(json['deliveryContact']),
        'lineItems': json['lineItems'] == null ? undefined : ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'stripePaymentMethodId': json['stripePaymentMethodId'] == null ? undefined : json['stripePaymentMethodId'],
        'selectedQuoteId': json['selectedQuoteId'] == null ? undefined : json['selectedQuoteId'],
        'billOfLadingDocumentId': json['billOfLadingDocumentId'] == null ? undefined : json['billOfLadingDocumentId'],
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'carrierPickupNumber': json['carrierPickupNumber'] == null ? undefined : json['carrierPickupNumber'],
        'pickupReferenceNumber': json['pickupReferenceNumber'] == null ? undefined : json['pickupReferenceNumber'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'] == null ? undefined : json['deliveryReferenceNumber'],
        'cancellationRequested': json['cancellationRequested'] == null ? undefined : json['cancellationRequested'],
        'deleted': json['deleted'] == null ? undefined : json['deleted'],
        'bookedAt': json['bookedAt'] == null ? undefined : json['bookedAt'],
        'trackingId': json['trackingId'] == null ? undefined : json['trackingId'],
        'broker': json['broker'] == null ? undefined : BrokerFromJSON(json['broker']),
        'isReviewingPriorToBooking': json['isReviewingPriorToBooking'] == null ? undefined : json['isReviewingPriorToBooking'],
        'trackingNote': json['trackingNote'] == null ? undefined : json['trackingNote'],
        'deletionReason': json['deletionReason'] == null ? undefined : json['deletionReason'],
        'emergencyContactName': json['emergencyContactName'] == null ? undefined : json['emergencyContactName'],
        'emergencyContactPhoneNumber': json['emergencyContactPhoneNumber'] == null ? undefined : json['emergencyContactPhoneNumber'],
        'emergencyContactPhoneNumberExtension': json['emergencyContactPhoneNumberExtension'] == null ? undefined : json['emergencyContactPhoneNumberExtension'],
        'customsDocsState': json['customsDocsState'] == null ? undefined : CustomsDocsStateFromJSON(json['customsDocsState']),
        'expectedDeliveryDateFromApi': json['expectedDeliveryDateFromApi'] == null ? undefined : json['expectedDeliveryDateFromApi'],
        'requestTrackingUpdateMondayId': json['requestTrackingUpdateMondayId'] == null ? undefined : json['requestTrackingUpdateMondayId'],
        'requestDeliveryDateUpdateMondayId': json['requestDeliveryDateUpdateMondayId'] == null ? undefined : json['requestDeliveryDateUpdateMondayId'],
        'handleMissedPickupMondayId': json['handleMissedPickupMondayId'] == null ? undefined : json['handleMissedPickupMondayId'],
        'billOfLadingNumber': json['billOfLadingNumber'] == null ? undefined : json['billOfLadingNumber'],
        'finalizeBookingStages': json['finalizeBookingStages'] == null ? undefined : json['finalizeBookingStages'],
        'deliveryAppointmentMade': json['deliveryAppointmentMade'] == null ? undefined : json['deliveryAppointmentMade'],
        'deliveryAppointmentHours': json['deliveryAppointmentHours'] == null ? undefined : json['deliveryAppointmentHours'],
        'outForDelivery': json['outForDelivery'] == null ? undefined : json['outForDelivery'],
        'late': json['late'] == null ? undefined : json['late'],
        'numberDaysLate': json['numberDaysLate'] == null ? undefined : json['numberDaysLate'],
        'missingInvoiceMondayId': json['missingInvoiceMondayId'] == null ? undefined : json['missingInvoiceMondayId'],
        'handleMissedDeliveryMondayId': json['handleMissedDeliveryMondayId'] == null ? undefined : json['handleMissedDeliveryMondayId'],
        'invoiceNeedsApprovalMondayId': json['invoiceNeedsApprovalMondayId'] == null ? undefined : json['invoiceNeedsApprovalMondayId'],
        'originalPickupDate': json['originalPickupDate'] == null ? undefined : json['originalPickupDate'],
        'handleBookPickupMondayId': json['handleBookPickupMondayId'] == null ? undefined : json['handleBookPickupMondayId'],
        'bookedBy': json['bookedBy'] == null ? undefined : json['bookedBy'],
        'totalWeight': json['totalWeight'] == null ? undefined : json['totalWeight'],
        'totalVolume': json['totalVolume'] == null ? undefined : json['totalVolume'],
        'density': json['density'] == null ? undefined : json['density'],
        'totalHandlingUnits': json['totalHandlingUnits'] == null ? undefined : json['totalHandlingUnits'],
        'declaredValue': json['declaredValue'] == null ? undefined : json['declaredValue'],
        'declaredValueCurrency': json['declaredValueCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['declaredValueCurrency']),
        'suggestedTrackingLine': json['suggestedTrackingLine'] == null ? undefined : json['suggestedTrackingLine'],
        'expectedDeliveryDateAtPickup': json['expectedDeliveryDateAtPickup'] == null ? undefined : json['expectedDeliveryDateAtPickup'],
        'latestPredictedDeliveryDateAtPickup': json['latestPredictedDeliveryDateAtPickup'] == null ? undefined : json['latestPredictedDeliveryDateAtPickup'],
        'pauseAmyEmailsUntilDate': json['pauseAmyEmailsUntilDate'] == null ? undefined : json['pauseAmyEmailsUntilDate'],
        'shipmentHash': json['shipmentHash'] == null ? undefined : json['shipmentHash'],
        'bookedByPhone': json['bookedByPhone'] == null ? undefined : json['bookedByPhone'],
        'bookedByEmail': json['bookedByEmail'] == null ? undefined : json['bookedByEmail'],
        'bookedByName': json['bookedByName'] == null ? undefined : json['bookedByName'],
        'quotedByPhone': json['quotedByPhone'] == null ? undefined : json['quotedByPhone'],
        'quotedByEmail': json['quotedByEmail'] == null ? undefined : json['quotedByEmail'],
        'quotedByName': json['quotedByName'] == null ? undefined : json['quotedByName'],
        'spotQuoteMondayId': json['spotQuoteMondayId'] == null ? undefined : json['spotQuoteMondayId'],
        'manualQuoting': json['manualQuoting'] == null ? undefined : json['manualQuoting'],
        'manualQuotingNotes': json['manualQuotingNotes'] == null ? undefined : json['manualQuotingNotes'],
        'manualQuotingOpen': json['manualQuotingOpen'] == null ? undefined : json['manualQuotingOpen'],
        'standardDryVanLinearFeet': json['standardDryVanLinearFeet'] == null ? undefined : json['standardDryVanLinearFeet'],
        'freightMissing': json['freightMissing'] == null ? undefined : json['freightMissing'],
        'apolloQuotingTriaged': json['apolloQuotingTriaged'] == null ? undefined : json['apolloQuotingTriaged'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'exclusiveUseNeeded': json['exclusiveUseNeeded'] == null ? undefined : json['exclusiveUseNeeded'],
        'addInsuranceToShipment': json['addInsuranceToShipment'] == null ? undefined : json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'insuranceCurrency': json['insuranceCurrency'] == null ? undefined : CurrencyCodeFromJSON(json['insuranceCurrency']),
        'outForDeliveryDate': json['outForDeliveryDate'] == null ? undefined : json['outForDeliveryDate'],
        'hotFreight': json['hotFreight'] == null ? undefined : json['hotFreight'],
        'pickupPackageDocumentId': json['pickupPackageDocumentId'] == null ? undefined : json['pickupPackageDocumentId'],
        'appointmentDate': json['appointmentDate'] == null ? undefined : json['appointmentDate'],
        'apolloQuotingFlagged': json['apolloQuotingFlagged'] == null ? undefined : json['apolloQuotingFlagged'],
        'freightMissingDate': json['freightMissingDate'] == null ? undefined : json['freightMissingDate'],
        'freightMissingMondayId': json['freightMissingMondayId'] == null ? undefined : json['freightMissingMondayId'],
        'challengeAllInvoices': json['challengeAllInvoices'] == null ? undefined : json['challengeAllInvoices'],
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
        'tarpRequired': json['tarpRequired'] == null ? undefined : json['tarpRequired'],
        'deliveryDeadline': json['deliveryDeadline'] == null ? undefined : json['deliveryDeadline'],
        'doNotFetchFurtherCarrierInvoices': json['doNotFetchFurtherCarrierInvoices'] == null ? undefined : json['doNotFetchFurtherCarrierInvoices'],
        'pickupBoothNumber': json['pickupBoothNumber'] == null ? undefined : json['pickupBoothNumber'],
        'deliveryBoothNumber': json['deliveryBoothNumber'] == null ? undefined : json['deliveryBoothNumber'],
        'isPickupBooked': json['isPickupBooked'] == null ? undefined : json['isPickupBooked'],
        'advanceInterlineProNumber': json['advanceInterlineProNumber'] == null ? undefined : json['advanceInterlineProNumber'],
        'advanceInterlineCarrierPickupNumber': json['advanceInterlineCarrierPickupNumber'] == null ? undefined : json['advanceInterlineCarrierPickupNumber'],
        'advanceInterlineCarrierIdentifier': json['advanceInterlineCarrierIdentifier'] == null ? undefined : json['advanceInterlineCarrierIdentifier'],
        'advanceInterlineCarrierDisplayName': json['advanceInterlineCarrierDisplayName'] == null ? undefined : json['advanceInterlineCarrierDisplayName'],
        'beyondInterlineProNumber': json['beyondInterlineProNumber'] == null ? undefined : json['beyondInterlineProNumber'],
        'beyondInterlineCarrierPickupNumber': json['beyondInterlineCarrierPickupNumber'] == null ? undefined : json['beyondInterlineCarrierPickupNumber'],
        'beyondInterlineCarrierIdentifier': json['beyondInterlineCarrierIdentifier'] == null ? undefined : json['beyondInterlineCarrierIdentifier'],
        'beyondInterlineCarrierDisplayName': json['beyondInterlineCarrierDisplayName'] == null ? undefined : json['beyondInterlineCarrierDisplayName'],
        'customDocsDeliveredAt': json['customDocsDeliveredAt'] == null ? undefined : json['customDocsDeliveredAt'],
        'customDocsSentTo': json['customDocsSentTo'] == null ? undefined : json['customDocsSentTo'],
        'pickupDeadline': json['pickupDeadline'] == null ? undefined : json['pickupDeadline'],
        'problems': json['problems'] == null ? undefined : json['problems'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'invoiceAuditOpen': json['invoiceAuditOpen'] == null ? undefined : json['invoiceAuditOpen'],
        'invoiceAuditAssignee': json['invoiceAuditAssignee'] == null ? undefined : json['invoiceAuditAssignee'],
        'invoiceAuditExisted': json['invoiceAuditExisted'] == null ? undefined : json['invoiceAuditExisted'],
        'invoiceAuditCustomerFrontConversationId': json['invoiceAuditCustomerFrontConversationId'] == null ? undefined : json['invoiceAuditCustomerFrontConversationId'],
        'invoiceAuditCarrierFrontConversationId': json['invoiceAuditCarrierFrontConversationId'] == null ? undefined : json['invoiceAuditCarrierFrontConversationId'],
        'invoiceAuditCarrierTurn': json['invoiceAuditCarrierTurn'] == null ? undefined : InvoiceAuditTurnFromJSON(json['invoiceAuditCarrierTurn']),
        'invoiceAuditCustomerTurn': json['invoiceAuditCustomerTurn'] == null ? undefined : InvoiceAuditTurnFromJSON(json['invoiceAuditCustomerTurn']),
        'invoiceAuditCarrierTurnTime': json['invoiceAuditCarrierTurnTime'] == null ? undefined : json['invoiceAuditCarrierTurnTime'],
        'invoiceAuditCustomerTurnTime': json['invoiceAuditCustomerTurnTime'] == null ? undefined : json['invoiceAuditCustomerTurnTime'],
        'invoiceAuditProblems': json['invoiceAuditProblems'] == null ? undefined : ((json['invoiceAuditProblems'] as Array<any>).map(InvoiceAuditProblemFromJSON)),
        'invoiceAuditFirstOpenedDate': json['invoiceAuditFirstOpenedDate'] == null ? undefined : json['invoiceAuditFirstOpenedDate'],
        'invoiceAuditSnoozedUntilDate': json['invoiceAuditSnoozedUntilDate'] == null ? undefined : json['invoiceAuditSnoozedUntilDate'],
        'standardDryVanLinearFeetSvg': json['standardDryVanLinearFeetSvg'] == null ? undefined : json['standardDryVanLinearFeetSvg'],
        'linearFeetOverride': json['linearFeetOverride'] == null ? undefined : json['linearFeetOverride'],
        'pauseCustomsDocsReminders': json['pauseCustomsDocsReminders'] == null ? undefined : json['pauseCustomsDocsReminders'],
    };
}

export function ShipmentToJSON(value?: Shipment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'createdAt': value['createdAt'],
        'companyId': value['companyId'],
        'createdBy': value['createdBy'],
        'state': ShipmentStateToJSON(value['state']),
        'pickupDate': value['pickupDate'],
        'expectedDeliveryDate': value['expectedDeliveryDate'],
        'expectedDeliveryHours': value['expectedDeliveryHours'],
        'actualDeliveryDate': value['actualDeliveryDate'],
        'actualDeliveryTime': value['actualDeliveryTime'],
        'pickupLocation': LocationToJSON(value['pickupLocation']),
        'pickupContact': ContactToJSON(value['pickupContact']),
        'deliveryLocation': LocationToJSON(value['deliveryLocation']),
        'deliveryContact': ContactToJSON(value['deliveryContact']),
        'lineItems': value['lineItems'] == null ? undefined : ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'selectedQuoteId': value['selectedQuoteId'],
        'billOfLadingDocumentId': value['billOfLadingDocumentId'],
        'proNumber': value['proNumber'],
        'carrierPickupNumber': value['carrierPickupNumber'],
        'pickupReferenceNumber': value['pickupReferenceNumber'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'cancellationRequested': value['cancellationRequested'],
        'deleted': value['deleted'],
        'bookedAt': value['bookedAt'],
        'trackingId': value['trackingId'],
        'broker': BrokerToJSON(value['broker']),
        'isReviewingPriorToBooking': value['isReviewingPriorToBooking'],
        'trackingNote': value['trackingNote'],
        'deletionReason': value['deletionReason'],
        'emergencyContactName': value['emergencyContactName'],
        'emergencyContactPhoneNumber': value['emergencyContactPhoneNumber'],
        'emergencyContactPhoneNumberExtension': value['emergencyContactPhoneNumberExtension'],
        'customsDocsState': CustomsDocsStateToJSON(value['customsDocsState']),
        'expectedDeliveryDateFromApi': value['expectedDeliveryDateFromApi'],
        'requestTrackingUpdateMondayId': value['requestTrackingUpdateMondayId'],
        'requestDeliveryDateUpdateMondayId': value['requestDeliveryDateUpdateMondayId'],
        'handleMissedPickupMondayId': value['handleMissedPickupMondayId'],
        'billOfLadingNumber': value['billOfLadingNumber'],
        'finalizeBookingStages': value['finalizeBookingStages'],
        'deliveryAppointmentMade': value['deliveryAppointmentMade'],
        'deliveryAppointmentHours': value['deliveryAppointmentHours'],
        'outForDelivery': value['outForDelivery'],
        'late': value['late'],
        'numberDaysLate': value['numberDaysLate'],
        'missingInvoiceMondayId': value['missingInvoiceMondayId'],
        'handleMissedDeliveryMondayId': value['handleMissedDeliveryMondayId'],
        'invoiceNeedsApprovalMondayId': value['invoiceNeedsApprovalMondayId'],
        'originalPickupDate': value['originalPickupDate'],
        'handleBookPickupMondayId': value['handleBookPickupMondayId'],
        'bookedBy': value['bookedBy'],
        'totalWeight': value['totalWeight'],
        'totalVolume': value['totalVolume'],
        'density': value['density'],
        'totalHandlingUnits': value['totalHandlingUnits'],
        'declaredValue': value['declaredValue'],
        'declaredValueCurrency': CurrencyCodeToJSON(value['declaredValueCurrency']),
        'suggestedTrackingLine': value['suggestedTrackingLine'],
        'expectedDeliveryDateAtPickup': value['expectedDeliveryDateAtPickup'],
        'latestPredictedDeliveryDateAtPickup': value['latestPredictedDeliveryDateAtPickup'],
        'pauseAmyEmailsUntilDate': value['pauseAmyEmailsUntilDate'],
        'shipmentHash': value['shipmentHash'],
        'bookedByPhone': value['bookedByPhone'],
        'bookedByEmail': value['bookedByEmail'],
        'bookedByName': value['bookedByName'],
        'quotedByPhone': value['quotedByPhone'],
        'quotedByEmail': value['quotedByEmail'],
        'quotedByName': value['quotedByName'],
        'spotQuoteMondayId': value['spotQuoteMondayId'],
        'manualQuoting': value['manualQuoting'],
        'manualQuotingNotes': value['manualQuotingNotes'],
        'manualQuotingOpen': value['manualQuotingOpen'],
        'standardDryVanLinearFeet': value['standardDryVanLinearFeet'],
        'freightMissing': value['freightMissing'],
        'apolloQuotingTriaged': value['apolloQuotingTriaged'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'exclusiveUseNeeded': value['exclusiveUseNeeded'],
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyCodeToJSON(value['insuranceCurrency']),
        'outForDeliveryDate': value['outForDeliveryDate'],
        'hotFreight': value['hotFreight'],
        'pickupPackageDocumentId': value['pickupPackageDocumentId'],
        'appointmentDate': value['appointmentDate'],
        'apolloQuotingFlagged': value['apolloQuotingFlagged'],
        'freightMissingDate': value['freightMissingDate'],
        'freightMissingMondayId': value['freightMissingMondayId'],
        'challengeAllInvoices': value['challengeAllInvoices'],
        'branchId': value['branchId'],
        'tarpRequired': value['tarpRequired'],
        'deliveryDeadline': value['deliveryDeadline'],
        'doNotFetchFurtherCarrierInvoices': value['doNotFetchFurtherCarrierInvoices'],
        'pickupBoothNumber': value['pickupBoothNumber'],
        'deliveryBoothNumber': value['deliveryBoothNumber'],
        'isPickupBooked': value['isPickupBooked'],
        'advanceInterlineProNumber': value['advanceInterlineProNumber'],
        'advanceInterlineCarrierPickupNumber': value['advanceInterlineCarrierPickupNumber'],
        'advanceInterlineCarrierIdentifier': value['advanceInterlineCarrierIdentifier'],
        'advanceInterlineCarrierDisplayName': value['advanceInterlineCarrierDisplayName'],
        'beyondInterlineProNumber': value['beyondInterlineProNumber'],
        'beyondInterlineCarrierPickupNumber': value['beyondInterlineCarrierPickupNumber'],
        'beyondInterlineCarrierIdentifier': value['beyondInterlineCarrierIdentifier'],
        'beyondInterlineCarrierDisplayName': value['beyondInterlineCarrierDisplayName'],
        'customDocsDeliveredAt': value['customDocsDeliveredAt'],
        'customDocsSentTo': value['customDocsSentTo'],
        'pickupDeadline': value['pickupDeadline'],
        'problems': value['problems'],
        'tags': value['tags'],
        'invoiceAuditOpen': value['invoiceAuditOpen'],
        'invoiceAuditAssignee': value['invoiceAuditAssignee'],
        'invoiceAuditExisted': value['invoiceAuditExisted'],
        'invoiceAuditCustomerFrontConversationId': value['invoiceAuditCustomerFrontConversationId'],
        'invoiceAuditCarrierFrontConversationId': value['invoiceAuditCarrierFrontConversationId'],
        'invoiceAuditCarrierTurn': InvoiceAuditTurnToJSON(value['invoiceAuditCarrierTurn']),
        'invoiceAuditCustomerTurn': InvoiceAuditTurnToJSON(value['invoiceAuditCustomerTurn']),
        'invoiceAuditCarrierTurnTime': value['invoiceAuditCarrierTurnTime'],
        'invoiceAuditCustomerTurnTime': value['invoiceAuditCustomerTurnTime'],
        'invoiceAuditProblems': value['invoiceAuditProblems'] == null ? undefined : ((value['invoiceAuditProblems'] as Array<any>).map(InvoiceAuditProblemToJSON)),
        'invoiceAuditFirstOpenedDate': value['invoiceAuditFirstOpenedDate'],
        'invoiceAuditSnoozedUntilDate': value['invoiceAuditSnoozedUntilDate'],
        'standardDryVanLinearFeetSvg': value['standardDryVanLinearFeetSvg'],
        'linearFeetOverride': value['linearFeetOverride'],
        'pauseCustomsDocsReminders': value['pauseCustomsDocsReminders'],
    };
}


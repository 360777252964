/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface EditCompanyInput
 */
export interface EditCompanyInput {
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    billingAddressLine: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    billingCity: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    billingState: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof EditCompanyInput
     */
    billingCountry?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    billingPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    leadSource: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    temperature?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    shipmentFrequency?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCompanyInput
     */
    associatedProfessionalDomain: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCompanyInput
     */
    knownGroupEmailAddresses: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyInput
     */
    invoiceAuditNotes: string;
}

/**
 * Check if a given object implements the EditCompanyInput interface.
 */
export function instanceOfEditCompanyInput(value: object): boolean {
    if (!('companyId' in value)) return false;
    if (!('companyName' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('billingAddressLine' in value)) return false;
    if (!('billingCity' in value)) return false;
    if (!('billingState' in value)) return false;
    if (!('billingPostalCode' in value)) return false;
    if (!('leadSource' in value)) return false;
    if (!('associatedProfessionalDomain' in value)) return false;
    if (!('knownGroupEmailAddresses' in value)) return false;
    if (!('invoiceAuditNotes' in value)) return false;
    return true;
}

export function EditCompanyInputFromJSON(json: any): EditCompanyInput {
    return EditCompanyInputFromJSONTyped(json, false);
}

export function EditCompanyInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditCompanyInput {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'companyName': json['companyName'],
        'phone': json['phone'],
        'billingAddressLine': json['billingAddressLine'],
        'billingCity': json['billingCity'],
        'billingState': json['billingState'],
        'billingCountry': json['billingCountry'] == null ? undefined : CountryCodeFromJSON(json['billingCountry']),
        'billingPostalCode': json['billingPostalCode'],
        'leadSource': json['leadSource'],
        'temperature': json['temperature'] == null ? undefined : json['temperature'],
        'shipmentFrequency': json['shipmentFrequency'] == null ? undefined : json['shipmentFrequency'],
        'associatedProfessionalDomain': json['associatedProfessionalDomain'],
        'knownGroupEmailAddresses': json['knownGroupEmailAddresses'],
        'invoiceAuditNotes': json['invoiceAuditNotes'],
    };
}

export function EditCompanyInputToJSON(value?: EditCompanyInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'companyName': value['companyName'],
        'phone': value['phone'],
        'billingAddressLine': value['billingAddressLine'],
        'billingCity': value['billingCity'],
        'billingState': value['billingState'],
        'billingCountry': CountryCodeToJSON(value['billingCountry']),
        'billingPostalCode': value['billingPostalCode'],
        'leadSource': value['leadSource'],
        'temperature': value['temperature'],
        'shipmentFrequency': value['shipmentFrequency'],
        'associatedProfessionalDomain': value['associatedProfessionalDomain'],
        'knownGroupEmailAddresses': value['knownGroupEmailAddresses'],
        'invoiceAuditNotes': value['invoiceAuditNotes'],
    };
}


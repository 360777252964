import { CountryCode } from "../generated-openapi-client";
import { assertNever } from "./assertNever";

export function emojiForCountry(countryCode: CountryCode) {
  switch (countryCode) {
    case CountryCode.Ca:
      return "🇨🇦";
    case CountryCode.Us:
      return "🇺🇸";
    default:
      assertNever(countryCode); 
  }
}

import { DatePicker, Typography } from "antd";
import Tooltip from "antd/es/tooltip";
import { getCountriesOfHoliday } from "freightsimple-utilities";
import {
  getHolidaysByDate,
  isHoliday,
} from "freightsimple-utilities/dist/holidays/moment";
import moment from "moment";
import { isDateInThePast } from "../Helpers/isDateInThePast";
import { isWeekend } from "../Helpers/isWeekend";
import Spacer from "../Spacer";
import HorizontalStack from "./HorizontalStack";
import { WarningComponent } from "./WarningComponent";

const { Text } = Typography;

interface DateInputBaseProps<T> {
  date: T;
  setDate: (_: T) => void;
  doNotErrorOnPastDate?: boolean;
  compactErrorsAndWarnings?: boolean;
}

interface OptionalDateInputProps
  extends DateInputBaseProps<string | undefined> {
  allowClear?: boolean; // default true
}

export function DateInput(props: DateInputBaseProps<string>) {
  return (
    <OptionalDateInput
      {...props}
      setDate={function (d) {
        props.setDate(d!!);
      }}
      allowClear={false}
    />
  );
}

// This is a version that basically allows no date
export function OptionalDateInput(props: OptionalDateInputProps) {
  const isPast = isDateInThePast(props.date) && props.date !== undefined;
  const startOfDate = moment(props.date).clone().startOf("day");
  const weekend = isWeekend(startOfDate);
  const isHolidayResult = isHoliday(startOfDate);
  const status = isPast ? "error" : "";

  const momentDate = props.date
    ? moment(props.date).clone().startOf("day")
    : undefined;

  function holidayMessage(): string {
    return getHolidaysByDate(startOfDate)
      .map(
        (h) =>
          h.description + " in " + getCountriesOfHoliday(h).join(" and ") + "."
      )
      .join(" ");
  }

  return (
    <HorizontalStack verticalAlign="middle">
      <DatePicker
        style={{ width: "200px" }}
        // This is tricky because we need it to represent the date in PST
        // but if you give moment a raw date it assumes that it is GMT
        value={momentDate}
        onChange={async function (newMomentDate: moment.Moment | null) {
          if (newMomentDate !== null) {
            const newDate = newMomentDate.format("YYYY-MM-DD");
            props.setDate(newDate);
          } else {
            props.setDate(undefined);
          }
        }}
        status={status}
        allowClear={props.allowClear === undefined ? true : props.allowClear}
      />
      {isPast && !props.doNotErrorOnPastDate && (
        <>
          <Spacer width={8} />
          {!props.compactErrorsAndWarnings && (
            <Text>❌ Date is in the past</Text>
          )}
          {props.compactErrorsAndWarnings && (
            <Tooltip title="Date is in the past">
              <Text>❌</Text>
            </Tooltip>
          )}
        </>
      )}
      {weekend && (
        <>
          <Spacer width={8} />
          {!props.compactErrorsAndWarnings && (
            <Text>
              <WarningComponent /> Weekend
            </Text>
          )}
          {props.compactErrorsAndWarnings && (
            <Tooltip title="Weekend">
              <Text>
                <WarningComponent />
              </Text>
            </Tooltip>
          )}
        </>
      )}
      {props.date !== undefined && isHolidayResult && (
        <>
          <Spacer width={8} />
          {!props.compactErrorsAndWarnings && (
            <Tooltip title={holidayMessage()}>
              <Text>
                <WarningComponent /> Holiday
              </Text>
            </Tooltip>
          )}
          {props.compactErrorsAndWarnings && (
            <Tooltip title={"Holiday - " + holidayMessage()}>
              <Text>
                <WarningComponent />
              </Text>
            </Tooltip>
          )}
        </>
      )}
    </HorizontalStack>
  );
}

import { ReactNode } from "react";
import { WarningComponent } from "../Components/WarningComponent";
import { ShipmentReportInvoiceWarningState } from "../generated-openapi-client/models/ShipmentReportInvoiceWarningState";
import { assertNever } from "./assertNever";

export function describeInvoiceWarningState(
  state: ShipmentReportInvoiceWarningState | undefined
): ReactNode {
  if (state === undefined) {
    return <></>;
  }
  switch (state) {
    case ShipmentReportInvoiceWarningState.BookingSuspendedDueToOverdueInvoices:
      return <>🚫 Overdue</>;
    case ShipmentReportInvoiceWarningState.BookingSuspendedDueToOverCreditLimit:
      return <>🚫 Credit Limit</>;
    case ShipmentReportInvoiceWarningState.BookingWarningInvoicesCloseToOverdue:
      return (
        <>
          <WarningComponent marginRight={4} />
          Near Overdue
        </>
      );
    case ShipmentReportInvoiceWarningState.BookingWarningCloseToCreditLimit:
      return (
        <>
          <WarningComponent marginRight={4} />
          Credit Limit
        </>
      );
    case ShipmentReportInvoiceWarningState.NoWarning:
      return <>✅ OK</>;
    case ShipmentReportInvoiceWarningState.BookingSuspendedNeedsAccountVerification:
      return <>🚫 Verification Needed</>;
    default:
      assertNever(state);
  }
}

import { Tag } from "antd";
import { ShipmentState } from "../generated-openapi-client/models/ShipmentState";
import { assertNever } from "../Helpers/assertNever";
import { daysAgo } from "../Helpers/daysAgo";
import { describeState } from "../Helpers/describeState";
import Colors from "./Colors";
import { WarningComponent } from "./WarningComponent";

interface ShipmentStatusTagProps {
  status: ShipmentState | undefined;
  deliveryDate?: string;
}

function backgroundColorForShipmentState(state: ShipmentState) {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return Colors.Gold;
    case ShipmentState.BookingFailed:
      return Colors.Red;
    case ShipmentState.BookingRequested:
      return Colors.Red;
    case ShipmentState.Cancelled:
      return "black";
    case ShipmentState.Delivered:
      return Colors.DarkGreen;
    case ShipmentState.InTransit:
      return Colors.Blue;
    case ShipmentState.QuoteRequested:
      return Colors.Red;
    case ShipmentState.Quoted:
      return Colors.MidGray;
    case ShipmentState.OnHold:
      return Colors.LightBlue;
    case ShipmentState.Lost:
      return Colors.DarkGray;
    default:
      assertNever(state);
  }
}

function color(state: ShipmentState) {
  switch (state) {
    case ShipmentState.BookingConfirmed:
      return "black";
    case ShipmentState.BookingFailed:
      return "white";
    case ShipmentState.BookingRequested:
      return "white";
    case ShipmentState.Cancelled:
      return "white";
    case ShipmentState.Delivered:
      return "white";
    case ShipmentState.InTransit:
      return "white";
    case ShipmentState.QuoteRequested:
      return "white";
    case ShipmentState.Quoted:
      return "white";
    case ShipmentState.Lost:
      return "white";
    case ShipmentState.OnHold:
      return "white";
    default:
      assertNever(state);
  }
}

export function ShipmentStatusTag(props: ShipmentStatusTagProps) {
  if (props.status === undefined) {
    return (
      <div>
        <WarningComponent /> Missing state!
      </div>
    );
  }

  function emojiForDeliveryDate() {
    const d = daysAgo(props.deliveryDate);
    if (d > 30) {
      return `🚨 `;
    } else if (d > 20) {
      return <WarningComponent />;
    } else {
      return "";
    }
  }

  function deliveryDateText() {
    if (props.deliveryDate === undefined) {
      return "";
    }

    return `: ${emojiForDeliveryDate()}${daysAgo(props.deliveryDate)} days`;
  }

  return (
    <Tag color={backgroundColorForShipmentState(props.status)}>
      <div style={{ color: color(props.status) }}>
        {describeState(props.status)}
        {deliveryDateText()}
      </div>
    </Tag>
  );
}

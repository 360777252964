import { Button, Form, Input, message, Modal, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import HorizontalStack from "../../Components/HorizontalStack";
import {
  CarrierServicePair,
  SelectCarrierAndService,
} from "../../Components/SelectCarrierAndService";
import { EmailDescription } from "../../generated-openapi-client";
import { RequestQuoteViaEmailRequest } from "../../generated-openapi-client/apis/QuotesApi";
import { CarrierServiceType } from "../../generated-openapi-client/models/CarrierServiceType";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { TabProps } from "./TabProps";

const { Text } = Typography;

export function EmailForQuoteButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;
  const createQuotesApi = useQuotesApi();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [sending, setSending] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState<
    CarrierServicePair | undefined
  >();
  const [emailDescription, setEmailDescription] = useState<
    EmailDescription | undefined
  >();
  const [extraText, setExtraText] = useState("");
  const [overrideCarriers, setOverrideCarriers] = useState(false);
  const [ignoreDimensionChecks, setIgnoreDimensionChecks] = useState(false);
  const [overrideEmailAddress, setOverrideEmailAddress] = useState("");
  const [carriersThatProvideEmailQuotes, setCarriersThatProvideEmailQuotes] =
    useState<CarrierServiceType[] | undefined>();

  async function fetchCarriersThatProvideEmailQuotes() {
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.fetchCarriersThatProvideEmailQuotes({
      shipmentId,
      ignoreDimensionChecks,
    });

    setCarriersThatProvideEmailQuotes(response);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        fetchCarriersThatProvideEmailQuotes();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible, ignoreDimensionChecks, shipmentId]
  );

  async function fetchPreview() {
    if (selectedCarrier === undefined && !overrideCarriers) {
      return;
    }
    const quotesApi = await createQuotesApi();
    const response = await quotesApi.previewRequestQuoteEmail({
      shipmentId,
      extraText,
      carrierIdentifier: selectedCarrier?.carrierIdentifier || "",
      serviceIdentifier: selectedCarrier?.serviceIdentifier || "",
      overrideCarriers,
      overrideEmailAddress,
    });
    setEmailDescription(response);
  }

  useEffect(
    function () {
      if (isModalVisible) {
        fetchPreview();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible, selectedCarrier, extraText, JSON.stringify(props)]
  );

  function filterPotentialCarriers() {
    if (carriersThatProvideEmailQuotes === undefined) {
      return [];
    }

    const emailQuoteRequests =
      props.shipmentData.manageQuotesTab.emailQuoteRequests;
    return carriersThatProvideEmailQuotes.filter(function (o) {
      return !emailQuoteRequests.some(
        (eqr) =>
          o.carrierIdentifier === eqr.request.carrierIdentifier &&
          o.serviceIdentifier === eqr.request.serviceIdentifier
      );
    });
  }

  const potentialCarriers = filterPotentialCarriers();

  const showModal = () => {
    const pickupDate = props.shipmentData.shipment.shipment.pickupDate;
    const isPickupInThePast = isDateInThePast(pickupDate);
    if (isPickupInThePast) {
      message.error(`Pickup date is in the past. Please correct first`);
      return;
    }
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (selectedCarrier === undefined && !overrideCarriers) {
      return;
    }

    setSending(true);
    try {
      const quotesApi = await createQuotesApi();

      const request: RequestQuoteViaEmailRequest = {
        shipmentId,
        extraText,
        carrierIdentifier: selectedCarrier?.carrierIdentifier ?? "",
        serviceIdentifier: selectedCarrier?.serviceIdentifier ?? "",
        overrideCarriers,
        overrideEmailAddress,
      };

      await quotesApi.requestQuoteViaEmail(request);
      await props.onRefresh();
      setIsModalVisible(false);
      message.success(
        "Quote requested. Email sent. Monday Item potentially created."
      );

      // Deselect the carrier - as we won't want to send it twice
      setSelectedCarrier(undefined);
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setSending(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title={
          <HorizontalStack align="spread" style={{ marginRight: "32px" }}>
            <div>✉️ Email for quote</div>
            <ButtonRow>
              <Form.Item label="Override Carriers?">
                <Switch
                  checked={overrideCarriers}
                  onChange={setOverrideCarriers}
                />
              </Form.Item>
              <Form.Item label="Ignore Dimension Checks?">
                <Switch
                  checked={ignoreDimensionChecks}
                  onChange={setIgnoreDimensionChecks}
                />
              </Form.Item>
            </ButtonRow>
          </HorizontalStack>
        }
        visible={isModalVisible}
        okText="✉️ Email for quote"
        onOk={handleOk}
        confirmLoading={sending}
        onCancel={handleCancel}
        width={1450}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 12, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          {!overrideCarriers && (
            <Form.Item label="Carrier">
              <SelectCarrierAndService
                selectedCarrierService={selectedCarrier}
                setSelectedCarrierService={setSelectedCarrier}
                overrideCarriers
                carriers={potentialCarriers}
              />
            </Form.Item>
          )}
          <Form.Item label="Extra Text">
            <Input
              value={extraText}
              onChange={function (e) {
                setExtraText(e.target.value);
              }}
            />
          </Form.Item>
          {!overrideCarriers && (
            <Form.Item label="Email Addresses">
              <Text>{emailDescription?.addresses.join(", ")}</Text>
            </Form.Item>
          )}
          {overrideCarriers && (
            <Form.Item label="Email Address">
              <Input
                value={overrideEmailAddress}
                onChange={function (e) {
                  setOverrideEmailAddress(e.target.value);
                }}
              />
            </Form.Item>
          )}
          <Form.Item label="Subject">
            <Text>{emailDescription?.subject}</Text>
          </Form.Item>
          <Form.Item label="Email Body">
            <div style={{ border: "1px solid #eee", padding: "16px" }}>
              {emailDescription && (
                <>
                  <div
                    title="Email Preview"
                    dangerouslySetInnerHTML={{
                      __html: emailDescription.emailBody,
                    }}
                  ></div>
                </>
              )}
              {!emailDescription && <>No preview</>}
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Button onClick={showModal} type="link">
        ✉️ Email for Quote
      </Button>
    </>
  );
}

import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Select,
  Spin,
  Switch,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../../Apis/Apis";
import CarrierLogo from "../../Components/CarrierLogo";
import { DateInput } from "../../Components/DateInput";
import HorizontalStack from "../../Components/HorizontalStack";
import { InfoBubble } from "../../Components/InfoBubble";
import Stack from "../../Components/Stack";
import {
  CarrierServicePair,
  CountryCode,
  LineItem,
  Location,
} from "../../generated-openapi-client";
import { CarrierServiceType } from "../../generated-openapi-client/models/CarrierServiceType";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { useOnce } from "../../Hooks/useOnce";
import Spacer from "../../Spacer";
import { CurrencySelector } from "../CurrencySelector";
import { AddLineItemButton } from "./AddLineItemButton";
import { LineItemsTable } from "./LineItemsTable";
import { QuoteGroupSelector } from "./QuoteGroupSelector";
import { TabProps } from "./TabProps";
import { LocationTypeDropdown } from "./LocationTypeDropdown";

const { Title } = Typography;
const { Option } = Select;

interface QuoteLocationEditorProps {
  location: Location;
  setLocation: (location: Location) => void;
  showAppointment: boolean;
  adjective: string;
}

function QuoteLocationEditor(props: QuoteLocationEditorProps) {
  const location = props.location;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const postalCode = props.location.address?.postalCode!!;
  const [postalCodeIsDirty, setPostalCodeIsDirty] = useState(false);
  const city = props.location.address?.city;
  const state = props.location.address?.stateOrProvinceCode;
  const country =
    props.location.address?.countryCode === CountryCode.Ca ? "🇨🇦" : "🇺🇸";
  const cityStateCountry = `${country} ${city}, ${state}, ${props.location.address?.countryCode}`;
  const [lookingUpPostalCode, setLookingUpPostalCode] = useState(false);
  const createQuotesApi = useQuotesApi();
  async function setPostalCode(postalCode: string) {
    props.setLocation({
      ...location,
      address: {
        ...location.address,
        postalCode,
      },
    });
    setPostalCodeIsDirty(true);
  }

  async function lookupCityState() {
    if (!postalCodeIsDirty) {
      return;
    }
    setLookingUpPostalCode(true);
    try {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.lookupPostalCode1({ postalCode });
      
      props.setLocation({
        ...location,
        address: {
          ...location.address,
          addressLine: "",
          addressLine2: "",
          countryCode: response.countryCode,
          stateOrProvinceCode: response.stateOrProvinceCode,
          city: response.city,
        },
        latitudeLongitude: {
          latitude: response.latitude,
          longitude: response.longitude,
        },
      });
      setPostalCodeIsDirty(true);
    } catch (e) {
      message.error("Postal code lookup failed");
    }
    setLookingUpPostalCode(false);
  }

  const suffix = lookingUpPostalCode ? <Spin size="small" /> : cityStateCountry;

  const inside = props.location.accessorials?.includes("LOGISTICS_INSIDE");
  const liftGate = props.location.accessorials?.includes(
    "LOGISTICS_LIFT_GATE_REQUIRED"
  );
  const appointmentRequired = props.location.accessorials?.includes(
    "SCHEDULING_APPOINTMENT_REQUIRED"
  );

  function onToggle(accessorialCode: string) {
    let updatedAccessorials = [...(props.location.accessorials || [])];

    if (props.location.accessorials?.includes(accessorialCode)) {
      // It's there, so remove it
      updatedAccessorials = updatedAccessorials.filter(
        (a) => a !== accessorialCode
      );
    } else {
      // It's not there, so add it
      updatedAccessorials.push(accessorialCode);
    }

    props.setLocation({
      ...location,
      accessorials: updatedAccessorials,
    });
  }

  function onToggleLiftGate() {
    onToggle("LOGISTICS_LIFT_GATE_REQUIRED");
  }

  function onToggleInside() {
    onToggle("LOGISTICS_INSIDE");
  }

  function onToggleAppointment() {
    onToggle("SCHEDULING_APPOINTMENT_REQUIRED");
  }

  function setLocationType(type: string) {
    props.setLocation({
      ...location,
      locationType: type,
    });
  }

  return (
    <Form
      style={{ width: "600px" }}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 8, offset: 1 }}
      layout="horizontal"
      colon={false}
    >
      <Form.Item label="Postal Code">
        <Input
          value={props.location.address?.postalCode}
          onChange={function (e) {
            setPostalCode(e.target.value);
          }}
          onBlur={lookupCityState}
          suffix={suffix}
          style={{ width: "300px" }}
        />
      </Form.Item>
      <Form.Item label="Location Type">
        <LocationTypeDropdown
          value={location.locationType}
          setValue={setLocationType}
        />
      </Form.Item>
      <Form.Item label="Accessorials">
        <HorizontalStack align="left">
          <Checkbox
            checked={liftGate}
            onChange={function () {
              onToggleLiftGate();
            }}
          >
            Liftgate
          </Checkbox>
          <Checkbox
            checked={inside}
            onChange={function () {
              onToggleInside();
            }}
          >
            Inside
          </Checkbox>
          {props.showAppointment && (
            <Checkbox
              checked={appointmentRequired}
              onChange={function () {
                onToggleAppointment();
              }}
            >
              Appointment
            </Checkbox>
          )}
        </HorizontalStack>
      </Form.Item>
    </Form>
  );
}

export function AddQuoteGroupButton(props: TabProps) {
  const shipmentId = props.shipmentData.shipment.shipment.shipmentId!!;
  const createQuotesApi = useQuotesApi();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickupLocation, setPickupLocation] = useState(
    props.shipmentData.shipment.shipment.pickupLocation!!
  );
  const [deliveryLocation, setDeliveryLocation] = useState(
    props.shipmentData.shipment.shipment.deliveryLocation!!
  );
  const [lineItems, setLineItems] = useState(
    props.shipmentData.shipment.shipment.lineItems!!
  );
  const [quoting, setQuoting] = useState(false);
  const [quoteGroupName, setQuoteGroupName] = useState("");
  const [allCarriers, setAllCarriers] = useState(true);
  const [carriers, setCarriers] = useState<undefined | CarrierServiceType[]>();
  const [selectedCarriers, setSelectedCarriers] = useState<
    CarrierServicePair[]
  >([]);
  const [pickupDate, setPickupDate] = useState<string>(
    props.shipmentData.shipment.shipment.pickupDate!!
  );
  const [disableStandardLimits, setDisableStandardLimits] = useState(false);

  const [addInsuranceToShipment, setAddInsuranceToShipment] = useState(
    props.shipmentData.shipment.shipment.addInsuranceToShipment!!
  );
  const [insuranceAmount, setInsuranceAmount] = useState(
    props.shipmentData.shipment.shipment.insuranceAmount!!
  );
  const [insuranceCurrency, setInsuranceCurrency] = useState(
    props.shipmentData.shipment.shipment.insuranceCurrency!!
  );

  
  const [timeRemaining, setTimeRemaining] = useState(30);

  useOnce(async function () {
    const quotesApi = await createQuotesApi();
    const response =
      await quotesApi.listCarrierAndServiceTypesThatSupportInstantQuoting();
    setCarriers(response);
  });

  useEffect(
    function () {
      if (isModalVisible) {
        // Reset the line items each time we open the modal
        setLineItems(props.shipmentData.shipment.shipment.lineItems!!);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalVisible]
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function wait30s() {
    return new Promise<void>(function (resolve) {
      const interval = setInterval(function () {
        setTimeRemaining((c) => c - 1);
      }, 1000);

      setTimeout(function () {
        clearInterval(interval);
        resolve();
      }, 30000);
    });
  }

  async function handleOk() {
    const pickupDateIsInThePast = isDateInThePast(pickupDate);
    if (pickupDateIsInThePast) {
      message.warn("Pickup Date is in the past");
      return;
    }

    setQuoting(true);
    try {
      const quotesApi = await createQuotesApi();
      await quotesApi.requestAdditionalQuotes({
        requestAdditionalQuotesBody: {
          shipmentId,
          pickupDate,
          quoteGroupName,
          pickupLocation,
          deliveryLocation,
          lineItems,
          allCarriers,
          selectedCarriers,
          disableStandardLimits,
          addInsuranceToShipment,
          insuranceAmount,
          insuranceCurrency,
        },
      });
      await wait30s();
      await props.onRefresh();
      setIsModalVisible(false);
      message.success("Quotes completed");
    } catch (e) {
      message.error(`Oops. Something went wrong. ${e}`);
    }
    setQuoting(false);
  }

  

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const okText = quoting
    ? `Creating Quote Group (${timeRemaining})`
    : "Create Quote Group";

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Fetch More Quotes"
        visible={isModalVisible}
        okText={okText}
        onOk={handleOk}
        confirmLoading={quoting}
        onCancel={handleCancel}
        width={1450}
      >
        <Title level={5}>General Info</Title>
        <Form
          style={{ width: "1200px" }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Quote Group Name">
            <QuoteGroupSelector
              value={quoteGroupName}
              onChange={setQuoteGroupName}
              shipmentData={props.shipmentData}
            />
          </Form.Item>
          <Form.Item label="Carriers" style={{ width: "1200px" }}>
            <HorizontalStack width="100%" align="left">
              <Radio.Group
                value={allCarriers}
                onChange={function (e) {
                  setAllCarriers(e.target.value);
                }}
                defaultValue={true}
                buttonStyle="solid"
                style={{ width: "400px" }}
              >
                <Radio.Button value={true}>All Carriers</Radio.Button>
                <Radio.Button value={false}>Selected Carriers</Radio.Button>
              </Radio.Group>
              <Spacer width={16} />
              {!allCarriers && (
                <>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select Carriers"
                    onChange={function (selections) {
                      const sc = selections.map(function (s: string) {
                        const parts = s.split(" - ");
                        
                        return {
                          carrierIdentifier: parts[0],
                          serviceIdentifier: parts[1],
                        };
                      });

                      setSelectedCarriers(sc);
                    }}
                    optionLabelProp="label"
                  >
                    {carriers?.map(function (carrierService) {
                      
                      return (
                        <Option
                          value={`${carrierService.carrierIdentifier} - ${carrierService.serviceIdentifier}`}
                          label={`${carrierService.carrierIdentifier} - ${carrierService.serviceIdentifier}`}
                        >
                          <div className="demo-option-label-item">
                            <HorizontalStack>
                              <CarrierLogo
                                carrierIdentifier={
                                  carrierService.carrierIdentifier
                                }
                                brokeredCarrierIdentifier={undefined}
                                width={30}
                                height={25}
                              />
                              <Spacer width={16} />
                              <div style={{ fontSize: "16px" }}>
                                {carrierService.carrierIdentifier} -{" "}
                                {carrierService.serviceIdentifier}
                              </div>
                            </HorizontalStack>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </>
              )}
            </HorizontalStack>
          </Form.Item>
          <Form.Item
            label={
              <HorizontalStack>
                <div>Disable Standard Limits</div>
                <Spacer width={4} />
                <InfoBubble overlay="By default we impose various limits to make sure we don't quote for things the carrier can't handle. Use this to turn off those safety guards." />
              </HorizontalStack>
            }
          >
            <Switch
              checked={disableStandardLimits}
              onChange={setDisableStandardLimits}
            />
          </Form.Item>
          <Form.Item label="Add Insurance To Shipment">
            <Switch
              checked={addInsuranceToShipment}
              onChange={setAddInsuranceToShipment}
            />
          </Form.Item>
          <Form.Item label="Insurance Amount">
            <InputNumber
              value={insuranceAmount}
              onChange={setInsuranceAmount}
              prefix={"$"}
            />
          </Form.Item>
          <Form.Item label="Insurance Currency">
            <CurrencySelector
              currency={insuranceCurrency}
              setCurrency={setInsuranceCurrency}
            />
          </Form.Item>
        </Form>
        <Spacer height={32} />
        <Stack align="left">
          <Title level={5}>Pickup Date</Title>
          <DateInput date={pickupDate} setDate={setPickupDate} />
        </Stack>
        <Spacer height={32} />
        <HorizontalStack>
          <Stack align="left">
            <Title level={5}>Pickup Location</Title>
            <QuoteLocationEditor
              location={pickupLocation}
              setLocation={setPickupLocation}
              adjective="Pickup"
              showAppointment={false}
            />
          </Stack>
          <Spacer width={64} />
          <Stack align="left">
            <Title level={5}>Delivery Location</Title>
            <QuoteLocationEditor
              location={deliveryLocation}
              setLocation={setDeliveryLocation}
              adjective="Delivery"
              showAppointment={true}
            />
          </Stack>
        </HorizontalStack>
        <Spacer height={32} />
        <Title level={5}>Line Items</Title>
        <LineItemsTable
          lineItems={lineItems}
          editable
          updateLineItems={setLineItems}
        />
        <Spacer height={32} />
        <AddLineItemButton
          addLineItem={function (newLineItem: LineItem) {
            
            setLineItems((_lineItems) => [..._lineItems, newLineItem]);
          }}
        />
      </Modal>
      <Button onClick={showModal}>⚡️ Fetch More Quotes</Button>
    </>
  );
}

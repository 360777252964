import { Select } from "antd";
import { useEffect, useState } from "react";
import { useQuotesApi } from "../Apis/Apis";
import { useOnce } from "../Hooks/useOnce";
import Spacer from "../Spacer";
import { CarrierServiceType } from "../generated-openapi-client/models/CarrierServiceType";
import CarrierLogo from "./CarrierLogo";
import HorizontalStack from "./HorizontalStack";

const { Option } = Select;

export interface CarrierServicePair {
  carrierIdentifier: string;
  serviceIdentifier: string;
}

interface SelectCarrierAndServiceProps {
  selectedCarrierService: CarrierServicePair | undefined;
  setSelectedCarrierService: (_: CarrierServicePair) => void;
  carriers?: CarrierServiceType[];
  overrideCarriers?: boolean;
}

export function SelectCarrierAndService(props: SelectCarrierAndServiceProps) {
  const createQuotesApi = useQuotesApi();
  const [carriers, setCarriers] = useState<undefined | CarrierServiceType[]>(
    props.carriers
  );

  useEffect(
    function () {
      setCarriers(props.carriers);
    },
    [props.carriers]
  );

  const { setSelectedCarrierService, selectedCarrierService } = props;

  useOnce(async function () {
    if (
      props.overrideCarriers === undefined ||
      props.overrideCarriers === false
    ) {
      const quotesApi = await createQuotesApi();
      const response = await quotesApi.listCarrierAndServiceTypes();
      setCarriers(response);
    }
  });

  function label(cs: CarrierServicePair | undefined) {
    if (cs === undefined) {
      return undefined;
    }
    return `${cs.carrierIdentifier} - ${cs.serviceIdentifier}`;
  }

  return (
    <Select
      showSearch
      style={{ width: "100%" }}
      placeholder="Select Carriers"
      onChange={function (s) {
        const parts = s.split(" - ");

        setSelectedCarrierService({
          carrierIdentifier: parts[0],
          serviceIdentifier: parts[1],
        });
      }}
      value={label(selectedCarrierService)}
      optionLabelProp="label"
    >
      {carriers?.map(function (carrierService) {
        return (
          <Option value={label(carrierService)} label={label(carrierService)}>
            <div className="demo-option-label-item">
              <HorizontalStack>
                <CarrierLogo
                  carrierIdentifier={carrierService.carrierIdentifier}
                  brokeredCarrierIdentifier={undefined}
                  width={30}
                  height={25}
                />
                <Spacer width={16} />
                <div style={{ fontSize: "16px" }}>
                  {carrierService.carrierIdentifier} -{" "}
                  {carrierService.serviceIdentifier}
                </div>
              </HorizontalStack>
            </div>
          </Option>
        );
      })}
    </Select>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaudeShipmentConversationItemResult
 */
export interface ClaudeShipmentConversationItemResult {
    /**
     * 
     * @type {string}
     * @memberof ClaudeShipmentConversationItemResult
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ClaudeShipmentConversationItemResult
     */
    html: string;
}

/**
 * Check if a given object implements the ClaudeShipmentConversationItemResult interface.
 */
export function instanceOfClaudeShipmentConversationItemResult(value: object): boolean {
    if (!('role' in value)) return false;
    if (!('html' in value)) return false;
    return true;
}

export function ClaudeShipmentConversationItemResultFromJSON(json: any): ClaudeShipmentConversationItemResult {
    return ClaudeShipmentConversationItemResultFromJSONTyped(json, false);
}

export function ClaudeShipmentConversationItemResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaudeShipmentConversationItemResult {
    if (json == null) {
        return json;
    }
    return {
        
        'role': json['role'],
        'html': json['html'],
    };
}

export function ClaudeShipmentConversationItemResultToJSON(value?: ClaudeShipmentConversationItemResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'role': value['role'],
        'html': value['html'],
    };
}


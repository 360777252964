import { Form, InputNumber, Switch } from "antd";
import moment from "moment";
import { useState } from "react";
import { ButtonRow } from "../../Components/ButtonRow";
import CarrierLogo from "../../Components/CarrierLogo";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Dollar } from "../../Components/Dollar";
import { Percentage } from "../../Components/Percentage";
import Stack from "../../Components/Stack";
import { assertNever } from "../../Helpers/assertNever";
import { calculateMargin } from "../../Helpers/calculateMargin";
import { groupBy } from "../../Helpers/groupBy";
import { sum } from "../../Helpers/sum";
import Spacer from "../../Spacer";
import {
  ShipmentReport,
  ShipmentReportSettlementStatus,
} from "../../generated-openapi-client";
import { PeriodFormats } from "../FinanceComponents/generatePeriodInfo";
import { OptionalEnumDropdown } from "../ViewShipmentScreenComponents/EnumDropdown";
import { ProfitLossGroupByGraph } from "./ProfitLossGroupByGraph";
import { ProfitLossGroupType } from "./ProfitLossGroupType";

export enum SummarizeBelowColumn {
  MoneyIn = "MoneyIn",
  MoneyOut = "MoneyOut",
  Profit = "Profit",
}

const SummarizeBelowColumnDropdown = OptionalEnumDropdown<
  SummarizeBelowColumn,
  typeof SummarizeBelowColumn
>(SummarizeBelowColumn, "Do not summarize", function (et) {
  switch (et) {
    case SummarizeBelowColumn.MoneyIn:
      return "Money In";
    case SummarizeBelowColumn.MoneyOut:
      return "Money Out";
    case SummarizeBelowColumn.Profit:
      return "Profit";
    default:
      assertNever(et);
  }
});

interface ProfitLossGroupByTableProps {
  data: ShipmentReport[];
  groupBy: ProfitLossGroupType;
}

export type ProfitLossGroupedType = { key: string; value: ShipmentReport[] };

export function sumMoneyIn(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.moneyInCad!!);
}

export function sumMoneyOut(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.moneyOutCad!!);
}

export function sumProfit(item: ProfitLossGroupedType) {
  return sum(item.value, (l) => l.profit!!);
}

export function margin(item: ProfitLossGroupedType) {
  return calculateMargin(sumMoneyIn(item), sumMoneyOut(item));
}

export function ProfitLossGroupByTable(props: ProfitLossGroupByTableProps) {
  const columns: DataTableColumn<ProfitLossGroupedType>[] = [];

  const [hideMarginAboveTwentyPercent, setHideMarginAboveTwentyPercent] =
    useState(false);
  const [summarizeBelow, setSummarizeBelow] = useState<
    SummarizeBelowColumn | undefined
  >();
  const [summarizeBelowAmount, setSummarizeBelowAmount] = useState(1000);

  function potentiallySummarizeBelow(items: ProfitLossGroupedType[]) {
    if (summarizeBelow === undefined) {
      return items;
    } else {
      const newItems = [];
      const summaryLines: ShipmentReport[] = [];

      items.forEach(function (item) {
        const moneyIn = sumMoneyIn(item);
        const moneyOut = sumMoneyOut(item);
        const profit = sumProfit(item);

        let summaryValue;
        if (summarizeBelow === SummarizeBelowColumn.MoneyIn) {
          summaryValue = moneyIn;
        } else if (summarizeBelow === SummarizeBelowColumn.MoneyOut) {
          summaryValue = moneyOut;
        } else if (summarizeBelow === SummarizeBelowColumn.Profit) {
          summaryValue = profit;
        } else {
          throw new Error("Should not be here");
        }

        const shouldSummarizeRow = summaryValue < summarizeBelowAmount;

        if (shouldSummarizeRow) {
          const summaryLine: ShipmentReport = {
            moneyInCad: moneyIn,
            moneyOutCad: moneyOut,
            profit: profit,
            settlementStatus: ShipmentReportSettlementStatus.Projected,
          };
          summaryLines.push(summaryLine);
        } else {
          newItems.push(item);
        }
      });

      const summaryRow: ProfitLossGroupedType = {
        key: "Other",
        value: summaryLines,
      };

      newItems.push(summaryRow);

      return newItems;
    }
  }

  const groupedData = potentiallySummarizeBelow(
    groupBy(props.data, function (line) {
      switch (props.groupBy) {
        case ProfitLossGroupType.Carrier:
          return line.carrierIdentifier!!;
        case ProfitLossGroupType.Company:
          return line.companyName!!;
        case ProfitLossGroupType.LeadSource:
          return line.companyLeadSource ?? "-";
        case ProfitLossGroupType.Year:
          return moment(line.bookedAt).format("YYYY");
        case ProfitLossGroupType.Quarter:
          return moment(line.bookedAt).format(PeriodFormats.Quarterly);
        case ProfitLossGroupType.Month:
          return moment(line.bookedAt).format("MMMM YYYY");
        case ProfitLossGroupType.Week:
          return moment(line.bookedAt).format("W YYYY");
        case ProfitLossGroupType.DayOfWeek:
          return moment(line.bookedAt).format("dddd");
        default:
          assertNever(props.groupBy);
      }
    }).filter(function (groupItem) {
      if (hideMarginAboveTwentyPercent) {
        return margin(groupItem) < 20;
      } else {
        return true;
      }
    })
  );

  if (props.groupBy === ProfitLossGroupType.Carrier) {
    columns.push({
      title: "Carrier",
      render: (o) => (
        <CarrierLogo
          carrierIdentifier={o.key}
          brokeredCarrierIdentifier={undefined}
          width={40}
          height={30}
        />
      ),
    });
  } else if (props.groupBy === ProfitLossGroupType.Company) {
    columns.push({
      title: "Company",
      render: (o) => <div>{o.key}</div>,
    });
  } else if (props.groupBy === ProfitLossGroupType.LeadSource) {
    columns.push({
      title: "Lead Source",
      render: (o) => <div>{o.key}</div>,
    });
  } else if (props.groupBy === ProfitLossGroupType.Year) {
    columns.push({
      title: "Year",
      render: (o) => <div>{o.key}</div>,
      sorter: function (a, b) {
        return (
          moment(a.key, "YYYY").valueOf() - moment(b.key, "YYYY").valueOf()
        );
      },
    });
  } else if (props.groupBy === ProfitLossGroupType.Quarter) {
    columns.push({
      title: "Quarter",
      render: (o) => <div>{o.key}</div>,
      sorter: function (a, b) {
        return (
          moment(a.key, PeriodFormats.Quarterly).valueOf() -
          moment(b.key, PeriodFormats.Quarterly).valueOf()
        );
      },
    });
  } else if (props.groupBy === ProfitLossGroupType.Month) {
    columns.push({
      title: "Month",
      render: (o) => <div>{o.key}</div>,
      sorter: function (a, b) {
        return (
          moment(a.key, "MMMM YYYY").valueOf() -
          moment(b.key, "MMMM YYYY").valueOf()
        );
      },
    });
  } else if (props.groupBy === ProfitLossGroupType.Week) {
    columns.push({
      title: "Week",
      render: (o) => <div>{moment(o.key, "W YYYY").format("Do MMM yyyy")}</div>,
      sorter: function (a, b) {
        return (
          moment(a.key, "W YYYY").valueOf() - moment(b.key, "W YYYY").valueOf()
        );
      },
    });
  } else if (props.groupBy === ProfitLossGroupType.DayOfWeek) {
    columns.push({
      title: "Day of week",
      render: (o) => <div>{o.key}</div>,
      sorter: function (a, b) {
        return (
          moment(a.key, "dddd").valueOf() - moment(b.key, "dddd").valueOf()
        );
      },
    });
  } else {
    throw new Error("Should not be here");
  }

  columns.push({
    title: "Number Shipments",
    render: (o) => <div>{o.value.length}</div>,
    sorter: function (a, b) {
      return a.value.length - b.value.length;
    },
  });

  columns.push({
    title: "Money In",
    render: (o) => <Dollar>{sumMoneyIn(o)}</Dollar>,
    sorter: function (a, b) {
      return sumMoneyIn(a) - sumMoneyIn(b);
    },
  });

  columns.push({
    title: "Money Out",
    render: (o) => <Dollar>{sumMoneyOut(o)}</Dollar>,
    sorter: function (a, b) {
      return sumMoneyOut(a) - sumMoneyOut(b);
    },
  });

  columns.push({
    title: "Margin",
    render: (o) => <Percentage colored>{margin(o)}</Percentage>,
    sorter: function (a, b) {
      return margin(a) - margin(b);
    },
  });

  columns.push({
    title: "Profit",
    render: (o) => <Dollar colored>{sumProfit(o)}</Dollar>,
    sorter: function (a, b) {
      return sumProfit(a) - sumProfit(b);
    },
  });

  return (
    <Stack align="left">
      <ButtonRow>
        <Form.Item label="Hide margin above 20%">
          <Switch
            checked={hideMarginAboveTwentyPercent}
            onChange={setHideMarginAboveTwentyPercent}
          />
        </Form.Item>
        <Spacer width={32} />
        <Form.Item label="Summarize Below">
          <SummarizeBelowColumnDropdown
            value={summarizeBelow}
            setValue={setSummarizeBelow}
          />
        </Form.Item>
        {summarizeBelow !== undefined && (
          <Form.Item label="Amount">
            <InputNumber
              value={summarizeBelowAmount}
              onChange={setSummarizeBelowAmount}
              prefix="$"
            />
          </Form.Item>
        )}
      </ButtonRow>
      {summarizeBelow !== undefined && (
        <ProfitLossGroupByGraph
          data={groupedData}
          summarizeBelow={summarizeBelow}
        />
      )}
      <DataTable pagination={false} columns={columns} data={groupedData} />
      <div>Rows : {groupedData.length}</div>
    </Stack>
  );
}

import moment from "moment";
import {
  QuoteFilterType,
  ShipmentReport,
} from "../../../generated-openapi-client";
import { assertNever } from "../../../Helpers/assertNever";

function applyNoQuotesFilter(q: ShipmentReport) {
  if (q.quotes!!.length === 0) {
    return true;
  } else {
    return false;
  }
}

function applyNotManyQuotesFilter(q: ShipmentReport) {
  if (q.quotes!!.length > 0 && q.quotes!!.length < 4) {
    return true;
  } else {
    return false;
  }
}

function applySuspiciouslyLowFilter(q: ShipmentReport) {
  if (q.quotes!!.length < 2) {
    return false;
  }

  const cheapestQuote = q.quotes!![0];
  const secondCheapestQuote = q.quotes!![1];

  // If it's less than half - that's weird
  const suspiciouslyCheap =
    cheapestQuote.priceCad!! < secondCheapestQuote.priceCad!! * 0.5;

  if (suspiciouslyCheap) {
    return true;
  } else {
    return false;
  }
}

function applyCompanySignedUpInLastTwoWeeksFilter(q: ShipmentReport) {
  return moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks"));
}

function applyCompanySignedUpInLastTwoWeeksDailyFilter(q: ShipmentReport) {
  return (
    moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks")) &&
    q.companyShipmentFrequency === "Daily"
  );
}

function applyCompanySignedUpInLastTwoWeeksWeeklyFilter(q: ShipmentReport) {
  return (
    moment(q.companyCreationDate).isAfter(moment().subtract(2, "weeks")) &&
    q.companyShipmentFrequency === "Weekly"
  );
}

function applyShipmentRookiesFilter(q: ShipmentReport) {
  return (
    q.numberOfShipmentsCompanyBooked!! >= 1 &&
    q.numberOfShipmentsCompanyBooked!! <= 5
  );
}

function applyNeverBookedFilter(q: ShipmentReport) {
  return q.numberOfShipmentsCompanyBooked === 0;
}

function applyNeverBookedFilterDaily(q: ShipmentReport) {
  return (
    q.numberOfShipmentsCompanyBooked === 0 &&
    q.companyShipmentFrequency === "Daily"
  );
}

function applyNeverBookedFilterWeekly(q: ShipmentReport) {
  return (
    q.numberOfShipmentsCompanyBooked === 0 &&
    q.companyShipmentFrequency === "Weekly"
  );
}

function applyIncludesFlaggedServicesFilter(q: ShipmentReport) {
  return q.quotes!!.some((o) => o.carrierIntegrationFlagged);
}

function applyFlaggedServiceIsInTwoCheapestFilter(q: ShipmentReport) {
  return q.quotes!!.slice(0, 2).some((o) => o.carrierIntegrationFlagged);
}

function applyLargeShipmentFilter(q: ShipmentReport) {
  // 48x40x48 pallet is 53ft
  return (
    q.standardDryVanLinearFeet!! >= 10 ||
    q.totalVolume!! > 6 * 53 ||
    q.totalWeight!! >= 4000
  );
}

export function useGeneralQuoteFilter(
  filter: QuoteFilterType | undefined | null
) {
  return function (q: ShipmentReport): boolean {
    if (filter === undefined || filter === null) {
      return true;
    }

    switch (filter) {
      case QuoteFilterType.NoQuotes:
        return applyNoQuotesFilter(q);
      case QuoteFilterType.NotManyQuotes:
        return applyNotManyQuotesFilter(q);
      case QuoteFilterType.SuspiciouslyLow:
        return applySuspiciouslyLowFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeks:
        return applyCompanySignedUpInLastTwoWeeksFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksDaily:
        return applyCompanySignedUpInLastTwoWeeksDailyFilter(q);
      case QuoteFilterType.CompanySignedUpInLastTwoWeeksWeekly:
        return applyCompanySignedUpInLastTwoWeeksWeeklyFilter(q);
      case QuoteFilterType.ShippingRookies:
        return applyShipmentRookiesFilter(q);
      case QuoteFilterType.NeverBooked:
        return applyNeverBookedFilter(q);
      case QuoteFilterType.NeverBookedWeekly:
        return applyNeverBookedFilterWeekly(q);
      case QuoteFilterType.NeverBookedDaily:
        return applyNeverBookedFilterDaily(q);
      case QuoteFilterType.IncludesFlaggedServices:
        return applyIncludesFlaggedServicesFilter(q);
      case QuoteFilterType.FlaggedServiceIsInTwoCheapest:
        return applyFlaggedServiceIsInTwoCheapestFilter(q);
      case QuoteFilterType.LargeShipment:
        return applyLargeShipmentFilter(q);
      default:
        assertNever(filter);
    }
  };
}

import {
  Button,
  DatePicker,
  Menu,
  message,
  Modal,
  Popconfirm,
  Table,
  TimePicker,
  Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import { useShipmentsApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { CreatedAt } from "../../Components/CreatedAt";
import HorizontalStack from "../../Components/HorizontalStack";
import Stack from "../../Components/Stack";
import { WarningComponent } from "../../Components/WarningComponent";
import { ShipmentState, TrackingSource } from "../../generated-openapi-client";
import { Shipment } from "../../generated-openapi-client/models/Shipment";
import { TrackingLine } from "../../generated-openapi-client/models/TrackingLine";
import { assertNever } from "../../Helpers/assertNever";
import { isDateInThePast } from "../../Helpers/isDateInThePast";
import { safeColumns } from "../../Helpers/safeColumns";
import Spacer from "../../Spacer";
import { AdjustTrackingLineTimestampMenuItem } from "./AdjustTrackingLineTimestampMenuItem";
import { AmyPauseBanner } from "./AmyPauseBanner";
import { AppointmentBanner } from "./AppointmentBanner";
import { CancelShipmentButton } from "./CancelShipmentButton";
import { ForceDocumentsUpdateMenuItem } from "./ForceDocumentsUpdateButton";
import { ForceTrackingUpdateMenuItem } from "./ForceTrackingUpdateMenuItem";
import { InTransitSupportEmailButton } from "./InTransitEmailButton";
import { MarkFreightMissingButton } from "./MarkFreightMissingButton";
import { MarkFreightNotMissingButton } from "./MarkFreightNotMissingButton";
import { MissedPickupSupportEmailButton } from "./MissedPickupSupportEmailButton";
import { MissingFreightBanner } from "./MissingFreightBanner";
import { PauseAmyEmailsButton } from "./PauseAmyEmailsButton";
import {
  RequestDeliveryDateUpdateMenuItem,
  RequestMissedDeliveryUpdateMenuItem,
  RequestMissedPickupUpdateMenuItem,
  RequestTrackingUpdateMenuItem,
} from "./RequestAmyUpdatesMenuItems";
import { SetAppointmentDateButton } from "./SetAppointmentDateButton";
import { ShipmentApiActionButton } from "./ShipmentApiActionButton";
import { TabProps } from "./TabProps";
import { TrackingSourceTag } from "./TrackingSourceTag";

const { Title, Text } = Typography;

interface DeleteTrackingLineButtonProps {
  trackingLineId: string;
  onRefresh: () => Promise<void>;
}

function DeleteTrackingLineButton(props: DeleteTrackingLineButtonProps) {
  const createShipmentsApi = useShipmentsApi();
  async function onClick() {
    const shipmentsApi = await createShipmentsApi();
    try {
      const { trackingLineId } = props;
      await shipmentsApi.deleteTrackingLine({ trackingLineId });
      await props.onRefresh();
      message.error(`Success`);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
  }
  return (
    <Popconfirm
      title={"Are you sure you want to delete this line?"}
      onConfirm={onClick}
      okText={"Delete"}
      cancelText="Cancel"
    >
      <Button type="link" danger>
        Delete
      </Button>
    </Popconfirm>
  );
}

function TrackingLinesTable(props: TabProps) {
  return (
    <Table
      columns={safeColumns([
        {
          title: "Created At",
          dataIndex: "timestamp",
          key: "timestamp",
          render: (timestamp) => <CreatedAt timestamp={timestamp} />,
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
          render: (message: string) => <div>{message}</div>,
        },
        {
          title: "Source",
          dataIndex: "trackingSource",
          key: "trackingSource",
          render: (trackingSource: TrackingSource, tl: TrackingLine) => (
            <TrackingSourceTag
              trackingSource={trackingSource}
              manualCreatedBy={tl.manualCreatedBy}
            />
          ),
        },
        {
          title: "Actions",
          dataIndex: "trackingLineId",
          key: "trackingLineId",
          render: (trackingLineId: string, trackingLine: TrackingLine) => (
            <ButtonRow
              extrasMenu={
                <Menu>
                  <AdjustTrackingLineTimestampMenuItem
                    trackingLineId={trackingLine.trackingLineId!!}
                    currentTimestamp={trackingLine.timestamp!!}
                    onRefresh={props.onRefresh}
                  />
                </Menu>
              }
            >
              <DeleteTrackingLineButton
                trackingLineId={trackingLineId}
                onRefresh={props.onRefresh}
              />
            </ButtonRow>
          ),
        },
      ])}
      dataSource={props.shipmentData.trackingLines.reverse()}
    />
  );
}

function showEstimatedDeliveryDate(state: ShipmentState): boolean {
  switch (state) {
    case ShipmentState.Delivered:
      return false;
    default:
      return true;
  }
}

export function ManageTrackingTab(props: TabProps) {
  const shipment = props.shipmentData.shipment.shipment;
  const shipmentId = shipment.shipmentId!!;
  const createShipmentsApi = useShipmentsApi();
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(
    props.shipmentData.shipment.shipment.expectedDeliveryDate
  );
  const [newTrackingLine, setNewTrackingLine] = useState(
    props.shipmentData.suggestedTrackingLine
  );

  const isExpectedDeliveryInThePast = isDateInThePast(estimatedDeliveryDate);
  const estimatedDeliveryDateStatus = isExpectedDeliveryInThePast
    ? "error"
    : "";

  const momentEstimatedDeliveryDate = moment(estimatedDeliveryDate)
    .clone()
    .startOf("day");

  async function onEstimatedDeliveryDateChange(
    newMomentDate: moment.Moment | null,
    dateString: string
  ) {
    try {
      if (newMomentDate !== null) {
        const newDate = newMomentDate.format("YYYY-MM-DD");
        setEstimatedDeliveryDate(newDate);

        const shipmentsApi = await createShipmentsApi();
        await shipmentsApi.changeExpectedDeliveryDate({
          shipmentId: props.shipmentData.shipment.shipment.shipmentId!!,
          newExpectedDeliveryDate: newDate,
        });
        await props.onRefresh();

        message.success(`Expected Delivery Date : Updated to ${newDate}`);
      } else {
        message.warn(`No date`);
      }
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  function shouldStatePickupDateIsInThePast(): boolean {
    const shipment = props.shipmentData.shipment.shipment;
    if (shipment.state !== ShipmentState.BookingConfirmed) {
      return false;
    }

    return isDateInThePast(shipment.pickupDate);
  }

  function shouldRecommendSettingAppointment(): boolean {
    const suggestedTrackingLine =
      props.shipmentData.shipment.shipment.suggestedTrackingLine;
    if (suggestedTrackingLine === undefined) {
      return false;
    }

    if (
      suggestedTrackingLine.toLowerCase().includes("appointment") ||
      suggestedTrackingLine.toLowerCase().includes("appt")
    ) {
      return true;
    }

    return false;
  }

  function shouldRecommendSendingCustomsDelayNotification(): boolean {
    const suggestedTrackingLine =
      props.shipmentData.shipment.shipment.suggestedTrackingLine;
    if (suggestedTrackingLine === undefined) {
      return false;
    }

    if (
      suggestedTrackingLine.toLowerCase().includes("broker") ||
      suggestedTrackingLine.toLowerCase().includes("customs")
    ) {
      return true;
    }

    return false;
  }

  function shouldRecommendMarkingMissing(): boolean {
    if (props.shipmentData.shipment.shipment.freightMissing) {
      return false;
    }

    const suggestedTrackingLine =
      props.shipmentData.shipment.shipment.suggestedTrackingLine;
    if (suggestedTrackingLine === undefined) {
      return false;
    }

    if (
      suggestedTrackingLine.toLowerCase().includes("missing") ||
      suggestedTrackingLine.toLowerCase().includes("lost") ||
      suggestedTrackingLine.toLowerCase().includes("short") ||
      suggestedTrackingLine.toLowerCase().includes("searching") ||
      suggestedTrackingLine.toLowerCase().includes("locating") ||
      suggestedTrackingLine.toLowerCase().includes("osd") ||
      suggestedTrackingLine.toLowerCase().includes("os&d") ||
      suggestedTrackingLine.toLowerCase().includes("unable to locate") ||
      suggestedTrackingLine.toLowerCase().includes("no freight")
    ) {
      return true;
    }

    return false;
  }

  return (
    <>
      <AppointmentBanner
        shipmentData={props.shipmentData}
        onRefresh={props.onRefresh}
        onPatchShipmentData={props.onPatchShipmentData}
      />
      <Spacer height={16} />
      <AmyPauseBanner
        shipmentData={props.shipmentData}
        onRefresh={props.onRefresh}
        onPatchShipmentData={props.onPatchShipmentData}
      />
      <Spacer height={16} />
      <MissingFreightBanner
        shipmentData={props.shipmentData}
        onRefresh={props.onRefresh}
        onPatchShipmentData={props.onPatchShipmentData}
      />
      <Spacer height={16} />
      {showEstimatedDeliveryDate(shipment.state!!) && (
        <>
          <Title level={4}>Estimated Delivery Date</Title>
          <Text>
            If the carrier has informed us that the estimated delivery date has
            changed - we can update it here.
          </Text>
          <Spacer height={8} />
          <HorizontalStack verticalAlign="middle">
            <DatePicker
              style={{ width: "200px" }}
              // This is tricky because we need it to represent the date in PST
              // but if you give moment a raw date it assumes that it is GMT
              value={momentEstimatedDeliveryDate}
              onChange={onEstimatedDeliveryDateChange}
              status={estimatedDeliveryDateStatus}
            />
            {isExpectedDeliveryInThePast && (
              <>
                <Spacer width={8} />
                <Text>❌ Estimated delivery is in the past</Text>
              </>
            )}
          </HorizontalStack>
          <Spacer height={32} />
        </>
      )}
      <HorizontalStack verticalAlign="top">
        <Stack align="left">
          <Title level={4}>Tracking Lines</Title>
          <TextArea
            style={{ width: "600px", height: "100px" }}
            value={newTrackingLine}
            onChange={function (e) {
              setNewTrackingLine(e.target.value);
            }}
            placeholder="Enter tracking line info here"
          />
          <Spacer height={8} />
          <ButtonRow>
            <AddTrackingLineButton
              shipmentId={shipmentId}
              message={newTrackingLine}
              onRefresh={props.onRefresh}
            />
            {shouldRecommendMarkingMissing() && (
              <>
                <div>
                  <WarningComponent /> Consider marking this freight as missing
                </div>
              </>
            )}
            {shouldRecommendSettingAppointment() && (
              <>
                <div>
                  <WarningComponent /> Consider updating appointment info
                </div>
              </>
            )}
            {shouldRecommendSendingCustomsDelayNotification() && (
              <>
                <div>
                  <WarningComponent /> Consider sending customs delay
                  notification
                </div>
              </>
            )}
            {shouldStatePickupDateIsInThePast() && (
              <>
                <div>🛑 Pickup date is in the past</div>
              </>
            )}
          </ButtonRow>
        </Stack>
        <Spacer width={32} />
        <Stack align="left">
          <Title level={4}>Actions</Title>
          <ButtonRow
            extrasMenu={
              <Menu>
                <ForceTrackingUpdateMenuItem
                  shipmentId={props.shipmentData.shipmentId}
                  onRefresh={props.onRefresh}
                />
                <ForceDocumentsUpdateMenuItem
                  shipmentId={props.shipmentData.shipmentId}
                  onRefresh={props.onRefresh}
                />
                <Menu.Divider />
                <RequestMissedPickupUpdateMenuItem
                  shipment={props.shipmentData.shipment.shipment}
                  onRefresh={props.onRefresh}
                />
                <RequestMissedDeliveryUpdateMenuItem
                  shipment={props.shipmentData.shipment.shipment}
                  onRefresh={props.onRefresh}
                />
                <RequestTrackingUpdateMenuItem
                  shipment={props.shipmentData.shipment.shipment}
                  onRefresh={props.onRefresh}
                />
                <RequestDeliveryDateUpdateMenuItem
                  shipment={props.shipmentData.shipment.shipment}
                  onRefresh={props.onRefresh}
                />
              </Menu>
            }
          >
            <MarkDeliveredButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />

            <MarkInTransitButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />
            <MarkBookingConfirmedButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />
            <MarkNotOutForDeliveryButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />
            <CancelShipmentButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />

            <MissedPickupSupportEmailButton data={props.shipmentData} />
            <InTransitSupportEmailButton data={props.shipmentData} />
          </ButtonRow>
          <Spacer height={8} />
          <ButtonRow>
            <SetAppointmentDateButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />

            <MarkFreightMissingButton
              shipmentData={props.shipmentData}
              onRefresh={props.onRefresh}
              onPatchShipmentData={props.onPatchShipmentData}
            />
            <MarkFreightNotMissingButton
              shipmentData={props.shipmentData}
              onRefresh={props.onRefresh}
              onPatchShipmentData={props.onPatchShipmentData}
            />
            <PauseAmyEmailsButton
              shipment={shipment}
              onRefresh={props.onRefresh}
            />
          </ButtonRow>
        </Stack>
      </HorizontalStack>

      <Spacer height={32} />
      {shipment.trackingNote && (
        <div>
          <strong>Tracking Note (Customer Visible)</strong>:{" "}
          {shipment.trackingNote}
        </div>
      )}
      <Spacer height={8} />
      <TrackingLinesTable {...props} />
    </>
  );
}

interface MarkDeliveredButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

function MarkDeliveredButton(props: MarkDeliveredButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const createShipmentApi = useShipmentsApi();
  const [deliveryDate, setDeliveryDate] = useState(moment());
  const [deliveryTime, setDeliveryTime] = useState(
    moment().set("hours", 12).set("minutes", 0)
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    try {
      const shipmentId = props.shipment.shipmentId!!;
      const actualDeliveryDate = deliveryDate.format("YYYY-MM-DD");
      const actualDeliveryTime = deliveryTime.format("HH:mm");

      const shipmentApi = await createShipmentApi();

      await shipmentApi.markDelivered({
        shipmentId,
        actualDeliveryDate,
        actualDeliveryTime,
      });
      await props.onRefresh();
      message.success("Marked Delivered");
      setIsModalVisible(false);
    } catch (e) {
      message.error(`Oops something went wrong : ${e}`);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (props.shipment.state !== ShipmentState.InTransit) {
    return <></>;
  }

  const timeFormat = "HH:mm";

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Mark Delivered"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose
      >
        <Stack align="left">
          <Text>
            Enter the date and time that this was delivered. Don't stress too
            much about accuracy of the time. We often just put noon if we don't
            know better
          </Text>
          <Spacer height={32} />
          <HorizontalStack>
            <DatePicker
              style={{ width: "200px" }}
              value={deliveryDate}
              onChange={function (m) {
                if (m !== null) {
                  setDeliveryDate(m);
                }
              }}
            />
            <Spacer width={16} />
            <TimePicker
              style={{ width: "200px" }}
              format={timeFormat}
              value={deliveryTime}
              minuteStep={30}
              onChange={function (m) {
                if (m !== null) {
                  setDeliveryTime(m);
                }
              }}
            />
          </HorizontalStack>
        </Stack>
      </Modal>
      <Button onClick={showModal}>🟢 Mark Delivered</Button>
    </>
  );
}

interface MarkInTransitButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

function MarkInTransitButton(props: MarkInTransitButtonProps) {
  const shipmentId = props.shipment.shipmentId!!;
  const state = props.shipment.state!!;

  function isHidden(): boolean {
    switch (state) {
      case ShipmentState.QuoteRequested:
        return true;
      case ShipmentState.Quoted:
        return true;
      case ShipmentState.BookingRequested:
        return true;
      case ShipmentState.BookingFailed:
        return true;
      case ShipmentState.BookingConfirmed:
        return false;
      case ShipmentState.Cancelled:
        return true;
      case ShipmentState.InTransit:
        return true;
      case ShipmentState.Delivered:
        return false;
      case ShipmentState.Lost:
        return false;
      case ShipmentState.OnHold:
        return false;
      default:
        assertNever(state);
    }
  }

  return (
    <ShipmentApiActionButton
      name="🔵 Mark In Transit"
      onClick={async function (shipmentApi) {
        await shipmentApi.markInTransit({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={isHidden()}
    />
  );
}

interface MarkBookingConfirmedButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

function MarkBookingConfirmedButton(props: MarkBookingConfirmedButtonProps) {
  const { shipment } = props;
  const shipmentId = shipment.shipmentId!!;
  const state = shipment.state!!;

  function isHidden(): boolean {
    switch (state) {
      case ShipmentState.QuoteRequested:
        return true;
      case ShipmentState.Quoted:
        return true;
      case ShipmentState.BookingRequested:
        return false;
      case ShipmentState.BookingFailed:
        return false;
      case ShipmentState.BookingConfirmed:
        return true;
      case ShipmentState.Cancelled:
        return false;
      case ShipmentState.Lost:
        return true;
      case ShipmentState.InTransit:
        return false;
      case ShipmentState.Delivered:
        return true;
      case ShipmentState.OnHold:
        return false;
      default:
        assertNever(state);
    }
  }

  return (
    <ShipmentApiActionButton
      name="🟡 Mark Booking Confirmed"
      onClick={async function (shipmentApi) {
        await shipmentApi.markBookingConfirmed({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={isHidden()}
    />
  );
}

interface MarkNotOutForDeliveryButtonProps {
  shipment: Shipment;
  onRefresh: () => Promise<void>;
}

function MarkNotOutForDeliveryButton(props: MarkNotOutForDeliveryButtonProps) {
  const outForDelivery = props.shipment.outForDelivery;
  const shipmentId = props.shipment.shipmentId!!;

  if (props.shipment.state === ShipmentState.Delivered) {
    return <></>;
  }

  return (
    <ShipmentApiActionButton
      name="✋ Mark Not Out For Delivery"
      onClick={async function (shipmentApi) {
        await shipmentApi.markNotOutForDelivery({ shipmentId });
      }}
      onRefresh={props.onRefresh}
      hidden={!outForDelivery}
    />
  );
}

interface AddTrackingLineButtonProps {
  shipmentId: string;
  message: string;
  onRefresh: () => Promise<void>;
}

function AddTrackingLineButton(props: AddTrackingLineButtonProps) {
  const { shipmentId, message } = props;
  return (
    <ShipmentApiActionButton
      name="Add Tracking Line"
      onClick={async function (shipmentApi) {
        await shipmentApi.addTrackingLine({ shipmentId, message });
      }}
      onRefresh={props.onRefresh}
      hidden={false}
    />
  );
}

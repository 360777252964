import { Button, Menu, MenuItemProps, Modal, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { ButtonType } from "antd/lib/button";
import { ReactNode, useState } from "react";
import Colors from "./Colors";
import Stack from "./Stack";

const { Text } = Typography;

interface ConfirmButtonProps {
  onConfirm: () => void;
  question: ReactNode;
  questionDescription?: ReactNode;
  okText: string;
  disabled?: boolean;
  children: ReactNode;
  tooltip: string;
  icon?: ReactNode;
  type?: ButtonType;
  loading?: boolean;
  danger?: boolean;
}

export function ConfirmButton(props: ConfirmButtonProps) {
  return (
    <ConfirmBase
      {...props}
      placement="bottom"
      button={(children, onClick) => (
        <Button
          danger={props.danger}
          type={props.type}
          icon={props.icon}
          disabled={props.disabled}
          onClick={onClick}
          loading={props.loading}
        >
          {children}
        </Button>
      )}
    />
  );
}

export function ConfirmMenuItem(props: ConfirmButtonProps & MenuItemProps) {
  return (
    <ConfirmBase
      {...props}
      placement="left"
      button={(children, onClick) => (
        <Menu.Item
          {...props}
          onClick={onClick}
          disabled={props.disabled}
          danger={props.danger}
          icon={props.icon}
        >
          {children}
        </Menu.Item>
      )}
    />
  );
}

interface ConfirmButtonBaseProps extends ConfirmButtonProps {
  button: (children: ReactNode, onClick: () => void) => ReactNode;
  placement: TooltipPlacement;
}

function ConfirmBase(props: ConfirmButtonBaseProps) {
  const [processing, setProcessing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setProcessing(true);
    await props.onConfirm();
    setProcessing(false);
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{
          loading: processing,
        }}
        okText={props.okText}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={600}
        closable={false}
      >
        <Stack align="left">
          <Text style={{ fontSize: "18px" }}>{props.question}</Text>
          {props.questionDescription && (
            <Text style={{ fontSize: "16px", color: Colors.LightText }}>
              {props.questionDescription}
            </Text>
          )}
        </Stack>
      </Modal>
      <Tooltip placement={props.placement} title={props.tooltip}>
        {props.button(props.children, showModal)}
      </Tooltip>
    </>
  );
}

import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { getConfig, isAuthenticationDisabled } from "../App";
import { loginRequest } from "../authConfig";

import {
  AdminLogsApi,
  AmyApi,
  CarrierInvoiceAuditApi,
  Configuration,
  FreightClaimsApi,
  LookupApi,
  MapApi,
  ShipmentsApi,
  SignalsApi,
  StatsApi,
} from "../generated-openapi-client";
import { CalendarStatsApi } from "../generated-openapi-client/apis/CalendarStatsApi";
import { CarrierInvoiceApi } from "../generated-openapi-client/apis/CarrierInvoiceApi";
import { CarriersApi } from "../generated-openapi-client/apis/CarriersApi";
import { CompaniesApi } from "../generated-openapi-client/apis/CompaniesApi";
import { CustomerInvoiceApi } from "../generated-openapi-client/apis/CustomerInvoiceApi";
import { DocumentsApi } from "../generated-openapi-client/apis/DocumentsApi";
import { ProfitLossApi } from "../generated-openapi-client/apis/ProfitLossApi";
import { QuotesApi } from "../generated-openapi-client/apis/QuotesApi";
import { SupportEmailApi } from "../generated-openapi-client/apis/SupportEmailApi";
import { TechnicalApi } from "../generated-openapi-client/apis/TechnicalApi";

function useApi<T>(
  creator: (configuration: Configuration) => T
): () => Promise<T> {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  // This is basically for working totally offline
  // Authentication requires making calls to Microsoft/Azure/AD
  // If you're on a flight, say, this isn't always possible
  // You would also need to start up a local version of the backend that doesn't check authentication
  // SKIP_LOCAL_APOLLO_AUTHENTICATION needs to be set on the server
  async function createApiWithoutAuthentication() {
    const api = creator(
      new Configuration({
        ...getConfig(),
      })
    );
    return api;
  }

  if (isAuthenticationDisabled()) {
    return async function () {
      return createApiWithoutAuthentication();
    };
  }

  if (account === null) {
    throw new Error("Should not be here with no account");
  }

  async function getToken(
    account: AccountInfo,
    instance: IPublicClientApplication
  ) {
    try {
      const response = await instance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: account,
      });

      return response.accessToken;
    } catch (e) {
      console.warn({ e });
      await instance.loginRedirect(loginRequest);
      throw new Error(
        "I don't know what to do here. Let's see if we ever get here"
      );
    }
  }

  async function createApi(
    account: AccountInfo,
    instance: IPublicClientApplication
  ) {
    const accessToken = await getToken(account, instance);

    const api = creator(
      new Configuration({
        ...getConfig(),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    );
    return api;
  }

  return async function () {
    return createApi(account, instance);
  };
}

export function useShipmentsApi(): () => Promise<ShipmentsApi> {
  return useApi(function (configuration) {
    return new ShipmentsApi(configuration);
  });
}

export function useQuotesApi(): () => Promise<QuotesApi> {
  return useApi(function (configuration) {
    return new QuotesApi(configuration);
  });
}

export function useCompaniesApi(): () => Promise<CompaniesApi> {
  return useApi(function (configuration) {
    return new CompaniesApi(configuration);
  });
}

export function useStatsApi(): () => Promise<StatsApi> {
  return useApi(function (configuration) {
    return new StatsApi(configuration);
  });
}

export function useDocumentsApi(): () => Promise<DocumentsApi> {
  return useApi(function (configuration) {
    return new DocumentsApi(configuration);
  });
}

export function useCalendarStatsApi(): () => Promise<CalendarStatsApi> {
  return useApi(function (configuration) {
    return new CalendarStatsApi(configuration);
  });
}

export function useMapsApi(): () => Promise<MapApi> {
  return useApi(function (configuration) {
    return new MapApi(configuration);
  });
}

export function useLookupApi(): () => Promise<LookupApi> {
  return useApi(function (configuration) {
    return new LookupApi(configuration);
  });
}

export function useCustomerInvoiceApi(): () => Promise<CustomerInvoiceApi> {
  return useApi(function (configuration) {
    return new CustomerInvoiceApi(configuration);
  });
}

export function useCarriersApi(): () => Promise<CarriersApi> {
  return useApi(function (configuration) {
    return new CarriersApi(configuration);
  });
}

export function useCarrierInvoiceApi(): () => Promise<CarrierInvoiceApi> {
  return useApi(function (configuration) {
    return new CarrierInvoiceApi(configuration);
  });
}

export function useCarrierInvoiceAuditApi(): () => Promise<CarrierInvoiceAuditApi> {
  return useApi(function (configuration) {
    return new CarrierInvoiceAuditApi(configuration);
  });
}

export function useAdminLogsApi(): () => Promise<AdminLogsApi> {
  return useApi(function (configuration) {
    return new AdminLogsApi(configuration);
  });
}

export function useProfitLossApi(): () => Promise<ProfitLossApi> {
  return useApi(function (configuration) {
    return new ProfitLossApi(configuration);
  });
}

export function useAmyApi(): () => Promise<AmyApi> {
  return useApi(function (configuration) {
    return new AmyApi(configuration);
  });
}

export function useSignalsApi(): () => Promise<SignalsApi> {
  return useApi(function (configuration) {
    return new SignalsApi(configuration);
  });
}

export function useTechnicalApi(): () => Promise<TechnicalApi> {
  return useApi(function (configuration) {
    return new TechnicalApi(configuration);
  });
}

export function useSupportEmailApi(): () => Promise<SupportEmailApi> {
  return useApi(function (configuration) {
    return new SupportEmailApi(configuration);
  });
}

export function useFreightClaimsApi(): () => Promise<FreightClaimsApi> {
  return useApi(function (configuration) {
    return new FreightClaimsApi(configuration);
  });
}

/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Apollo API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';
import type { CurrencyCode } from './CurrencyCode';
import {
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import type { QualificationStatus } from './QualificationStatus';
import {
    QualificationStatusFromJSON,
    QualificationStatusFromJSONTyped,
    QualificationStatusToJSON,
} from './QualificationStatus';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    stripeCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    addCreditCardAuthorizationId?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    discountPercentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    forTest?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    preferredBrokerNorthbound?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    preferredBrokerSouthbound?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    defaultPickupLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    defaultDeliveryLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    utmMedium?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    salesforceAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    shipmentFrequency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    quotingDisabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    paymentTermsDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    sendInvoicesViaEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    invoiceNotificationEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    leadSource?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    temperature?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    creditLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    referralCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    chequePaymentMethodPermitted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    wirePaymentMethodPermitted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    quotesRan?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastQuotedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    shipmentsBooked?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastBookedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    acceptanceRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    totalCadSales?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    fixedMarkup?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    doNotAutochargeOverdueInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    needsVerification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    verificationDenied?: boolean;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Company
     */
    currency?: CurrencyCode;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    isPersonal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    billingLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    billingLongitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingState?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    billingPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    defaultSalesContactId?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof Company
     */
    billingCountry?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastSignificantSalesCallDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastSalesCallDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    legacySalesforceLeadId?: string;
    /**
     * 
     * @type {QualificationStatus}
     * @memberof Company
     */
    qualificationStatus?: QualificationStatus;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    snoozeSalesCallsUntil?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    associatedProfessionalDomain?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    hasNonProfessionalDomain?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Company
     */
    knownGroupEmailAddresses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    referralCompanyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    referralShipmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    firstQuoteDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    isBroker?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    firstBookedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastSignificantSalesCallBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    lastSalesCallBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    claudeAnalysis?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    gclid?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    utmTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    invoiceAuditNotes?: string;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    return true;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'stripeCustomerId': json['stripeCustomerId'] == null ? undefined : json['stripeCustomerId'],
        'addCreditCardAuthorizationId': json['addCreditCardAuthorizationId'] == null ? undefined : json['addCreditCardAuthorizationId'],
        'discountPercentage': json['discountPercentage'] == null ? undefined : json['discountPercentage'],
        'forTest': json['forTest'] == null ? undefined : json['forTest'],
        'preferredBrokerNorthbound': json['preferredBrokerNorthbound'] == null ? undefined : json['preferredBrokerNorthbound'],
        'preferredBrokerSouthbound': json['preferredBrokerSouthbound'] == null ? undefined : json['preferredBrokerSouthbound'],
        'defaultPickupLocation': json['defaultPickupLocation'] == null ? undefined : json['defaultPickupLocation'],
        'defaultDeliveryLocation': json['defaultDeliveryLocation'] == null ? undefined : json['defaultDeliveryLocation'],
        'utmSource': json['utmSource'] == null ? undefined : json['utmSource'],
        'utmMedium': json['utmMedium'] == null ? undefined : json['utmMedium'],
        'utmCampaign': json['utmCampaign'] == null ? undefined : json['utmCampaign'],
        'salesforceAccountId': json['salesforceAccountId'] == null ? undefined : json['salesforceAccountId'],
        'shipmentFrequency': json['shipmentFrequency'] == null ? undefined : json['shipmentFrequency'],
        'quotingDisabled': json['quotingDisabled'] == null ? undefined : json['quotingDisabled'],
        'paymentTermsDays': json['paymentTermsDays'] == null ? undefined : json['paymentTermsDays'],
        'sendInvoicesViaEmail': json['sendInvoicesViaEmail'] == null ? undefined : json['sendInvoicesViaEmail'],
        'invoiceNotificationEmail': json['invoiceNotificationEmail'] == null ? undefined : json['invoiceNotificationEmail'],
        'leadSource': json['leadSource'] == null ? undefined : json['leadSource'],
        'temperature': json['temperature'] == null ? undefined : json['temperature'],
        'creditLimit': json['creditLimit'] == null ? undefined : json['creditLimit'],
        'referralCode': json['referralCode'] == null ? undefined : json['referralCode'],
        'chequePaymentMethodPermitted': json['chequePaymentMethodPermitted'] == null ? undefined : json['chequePaymentMethodPermitted'],
        'wirePaymentMethodPermitted': json['wirePaymentMethodPermitted'] == null ? undefined : json['wirePaymentMethodPermitted'],
        'quotesRan': json['quotesRan'] == null ? undefined : json['quotesRan'],
        'lastQuotedDate': json['lastQuotedDate'] == null ? undefined : json['lastQuotedDate'],
        'shipmentsBooked': json['shipmentsBooked'] == null ? undefined : json['shipmentsBooked'],
        'lastBookedDate': json['lastBookedDate'] == null ? undefined : json['lastBookedDate'],
        'acceptanceRate': json['acceptanceRate'] == null ? undefined : json['acceptanceRate'],
        'totalCadSales': json['totalCadSales'] == null ? undefined : json['totalCadSales'],
        'fixedMarkup': json['fixedMarkup'] == null ? undefined : json['fixedMarkup'],
        'doNotAutochargeOverdueInvoices': json['doNotAutochargeOverdueInvoices'] == null ? undefined : json['doNotAutochargeOverdueInvoices'],
        'needsVerification': json['needsVerification'] == null ? undefined : json['needsVerification'],
        'verificationDenied': json['verificationDenied'] == null ? undefined : json['verificationDenied'],
        'currency': json['currency'] == null ? undefined : CurrencyCodeFromJSON(json['currency']),
        'isPersonal': json['isPersonal'] == null ? undefined : json['isPersonal'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'billingLatitude': json['billingLatitude'] == null ? undefined : json['billingLatitude'],
        'billingLongitude': json['billingLongitude'] == null ? undefined : json['billingLongitude'],
        'billingAddressLine': json['billingAddressLine'] == null ? undefined : json['billingAddressLine'],
        'billingCity': json['billingCity'] == null ? undefined : json['billingCity'],
        'billingState': json['billingState'] == null ? undefined : json['billingState'],
        'billingPostalCode': json['billingPostalCode'] == null ? undefined : json['billingPostalCode'],
        'defaultSalesContactId': json['defaultSalesContactId'] == null ? undefined : json['defaultSalesContactId'],
        'billingCountry': json['billingCountry'] == null ? undefined : CountryCodeFromJSON(json['billingCountry']),
        'lastSignificantSalesCallDate': json['lastSignificantSalesCallDate'] == null ? undefined : json['lastSignificantSalesCallDate'],
        'lastSalesCallDate': json['lastSalesCallDate'] == null ? undefined : json['lastSalesCallDate'],
        'legacySalesforceLeadId': json['legacySalesforceLeadId'] == null ? undefined : json['legacySalesforceLeadId'],
        'qualificationStatus': json['qualificationStatus'] == null ? undefined : QualificationStatusFromJSON(json['qualificationStatus']),
        'snoozeSalesCallsUntil': json['snoozeSalesCallsUntil'] == null ? undefined : json['snoozeSalesCallsUntil'],
        'associatedProfessionalDomain': json['associatedProfessionalDomain'] == null ? undefined : json['associatedProfessionalDomain'],
        'hasNonProfessionalDomain': json['hasNonProfessionalDomain'] == null ? undefined : json['hasNonProfessionalDomain'],
        'knownGroupEmailAddresses': json['knownGroupEmailAddresses'] == null ? undefined : json['knownGroupEmailAddresses'],
        'referralCompanyId': json['referralCompanyId'] == null ? undefined : json['referralCompanyId'],
        'referralShipmentId': json['referralShipmentId'] == null ? undefined : json['referralShipmentId'],
        'firstQuoteDate': json['firstQuoteDate'] == null ? undefined : json['firstQuoteDate'],
        'isBroker': json['isBroker'] == null ? undefined : json['isBroker'],
        'firstBookedDate': json['firstBookedDate'] == null ? undefined : json['firstBookedDate'],
        'lastSignificantSalesCallBy': json['lastSignificantSalesCallBy'] == null ? undefined : json['lastSignificantSalesCallBy'],
        'lastSalesCallBy': json['lastSalesCallBy'] == null ? undefined : json['lastSalesCallBy'],
        'claudeAnalysis': json['claudeAnalysis'] == null ? undefined : json['claudeAnalysis'],
        'gclid': json['gclid'] == null ? undefined : json['gclid'],
        'utmTerm': json['utmTerm'] == null ? undefined : json['utmTerm'],
        'invoiceAuditNotes': json['invoiceAuditNotes'] == null ? undefined : json['invoiceAuditNotes'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyId': value['companyId'],
        'createdAt': value['createdAt'],
        'companyName': value['companyName'],
        'stripeCustomerId': value['stripeCustomerId'],
        'addCreditCardAuthorizationId': value['addCreditCardAuthorizationId'],
        'discountPercentage': value['discountPercentage'],
        'forTest': value['forTest'],
        'preferredBrokerNorthbound': value['preferredBrokerNorthbound'],
        'preferredBrokerSouthbound': value['preferredBrokerSouthbound'],
        'defaultPickupLocation': value['defaultPickupLocation'],
        'defaultDeliveryLocation': value['defaultDeliveryLocation'],
        'utmSource': value['utmSource'],
        'utmMedium': value['utmMedium'],
        'utmCampaign': value['utmCampaign'],
        'salesforceAccountId': value['salesforceAccountId'],
        'shipmentFrequency': value['shipmentFrequency'],
        'quotingDisabled': value['quotingDisabled'],
        'paymentTermsDays': value['paymentTermsDays'],
        'sendInvoicesViaEmail': value['sendInvoicesViaEmail'],
        'invoiceNotificationEmail': value['invoiceNotificationEmail'],
        'leadSource': value['leadSource'],
        'temperature': value['temperature'],
        'creditLimit': value['creditLimit'],
        'referralCode': value['referralCode'],
        'chequePaymentMethodPermitted': value['chequePaymentMethodPermitted'],
        'wirePaymentMethodPermitted': value['wirePaymentMethodPermitted'],
        'quotesRan': value['quotesRan'],
        'lastQuotedDate': value['lastQuotedDate'],
        'shipmentsBooked': value['shipmentsBooked'],
        'lastBookedDate': value['lastBookedDate'],
        'acceptanceRate': value['acceptanceRate'],
        'totalCadSales': value['totalCadSales'],
        'fixedMarkup': value['fixedMarkup'],
        'doNotAutochargeOverdueInvoices': value['doNotAutochargeOverdueInvoices'],
        'needsVerification': value['needsVerification'],
        'verificationDenied': value['verificationDenied'],
        'currency': CurrencyCodeToJSON(value['currency']),
        'isPersonal': value['isPersonal'],
        'phone': value['phone'],
        'billingLatitude': value['billingLatitude'],
        'billingLongitude': value['billingLongitude'],
        'billingAddressLine': value['billingAddressLine'],
        'billingCity': value['billingCity'],
        'billingState': value['billingState'],
        'billingPostalCode': value['billingPostalCode'],
        'defaultSalesContactId': value['defaultSalesContactId'],
        'billingCountry': CountryCodeToJSON(value['billingCountry']),
        'lastSignificantSalesCallDate': value['lastSignificantSalesCallDate'],
        'lastSalesCallDate': value['lastSalesCallDate'],
        'legacySalesforceLeadId': value['legacySalesforceLeadId'],
        'qualificationStatus': QualificationStatusToJSON(value['qualificationStatus']),
        'snoozeSalesCallsUntil': value['snoozeSalesCallsUntil'],
        'associatedProfessionalDomain': value['associatedProfessionalDomain'],
        'hasNonProfessionalDomain': value['hasNonProfessionalDomain'],
        'knownGroupEmailAddresses': value['knownGroupEmailAddresses'],
        'referralCompanyId': value['referralCompanyId'],
        'referralShipmentId': value['referralShipmentId'],
        'firstQuoteDate': value['firstQuoteDate'],
        'isBroker': value['isBroker'],
        'firstBookedDate': value['firstBookedDate'],
        'lastSignificantSalesCallBy': value['lastSignificantSalesCallBy'],
        'lastSalesCallBy': value['lastSalesCallBy'],
        'claudeAnalysis': value['claudeAnalysis'],
        'gclid': value['gclid'],
        'utmTerm': value['utmTerm'],
        'invoiceAuditNotes': value['invoiceAuditNotes'],
    };
}


import { ShipmentDocumentSource } from "../../generated-openapi-client/models/ShipmentDocumentSource";
import { assertNever } from "../../Helpers/assertNever";

function describeShipmentDocumentSource(
  source: ShipmentDocumentSource | undefined
) {
  if (!source) {
    return "Unknown";
  }
  switch (source) {
    case ShipmentDocumentSource.ManuallyUploaded:
      return "Manually Uploaded";
    case ShipmentDocumentSource.EmailAttachment:
      return "Email Attachment";
    case ShipmentDocumentSource.Api:
      return "API";
    case ShipmentDocumentSource.WebsiteScraped:
      return "Website Scraped";
    default:
      assertNever(source);
  }
}

interface DocumentSourceTagProps {
  source: ShipmentDocumentSource | undefined;
}

export function DocumentSourceTag(props: DocumentSourceTagProps) {
  return <div>{describeShipmentDocumentSource(props.source)}</div>;
}

import ExcelJS from "exceljs";
import { ReactNode } from "react";
import Colors from "./Colors";

type Data = object[];

interface ExcelLinkProps {
  data: Data;
  filename: string;
  headers: string[];
  children: ReactNode;
  boldHeaders?: boolean;
  autoWidth?: boolean;
}

export function ExcelLink(props: ExcelLinkProps) {
  const {
    data,
    filename,
    headers,
    children,
    autoWidth = true,
    boldHeaders = true,
  } = props;

  const downloadExcel = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add headers
    worksheet.addRow(headers);

    // Add data
    data.forEach((item) => {
      worksheet.addRow(headers.map((header) => (item as any)[header]));
    });

    // Style headers
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: boldHeaders };
      cell.alignment = { horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCCCCC" },
      };
    });

    // Auto-fit columns if enabled
    if (autoWidth) {
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell?.({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 10;
          maxLength = Math.max(maxLength, columnLength);
        });
        // Set column width with minimum and maximum constraints
        column.width = Math.max(Math.min(maxLength + 2, 50), 8);
      });
    }

    try {
      // Generate buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Create blob and trigger download
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename.endsWith(".xlsx")
        ? filename
        : `${filename}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  return (
    <div
      onClick={downloadExcel}
      style={{ cursor: "pointer", color: Colors.Blue }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          downloadExcel();
        }
      }}
    >
      {children || "Download Excel"}
    </div>
  );
}

import { Timeline, Typography } from "antd";
import moment from "moment";
import Colors from "../../../Components/Colors";
import Stack from "../../../Components/Stack";
import {
  CompanyData,
  CompanyNote,
  CompanyNoteType,
} from "../../../generated-openapi-client";
import { groupBy } from "../../../Helpers/groupBy";
import { CompanyNoteElement } from "../CompanyNote";
import {
  PhoneOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
  FileSearchOutlined,
  CreditCardOutlined,
  CloudServerOutlined,
  MessageOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { CollapseShowGroupsTimelineItems } from "../../../Components/CollapsibleTimeline";
import { groupCollapsibleElements } from "../../../Helpers/groupCollapsibleElements";
import { CSSProperties } from "react";
import Spacer from "../../../Spacer";
import { LeftAlignedTimeline } from "../../../Components/LeftAlignedTimeline";
import { FallbackErrorBoundary } from "../../../Components/FallbackErrorBoundary";
import { ErrorCompanyNoteElement } from "../ErrorCompanyNoteElement";
import { assertNever } from "../../../Helpers/assertNever";

const { Title } = Typography;
export type CompanyNotesTimelineOrigin =
  | "sales-call-list"
  | "company-notes-tab";
interface CompanyNotesTimelineProps {
  data?: CompanyData;
  noteTypeFilter: CompanyNoteType | CompanyNoteType[] | undefined;
  noteAuthorFilter: string | string[] | undefined;
  significantFilter: boolean | undefined;
  freeFilter: string | undefined;
  notes: CompanyNote[];
  includeViewCompanyLinks?: boolean;
  onRefresh: () => Promise<void>;
  collapse: boolean;
  origin: CompanyNotesTimelineOrigin;
}

export function CompanyNotesTimeline(props: CompanyNotesTimelineProps) {
  function filterByNoteType(note: CompanyNote): boolean {
    if (
      props.noteTypeFilter === undefined ||
      props.noteTypeFilter.length === 0
    ) {
      return true;
    }

    return note.type ? props.noteTypeFilter.includes(note.type) : false;
  }

  function filterBySignificant(note: CompanyNote): boolean {
    if (props.significantFilter === undefined) {
      return true;
    }

    return note.significant === props.significantFilter;
  }

  function filterByAuthor(note: CompanyNote): boolean {
    if (
      props.noteAuthorFilter === undefined ||
      props.noteAuthorFilter.length === 0
    ) {
      return true;
    }

    return note.author ? props.noteAuthorFilter.includes(note.author) : false;
  }

  function applyFreeFilter(note: CompanyNote): boolean {
    if (props.freeFilter === undefined || props.freeFilter.trim() === "") {
      return true;
    }

    return JSON.stringify(note)
      .toLowerCase()
      .includes(props.freeFilter.toLowerCase());
  }

  function filterNotes() {
    return props.notes
      .filter(filterByNoteType)
      .filter(filterByAuthor)
      .filter(filterBySignificant)
      .filter(applyFreeFilter);
  }

  function sortByDate(a: CompanyNote, b: CompanyNote) {
    return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
  }

  function groupByCreationDate(note: CompanyNote) {
    const m = moment.utc(note.createdAt).tz("America/Vancouver");
    return m.format("MMMM YYYY");
  }

  /**
   * Notes to show after applying the filters
   */
  const noteToShow = filterNotes();

  const groupedByDate = groupBy(
    props.notes.sort(sortByDate),
    groupByCreationDate
  );

  const showGroupsByDate = groupCollapsibleElements(groupedByDate, (e) =>
    noteToShow.includes(e)
  );

  return (
    <>
      {showGroupsByDate.map((group) => (
        <LeftAlignedTimeline key={group.key}>
          <Timeline.Item
            dot={
              <Title level={2} style={{ margin: 0 }}>
                {group.key}
              </Title>
            }
          >
            <Spacer height={50} />
          </Timeline.Item>
          <CollapseShowGroupsTimelineItems
            showGroups={group.showGroups}
            renderShowGroupHeader={(group) => (
              <>
                {group.elements.length}
                {group.elements.length > 1 ? " notes" : " note"} hidden{" "}
              </>
            )}
            onTimelineItemRender={(e) => ({
              dot: <IconForCompanyNote note={e} />,
              label: <NoteTimelineLabel note={e} />,
              element: (
                <div
                  style={{
                    marginLeft: "16px",
                    marginBlock: "12px",
                  }}
                >
                  <FallbackErrorBoundary
                    fallback={
                      <ErrorCompanyNoteElement
                        origin={props.origin}
                        note={e}
                        onRefresh={async () => {}}
                        collapseActive={false}
                      />
                    }
                  >
                    <CompanyNoteElement
                      origin={props.origin}
                      data={props.data}
                      note={e}
                      onRefresh={props.onRefresh}
                      collapseActive={props.collapse}
                    />
                  </FallbackErrorBoundary>
                </div>
              ),
            })}
          />
        </LeftAlignedTimeline>
      ))}
    </>
  );
}

function NoteTimelineLabel({ note }: { note: CompanyNote }) {
  const date = moment(note.createdAt);
  return (
    <Stack align="right" style={{ marginRight: "20px" }}>
      <div style={{ fontSize: "14px", fontWeight: "500" }}>
        {date.format("dddd, Do")}
      </div>

      {note.origin && (
        <div style={{ fontSize: "12px", color: Colors.LightText }}>
          {note.origin}
        </div>
      )}

      <span style={{ fontSize: "12px", color: Colors.LightText }}>
        {date.format("h:mm a")}
      </span>
    </Stack>
  );
}

function IconForCompanyNote({ note }: { note: CompanyNote }) {
  const noteStyle: CSSProperties = {
    color: Colors.Blue,
    fontSize: "24px",
    marginRight: "8px",
    marginLeft: "8px",
  };
  const type = note.type!!;
  switch (type) {
    case CompanyNoteType.DialpadRecording:
      return <AudioOutlined style={noteStyle} />;
    case CompanyNoteType.Call:
      return <PhoneOutlined style={noteStyle} />;
    case CompanyNoteType.ApolloAction:
      return <RocketOutlined style={noteStyle} />;
    case CompanyNoteType.UserAction:
      return <UserOutlined style={noteStyle} />;
    case CompanyNoteType.ShipmentBooking:
      return <TeamOutlined style={noteStyle} />;
    case CompanyNoteType.QuoteRun:
      return <FileSearchOutlined style={noteStyle} />;
    case CompanyNoteType.CreditModified:
      return <CreditCardOutlined style={noteStyle} />;
    case CompanyNoteType.SystemEvent:
      return <CloudServerOutlined style={noteStyle} />;
    case CompanyNoteType.InternalNote:
      return <MessageOutlined style={noteStyle} />;
    default:
      assertNever(type);
  }
  return <></>;
}

import { Select } from "antd";
import styled from "styled-components";
import Colors from "../../Components/Colors";
import Stack from "../../Components/Stack";
import InvoiceAuditProblemTypes from "../../GeneratedFixtures/InvoiceAuditProblemTypesGrouped.json";
import { InvoiceAuditProblemType } from "../../generated-openapi-client";

interface InvoiceAuditProblemTypeDropdownProps {
  value: InvoiceAuditProblemType | undefined;
  setValue: (_: InvoiceAuditProblemType | undefined) => void;
}

const ProblemName = styled.div``;
const ProblemDescription = styled.div`
  color: ${Colors.LightText};
  font-size: 11px;
  margin-top: -5px;
`;

export function InvoiceAuditProblemTypeDropdown(
  props: InvoiceAuditProblemTypeDropdownProps
) {
  return (
    <Select
      showSearch
      value={props.value}
      onChange={function (e) {
        props.setValue(e);
      }}
      style={{ width: "500px" }}
      placeholder="No problem selected"
      allowClear
    >
      {InvoiceAuditProblemTypes.map(function (group) {
        return (
          <Select.OptGroup label={group.name}>
            {group.items.map(function (item) {
              return (
                <Select.Option value={item.type}>
                  <Stack align="left">
                    <ProblemName>{item.name}</ProblemName>
                    <ProblemDescription>{item.description}</ProblemDescription>
                  </Stack>
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        );
      })}
    </Select>
  );
}

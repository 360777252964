import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import { useState } from "react";
import { useSupportEmailApi } from "../../Apis/Apis";
import { ButtonRow } from "../../Components/ButtonRow";
import { DataTable, DataTableColumn } from "../../Components/DataTable";
import { Weight } from "../../Components/Weight";
import {
  CarrierInvoice,
  CarrierInvoiceState,
  ViewShipmentDataForApollo,
} from "../../generated-openapi-client";
import { DimensionReportLineItem } from "../../generated-openapi-client/models/DimensionReportLineItem";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { HandlingUnitType } from "../../generated-openapi-client/models/HandlingUnitType";
import Spacer from "../../Spacer";
import { SupportAgent } from "../ViewCompanyScreen";
import { ExtraTextButton } from "./ExtraTextButton";
import {
  SupportEmailBodyExtraItem,
  SupportEmailButton,
} from "./SupportEmailButton";

interface AddReportLineItemButtonProps {
  onAdd: (_: DimensionReportLineItem) => void;
}

function AddReportLineItemButton(props: AddReportLineItemButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportLine, setReportLine] = useState<
    Partial<DimensionReportLineItem>
  >({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    if (reportLine.description === undefined || reportLine.description === "") {
      message.warning("Missing description");
      return;
    }

    if (reportLine.handlingUnitType === undefined) {
      message.warning("Needs handling unit type");
      return;
    }

    if (reportLine.numberHandlingUnits === undefined) {
      message.warning("Needs number handling units");
      return;
    }

    if (reportLine.totalWeight === undefined) {
      message.warning("Needs weight");
      return;
    }

    if (reportLine.length === undefined) {
      message.warning("Needs length");
      return;
    }

    if (reportLine.width === undefined) {
      message.warning("Needs width");
      return;
    }

    if (reportLine.height === undefined) {
      message.warning("Needs height");
      return;
    }

    setIsModalVisible(false);
    props.onAdd(reportLine as DimensionReportLineItem);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlingUnitTypes = [
    "Skid",
    "Pallet",
    "Bin",
    "Box",
    "Bundle",
    "Cage",
    "Crate",
    "Drum",
    "Reel",
    "Roll",
    "Other",
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Add Report Line Item"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose={true}
      >
        <Form
          style={{ width: "600px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 15, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Description">
            <Input
              value={reportLine.description}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  description: e.target.value,
                });
              }}
              width={300}
            />
          </Form.Item>
          <Form.Item label="Number Handling Units">
            <InputNumber
              value={reportLine.numberHandlingUnits}
              onChange={function (o) {
                setReportLine({
                  ...reportLine,
                  numberHandlingUnits: o,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Handling Unit Type">
            <Select
              value={reportLine.handlingUnitType}
              style={{ width: 120 }}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  handlingUnitType: e,
                });
              }}
            >
              {handlingUnitTypes.map(function (type) {
                return <Select.Option value={type}>{type}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Total Weight">
            <InputNumber
              value={reportLine.totalWeight}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  totalWeight: e,
                });
              }}
              addonAfter={`lb`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Length">
            <InputNumber
              value={reportLine.length}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  length: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Width">
            <InputNumber
              value={reportLine.width}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  width: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Height">
            <InputNumber
              value={reportLine.height}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  height: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Spacer height={8} />
      <Button onClick={showModal}>Add Report Line</Button>
    </>
  );
}

interface EditReportLineItemButtonProps {
  onEdit: (_: DimensionReportLineItem) => void;
  reportLineItem: DimensionReportLineItem;
}

function EditReportLineItemButton(props: EditReportLineItemButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportLine, setReportLine] = useState<DimensionReportLineItem>(
    props.reportLineItem
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
    props.onEdit(reportLine);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlingUnitTypes = [
    "Skid",
    "Pallet",
    "Bin",
    "Box",
    "Bundle",
    "Cage",
    "Crate",
    "Drum",
    "Reel",
    "Roll",
    "Other",
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Edit Report Line Item"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        destroyOnClose={true}
      >
        <Form
          style={{ width: "600px" }}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 15, offset: 1 }}
          layout="horizontal"
          colon={false}
        >
          <Form.Item label="Description">
            <Input
              value={reportLine.description}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  description: e.target.value,
                });
              }}
              width={300}
            />
          </Form.Item>
          <Form.Item label="Number Handling Units">
            <InputNumber
              value={reportLine.numberHandlingUnits}
              onChange={function (o) {
                setReportLine({
                  ...reportLine,
                  numberHandlingUnits: o,
                });
              }}
            />
          </Form.Item>
          <Form.Item label="Handling Unit Type">
            <Select
              value={reportLine.handlingUnitType}
              style={{ width: 120 }}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  handlingUnitType: e,
                });
              }}
            >
              {handlingUnitTypes.map(function (type) {
                return <Select.Option value={type}>{type}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Total Weight">
            <InputNumber
              value={reportLine.totalWeight}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  totalWeight: e,
                });
              }}
              addonAfter={`lb`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Length">
            <InputNumber
              value={reportLine.length}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  length: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Width">
            <InputNumber
              value={reportLine.width}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  width: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
          <Form.Item label="Height">
            <InputNumber
              value={reportLine.height}
              onChange={function (e) {
                setReportLine({
                  ...reportLine,
                  height: e,
                });
              }}
              addonAfter={`"`}
              width={400}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Spacer height={8} />
      <Button onClick={showModal}>Edit Report Line</Button>
    </>
  );
}

interface ReportLineItemsButtonProps {
  reportLineItems: DimensionReportLineItem[];
  setReportLineItems: (_: DimensionReportLineItem[]) => void;
}

function ReportLineItemsButton(props: ReportLineItemsButtonProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: DataTableColumn<DimensionReportLineItem>[] = [
    {
      title: "Handling Units",
      render: (o) => (
        <div>
          {o.numberHandlingUnits} x {o.handlingUnitType}
        </div>
      ),
    },
    {
      title: "Description",
      render: (o) => <div>{o.description}</div>,
    },
    {
      title: "Weight",
      render: (o) => <Weight>{o.totalWeight}</Weight>,
    },
    {
      title: "Dimensions",
      render: (o) => (
        <div>
          {o.length}" x {o.width}" x {o.height}"
        </div>
      ),
    },
    {
      title: "Actions",
      render: (o, rowIndex) => (
        <ButtonRow>
          <EditReportLineItemButton
            reportLineItem={o}
            onEdit={function (o) {
              props.setReportLineItems(
                props.reportLineItems.map(function (li, mapIndex) {
                  if (rowIndex === mapIndex) {
                    return o;
                  } else {
                    return li;
                  }
                })
              );
            }}
          />
        </ButtonRow>
      ),
    },
  ];


  return (
    <>
      {/* @ts-ignore */}
      <Modal
        title="Report Line Items"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
      >
        <DataTable
          pagination={false}
          columns={columns}
          data={props.reportLineItems}
        />
        <AddReportLineItemButton
          onAdd={function (o) {
            props.setReportLineItems([...props.reportLineItems, o]);
          }}
        />
      </Modal>
      <Button onClick={showModal}>Modify</Button>
    </>
  );
}

interface DimensionReportSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
  carrierInvoice: CarrierInvoice;
}

export function DimensionReportSupportEmailButton(
  props: DimensionReportSupportEmailButtonProps
) {
  const shipmentId = props.data.shipmentId;
  const shipment = props.data.shipment;

  function initialDimensionReportLineItems(): DimensionReportLineItem[] {
    return shipment.shipment.lineItems!!.map(function (li) {
      return {
        description: li.description!!,
        numberHandlingUnits: li.numberHandlingUnits!!,
        handlingUnitType:
          HandlingUnitType[
            li.handlingUnitType!! as keyof typeof HandlingUnitType
          ],
        totalWeight: li.numberHandlingUnits!! * li.weightPerHandlingUnit!!,
        length: li.length!!,
        width: li.width!!,
        height: li.height!!,
      };
    });
  }

  const carrierInvoice = props.carrierInvoice;
  const carrierInvoiceId = carrierInvoice.carrierInvoiceId!!;
  const createSupportEmailApi = useSupportEmailApi();
  const [reportLineItems, setReportLineItems] = useState<
    DimensionReportLineItem[]
  >(initialDimensionReportLineItems);
  const [reportLineItemsDirty, setReportLineItemsDirty] = useState(false);
  const [extraText, setExtraText] = useState("");

  if (carrierInvoice.invoiceState === CarrierInvoiceState.Voided) {
    return <></>;
  }

  const hash = JSON.stringify({
    carrierInvoiceId,
    reportLineItems,
    extraText,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewDimensionReportEmail({
        shipmentId,
        dimensionReportSupportEmailInput: {
          carrierInvoiceId,
          reportLineItems,
          extraText,
        },

        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    if (!reportLineItemsDirty) {
      message.warn(`Have not modified`);
      return false;
    }

    if (attachmentDocumentIds.length === 0) {
      message.warn(`No attachments. Please attach weight report`);
      return false;
    }

    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendDimensionReportEmail({
        shipmentId,
        dimensionReportSupportEmailInput: {
          carrierInvoiceId,
          reportLineItems,
          extraText,
        },
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Dimensions Report Email"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={
        <>
          <ExtraTextButton extraText={extraText} setExtraText={setExtraText} />
          <SupportEmailBodyExtraItem label="Report Line Items">
            <ReportLineItemsButton
              reportLineItems={reportLineItems}
              setReportLineItems={function (_reportLineItems) {
                setReportLineItemsDirty(true);
                setReportLineItems(_reportLineItems);
              }}
            />
          </SupportEmailBodyExtraItem>
        </>
      }
      shipmentData={props.data}
    />
  );
}

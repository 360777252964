import { message } from "antd";
import { useSupportEmailApi } from "../../Apis/Apis";
import { ViewShipmentDataForApollo } from "../../generated-openapi-client";
import { EmailDescription } from "../../generated-openapi-client/models/EmailDescription";
import { SupportAgent } from "../ViewCompanyScreen";
import { SupportEmailButton } from "./SupportEmailButton";

interface UploadCustomsDocsReminderSupportEmailButtonProps {
  data: ViewShipmentDataForApollo;
}

export function UploadCustomsDocsReminderSupportEmailButton(
  props: UploadCustomsDocsReminderSupportEmailButtonProps
) {
  const createSupportEmailApi = useSupportEmailApi();
  const shipmentId = props.data.shipmentId;

  const hash = JSON.stringify({
    shipmentId,
  });

  async function onFetchPreview(
    supportAgent: SupportAgent
  ): Promise<EmailDescription | undefined> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      return supportEmailApi.previewUploadCustomsDocsReminderEmail({
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
      });
    } catch (e) {
      message.error(`Oops. Something went wrong`);
    }
  }

  async function onSend(
    supportAgent: SupportAgent,
    attachmentDocumentIds: string[]
  ): Promise<boolean> {
    try {
      const supportEmailApi = await createSupportEmailApi();
      await supportEmailApi.sendUploadCustomsDocsReminderEmail({
        shipmentId,
        supportAgentFirstName: supportAgent.firstName,
        supportAgentLastName: supportAgent.lastName,
        attachmentDocumentIds,
      });
      return true;
    } catch (e) {
      message.error(`Oops. Something went wrong`);
      return false;
    }
  }

  return (
    <SupportEmailButton
      label="Upload Customs Docs Reminder"
      onFetchPreview={onFetchPreview}
      onSend={onSend}
      previewUpdateHash={hash}
      extraFormItems={<></>}
      shipmentData={props.data}
    />
  );
}
